import { BASE_URL_UNAUTHORISED } from '@/api'

export interface NewsItemInterface {
    id: number,
    imageUri: string,
    title: string,
    subTitle: string,
    url: string,
    listOrder: number
  }

export class NewsItem implements NewsItemInterface {
  id = 0
  imageUri = ''
  title = ''
  subTitle = ''
  url = ''
  listOrder = 0

  constructor (data: NewsItemInterface) {
    this.id = data.id
    this.imageUri = BASE_URL_UNAUTHORISED + 'profile/news-item-image/' + data.imageUri
    this.title = data.title
    this.subTitle = data.subTitle
    this.url = data.url
    this.listOrder = data.listOrder
  }

  imageExists () {
    return this.imageUri !== BASE_URL_UNAUTHORISED + 'profile/news-item-image/default'
  }
}
