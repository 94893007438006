import BaseAnalytics from './BaseAnalytics'
import Conversion from './Conversion'
import ScanLocation from './ScanLocation'
import TopLink from './TopLink'

interface SubProfileAnalyticsInterface {
    id: string
    name: string
    totalScansLifetime: number
    totalScansToday: number
    totalLinkClicksLifetime: number
    averageClickTime: number
    topLocations: ScanLocation[]
    topLinks: TopLink[]
    conversions: Conversion[]
}

export default class SubProfileAnalytics extends BaseAnalytics implements SubProfileAnalyticsInterface {
  id = ''
  name = ''
  topLinks = [] as Array<TopLink>
  conversions = [] as Array<Conversion>

  constructor (subProfileAnalytics?: SubProfileAnalytics) {
    super(subProfileAnalytics)
    if (subProfileAnalytics) {
      this.id = subProfileAnalytics.id
      this.name = subProfileAnalytics.name
      this.topLinks = subProfileAnalytics.topLinks.map(topLink => new TopLink(topLink))
      this.conversions = subProfileAnalytics.conversions.map(conversion => new Conversion(conversion))
    }
  }
}
