import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5685bdea"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "select-label",
  for: "select-input"
}
const _hoisted_2 = ["selected"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "select-container",
    style: _normalizeStyle(_ctx.cssVarsContainer)
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("select", {
      class: "select-wrapper",
      selected: this.internalValue,
      id: "select-tinput",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
      style: _normalizeStyle(_ctx.cssVarsComponent)
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.entries(this.options), ([k,v]) => {
        return (_openBlock(), _createElementBlock("option", { key: k }, _toDisplayString(v), 1))
      }), 128))
    ], 12, _hoisted_2), [
      [_vModelSelect, _ctx.value]
    ])
  ], 4))
}