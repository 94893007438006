<template>
    <div class="image-input-container d-flex flex-column align-items-center justify-content-center">
        <p class="image-input-text">Image</p>
        <input type="file" ref="imageSelect" hidden @change="onSelectImageEnd( $event )"/>
        <div class="d-flex align-items-center image-input-button-content" :style="cssVars" @click="onSelectImageStart()">
            <div class="d-flex flex-column justify-content-center image-input-icon-container">
                <img :src="require('@/assets/icons/svg/editor-image.svg')" alt="icon" class="image-input-icon">
            </div>
            <p class="image-input-button-text">Select</p>
        </div>
        <div class="d-flex align-items-center image-input-button-content" :style="cssVars" @click="onSelectImageStart()">
            <div class="d-flex flex-column justify-content-center image-input-icon-container">
                <img :src="require('@/assets/icons/svg/editor-upload.svg')" alt="icon" class="image-input-icon">
            </div>
            <p class="image-input-button-text">Upload</p>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'

import { Palette } from '@/theme'

export default defineComponent({
  name: 'ImageInput',
  props: {
    style: {
      type: Object,
      default: null
    }
  },
  computed: {
    cssVars () {
      return {
        ...this.style
      }
    }
  },
  methods: {
    onSelectImageStart () {
      this.$refs.imageSelect.click()
    },
    onSelectImageEnd (event : Event) {
      const target = event.target as HTMLInputElement
      if (target.files) {
        this.$emit('onSelect', target.files[0])
        target.value = ''
      }
    }
  }
})
</script>

<style scoped lang="scss">

  div.image-input-container {
    width: 126px;
    height: 126px;
    background: var(--White, #FFFFFF);
    border-radius: 6px;
  }

  div.image-input-button-content {
      cursor: pointer;
      width: 108px;
      height: 36px;
      border-radius: 8px;
      margin:  0 0 10px 0;
      padding: 0 0 0 16px;
      background: var(--Light-Grey, #F1F1F1);
  }

  div.image-input-icon-container {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  img.image-input-icon {
    width: 100%;
  }

  p.image-input-text {
    color: var(--Nearly-Black, #212529);
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    opacity: 0.5;
    margin: 8px 0 8px 0;
  }

  p.image-input-button-text {
    margin: 0px;
    color: var(--Nearly-Black, #212529);
    text-align: center;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 107.692% */
    opacity: 0.5;
  }
</style>
