interface TopQrInterface {
  id: string,
  name: string,
  totalScans: number,
  totalClicks: number
}

export default class TopQr implements TopQrInterface {
  id = ''
  name = ''
  totalScans = 0
  totalClicks = 0

  constructor (topQr?: TopQrInterface) {
    if (topQr) {
      this.id = topQr.id
      this.name = topQr.name
      this.totalScans = topQr.totalScans
      this.totalClicks = topQr.totalClicks
    }
  }
}
