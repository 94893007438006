import { SubProfileComponentTheme } from './SubProfileComponentTheme'
import { SubProfileDoubleBorderTheme } from './SubProfileDoubleBorderTheme'
import { SubProfileSplitButtonTheme } from './SubProfileSplitButtonTheme'

export interface SubProfileThemeInterface {
  layoutId: number,
  name: string,
  backgroundImage: string,
  backgroundColour: string,
  headerTheme: SubProfileDoubleBorderTheme,
  descriptionTheme: SubProfileComponentTheme,
  buttonTheme: SubProfileSplitButtonTheme
}

export class SubProfileTheme {
  layoutId = 0
  name = ''
  backgroundImage = ''
  backgroundColour = ''
  headerTheme = new SubProfileDoubleBorderTheme()
  descriptionTheme = new SubProfileComponentTheme()
  buttonTheme = new SubProfileSplitButtonTheme()

  constructor (theme?: SubProfileThemeInterface) {
    if (theme) {
      const { layoutId, name, backgroundImage, backgroundColour, headerTheme, descriptionTheme, buttonTheme } = theme
      this.layoutId = layoutId
      this.name = name
      this.backgroundImage = backgroundImage
      this.backgroundColour = backgroundColour
      this.headerTheme = new SubProfileDoubleBorderTheme(headerTheme)
      this.descriptionTheme = new SubProfileComponentTheme(descriptionTheme)
      this.buttonTheme = new SubProfileSplitButtonTheme(buttonTheme)
    }
  }
}
