export interface SubProfileComponentThemeInterface {
  backgroundColour: string,
  borderColour: string,
  textColour: string
}

export class SubProfileComponentTheme {
  backgroundColour = ''
  borderColour = ''
  textColour = ''

  constructor (theme?: SubProfileComponentThemeInterface) {
    if (theme) {
      const { backgroundColour, borderColour, textColour } = theme
      this.backgroundColour = backgroundColour
      this.borderColour = borderColour
      this.textColour = textColour
    }
  }
}
