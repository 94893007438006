<template>
    <EditorPanel
      :title="socialsSection.label"
      :active="socialsSection.active"
      :persistent="socialsSection.persistent"
      :styleMode="styleMode"
      @onSetActive="onSetActive"
      @onDelete="onDelete"
      @onToggleMode="onToggleMode"
      >
      <div v-if="!this.styleMode" class="d-flex flex-column">
      <SlickList axis="y" v-model:list="socials" @update:list="onChangeSocialOrder" :useDragHandle="true">
              <SlickItem v-for="(social, i) in socials" :key="social" :index="i">
                <div class="d-flex flex-direction-row social-container align-items-center">
                  <div class="social-icon-container">
                    <img class="social-icon-image" :src="require(`@/assets/${getIcon(social.type)}`)"/>
                  </div>
                  <p class="social-type-label">{{ social.type }}</p>
                  <div class="social-value-input-container">
                    <DebouncedTextInput
                      :value="social.value"
                      type="text"
                      placeholder="URL"
                      @update:value="(value: string) => $emit('onEditSocialsItem', social.id, value, socialsSection.id)"
                      />
                  </div>
                  <div class="social-delete-socials-container">
                    <CrossButton @click="() => $emit('onDeleteSocial', social.id, socialsSection.id)" :style="cssDeleteSocialButton"/>
                  </div>
                  <div class="social-grip-handle-container">
                    <span v-handle class="handle"><a href="#"><img class="grip-icon" :src="require('@/assets/icons/icon-grip-1.png')"></a></span>
                  </div>
                </div>
            </SlickItem>
        </SlickList>
        <div class="my-2 align-self-start">
            <FaIconLink
              icon="plus"
              size="md"
              text="Add item"
              :icon-container-style="cssAddIcon"
              :text-style="cssAddText"
              :text-uses-gradient="true"
              @click="() => $emit('onAddSocials')"
              />
          </div>
        </div>
          <div v-else class="d-flex flex-column socials-style-panel-content">
          <div class="d-flex socials-style-panel-row align-items-center">
            <p class="style-header d-flex flex-fill">Layout</p>
            <div class="d-flex">
              <StyleTextIconButton :icon="'style-layout-list'" :selected="styleList" @click="() => onEditLayout('list')">List</StyleTextIconButton>
              <StyleTextIconButton :icon="'style-layout-row'" :selected="!styleList" @click="() => onEditLayout('row')">Row</StyleTextIconButton>
            </div>
          </div>
          <div class="d-flex socials-style-panel-row align-items-center">
            <p class="style-header d-flex flex-fill">Style</p>
            <div class="d-flex align-items-center socials-style-panel-row-group-br" style="padding: 0 10px 0 2px">
              <StyleTextButton
                :selected="socialsSection.style.style == 'solid'"
                @click="() => onEditStyle('solid')"
                style="width: 94px; border-radius: 0">
                Solid
              </StyleTextButton>
              <StyleTextButton
                :selected="socialsSection.style.style == 'outline'"
                @click="() => onEditStyle('outline')"
                style="width: 94px; border-radius: 0; background-color: #FFFFFF; box-shadow: 0px 0px 0px 2px rgba(33, 37, 41, 0.20) inset">
                Outline
              </StyleTextButton>
              <StyleTextButton
                :selected="socialsSection.style.style == 'pill'"
                @click="() => onEditStyle('pill')"
                style="width: 94px; border-radius: 18px">
                Pill
              </StyleTextButton>
            </div>
            <div class="d-flex" style="padding: 0 0 0 2px">
              <StyleTextButton
                :selected="socialsSection.style.corners == 'sharp'"
                @click="() => onEditCorners('sharp')"
                style="width: 108px; border-radius: 0">
                Sharp Corners
              </StyleTextButton>
              <StyleTextButton
                :selected="socialsSection.style.corners == 'rounded'"
                @click="() => onEditCorners('rounded')"
                style="width: 135px">
                Rounded Corners
              </StyleTextButton>
            </div>
          </div>
          <div class="d-flex socials-style-panel-row align-items-center">
            <p class="style-header d-flex flex-fill">Size & Align</p>
            <div class="d-flex align-items-center socials-style-panel-row-group-br" style="padding: 0 10px 0 2px">
              <StyleTextButton
                :selected="socialsSection.style.size == 'sm'"
                @click="() => onEditSize('sm')"
                style="width: 30px; height: 30px">
                S
              </StyleTextButton>
              <StyleTextButton
                :selected="socialsSection.style.size == 'md'"
                @click="() => onEditSize('md')"
                style="width: 36px; height: 36px;">
                M
              </StyleTextButton>
              <StyleTextButton
                :selected="socialsSection.style.size == 'lg'"
                @click="() => onEditSize('lg')"
                style="width: 40px;">
                L
              </StyleTextButton>
            </div>
            <div class="d-flex align-items-center" style="padding: 0 10px 0 2px">
              <StyleIconButton :icon="'style-align-left'" :selected="socialsSection.style.align == 'left'" @click="() => onEditAlign('left')"/>
              <StyleIconButton :icon="'style-align-center'" :selected="socialsSection.style.align == 'center'" @click="() => onEditAlign('center')"/>
              <StyleIconButton :icon="'style-align-right'" :selected="socialsSection.style.align == 'right'" @click="() => onEditAlign('right')"/>
              <StyleIconButton :icon="'style-align-fill'" :selected="socialsSection.style.align == 'fill'" @click="() => onEditAlign('fill')"/>
            </div>
          </div>
          <div class="d-flex socials-style-panel-row align-items-center">
            <p class="style-header d-flex flex-fill">Logo Theme</p>
            <div class="d-flex align-items-center socials-style-panel-row-group-br" style="padding: 0 10px 0 2px">
              <SelectInput
                :options="logoThemeOptions"
                v-model="logoTheme"
                @update:value="onEditLogoTheme"
                />
            </div>
            <div class="d-flex align-items-center" style="padding: 0 0 0 10px">
              <ColourInput :label="'Custom Colour'" :value="socialsSection.style.logoColour" @onChangeColour="onEditLogoColour" :disabled="socialsSection.style.logoTheme === 'brand'"/>
            </div>
          </div>
          <div class="d-flex socials-style-panel-row align-items-center">
            <p class="style-header d-flex flex-fill">Block Colours</p>
            <div class="d-flex">
              <ColourInput :label="'Background'" :value="socialsSection.style.backgroundColour" @onChangeColour="onEditBackgroundColour"/>
            </div>
          </div>
          <div class="d-flex socials-style-panel-row align-items-center">
            <p class="style-header d-flex flex-fill">Block Spacing</p>
            <div class="d-flex ">
              <EditorNumInputComponent label="Left" :value="socialsSection.style.spacingLeft" @onChange="onEditSpacingLeft"/>
              <EditorNumInputComponent label="Top" :value="socialsSection.style.spacingTop" @onChange="onEditSpacingTop" style="margin-left: 10px"/>
              <EditorNumInputComponent label="Right" :value="socialsSection.style.spacingRight" @onChange="onEditSpacingRight" style="margin-left: 10px"/>
              <EditorNumInputComponent label="Bottom" :value="socialsSection.style.spacingBottom" @onChange="onEditSpacingBottom" style="margin-left: 10px"/>
            </div>
          </div>
        </div>
      </EditorPanel>
</template>

<script lang="ts">

import { defineComponent } from 'vue'

import ColourInput from '../input/ColourInput.vue'
import CrossButton from '../button/CrossButton.vue'
import DebouncedTextInput from '../input/DebouncedTextInput.vue'
import EditorNumInputComponent from '../input/EditorNumInputComponent.vue'
import EditorPanel from '@/components/editor/EditorPanel.vue'
import FaIconLink from '../button/FaIconLink.vue'
import SelectInput from '@/components/input/SelectInput.vue'
import StyleIconButton from '../button/style-panel/StyleIconButton.vue'
import StyleTextButton from '../button/style-panel/StyleTextButton.vue'
import StyleTextIconButton from '../button/style-panel/StyleTextIconButton.vue'

import SocialsSection from '@/models/qr-sections/SocialsSection'
import { SocialsItem } from '@/models/qr-sections/SocialsItem'
import { SlickItem, SlickList, HandleDirective } from 'vue-slicksort'
import { DETAIL_TYPES } from '@/constants'
import { Palette } from '@/theme'
import { SOCIAL_TYPE_TO_ICON, SOCIALS_LOGO_THEME_FROM_OPTIONS, SOCIALS_LOGO_THEME_TO_OPTIONS } from '@/constants/constants'

export default defineComponent({
  name: 'SocialsPanel',
  components: {
    ColourInput,
    CrossButton,
    DebouncedTextInput,
    EditorNumInputComponent,
    EditorPanel,
    FaIconLink,
    SelectInput,
    SlickItem,
    SlickList,
    StyleIconButton,
    StyleTextButton,
    StyleTextIconButton
  },
  props: {
    socialsSection: {
      type: SocialsSection,
      required: true
    }
  },
  data () {
    return {
      socials: this.socialsSection.socials,
      drag: false,
      styleMode: false,
      logoTheme: SOCIALS_LOGO_THEME_TO_OPTIONS[this.socialsSection.style.logoTheme].value,
      logoThemeOptions: [
        'Brand Colours',
        'Custom'
      ]
    }
  },
  computed: {
    cssVarsContainer () {
      return {
        ...this.containerStyle
      }
    },
    cssAddIcon () {
      return {
        backgroundImage: Palette.gradientBP,
        width: '1.95em',
        borderRadius: '0.975em',
        padding: '0.5em',
        color: Palette.white
      }
    },
    cssAddText () {
      return {
        backgroundImage: Palette.gradientBP,
        backgroundClip: 'text',
        backgroundSize: '100%',
        textFillColor: 'transparent'
      }
    },
    cssDeleteSocialButton () {
      return {
        width: '30px',
        height: '30px'
      }
    },
    styleList () {
      return this.socialsSection.style.layout === 'list'
    }
  },
  directives: { handle: HandleDirective },
  watch: {
    socialSection (socialSection) {
      if (socialSection) this.socials = socialSection.socials
    }
  },
  methods: {
    onToggleMode (emit = true) {
      this.styleMode = !this.styleMode
      if (emit) this.$emit('onToggleMode', this.socialsSection.id, this.styleMode)
    },
    getIcon (type: string): string {
      if (this.socialsSection) return SOCIAL_TYPE_TO_ICON[type].value
      return SOCIAL_TYPE_TO_ICON.default.value
    },
    onSetActive (value: boolean) {
      this.$emit('onEdit', this.socialsSection.id, this.socialsSection.label, this.socialsSection.listOrder, value)
    },
    onChangeSocialOrder (socials : Array<SocialsItem>) {
      this.drag = false
      this.$emit('onChangeSocialsItemOrder', this.socialsSection.id, socials)
    },
    onDelete () {
      this.$emit('onDelete', this.socialsSection.id)
    },
    onEditLayout (value: string) {
      if (value === this.socialsSection.style.layout) return
      this.$emit(
        'onEditStyle',
        this.socialsSection.id,
        value,
        this.socialsSection.style.style,
        this.socialsSection.style.corners,
        this.socialsSection.style.size,
        this.socialsSection.style.align,
        this.socialsSection.style.logoTheme,
        this.socialsSection.style.logoColour,
        this.socialsSection.style.backgroundColour,
        this.socialsSection.style.spacingLeft,
        this.socialsSection.style.spacingTop,
        this.socialsSection.style.spacingRight,
        this.socialsSection.style.spacingBottom
      )
    },
    onEditStyle (value: string) {
      if (value === this.socialsSection.style.style) return
      this.$emit(
        'onEditStyle',
        this.socialsSection.id,
        this.socialsSection.style.layout,
        value,
        this.socialsSection.style.corners,
        this.socialsSection.style.size,
        this.socialsSection.style.align,
        this.socialsSection.style.logoTheme,
        this.socialsSection.style.logoColour,
        this.socialsSection.style.backgroundColour,
        this.socialsSection.style.spacingLeft,
        this.socialsSection.style.spacingTop,
        this.socialsSection.style.spacingRight,
        this.socialsSection.style.spacingBottom
      )
    },
    onEditCorners (value: string) {
      if (value === this.socialsSection.style.corners) return
      this.$emit(
        'onEditStyle',
        this.socialsSection.id,
        this.socialsSection.style.layout,
        this.socialsSection.style.style,
        value,
        this.socialsSection.style.size,
        this.socialsSection.style.align,
        this.socialsSection.style.logoTheme,
        this.socialsSection.style.logoColour,
        this.socialsSection.style.backgroundColour,
        this.socialsSection.style.spacingLeft,
        this.socialsSection.style.spacingTop,
        this.socialsSection.style.spacingRight,
        this.socialsSection.style.spacingBottom
      )
    },
    onEditSize (value: string) {
      if (value === this.socialsSection.style.size) return
      this.$emit(
        'onEditStyle',
        this.socialsSection.id,
        this.socialsSection.style.layout,
        this.socialsSection.style.style,
        this.socialsSection.style.corners,
        value,
        this.socialsSection.style.align,
        this.socialsSection.style.logoTheme,
        this.socialsSection.style.logoColour,
        this.socialsSection.style.backgroundColour,
        this.socialsSection.style.spacingLeft,
        this.socialsSection.style.spacingTop,
        this.socialsSection.style.spacingRight,
        this.socialsSection.style.spacingBottom
      )
    },
    onEditAlign (value: string) {
      if (value === this.socialsSection.style.align) return
      this.$emit(
        'onEditStyle',
        this.socialsSection.id,
        this.socialsSection.style.layout,
        this.socialsSection.style.style,
        this.socialsSection.style.corners,
        this.socialsSection.style.size,
        value,
        this.socialsSection.style.logoTheme,
        this.socialsSection.style.logoColour,
        this.socialsSection.style.backgroundColour,
        this.socialsSection.style.spacingLeft,
        this.socialsSection.style.spacingTop,
        this.socialsSection.style.spacingRight,
        this.socialsSection.style.spacingBottom
      )
    },
    onEditLogoTheme (value: string) {
      value = SOCIALS_LOGO_THEME_FROM_OPTIONS[value].value
      console.log('premit ' + value)
      if (value === this.socialsSection.style.logoTheme) return
      this.$emit(
        'onEditStyle',
        this.socialsSection.id,
        this.socialsSection.style.layout,
        this.socialsSection.style.style,
        this.socialsSection.style.corners,
        this.socialsSection.style.size,
        this.socialsSection.style.align,
        value,
        this.socialsSection.style.logoColour,
        this.socialsSection.style.backgroundColour,
        this.socialsSection.style.spacingLeft,
        this.socialsSection.style.spacingTop,
        this.socialsSection.style.spacingRight,
        this.socialsSection.style.spacingBottom
      )
    },
    onEditLogoColour (value: string) {
      if (value === this.socialsSection.style.logoColour) return
      this.$emit(
        'onEditStyle',
        this.socialsSection.id,
        this.socialsSection.style.layout,
        this.socialsSection.style.style,
        this.socialsSection.style.corners,
        this.socialsSection.style.size,
        this.socialsSection.style.align,
        this.socialsSection.style.logoTheme,
        value,
        this.socialsSection.style.backgroundColour,
        this.socialsSection.style.spacingLeft,
        this.socialsSection.style.spacingTop,
        this.socialsSection.style.spacingRight,
        this.socialsSection.style.spacingBottom
      )
    },
    onEditBackgroundColour (value: string) {
      if (value === this.socialsSection.style.backgroundColour) return
      this.$emit(
        'onEditStyle',
        this.socialsSection.id,
        this.socialsSection.style.layout,
        this.socialsSection.style.style,
        this.socialsSection.style.corners,
        this.socialsSection.style.size,
        this.socialsSection.style.align,
        this.socialsSection.style.logoTheme,
        this.socialsSection.style.logoColour,
        value,
        this.socialsSection.style.spacingLeft,
        this.socialsSection.style.spacingTop,
        this.socialsSection.style.spacingRight,
        this.socialsSection.style.spacingBottom
      )
    },
    onEditSpacingLeft (value: number) {
      if (value === this.socialsSection.style.spacingLeft) return
      this.$emit(
        'onEditStyle',
        this.socialsSection.id,
        this.socialsSection.style.layout,
        this.socialsSection.style.style,
        this.socialsSection.style.corners,
        this.socialsSection.style.size,
        this.socialsSection.style.align,
        this.socialsSection.style.logoTheme,
        this.socialsSection.style.logoColour,
        this.socialsSection.style.backgroundColour,
        value,
        this.socialsSection.style.spacingTop,
        this.socialsSection.style.spacingRight,
        this.socialsSection.style.spacingBottom
      )
    },
    onEditSpacingTop (value: number) {
      if (value === this.socialsSection.style.spacingTop) return
      this.$emit(
        'onEditStyle',
        this.socialsSection.id,
        this.socialsSection.style.layout,
        this.socialsSection.style.style,
        this.socialsSection.style.corners,
        this.socialsSection.style.size,
        this.socialsSection.style.align,
        this.socialsSection.style.logoTheme,
        this.socialsSection.style.logoColour,
        this.socialsSection.style.backgroundColour,
        this.socialsSection.style.spacingLeft,
        value,
        this.socialsSection.style.spacingRight,
        this.socialsSection.style.spacingBottom
      )
    },
    onEditSpacingRight (value: number) {
      if (value === this.socialsSection.style.spacingRight) return
      this.$emit(
        'onEditStyle',
        this.socialsSection.id,
        this.socialsSection.style.layout,
        this.socialsSection.style.style,
        this.socialsSection.style.corners,
        this.socialsSection.style.size,
        this.socialsSection.style.align,
        this.socialsSection.style.logoTheme,
        this.socialsSection.style.logoColour,
        this.socialsSection.style.backgroundColour,
        this.socialsSection.style.spacingLeft,
        this.socialsSection.style.spacingTop,
        value,
        this.socialsSection.style.spacingBottom
      )
    },
    onEditSpacingBottom (value: number) {
      if (value === this.socialsSection.style.spacingBottom) return
      this.$emit(
        'onEditStyle',
        this.socialsSection.id,
        this.socialsSection.style.layout,
        this.socialsSection.style.style,
        this.socialsSection.style.corners,
        this.socialsSection.style.size,
        this.socialsSection.style.align,
        this.socialsSection.style.logoTheme,
        this.socialsSection.style.logoColour,
        this.socialsSection.style.backgroundColour,
        this.socialsSection.style.spacingLeft,
        this.socialsSection.style.spacingTop,
        this.socialsSection.style.spacingRight,
        value
      )
    }
  }
})
</script>

<style scoped>

div.social-container {
  border-radius: 8px;
  background: var(--Light-Grey, #F1F1F1);
  width: 100%;
  padding: 12px;
}

div.social-icon-container {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid rgba(33, 37, 41, 0.15);
  background: var(--White, #FFF);
  margin: 0 10px 0 0;
}
img.social-icon-image {
  width: 20px;
  height: 20px;
}
p.social-type-label {
  color: var(--Nearly-Black, #212529);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  margin: 0 29px 0 0;
  text-align: left;
  text-transform: capitalize;
  width: 20%;
}

div.social-container{
  margin: 10px 0 10px 0;
}

div.social-value-input-container {
  display: flex;
  flex-grow: 1;
}

div.social-delete-socials-container {
  width: 40px;
  margin: 0 10px 0 10px;
}

div.social-grip-handle-container {
  width: 10px;
  margin: 0 8px 0 0;
}

img.grip-icon {
  height: 16.8px;
  width: 10px;
}

div.socials-style-panel-content {
    flex-shrink: 0;
    width: 100%;
    padding: 10px;
  }

  div.socials-style-panel-row {
    width: 100%;
    height: 60px;
    border-top: 1px solid rgba(33, 37, 41, 0.1);
  }

  div.socials-style-panel-row-group-br {
    height: 100%;
    border-right: 1px solid rgba(33, 37, 41, 0.1);
  }

  p.style-header {
    color: var(--Nearly-Black, #212529);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    opacity: 0.5;
    margin: 0px;
  }

</style>
