<template >
    <div v-if="profile && colorScheme" id="container">
        <img id="avatar" :src="avatar">
        <div id="content">
            <div id="icon-label">
                <IconLabel :text="profile.keypin" image="icons/main/icon.png" :color="colorScheme.foreground"/>
            </div>
            <div style="height: 85%; width: 100%">
                <p :style="cssVars">{{ profile.firstName + " " + profile.lastName}}</p>
                <div style="height: 25%; width: 100%;">
                  <!---<ProfileDetailButton v-for="detail, idx in profile.getProfileDetails()" :key="idx" :detail="detail" />-->
                  <a href="google.co.uk"><img class="pdtemp" :src="require(`@/assets/icons/details/core/icon_website.png`)"></a>
                  <a href="google.co.uk"><img class="pdtemp" :src="require(`@/assets/icons/details/socials/icon_facebook.png`)"></a>
                  <a href="google.co.uk"><img class="pdtemp" :src="require(`@/assets/icons/details/services/icon_amazon.png`)"></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import IconLabel from '@/components/label/IconLabel.vue'
import ProfileDetailButton from '../button/ProfileDetailButton.vue'

import Profile from '../../models/Profile'

import { DEFAULT_AVATAR } from '@/constants'

export default defineComponent({
  name: 'RootProfile',
  components: {
    IconLabel
  },
  props: {
    profile: Profile
  },
  computed: {
    avatar () {
      return require(`@/assets/${DEFAULT_AVATAR}`)
    },
    cssVars () {
      return {
        '--color': '#000000'
      }
    }
  }
})
</script>

<style scoped lang="scss">
    #container{
        width: 100%;
        height: 200px;
        flex-direction: "row";
        display: inline-block;
        padding: 1%;
        margin-top: 5%;
    }
    #content{
        float: right;
        width: 58%;
        height: 100%;
        text-align: left;
        padding-left: 1%;
    }
    #icon-label{
        height: 25%;
        width: 100%;
        margin-bottom: 5%;
    }
    #avatar{
        width: 40%;
        aspect-ratio: 1 / 1;
        float: left;
    }
    p{
        color: var(--color)
    }
    .pdtemp{
        width: 20%;
        aspect-ratio: 1 / 1;
        margin-right: 3%;
        margin-top: 3%;
        transition: all .2s ease-in-out;
    }
    .pdtemp:hover{
        transform: scale(1.1);
    }
</style>
