import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "fa-icon-button-wrapper",
    style: _normalizeStyle(_ctx.cssVars)
  }, [
    _createVNode(_component_fa_icon, {
      class: _normalizeClass(_ctx.faIconClass),
      icon: _ctx.faIconIcon
    }, null, 8, ["class", "icon"])
  ], 4))
}