import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0eeb9176"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "image-container d-flex justify-content-center py-5"
}
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.image)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("img", {
          class: "image",
          src: _ctx.image.uri,
          crossorigin: "anonymous"
        }, null, 8, _hoisted_2)
      ]))
    : _createCommentVNode("", true)
}