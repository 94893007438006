<template>
      <div class="detail-button-container" :style="cssVars" @click=" onClick()">
        <div class="button-image-container">
          <div style="height: 25%; width: 100%"/>
          <img :src="require(`@/assets/${icon}`)"/>
        </div>
        <div class="button-text-container">
          <div style="height: 25%; width: 100%"/>
          <p>{{ text }}</p>
        </div>
      </div>
</template>

<script lang="ts">
import { DETAIL_TYPES } from '@/constants/constants'
import { Detail } from '@/models/Detail'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'DetailButtonSlant',
  props: {
    detail: Detail,
    leftColour: String,
    rightColour: String,
    textColour: String,
    closable: Boolean
  },
  methods: {
    onClick () {
      const container = document.getElementById('main-container')
      this.$emit('onClick', this.detail?.id)
    }
  },
  computed: {
    icon () {
      if (this.closable) {
        return 'icons/main/icon_cross_white.png'
      } else {
        return this.detail ? DETAIL_TYPES[this.detail.type].icon : 'icons/main/icon.png'
      }
    },
    text () {
      if (this.closable) {
        return 'Close ' + (this.detail ? this.detail.type : '')
      } else {
        return this.detail ? this.detail.label : ''
      }
    },
    cssVars () {
      return {
        '--left-color': this.closable ? '#993333' : this.leftColour,
        '--right-color': this.rightColour,
        '--text-color': this.textColour,
        '--border-width': 0,
        '--height': '50px',
        '--slant-width': '5px'
      }
    }
  }
})
</script>

<style scoped lang="scss">
    .detail-button-container{
        border-radius: 20px;
        width: 100%;
        height: var(--height);
        border-width: var(--border-width);
        color: var(--text-color);
        overflow: hidden;
        transition: all .2s ease-in-out;
        /*background: linear-gradient(
          to bottom right,
          var(--left-color) 0%,
          var(--left-color) 20%,
          #000 20%,
          #000 25%
          var(--right-color) 25%,
          var(--right-color) 100%,
        );*/
    }
    @media (hover: hover) and (pointer: fine) {
      .detail-button-container:hover{
        transform: scale(1.1);
      }
    }

    .detail-button-container:active{
      background-color: aqua;
      transform: scale(1.05);
      transition: .2s;
    }

    .button-image-container2, .button-text-container2 {
      position: relative;
      height: 100%;
      float: left;
    }
    .button-image-container2 {
      background: transparent;
      z-index: 1;
      width: 20%;
      align-items: center;
    }

    .button-text-container2 {
      background: transparent;
      width: 80%;
      padding-left: 20%;
    }

    .button-image-container, .button-text-container {
      position: relative;
      height: 100%;
      float: left;
      backface-visibility: hidden;
    }
    .button-image-container {
      background: var(--left-color);
      z-index: 1;
      width: 20%;
      align-items: center;
    }

    .button-text-container {
      background: var(--right-color);
      width: 80%;
      padding-left: 20%;
    }

    .button-image-container:after {
      content:'';
      position:absolute;
      border-right:var(--slant-width) solid #fff;
      top: -6em;
      bottom: 0px;
      left:0;
      right: -3em;
      transform: rotate(40deg);
      background: var(--left-color);
      z-index: -1;
    }

    img{
        height: 55%;
        aspect-ratio: 1 / 1;
        margin-left: 25%;
    }

    p{
        font-size: 14px;
        color: var(--color);
        height: 100%;
        width: 100%;
        flex-direction: row;
        margin-bottom: 0px;
    }
</style>
