import axios from 'axios'

export const BASE_URL = 'https://www.keypin.tech:8000/api/'
export const BASE_URL_UNAUTHORISED = 'https://www.keypin.tech:8000/'

export function authorisedGet (
  url : string,
  token : string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback : () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void) {
  console.log('will fetch ' + url)
  axios(
    { method: 'get', url: BASE_URL + url, withCredentials: false, headers: { Authorization: `bearer ${token}` } })
    .then((response) => {
      callback(response)
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        if (error.response.status === 401 || error.response.status === 403) {
          unauthorisedCallback()
        } else if (error.response.status === 404) {
          notFoundCallback()
        } else if (error.response.status === 400) {
          badRequestCallback()
        } else if (error.response.status === 500) {
          serverErrorCallback()
        }
      } else {
        console.log(error)
      }
    })
}

export function authorisedGetBlob (
  url : string,
  token : string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback : () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void) {
  axios(
    { method: 'get', url: BASE_URL + url, withCredentials: false, responseType: 'blob', headers: { Authorization: `bearer ${token}` } })
    .then((response) => {
      callback(response)
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        if (error.response.status === 401 || error.response.status === 403) {
          unauthorisedCallback()
        } else if (error.response.status === 404) {
          notFoundCallback()
        } else if (error.response.status === 400) {
          badRequestCallback()
        } else if (error.response.status === 500) {
          serverErrorCallback()
        }
      } else {
        console.log(error)
      }
    })
}

export function getBlob (
  url : string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback : () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void) {
  axios(
    { method: 'get', url: BASE_URL_UNAUTHORISED + url, withCredentials: false, responseType: 'blob' })
    .then((response) => {
      callback(response)
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        if (error.response.status === 401 || error.response.status === 403) {
          unauthorisedCallback()
        } else if (error.response.status === 404) {
          notFoundCallback()
        } else if (error.response.status === 400) {
          badRequestCallback()
        } else if (error.response.status === 500) {
          serverErrorCallback()
        }
      } else {
        console.log(error)
      }
    })
}

export function authorisedPost (
  url : string,
  data: any,
  token : string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  console.log('will post ' + JSON.stringify(data))
  console.log('to ' + BASE_URL + url)
  axios(
    { method: 'post', url: BASE_URL + url, data: data, withCredentials: false, headers: { Authorization: `bearer ${token}` } })
    .then((response) => {
      callback(response)
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        if (error.response.status === 401 || error.response.status === 403) {
          unauthorisedCallback()
        } else if (error.response.status === 404) {
          notFoundCallback()
        } else if (error.response.status === 400) {
          badRequestCallback()
        } else if (error.response.status === 500) {
          serverErrorCallback()
        }
      } else {
        console.log(error)
      }
    })
}

export function get (
  url : string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  console.log('will get: ' + BASE_URL_UNAUTHORISED + url)
  axios(
    { method: 'get', url: BASE_URL_UNAUTHORISED + url, withCredentials: false })
    .then((response) => {
      callback(response)
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        if (error.response.status === 401 || error.response.status === 403) {
          unauthorisedCallback()
        } else if (error.response.status === 404) {
          notFoundCallback()
        } else if (error.response.status === 400) {
          badRequestCallback()
        } else if (error.response.status === 500) {
          serverErrorCallback()
        }
      } else {
        console.log(error)
      }
    })
}

export function post (url : string,
  data: any,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  axios(
    { method: 'post', url: url, data: data, withCredentials: false })
    .then((response) => {
      callback(response)
    }).catch((error) => {
      console.log(error.response)
      if (error.response.status === 401 || error.response.status === 403) {
        unauthorisedCallback()
      } else if (error.response.status === 404) {
        notFoundCallback()
      } else if (error.response.status === 400) {
        badRequestCallback()
      } else if (error.response.status === 500) {
        serverErrorCallback()
      }
    })
}
