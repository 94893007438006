<template>
     <div class="form-check form-switch" :style="cssVars">
        <input type="checkbox" class="form-check-input" id="customSwitch1" v-model="value">
        <label class="check-label" for="customSwitch1">{{ this.label }}</label>
    </div>
</template>

<script lang="ts">

import { defineComponent } from 'vue'

import 'vue-loading-overlay/dist/css/index.css'

import { Palette } from '@/theme'

export default defineComponent({
  name: 'SwitchComponent',
  props: {
    label: {
      type: String
    },
    backColor: {
      type: String,
      default: '#65C372'
    },
    initialValue: {
      type: Boolean,
      default: false
    },
    containerStyle: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      modelValue: this.initialValue
    }
  },
  emits: ['update:modelValue'],
  computed: {
    value: {
      get () {
        return this.modelValue
      },
      set (value : boolean) {
        this.modelValue = value
        this.$emit('update:modelValue', value)
      }
    },
    cssVars () {
      return {
        '--back-color': this.backColor,
        ...this.containerStyle
      }
    }
  }
})
</script>

<style scoped>

label.check-label {
    font-size: 0.8rem;
    opacity: 0.9;
    float: right;
}

.form-check-input {
  --ct-form-check-bg: var(--ct-secondary-bg);
  width: 40px;
  height: 20px;
  flex-shrink: 0;
  vertical-align: top;
  background-color: #21252933;
  background-image: url('../../assets/components/Toggle.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  -webkit-print-color-adjust: exact;
          print-color-adjust: exact;
  -webkit-transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-check-input {
    -webkit-transition: none;
    transition: none;
  }
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  -webkit-filter: brightness(90%);
          filter: brightness(90%);
}
.form-check-input:focus {
  border-color: var(--ct-border-color);
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.form-check-input:checked {
  background-color: var(--back-color);
  border-color: var(--back-color);
}
.form-check-input:checked[type=checkbox] {
  --ct-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  --ct-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #6658dd;
  border-color: #6658dd;
  --ct-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  -webkit-filter: none;
          filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-right: 2.5em;
}
.form-switch .form-check-input {
  --ct-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  width: 3em;
  margin-right: -2.5em;
  background-image: var(--ct-form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  -webkit-transition: background-position 0.15s ease-in-out;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    -webkit-transition: none;
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  --ct-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='var%28--ct-border-color%29'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  --ct-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
</style>
