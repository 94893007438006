import { authorisedPost } from './Api'

export function editSocialsSectionStyle (
  subProfileId: string,
  id: number,
  layout: string,
  style: string,
  corners: string,
  size: string,
  align: string,
  logoTheme: string,
  logoColour: string,
  backgroundColour: string,
  spacingLeft: number,
  spacingTop: number,
  spacingRight: number,
  spacingBottom: number,
  token : string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  const data = {
    layout,
    style,
    corners,
    size,
    align,
    logoTheme,
    logoColour,
    backgroundColour,
    spacingLeft,
    spacingTop,
    spacingRight,
    spacingBottom

  }
  authorisedPost(`dashboard/${subProfileId}/edit-socials-style?section=${id}`, data, token, unauthorisedCallback, badRequestCallback, serverErrorCallback, notFoundCallback, callback)
}
