<template>
    <div class="editor-panel-container" :style="cssVarsContainer">
      <div class="d-flex flex-row align-items-center">
        <span v-handle class="handle"><a href="#"><img class="grip-icon" :src="require('@/assets/icons/icon-grip-1.png')"></a></span>
        <h4 class="label-text">{{  title }}</h4>
        <div class="controls-container d-flex flex-row align-items-center">
          <FaIconButton
            v-if="!this.persistent"
            icon="trash"
            @click="() => $emit('onDelete')"
            :style="cssDeleteSectionButton"
            />
          <SwitchComponent :initialValue="isActive" :v-model="isActive" @update:modelValue="(value: boolean) => $emit('onSetActive', value)"/>
          <EditorToggle :styleMode="this.styleMode" style="margin-left: 10px" @click="() => $emit('onToggleMode')"/>
        </div>
      </div>
      <slot />
    </div>
</template>

<script lang="ts">

import { defineComponent } from 'vue'

import EditorToggle from '../switch/EditorToggle.vue'
import SwitchComponent from '../switch/Switch.vue'
import { HandleDirective } from 'vue-slicksort'
import FaIconButton from '../button/FaIconButton.vue'
import { Palette } from '@/theme'

export default defineComponent({
  name: 'SectionPanel',
  components: {
    EditorToggle,
    SwitchComponent,
    FaIconButton
  },
  props: {
    title: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      required: true
    },
    persistent: {
      type: Boolean,
      required: true
    },
    styleMode: {
      type: Boolean,
      required: true
    },
    containerStyle: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      isActive: this.active
    }
  },
  directives: { handle: HandleDirective },
  computed: {
    cssVarsContainer () {
      return {
        ...this.containerStyle
      }
    },
    cssDeleteSectionButton () {
      return {
        width: '35px',
        height: '35px',
        backgroundColor: 'transparent',
        color: Palette.neutral800
      }
    }
  }
})
</script>

<style scoped>

div.editor-panel-container {
  display: flex;
  flex-direction: column;
  background-color: white;
  margin-bottom: 20px;
  padding: 0.75em 1em 0.75em 1em;
  border-radius: 1.5em;
}

img.grip-icon {
  height: 1.5em;
  aspect-ratio: 0.75;
  margin-right: 0.75em;
}

div.controls-container {
  margin-left: auto;
}

</style>
