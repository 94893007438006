<template >
    <div v-if="header" class="header-container d-flex flex-column align px-2 py-3" :style="cssVars">
      <p class="header-pretitle-text" >{{ header.preTitle }}</p>
      <p class="header-title-text" >{{ header.title }}</p>
      <p class="header-subtitle-text" >{{ header.subTitle }}</p>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import HeaderSection from '../../models/qr-sections/HeaderSection'

export default defineComponent({
  name: 'HeaderSection',
  props: {
    header: HeaderSection
  },
  computed: {
    avatar () {
      return this.subProfile?.image.imageUri
    },
    cssVars () {
      return {
        '--title-color': '#000000',
        '--pre-title-color': '#00FF00',
        '--sub-title-color': '#787878'
      }
    }
  }
})
</script>

<style scoped lang="scss">
    div.header-container {
        height: 150px;
        background-color: transparent;
    }

    p {
      height: 6em;
      margin: 0.1em 0 0.1em 0;
    }
    p.header-pretitle-text {
      font-size: small;
      color: var(--pre-title-color);
    }

    p.header-title-text {
      font-size: large;
      font-weight: bold;
      color: var(--title-color);
    }

    p.header-subtitle-text {
      font-size: small;
      color: var(--sub-title-color);
    }
</style>
