<template>
    <div v-if="newsSection" class="news-body" :style="cssVarsContainer">
        <p v-if="newsSection.labelVisible" class="label-text" :style="cssVarsText">{{ newsSection.label }}</p>
        <div v-for="(item, i) in newsSection.items" :key="item.id" :index="i" class="news-item" :style="cssVarsItem" @click="() => onClick(item)">
            <img class="news-image" :src="item.imageUri" />
            <div class="news-text-container">
                <p class="news-title" :style="cssVarsText">{{ item.title }}</p>
                <p class="news-sub-title" :style="cssVarsText">{{ item.subTitle }}</p>
            </div>
            <IconChevronNewsSectionSvg :colour="newsSection.style.textColour" />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import { NewsItem, NewsItemInterface } from '@/models/qr-sections/NewsItem'
import NewsSectionModel from '@/models/qr-sections/NewsSection'

import IconChevronNewsSectionSvg from '@/components/svg/qr-section/IconChevronNewsSectionSvg.vue'

export default defineComponent({
  name: 'NewsSection',
  components: {
    IconChevronNewsSectionSvg
  },
  props: {
    newsSection: NewsSectionModel
  },
  computed: {
    cssVarsContainer () {
      return {
        '--background': this.newsSection.style.backgroundColour
      }
    },
    cssVarsItem () {
      return {
        background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.05) 100%), ' + this.newsSection.style.backgroundColour
      }
    },
    cssVarsText () {
      return {
        '--text-align': this.newsSection.style.align,
        '--colour': this.newsSection.style.textColour
      }
    }
  },
  methods: {
    onClick (newsItem: NewsItem) {
      window.open(newsItem.url, '_blank')
      this.$emit('onPushClick', newsItem.id)
    }
  }
})
</script>

<style scoped lang="scss">

  div.news-body {
    display: flex;
    flex-direction: column;
    padding: 20px 30px 55px 30px;
    background-color: var(--background);
    width: 111%;
    margin: 30px 0 30px 0;
  }

  p.label-text {
    color: var(--colour);
    text-align: var(--text-align);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 153.846% */
    opacity: 0.5;
    margin: 0;
  }

  div.news-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    cursor: pointer;
    overflow: hidden;
    border-radius: 10px;
    border: 0.5px solid rgba(255, 255, 255, 0.20);
    aspect-ratio: 3.1 / 1.25;
    padding: 0 15px 0 0;
  }
  img.news-image {
    height: 100%;
    flex-shrink: 1;
    aspect-ratio: 1 / 1.25;
  }
  div.news-text-container {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    margin: 0 0 0 8%;
  }
  p.news-title {
    color: var(--colour);
    text-align: var(--text-align);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    margin: 0;
  }
  p.news-sub-title{
    color: var(--colour);
    text-align: var(--text-align);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
    margin: 0;
    opacity: 0.5;
  }
</style>
