import { SocialsItem, SocialsItemInterface } from './SocialsItem'

interface SocialsSectionStyleInterface {
    layout: string,
    style: string,
    corners: string,
    size: string,
    align: string,
    logoTheme: string,
    logoColour: string,
    backgroundColour: string,
    spacingLeft: number,
    spacingTop: number,
    spacingRight: number,
    spacingBottom: number,
  }

interface SocialsSectionInterface {
    id: number
    label: string
    listOrder: number,
    active: boolean,
    persistent: boolean,
    labelVisible: boolean,
    socials: SocialsItemInterface[],
    style: SocialsSectionStyleInterface
}

class SocialsSectionStyle implements SocialsSectionStyleInterface {
  layout = ''
  style = ''
  corners = ''
  size = ''
  align = ''
  logoTheme = ''
  logoColour = ''
  backgroundColour = ''
  spacingLeft = 0
  spacingTop = 0
  spacingRight = 0
  spacingBottom = 0

  constructor (style?: SocialsSectionStyleInterface) {
    if (style) {
      this.layout = style.layout
      this.style = style.style
      this.corners = style.corners
      this.size = style.size
      this.align = style.align
      this.logoTheme = style.logoTheme
      this.logoColour = style.logoColour
      this.backgroundColour = style.backgroundColour
      this.spacingLeft = style.spacingLeft
      this.spacingTop = style.spacingTop
      this.spacingRight = style.spacingRight
      this.spacingBottom = style.spacingBottom
    }
  }
}

export default class SocialsSection implements SocialsSectionInterface {
  id = 0
  label = ''
  listOrder = 0
  active = false
  persistent = false
  labelVisible = false
  socials = Array<SocialsItemInterface>()
  style = new SocialsSectionStyle()

  constructor (socialsSection?: SocialsSectionInterface) {
    if (socialsSection) {
      this.id = socialsSection.id
      this.label = socialsSection.label
      this.listOrder = socialsSection.listOrder
      this.active = socialsSection.active
      this.persistent = socialsSection.persistent
      this.labelVisible = socialsSection.labelVisible
      this.socials = socialsSection.socials.map(d => new SocialsItem(d)).sort(function (a: SocialsItem, b: SocialsItem) { return a.listOrder - b.listOrder })
      this.style = new SocialsSectionStyle(socialsSection.style)
    }
  }
}
