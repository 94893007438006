import { DETAIL_TYPES } from '@/constants'

export interface ParsableDetailInterface {
  id: string,
  category: string,
  type: string,
  label: string,
  value: string,
  listOrder: number
}

export interface DetailInterface {
  id: string,
  category: string,
  type: string,
  label: string,
  value: object,
  listOrder: number
}

export class Detail implements DetailInterface {
  id = ''
  category = ''
  type = ''
  label = ''
  value
  listOrder = 0

  constructor (detail?: ParsableDetailInterface) {
    if (detail) {
      const { id, category, type, label, value, listOrder } = detail
      this.id = id
      this.category = category
      this.type = type
      this.label = label
      this.value = JSON.parse(value)
      this.listOrder = listOrder
    }
  }

  getValue (): string {
    if (!this.value) return ''
    else {
      const arr = Object.keys(this.value).map(key => this.value[key])
      return arr.join(',\n')
    }
  }

  getType (): string {
    if (this.type.length === 0) return ''
    if (this.type.length === 1) return this.type.charAt(0).toUpperCase()
    return this.type.charAt(0).toUpperCase() + this.type.slice(1)
  }

  getDetailButton () {
    return {
      icon: DETAIL_TYPES[this.type].icon,
      url: DETAIL_TYPES[this.type].url(this.value)
    }
  }

  getUrl (): string {
    return DETAIL_TYPES[this.type].url(this.getValue())
  }
}
