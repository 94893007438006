import BaseAnalytics from './BaseAnalytics'
import ScanLocation from './ScanLocation'
import TopQr from './TopQr'

interface ProfileAnalyticsInterface {
    totalScansLifetime: number
    totalScansToday: number
    totalLinkClicksLifetime: number
    averageClickTime: number
    topLocations: ScanLocation[]
    topQrs: TopQr[]
}

export default class ProfileAnalytics extends BaseAnalytics implements ProfileAnalyticsInterface {
  topQrs = [] as Array<TopQr>

  constructor (subProfileAnalytics?: ProfileAnalytics) {
    super(subProfileAnalytics)
    if (subProfileAnalytics) {
      this.topQrs = subProfileAnalytics.topQrs.map(topQr => new TopQr(topQr))
    }
  }
}
