import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7cc9a329"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "button-container" }
const _hoisted_2 = {
  key: 0,
  id: "video-player"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DetailButtonSlant = _resolveComponent("DetailButtonSlant")!
  const _component_DetailButton = _resolveComponent("DetailButton")!
  const _component_VideoPlayer = _resolveComponent("VideoPlayer")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.type == 'slant')
        ? (_openBlock(), _createBlock(_component_DetailButtonSlant, {
            key: 0,
            detail: _ctx.detail,
            leftColour: _ctx.leftColour,
            rightColour: _ctx.rightColour,
            textColour: _ctx.textColour,
            closable: _ctx.contentActive,
            onOnClick: _ctx.onClick
          }, null, 8, ["detail", "leftColour", "rightColour", "textColour", "closable", "onOnClick"]))
        : (_openBlock(), _createBlock(_component_DetailButton, {
            key: 1,
            detail: _ctx.detail,
            color: _ctx.color,
            textColour: _ctx.textColour,
            closable: _ctx.contentActive,
            onOnClick: _ctx.onClick
          }, null, 8, ["detail", "color", "textColour", "closable", "onOnClick"]))
    ]),
    (_ctx.contentActive)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_VideoPlayer, {
            sources: [{type: 'video/youtube', src: _ctx.detail?.getValue()}],
            "tech-order": ['youtube'],
            controls: "",
            autoplay: "",
            fluid: true
          }, null, 8, ["sources"])
        ]))
      : _createCommentVNode("", true)
  ]))
}