import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6d16b7e6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "qr-list-item-image-container" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "qr-list-item-label-container" }
const _hoisted_4 = { class: "qr-list-item-label" }
const _hoisted_5 = { class: "qr-list-item-tag-container" }
const _hoisted_6 = { class: "qr-list-item-tag-container" }
const _hoisted_7 = { class: "qr-grid-item-code-container" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "qr-grid-item-buttons-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QrRootTag = _resolveComponent("QrRootTag")!
  const _component_QrPublishedTag = _resolveComponent("QrPublishedTag")!
  const _component_QrUnpublishedTag = _resolveComponent("QrUnpublishedTag")!
  const _component_DropdownQrDownload = _resolveComponent("DropdownQrDownload")!
  const _component_DropdownQrEdit = _resolveComponent("DropdownQrEdit")!

  return (_openBlock(), _createElementBlock("div", {
    class: "qr-list-item-container",
    style: _normalizeStyle(_ctx.cssVars)
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.subProfile.imageSections[0])
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: "qr-list-item-image",
            src: _ctx.subProfile.imageSections[0].uri,
            crossorigin: "anonymous"
          }, null, 8, _hoisted_2))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.subProfile.getTruncatedLabel()), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_ctx.subProfile.isRootProfile)
        ? (_openBlock(), _createBlock(_component_QrRootTag, { key: 0 }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_6, [
      (_ctx.subProfile.active)
        ? (_openBlock(), _createBlock(_component_QrPublishedTag, { key: 0 }))
        : (_openBlock(), _createBlock(_component_QrUnpublishedTag, { key: 1 }))
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("img", {
        class: "qr-grid-item-code",
        src: _ctx.subProfile.qrUri
      }, null, 8, _hoisted_8)
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createVNode(_component_DropdownQrDownload, {
        onOnClickPng: _cache[0] || (_cache[0] = () => _ctx.onDownload('png', _ctx.subProfile.id)),
        onOnClickSvg: _cache[1] || (_cache[1] = () => _ctx.onDownload('svg', _ctx.subProfile.id))
      }),
      _createVNode(_component_DropdownQrEdit, {
        onOnClickEdit: _cache[2] || (_cache[2] = () => _ctx.$emit('onSelect', _ctx.subProfile.id))
      })
    ])
  ], 4))
}