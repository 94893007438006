<template>
    <div class="button-container d-flex flex-row justify-content-center align-items-center" :style="cssVars">
        <p :class="cssTextClass" :style="cssText">{{ text }}</p>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ButtonTwo',
  props: {
    text: {
      type: String,
      required: true
    },
    style: {
      type: Object,
      default: null
    },
    textUsesGradient: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    cssVars () {
      return {
        ...this.style
      }
    },
    cssText () {
      return {
        ...this.textStyle
      }
    },
    cssTextClass () {
      const baseClass = 'button-text mx-1'
      return this.textUsesGradient ? baseClass + ' text-gradient' : baseClass
    }
  }
})
</script>

<style scoped lang="scss">
  div.button-container {
      cursor: pointer;
  }

  p.button-text {
    font-family: inter;
    margin-bottom: 0;
  }

  p.text-gradient {
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }
</style>
