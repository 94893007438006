import 'bootstrap/dist/css/bootstrap.min.css'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VueCookies from 'vue-cookies'
import VueApexCharts from 'vue3-apexcharts'

import 'bootstrap/dist/js/bootstrap.js'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faArrowPointer,
  faBarChart,
  faBars,
  faBookOpen,
  faCalendarDay,
  faChevronLeft,
  faClock,
  faDiagramProject,
  faDownload,
  faEllipsisVertical,
  faGear,
  faICursor,
  faImage,
  faList,
  faPlus,
  faQrcode,
  faSearch,
  faTableCellsLarge,
  faTrash,
  faUpload,
  faUsers,
  faXmark
} from '@fortawesome/free-solid-svg-icons'

import '@/assets/css/app.css'
import '@/assets/css/bootstrap.css'
import '@/assets/css/icons.css'
import '@/assets/css/landing.css'
import '@/assets/css/materialdesignicons.min.css'

const token = localStorage.getItem('token')
if (token) {
  store.commit('setToken', token.replaceAll('\\', '').replaceAll('"', ''))
}

library.add(faArrowPointer)
library.add(faBars)
library.add(faBarChart)
library.add(faBookOpen)
library.add(faCalendarDay)
library.add(faChevronLeft)
library.add(faDiagramProject)
library.add(faDownload)
library.add(faEllipsisVertical)
library.add(faGear)
library.add(faICursor)
library.add(faImage)
library.add(faList)
library.add(faPlus)
library.add(faClock)
library.add(faSearch)
library.add(faTableCellsLarge)
library.add(faTrash)
library.add(faQrcode)
library.add(faUpload)
library.add(faUsers)
library.add(faXmark)

createApp(App).component('fa-icon', FontAwesomeIcon).use(VueApexCharts).use(VueCookies).use(store).use(router).mount('#app')
