<template>
    <div v-if="embeddedVideoSection" class="embedded-video-container" @click="() => this.$emit('onPushClick')">
        <EmbeddedVideoPlayer
            :embeddedVideoSection="embeddedVideoSection"
            :playerWidth="310"
            :playerHeight="174"/>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import EmbeddedVideoPlayer from '@/components/video/EmbeddedVideoPlayer.vue'
import EmbeddedVideoSection from '@/models/qr-sections/EmbeddedVideoSection'

import { hexToRGBA } from '@/utils'

export default defineComponent({
  name: 'EmbeddedVideoSection',
  components: {
    EmbeddedVideoPlayer
  },
  props: {
    embeddedVideoSection: EmbeddedVideoSection
  },
  data () {
    return {
      activeContentId: ''
    }
  }
})
</script>

<style scoped lang="scss">
div.embedded-video-container {
    margin: 15px 0 0 0;
    min-width: 100%;
    display: flex;
    justify-content: center;
}
</style>
