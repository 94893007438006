<template>
    <div class="d-flex flex-wrap align-items-center">
        <label for="status-select" class="me-2">{{ this.label }}</label>
        <div class="me-sm-3" style="width: 50%">
            <select class="form-select my-1 my-md-0" id="status-select" @change="onChange($event)" :value="internalValue">
                <option v-for="(option, i) in options" :key="i">{{ option }}</option>
            </select>
        </div>
   </div>
</template>

<script lang="ts">
import { UserSubProfile } from '@/models/UserSubProfile'
import { defineComponent } from 'vue'

import 'vue-loading-overlay/dist/css/index.css'

export default defineComponent({
  name: 'SelectComponent',
  props: {
    label: {
      type: String,
      default: 'Dropdown'
    },
    options: {
      type: Array
    },
    value: {
      type: String
    }
  },
  data () {
    return {
      internalValue: ''
    }
  },
  watch: {
    value (value) {
      this.internalValue = value
    }
  },
  methods: {
    onChange (event) {
      this.$emit('onChange', event.target.value)
      this.internalValue = event.target.value
    }
  }
})
</script>
