/* eslint-disable quote-props */

import { Palette } from '@/theme'

export const DEFAULT_AVATAR = 'avatars/profile_default.png'

export const DEFAULT_SUB_PROFILE = { name: 'New QR', label: 'New QR', preTitle: '', subTitle: '', description: '' }

export const DEFAULT_DETAIL = { category: 'core', type: 'website', label: 'New Detail', value: JSON.stringify({ value: '' }) }

export const DETAIL_TYPES_SOCIALS = () => {
  const socials = [] as { label: string, icon: string}[]
  for (const key in DETAIL_TYPES) {
    if (DETAIL_TYPES[key].category === 'social') socials.push({ label: key.charAt(0).toUpperCase() + key.slice(1), icon: DETAIL_TYPES[key].icon })
  }
  return socials
}

export const DETAIL_TYPES_STREAMING = () => {
  const streaming = [] as { label: string, icon: string}[]
  for (const key in DETAIL_TYPES) {
    if (DETAIL_TYPES[key].category === 'streaming') streaming.push({ label: key.charAt(0).toUpperCase() + key.slice(1), icon: DETAIL_TYPES[key].icon })
  }
  return streaming
}

export const DETAIL_TYPES_OTHER = () => {
  const other = [] as { label: string, icon: string}[]
  for (const key in DETAIL_TYPES) {
    if (DETAIL_TYPES[key].category === 'other') other.push({ label: key.charAt(0).toUpperCase() + key.slice(1), icon: DETAIL_TYPES[key].icon })
  }
  return other
}

export const DETAIL_TYPES: {[id: string] : { category: string, icon: string; url: (url: string) => string; }} = {
  'amazon': {
    category: 'service',
    icon: 'icons/details/services/icon_amazon.png',
    url: (url: string) => url
  },
  'deliveroo': {
    category: 'service',
    icon: 'icons/details/services/icon_deliveroo.png',
    url: (url: string) => url
  },
  'email': {
    category: 'other',
    icon: 'icons/details/core/icon_email.png',
    url: (email) => 'mailto:' + email
  },
  'facebook': {
    category: 'social',
    icon: 'icons/details/socials/icon_facebook.png',
    url: (url: string) => url
    // url: (id: string) => 'https://www.facebook.com/profile.php?id=' + id
  },
  'instagram': {
    category: 'social',
    icon: 'icons/details/socials/icon_instagram.png',
    url: (url: string) => url
  },
  'linkedin': {
    category: 'social',
    icon: 'icons/details/socials/icon_linkedin.png',
    url: (url: string) => url
  },
  'location': {
    category: 'core',
    icon: 'icons/details/core/icon_location.png',
    url: (addr: string) => 'https://www.google.com/maps/search/' + addr
  },
  'mixcloud': {
    category: 'streaming',
    icon: 'icons/details/content/icon_mixcloud.png',
    url: (url: string) => url
    // url: (url: string) => 'https://www.mixcloud.com/' + url
  },
  'phone': {
    category: 'core',
    icon: 'icons/details/core/icon_phone.png',
    url: (number: string) => (`tel:${number}`).replace('{', '').replace('}', '')
  },
  'snapchat': {
    category: 'social',
    icon: 'icons/details/socials/icon_snapchat.png',
    url: (url: string) => url
  },
  'soundcloud': {
    category: 'streaming',
    icon: 'icons/details/content/icon_soundcloud.png',
    url: (url: string) => url
    // url: (url: string) => 'https://www.soundcloud.com/' + url
  },
  'telegram': {
    category: 'social',
    icon: 'icons/details/socials/icon_telegram.png',
    url: (url: string) => url
  },
  'video': {
    category: 'content',
    icon: 'icons/details/content/icon_video.png',
    url: (url: string) => url
  },
  'website': {
    category: 'other',
    icon: 'icons/details/core/icon_website.png',
    url: (url: string) => url
  },
  'wechat': {
    category: 'social',
    icon: 'icons/details/socials/icon_wechat.png',
    url: (url: string) => url
  },
  'whatsapp': {
    category: 'social',
    icon: 'icons/details/socials/icon_whatsapp.png',
    url: (number: string) => number[0] === '+' ? ('https://wa.me/' + number.replace('+', '')) : (`tel:${number}`).replace('{', '').replace('}', '')
  },
  'youtube': {
    category: 'streaming',
    icon: 'icons/details/content/icon_youtube.png',
    url: (url: string) => url
  },
  'default': {
    category: 'core',
    icon: 'icons/main/icon.png',
    url: (url: string) => url
  }
}

export const SOCIAL_TYPES: {[id: string] : { category: string, icon: string; url: (url: string) => string}} = {
  'facebook': {
    category: 'social',
    icon: 'icons/socials/icon-facebook.png',
    url: (url: string) => url
  },
  'instagram': {
    category: 'social',
    icon: 'icons/socials/icon-instagram.png',
    url: (url: string) => url
  },
  'twitter': {
    category: 'social',
    icon: 'icons/socials/icon-twitter.png',
    url: (url: string) => url
  }
}

export const SOCIAL_TYPES_SOCIALS = () => {
  const socials = [] as { label: string, icon: string}[]
  for (const key in SOCIAL_TYPES) {
    if (SOCIAL_TYPES[key].category === 'social') socials.push({ label: key.charAt(0).toUpperCase() + key.slice(1), icon: SOCIAL_TYPE_TO_ICON[key].value })
  }
  return socials
}

export const SOCIAL_TYPES_STREAMING = () => {
  return []
}

export const SOCIAL_TYPES_PODCAST = () => {
  return []
}

export const SOCIAL_TYPES_OTHER = () => {
  return []
}

export const SOCIAL_TYPE_TO_ICON: {[type: string] : { value: string }} = {
  'facebook': { value: 'icons/socials/icon-facebook.png' },
  'instagram': { value: 'icons/socials/icon-instagram.png' },
  'linkedin': { value: 'icons/socials/icon-facebook.png' },
  'twitter': { value: 'icons/socials/icon-twitter.png' },
  'default': { value: 'icons/main/icon.png' }
}

export const SOCIALS_LOGO_THEME_TO_OPTIONS: {[theme: string] : { value: string }} = {
  'brand': { value: 'Brand Colours' },
  'custom': { value: 'Custom' }
}

export const SOCIALS_LOGO_THEME_FROM_OPTIONS: {[theme: string] : { value: string }} = {
  'Brand Colours': { value: 'brand' },
  'Custom': { value: 'custom' }
}

export const PROFILE_COLORS = [
  {
    id: 0,
    background: Palette.white,
    foreground: Palette.black,
    text: Palette.white
  },
  {
    id: 1,
    background: Palette.black,
    foreground: Palette.white,
    text: Palette.black
  },
  {
    id: 2,
    background: Palette.red,
    foreground: Palette.white,
    text: Palette.red
  },
  {
    id: 3,
    background: Palette.green,
    foreground: Palette.white,
    text: Palette.green
  },
  {
    id: 4,
    background: Palette.dark_blue,
    foreground: Palette.white,
    text: Palette.black
  }

]
