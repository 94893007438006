import { authorisedPost, get } from './Api'

export function editEmbeddedVideoSection (
  subProfileId: string,
  id: number,
  label: string,
  listOrder: number,
  active: boolean,
  url: string,
  token: string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  const data = {
    id,
    label,
    listOrder,
    active,
    labelVisible: false,
    url
  }
  authorisedPost(`dashboard/${subProfileId}/edit-embedded-video?section=${id}`, data, token, unauthorisedCallback, badRequestCallback, serverErrorCallback, notFoundCallback, callback)
}

export function editEmbeddedVideoSectionStyle (
  subProfileId: string,
  id: number,
  controls: string,
  thumbnail: string,
  thumbnailUri: string,
  token: string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  const data = {
    controls,
    thumbnail,
    thumbnailUri
  }
  authorisedPost(`dashboard/${subProfileId}/edit-embedded-video-style?section=${id}`, data, token, unauthorisedCallback, badRequestCallback, serverErrorCallback, notFoundCallback, callback)
}

export function editEmbeddedVideoSectionThumbnail (
  image: File,
  sectionId: number,
  subProfileId: string,
  token : string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  const data = new FormData()
  data.append('file', image)
  authorisedPost(
    `dashboard/${encodeURIComponent(subProfileId)}/edit-embedded-video-thumbnail?section=${encodeURIComponent(sectionId)}`,
    data,
    token,
    unauthorisedCallback,
    notFoundCallback,
    badRequestCallback,
    serverErrorCallback,
    callback
  )
}

export function pushSectionEmbeddedVideoClick (
  qrSectionId: number,
  qrScanId: number,
  accessType: string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  get(
    `profile/push-section-embedded-video-click?qrScanId=${qrScanId}&sectionId=${qrSectionId}&accessType=${accessType}`,
    unauthorisedCallback,
    notFoundCallback,
    badRequestCallback,
    notFoundCallback,
    callback
  )
}
