<template>
    <div class="content-page px-1">
      <div class="container-fluid" style="max-width: 90%;">
        <div v-if="this.scrolling" class="row top-bar-scroll">
          <div class="col-xl-2 col-6 d-flex align-items-center">
            <h1 class="page-title page-title-scrolling">Keypins</h1>
          </div>
          <div class="col-xl-3 col-lg-4 d-sm-flex d-none align-items-center">
            <SearchInput
              v-model="searchText"
              placeholder="Search your QR Codes..."
              :wrapper-style="{width: '100%'}"
              :container-style="{width: '100%'}"/>
          </div>
          <div class="col-xl-2 col-lg-4 col-sm-6 col-12 d-flex align-items-center">
            <SelectInput :options="sortByOptions" v-model="sortBy" label="Sort by" layout="row"/>
          </div>
          <div class="col-xl-2 col-lg-4 col-sm-6 col-12 d-flex align-items-center">
            <ViewSelect layout="row" v-model="isGrid"/>
          </div>
          <div class="d-lg-flex d-none col-2">
            <ManageCreateQrButton @click="onCreateSubProfile"/>
          </div>
        </div>
        <div v-else class="mt-5 d-flex flex-row">
          <div style="flex: 1">
            <h1 class="page-title page-title-fixed">Keypins</h1>
          </div>
          <div style="width: 50%; max-width: 200px; justify-self: end;">
            <ManageCreateQrButton @click="onCreateSubProfile"/>
          </div>
        </div>
        <div class="row mt-3">
            <SubNav :tabs="tabs" :current-tab="currentTab" @selectTab="selectTab"/>
        </div>
        <div  class="row mt-4 mb-3">
          <div class="col-xl-6 col-lg-4 d-sm-block d-none">
            <SearchInput
              v-model="searchText"
              placeholder="Search your QR Codes..."/>
          </div>
          <div class="col-xl-3 col-lg-4 col-sm-6 col-12 justify-content-end">
            <div style="max-width: 200px;">
              <SelectInput :options="sortByOptions" v-model="sortBy" label="Sort by:" layout="row"/>
            </div>
          </div>
          <div class="col-xl-3 col-lg-4 col-sm-6 col-12">
            <ViewSelect layout="row" v-model="isGrid"/>
          </div>
        </div>
          <div class="col-lg-4 col-md-6 col-sm-8 mx-auto">
            <RootProfile :profile="profile" :colorScheme="colorScheme" />
          </div>
        <div class="row">
          <div class="qr-list-container">
            <QRGrid v-if="isGrid" @onDelete="onDeleteSubProfile" @onSelectSubProfile="onSelectSubProfile" @onToggleActive="onToggleActiveSubProfile"
              :subProfiles="getVisibleSubProfiles()" />
            <QRList v-else @onDelete="onDeleteSubProfile" @onSelectSubProfile="onSelectSubProfile" @onToggleActive="onToggleActiveSubProfile"
              :subProfiles="getVisibleSubProfiles()" />
          </div>
        </div>
      </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import ManageCreateQrButton from '@/components/button/ManageCreateQrButton.vue'
import SubNav from '@/components/nav/SubNav.vue'
import QRGrid from '@/components/list/QRGrid.vue'
import QRList from '@/components/list/QRList.vue'
import RootProfile from '@/components/list/RootProfile.vue'
import SelectInput from '@/components/input/SelectInput.vue'
import ViewSelect from '@/components/select/ViewSelect.vue'

import Profile from '@/models/Profile'
import { UserSubProfile } from '@/models/UserSubProfile'

import { DEFAULT_SUB_PROFILE, PROFILE_COLORS } from '@/constants'
import { fetchDashboard, postSubProfile, deleteSubProfile, toggleActiveSubProfile } from '@/api'
import SearchInput from '@/components/input/SearchInput.vue'

export default defineComponent({
  name: 'ManageView',
  components: {
    ManageCreateQrButton,
    SubNav,
    QRGrid,
    QRList,
    RootProfile,
    SelectInput,
    ViewSelect,
    SearchInput
  },
  data () {
    return {
      tabs: ['All QRs', 'Published', 'Unpublished', 'Archived'],
      currentTab: 0,
      scrolling: false,
      profile: new Profile(),
      subProfiles: [new UserSubProfile()],
      colorScheme: PROFILE_COLORS[0],
      searchText: '',
      sortBy: 'Newest',
      sortByOptions: [
        'Newest',
        'A-Z'
      ],
      isGrid: true
    }
  },
  methods: {
    selectTab (tab: number) {
      this.currentTab = tab
    },
    updateScroll () {
      this.scrolling = window.scrollY > 150
    },
    getVisibleSubProfiles () {
      let visibleSubProfiles = this.subProfiles
      if (this.currentTab === 1) visibleSubProfiles = this.subProfiles.filter((sp: UserSubProfile) => sp.active)
      else if (this.currentTab === 2) visibleSubProfiles = this.subProfiles.filter((sp: UserSubProfile) => !sp.active)

      if (this.searchText.length > 0) visibleSubProfiles = visibleSubProfiles.filter((sp: UserSubProfile) => sp.label.toLowerCase().includes(this.searchText.toLowerCase()))
      if (this.sortBy === 'Newest') return visibleSubProfiles.sort(function (a: UserSubProfile, b: UserSubProfile) { return b.id.localeCompare(a.id) })
      else if (this.sortBy === 'A-Z') return visibleSubProfiles.sort(function (a: UserSubProfile, b: UserSubProfile) { return a.label.localeCompare(b.label) })
      return visibleSubProfiles
    },
    configureFromResponse (response: any) {
      this.profile = new Profile(response.data.data.userProfile)
      this.subProfiles = response.data.data.subProfiles.map((subProfile: any) => new UserSubProfile(subProfile))

      this.$store.commit('setProfile', this.profile)

        .sort(function (a: UserSubProfile, b: UserSubProfile) {
          for (let i = 3; i < a.id.length && i < b.id.length; i++) {
            if (a.isRootProfile) {
              return -1
            }
            if (b.isRootProfile || a.id.charCodeAt(i) > b.id.charCodeAt(i)) {
              return 1
            }
          }
          return -1
        })
    },
    onCreateSubProfile () {
      postSubProfile(
        DEFAULT_SUB_PROFILE.name,
        DEFAULT_SUB_PROFILE.label + ' #' + String(this.subProfiles.length),
        DEFAULT_SUB_PROFILE.preTitle,
        DEFAULT_SUB_PROFILE.subTitle,
        DEFAULT_SUB_PROFILE.description,
        this.$store.state.token,
        () => this.$router.push('/login'),
        () => null,
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response) => {
          this.configureFromResponse(response)
          const newSubProfileId = response.data.data.newSubProfileId.value
          this.$router.push(`/dashboard/${newSubProfileId}`)
        })
    },
    onSelectSubProfile (id: string) {
      this.$router.push(`/dashboard/${encodeURIComponent(id)}`)
    },
    onDeleteSubProfile (id: string) {
      deleteSubProfile(
        id,
        this.$store.state.token,
        () => this.$router.push('/login'),
        () => null,
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response) => {
          this.configureFromResponse(response)
        })
    }
  },
  mounted () {
    window.addEventListener('scroll', this.updateScroll)
    fetchDashboard(
      this.$store.state.token,
      () => this.$router.push('/login'),
      () => this.$router.push('/not-found'),
      () => this.$router.back(),
      () => this.$router.push('/server-error'),
      (response) => {
        this.configureFromResponse(response)
      })
  },
  unmounted () {
    window.removeEventListener('scroll', this.updateScroll)
  }
})
</script>

<style scoped lang="scss">

h1.page-title {
  color: var(--Nearly-Black, #212529);
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  width: 100%;
  text-align: left;
}

h1.page-title-fixed{
  font-size: 30px;
  line-height: 38px; /* 126.667% */
}

h1.page-title-scrolling{
  font-size: 20px;
  line-height: 10px; /* 100% */
  margin: 0 60px 0 60px;
}

div.top-bar-scroll {
  position: sticky;
  top: 0;
  z-index: 1;
  width: 115%;
  margin-left: -7.5%;
  background-color: var(--ct-body-bg);
  box-shadow: 0px 10px 10px -15px #111;
  padding: 10px 0 10px 0;
}

.create-qr-button-icon {
  margin-right: 0.5rem;
}
div.qr-list-container {
  width: 100%;
}
</style>
