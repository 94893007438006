<template>
    <div v-if="amazonReviewCTASection" class="amazon-review-cta-body" :style="cssVars">
        <div class="d-flex flex-column">
            <p :style="cssVars" class="amazon-review-cta-title">{{ amazonReviewCTASection.title }}</p>
            <p :style="cssVars" class="amazon-review-cta-subtitle">{{ amazonReviewCTASection.subtitle }}</p>
            <a :href="amazonReviewCTASection.amazonUrl" class="amazon-review-cta-btn" @click="() => this.$emit('onPushClick')">{{ amazonReviewCTASection.btnText }}</a>
        </div>
        <div class="d-flex flex-column align-items-end">
          <img :src=logoSrc class="logo-image mb-auto"/>
          <img :src="require('@/assets/images/qr-sections/amazon-stars.svg')" class="stars-image"/>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import AmazonReviewCTASection from '@/models/qr-sections/AmazonReviewCTASection'

export default defineComponent({
  name: 'AmazonReviewCTASection',
  props: {
    amazonReviewCTASection: AmazonReviewCTASection
  },
  computed: {
    cssVars () {
      return {
        '--background-color': this.amazonReviewCTASection.style.theme === 'dark' ? '#202F3F' : '#FFFFFF',
        '--title-color': this.amazonReviewCTASection.style.theme === 'dark' ? '#FFFFFF' : '#212529',
        '--subtitle-color': this.amazonReviewCTASection.style.theme === 'dark' ? '#FFFFFF' : '#212529'
      }
    },
    logoSrc () {
      return this.amazonReviewCTASection.style.theme === 'dark'
        ? require('@/assets/images/qr-sections/amazon-logo-dark.png')
        : require('@/assets/images/qr-sections/amazon-logo.png')
    }
  }
})
</script>

<style scoped lang="scss">
  div.amazon-review-cta-body{
    width: 111%;
    margin-top: 20px;
    display: flex;
    background-color: var(--background-color);
    padding: 20px 30px 20px 30px;
  }
  p.amazon-review-cta-title{
    color: var(--title-color);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 137.5% */
    margin: 0px;
    text-align: left;
    width: 155px;
  }
  p.amazon-review-cta-subtitle{
    color: var(--subtitle-color);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 153.846% */
    opacity: 0.5;
    margin: 10px 0 0 0;
    text-align: left;
    width: 208px;
  }
  a.amazon-review-cta-btn{
    display: inline-flex;
    padding: 13px 24px;
    align-items: center;
    gap: 12px;
    border-radius: 10px;
    background: #FF9300;
    color: var(--Nearly-Black, #212529);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 100% */
    width: 131px;
    height: 40px;
    margin: 20px 0 0 0;
  }
  img.logo-image {
    width: 65.294px;
    height: 20px;
    flex-shrink: 0;
  }
  img.stars-image {
    width: 100px;
    height: 20px;
    flex-shrink: 0;
  }
</style>
