import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-17095e52"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = {
  key: 0,
  class: "nav-item-underline"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("nav", {
    class: "navbar navbar-submenu d-flex justify-content-start",
    style: _normalizeStyle(_ctx.cssVars)
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.tabs, (tab, idx) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "nav-item-wrapper",
        key: idx
      }, [
        _createElementVNode("a", {
          class: "nav-item",
          href: "#",
          style: _normalizeStyle(_ctx.getItemStyleOverride(idx)),
          onClick: () => _ctx.$emit('selectTab', idx)
        }, _toDisplayString(tab), 13, _hoisted_1),
        (idx == this.currentTab)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ], 4))
}