import { Detail, ParsableDetailInterface } from './Detail'
import FollowCount from './FollowCount'

interface ProfileInterface {
  keypin: string,
  avatar: NodeRequire,
  firstName: string,
  lastName: string,
  bio: string,
  details: Detail[],
  profileDetails: string[]
  colorSchemeId: number,
  followCount: FollowCount
}

interface ParsableProfileInterface {
  keypin: string,
  avatar: string,
  firstName: string,
  lastName: string,
  bio: string,
  details: ParsableDetailInterface[],
  profileDetails: string[]
  colorSchemeId: number,
  followCount: FollowCount
}

export default class Profile implements ProfileInterface {
  keypin = ''
  avatar = require('@/assets/logo.png')
  firstName = ''
  lastName = ''
  bio = ''
  details = new Array<Detail>()
  profileDetails = new Array<string>()
  colorSchemeId = 0
  followCount = { followers: 0, following: 0 }

  constructor (profile?: ParsableProfileInterface) {
    if (profile) {
      this.keypin = profile.keypin
      this.firstName = profile.firstName
      this.lastName = profile.lastName
      this.bio = profile.bio
      this.details = profile.details.map(d => new Detail(d))
      this.profileDetails = profile.profileDetails
      this.colorSchemeId = profile.colorSchemeId
      this.followCount = profile.followCount
    }
  }

  getProfileDetails () {
    return this.profileDetails.map(id => this.details.find(d => d.id === id))
  }
}
