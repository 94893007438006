<template>
    <EditorPanel
      :title="linkSection.label"
      :active="linkSection.active"
      :persistent="linkSection.persistent"
      :styleMode="styleMode"
      @onSetActive="onSetActive"
      @onDelete="onDelete"
      @onToggleMode="onToggleMode"
      >
      <div v-if="!this.styleMode" class="d-flex flex-column">
        <EditorInputComponent label="Link Text" :value="linkSection.text" @onChangeValue="onEditText"/>
        <EditorInputComponent label="URL" :value="linkSection.url" @onChangeValue="onEditUrl"/>
        </div>
      <div v-else class="d-flex flex-column link-style-panel-content">
        <div class="d-flex link-style-panel-row align-items-center">
            <p class="style-header d-flex flex-fill">Format</p>
            <div class="d-flex align-items-center link-style-panel-row-group-br" style="padding: 0 10px 0 2px">
              <StyleIconButton :icon="'style-align-left'" :selected="linkSection.style.align === 'left'" @click="() => onEditAlign('left')"/>
              <StyleIconButton :icon="'style-align-center'" :selected="linkSection.style.align === 'center'" @click="() => onEditAlign('center')"/>
              <StyleIconButton :icon="'style-align-right'" :selected="linkSection.style.align === 'right'" @click="() => onEditAlign('right')"/>
            </div>
            <div class="d-flex align-items-center link-style-panel-row-group-br" style="padding: 0 10px 0 2px">
              <StyleIconButton :icon="'text-bold'" :selected="linkSection.style.bold" @click="() => onEditBold(!linkSection.style.bold)"/>
              <StyleIconButton :icon="'text-italic'" :selected="linkSection.style.italic" @click="() => onEditItalic(!linkSection.style.italic)"/>
              <StyleIconButton :icon="'text-underline'" :selected="linkSection.style.underline" @click="() => onEditUnderline(!linkSection.style.underline)"/>
            </div>
            <div class="d-flex" style="padding: 0 0 0 10px">
              <EditorNumSelectComponent label="Size" :value="linkSection.style.size" :min="8" :max="24" @onChange="onEditSize"/>
              </div>
          </div>
          <div class="d-flex link-style-panel-row align-items-center">
            <p class="style-header d-flex flex-fill">Colour</p>
            <div class="d-flex align-items-center link-style-panel-row-group-br" style="padding: 0 10px 0 0">
              <ColourInput :label="'Text'" :value="linkSection.style.textColour" @onChangeColour="onEditTextColour"/>
            </div>
            <div class="d-flex" style="padding: 0 0 0 10px">
              <ColourInput :label="'Background'" :value="linkSection.style.backgroundColour" @onChangeColour="onEditBackgroundColour"/>
            </div>
          </div>
          <div class="d-flex link-style-panel-row align-items-center">
            <p class="style-header d-flex flex-fill">Spacing</p>
            <div class="d-flex ">
              <EditorNumInputComponent label="Left" :value="linkSection.style.spacingLeft" @onChange="onEditSpacingLeft"/>
              <EditorNumInputComponent label="Top" :value="linkSection.style.spacingTop" @onChange="onEditSpacingTop" style="margin-left: 10px"/>
              <EditorNumInputComponent label="Right" :value="linkSection.style.spacingRight" @onChange="onEditSpacingRight" style="margin-left: 10px"/>
              <EditorNumInputComponent label="Bottom" :value="linkSection.style.spacingBottom" @onChange="onEditSpacingBottom" style="margin-left: 10px"/>
            </div>
          </div>
        </div>
      </EditorPanel>
</template>

<script lang="ts">

import { defineComponent } from 'vue'

import ColourInput from '../input/ColourInput.vue'
import CrossButton from '../button/CrossButton.vue'
import DebouncedTextInput from '../input/DebouncedTextInput.vue'
import EditorPanel from '@/components/editor/EditorPanel.vue'
import EditorNumInputComponent from '../input/EditorNumInputComponent.vue'
import EditorNumSelectComponent from '../input/EditorNumSelectComponent.vue'
import FaIconLink from '../button/FaIconLink.vue'
import StyleTextIconButton from '../button/style-panel/StyleTextIconButton.vue'
import StyleIconButton from '../button/style-panel/StyleIconButton.vue'

import LinkSection from '@/models/qr-sections/LinkSection'
import { SlickItem, SlickList, HandleDirective } from 'vue-slicksort'
import EditorInputComponent from '../input/EditorInputComponent.vue'

export default defineComponent({
  name: 'LinkPanel',
  components: {
    ColourInput,
    EditorInputComponent,
    EditorNumInputComponent,
    EditorNumSelectComponent,
    EditorPanel,
    StyleIconButton
  },
  props: {
    linkSection: {
      type: LinkSection,
      required: true
    }
  },
  data () {
    return {
      styleMode: false
    }
  },
  computed: {
    styleDark () {
      return this.linkSection.style.theme === 'dark'
    }
  },
  methods: {
    onToggleMode (emit = true) {
      this.styleMode = !this.styleMode
      if (emit) this.$emit('onToggleMode', this.linkSection.id, this.styleMode)
    },
    onSetActive (value: boolean) {
      this.$emit(
        'onEdit',
        this.linkSection.id,
        this.linkSection.label,
        this.linkSection.listOrder,
        value,
        this.linkSection.text,
        this.linkSection.url
      )
    },
    onDelete () {
      this.$emit('onDelete', this.linkSection.id)
    },
    onEditText (value: string) {
      this.$emit(
        'onEdit',
        this.linkSection.id,
        this.linkSection.label,
        this.linkSection.listOrder,
        this.linkSection.active,
        value,
        this.linkSection.url
      )
    },
    onEditUrl (value: string) {
      this.$emit(
        'onEdit',
        this.linkSection.id,
        this.linkSection.label,
        this.linkSection.listOrder,
        this.linkSection.active,
        this.linkSection.text,
        value
      )
    },
    onEditAlign (value: string) {
      this.$emit(
        'onEditStyle',
        this.linkSection.id,
        value,
        this.linkSection.style.bold,
        this.linkSection.style.italic,
        this.linkSection.style.underline,
        this.linkSection.style.size,
        this.linkSection.style.textColour,
        this.linkSection.style.backgroundColour,
        this.linkSection.style.spacingLeft,
        this.linkSection.style.spacingTop,
        this.linkSection.style.spacingRight,
        this.linkSection.style.spacingBottom
      )
    },
    onEditBold (value: boolean) {
      this.$emit(
        'onEditStyle',
        this.linkSection.id,
        this.linkSection.style.align,
        value,
        this.linkSection.style.italic,
        this.linkSection.style.underline,
        this.linkSection.style.size,
        this.linkSection.style.textColour,
        this.linkSection.style.backgroundColour,
        this.linkSection.style.spacingLeft,
        this.linkSection.style.spacingTop,
        this.linkSection.style.spacingRight,
        this.linkSection.style.spacingBottom
      )
    },
    onEditItalic (value: boolean) {
      this.$emit(
        'onEditStyle',
        this.linkSection.id,
        this.linkSection.style.align,
        this.linkSection.style.bold,
        value,
        this.linkSection.style.underline,
        this.linkSection.style.size,
        this.linkSection.style.textColour,
        this.linkSection.style.backgroundColour,
        this.linkSection.style.spacingLeft,
        this.linkSection.style.spacingTop,
        this.linkSection.style.spacingRight,
        this.linkSection.style.spacingBottom
      )
    },
    onEditUnderline (value: boolean) {
      this.$emit(
        'onEditStyle',
        this.linkSection.id,
        this.linkSection.style.align,
        this.linkSection.style.bold,
        this.linkSection.style.italic,
        value,
        this.linkSection.style.size,
        this.linkSection.style.textColour,
        this.linkSection.style.backgroundColour,
        this.linkSection.style.spacingLeft,
        this.linkSection.style.spacingTop,
        this.linkSection.style.spacingRight,
        this.linkSection.style.spacingBottom
      )
    },
    onEditSize (value: number) {
      this.$emit(
        'onEditStyle',
        this.linkSection.id,
        this.linkSection.style.align,
        this.linkSection.style.bold,
        this.linkSection.style.italic,
        this.linkSection.style.underline,
        value,
        this.linkSection.style.textColour,
        this.linkSection.style.backgroundColour,
        this.linkSection.style.spacingLeft,
        this.linkSection.style.spacingTop,
        this.linkSection.style.spacingRight,
        this.linkSection.style.spacingBottom
      )
    },
    onEditTextColour (value: string) {
      this.$emit(
        'onEditStyle',
        this.linkSection.id,
        this.linkSection.style.align,
        this.linkSection.style.bold,
        this.linkSection.style.italic,
        this.linkSection.style.underline,
        this.linkSection.style.size,
        value,
        this.linkSection.style.backgroundColour,
        this.linkSection.style.spacingLeft,
        this.linkSection.style.spacingTop,
        this.linkSection.style.spacingRight,
        this.linkSection.style.spacingBottom
      )
    },
    onEditBackgroundColour (value: string) {
      this.$emit(
        'onEditStyle',
        this.linkSection.id,
        this.linkSection.style.align,
        this.linkSection.style.bold,
        this.linkSection.style.italic,
        this.linkSection.style.underline,
        this.linkSection.style.size,
        this.linkSection.style.textColour,
        value,
        this.linkSection.style.spacingLeft,
        this.linkSection.style.spacingTop,
        this.linkSection.style.spacingRight,
        this.linkSection.style.spacingBottom
      )
    },
    onEditSpacingLeft (value: number) {
      this.$emit(
        'onEditStyle',
        this.linkSection.id,
        this.linkSection.style.align,
        this.linkSection.style.bold,
        this.linkSection.style.italic,
        this.linkSection.style.underline,
        this.linkSection.style.size,
        this.linkSection.style.textColour,
        this.linkSection.style.backgroundColour,
        value,
        this.linkSection.style.spacingTop,
        this.linkSection.style.spacingRight,
        this.linkSection.style.spacingBottom
      )
    },
    onEditSpacingTop (value: number) {
      this.$emit(
        'onEditStyle',
        this.linkSection.id,
        this.linkSection.style.align,
        this.linkSection.style.bold,
        this.linkSection.style.italic,
        this.linkSection.style.underline,
        this.linkSection.style.size,
        this.linkSection.style.textColour,
        this.linkSection.style.backgroundColour,
        this.linkSection.style.spacingLeft,
        value,
        this.linkSection.style.spacingRight,
        this.linkSection.style.spacingBottom
      )
    },
    onEditSpacingRight (value: number) {
      this.$emit(
        'onEditStyle',
        this.linkSection.id,
        this.linkSection.style.align,
        this.linkSection.style.bold,
        this.linkSection.style.italic,
        this.linkSection.style.underline,
        this.linkSection.style.size,
        this.linkSection.style.textColour,
        this.linkSection.style.backgroundColour,
        this.linkSection.style.spacingLeft,
        this.linkSection.style.spacingTop,
        value,
        this.linkSection.style.spacingBottom
      )
    },
    onEditSpacingBottom (value: number) {
      this.$emit(
        'onEditStyle',
        this.linkSection.id,
        this.linkSection.style.align,
        this.linkSection.style.bold,
        this.linkSection.style.italic,
        this.linkSection.style.underline,
        this.linkSection.style.size,
        this.linkSection.style.textColour,
        this.linkSection.style.backgroundColour,
        this.linkSection.style.spacingLeft,
        this.linkSection.style.spacingTop,
        this.linkSection.style.spacingRight,
        value
      )
    }
  }
})
</script>

<style scoped>

div.link-style-panel-content {
    flex-shrink: 0;
    width: 100%;
    padding: 10px;
  }

  div.link-style-panel-row {
    width: 100%;
    height: 60px;
    border-top: 1px solid rgba(33, 37, 41, 0.1);
  }

  div.link-style-panel-row-group-br {
    height: 100%;
    border-right: 1px solid rgba(33, 37, 41, 0.1);
  }

  p.style-header {
    color: var(--Nearly-Black, #212529);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    opacity: 0.5;
    margin: 0px;
  }

</style>
