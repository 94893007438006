import { SubProfileComponentTheme } from './SubProfileComponentTheme'

export interface SubProfileDoubleBorderThemeInterface {
  backgroundColour: string,
  borderColour: string,
  secondaryBorderColour: string
  textColour: string
}

export class SubProfileDoubleBorderTheme extends SubProfileComponentTheme implements SubProfileDoubleBorderThemeInterface {
  secondaryBorderColour = ''
  constructor (theme?: SubProfileDoubleBorderThemeInterface) {
    super(theme)
    if (theme) {
      const { secondaryBorderColour } = theme
      this.secondaryBorderColour = secondaryBorderColour
    }
  }
}
