interface QrRedirectInterface {
    url: string,
    active: boolean
}

export default class QrRedirect implements QrRedirectInterface {
  url = ''
  active = false

  constructor (qrRedirect?: QrRedirectInterface) {
    if (qrRedirect) {
      this.url = qrRedirect.url
      this.active = qrRedirect.active
    }
  }
}
