import { authorisedPost } from './Api'

export function editImageSection (
  subProfileId: string,
  id: number,
  label: string,
  listOrder: number,
  active: boolean,
  token : string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  const data = {
    id,
    label,
    listOrder,
    active,
    labelVisible: false
  }
  authorisedPost(`dashboard/${subProfileId}/edit-image-section`, data, token, unauthorisedCallback, badRequestCallback, serverErrorCallback, notFoundCallback, callback)
}

export function editImageSectionImage (
  image: File,
  sectionId: number,
  subProfileId: string,
  token : string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  const data = new FormData()
  data.append('file', image)
  authorisedPost(
    `dashboard/${encodeURIComponent(subProfileId)}/${encodeURIComponent(sectionId)}/edit-image`,
    data,
    token,
    unauthorisedCallback,
    notFoundCallback,
    badRequestCallback,
    serverErrorCallback,
    callback
  )
}

export function resetImageSection (
  sectionId: number,
  subProfileId: string,
  token : string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  authorisedPost(
    `dashboard/${encodeURIComponent(subProfileId)}/${encodeURIComponent(sectionId)}/reset-image`,
    null,
    token,
    unauthorisedCallback,
    notFoundCallback,
    badRequestCallback,
    serverErrorCallback,
    callback
  )
}
