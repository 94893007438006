import { authorisedPost, get } from './Api'

export function postSocialsItemBatch (
  sectionId: number,
  subProfileId: string,
  items: string[],
  token: string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  authorisedPost(
    `dashboard/${subProfileId}/create-socials-item-batch?section=${sectionId}`,
    { items },
    token,
    unauthorisedCallback,
    notFoundCallback,
    badRequestCallback,
    notFoundCallback,
    callback
  )
}

export function editSocialsItem (
  id: number,
  value: string,
  sectionId: number,
  subProfileId: string,
  token: string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  authorisedPost(
    `dashboard/${subProfileId}/edit-socials-item?section=${sectionId}&socials=${id}`,
    { value },
    token,
    unauthorisedCallback,
    notFoundCallback,
    badRequestCallback,
    notFoundCallback,
    callback
  )
}

export function deleteSocialsItem (
  id: number,
  sectionId: number,
  subProfileId: string,
  token: string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  authorisedPost(
    `dashboard/${subProfileId}/delete-socials-item?section=${sectionId}&socials=${id}`,
    {},
    token,
    unauthorisedCallback,
    notFoundCallback,
    badRequestCallback,
    notFoundCallback,
    callback
  )
}

export function postSocialsItemOrder (
  value : Array<number>,
  sectionId: number,
  subProfileId: string,
  token: string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  authorisedPost(
    `dashboard/${subProfileId}/edit-socials-order?section=${sectionId}`,
    { value },
    token,
    unauthorisedCallback,
    notFoundCallback,
    badRequestCallback,
    notFoundCallback,
    callback
  )
}

export function pushSectionSocialsItemClick (
  qrSectionId: number,
  qrScanId: number,
  socialsId: number,
  accessType: string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  get(
    `profile/push-section-socials-item-click?qrScanId=${qrScanId}&sectionId=${qrSectionId}&socialsItemId=${socialsId}&accessType=${accessType}`,
    unauthorisedCallback,
    notFoundCallback,
    badRequestCallback,
    notFoundCallback,
    callback
  )
}
