import { Detail, ParsableDetailInterface } from './Detail'
import ImageSection from './qr-sections/ImageSection'
import HeaderSection from './qr-sections/HeaderSection'
import TextSection from './qr-sections/TextSection'
import SubProfileHeaderListItem from './SubProfileHeaderListItem'
import { SubProfileTheme, SubProfileThemeInterface } from './theme/SubProfileTheme'
import UserDetailSection from './qr-sections/UserDetailSection'
import TableSection from './qr-sections/TableSection'
import QrRedirect from './QrRedirect'
import FeaturesSection from './qr-sections/FeaturesSection'
import AmazonReviewCTASection from './qr-sections/AmazonReviewCTASection'
import LinkSection from './qr-sections/LinkSection'
import ButtonSection from './qr-sections/ButtonSection'
import EmbeddedVideoSection from './qr-sections/EmbeddedVideoSection'
import SocialsSection from './qr-sections/SocialsSection'
import NewsSection from './qr-sections/NewsSection'
import Profile from './Profile'

interface SubProfileInterface {
  id: string,
  keypin: string,
  name: string,
  label: string,
  qrUri: string,
  qrRedirect: QrRedirect,
  amazonReviewCTASections: AmazonReviewCTASection[],
  buttonSections: ButtonSection[],
  embeddedVideoSections: EmbeddedVideoSection[],
  featuresSections: FeaturesSection[],
  imageSections: ImageSection[],
  headerSections: HeaderSection[],
  linkSections: LinkSection[],
  newsSections: NewsSection[],
  socialsSections: SocialsSection[],
  textSections: TextSection[],
  tableSections: TableSection[],
  userDetailSections: UserDetailSection[],
  details: ParsableDetailInterface[],
  headerDetails: string[]
  headerGalleryItems: string[]
  headerListItems: SubProfileHeaderListItem[]
  theme: SubProfileThemeInterface,
}

export default class SubProfile implements SubProfileInterface {
  id = ''
  keypin = ''
  name = ''
  label = ''
  qrUri = ''
  qrRedirect = new QrRedirect()
  amazonReviewCTASections = [] as Array<AmazonReviewCTASection>
  buttonSections = [] as Array<ButtonSection>
  embeddedVideoSections = [] as Array<EmbeddedVideoSection>
  featuresSections = [] as Array<FeaturesSection>
  imageSections = [] as Array<ImageSection>
  headerSections = [] as Array<HeaderSection>
  linkSections = [] as Array<LinkSection>
  newsSections = [] as Array<NewsSection>
  socialsSections = [] as Array<SocialsSection>
  textSections = [] as Array<TextSection>
  tableSections = [] as Array<TableSection>
  userDetailSections = [] as Array<UserDetailSection>

  sections = [] as Array<{
    type: number,
    section: AmazonReviewCTASection | ButtonSection | EmbeddedVideoSection | FeaturesSection | ImageSection | HeaderSection | LinkSection | NewsSection | SocialsSection | TextSection | TableSection | UserDetailSection
  }>

  details = new Array<Detail>()
  headerDetails = [] as Array<string>
  headerGalleryItems = [] as Array<string>
  headerListItems = [] as Array<SubProfileHeaderListItem>
  theme = new SubProfileTheme()

  constructor (subProfile?: SubProfileInterface) {
    if (subProfile) {
      this.id = subProfile.id
      this.keypin = subProfile.keypin
      this.name = subProfile.name
      this.label = subProfile.label
      this.qrUri = subProfile.qrUri

      this.qrRedirect = new QrRedirect(subProfile.qrRedirect)

      this.amazonReviewCTASections = subProfile.amazonReviewCTASections.map(s => new AmazonReviewCTASection(s)).sort(function (a: AmazonReviewCTASection, b: AmazonReviewCTASection) { return a.listOrder - b.listOrder })
      this.buttonSections = subProfile.buttonSections.map(s => new ButtonSection(s)).sort(function (a: ButtonSection, b: ButtonSection) { return a.listOrder - b.listOrder })
      this.embeddedVideoSections = subProfile.embeddedVideoSections.map(s => new EmbeddedVideoSection(s)).sort(function (a: EmbeddedVideoSection, b: EmbeddedVideoSection) { return a.listOrder - b.listOrder })
      this.featuresSections = subProfile.featuresSections.map(s => new FeaturesSection(s)).sort(function (a: FeaturesSection, b: FeaturesSection) { return a.listOrder - b.listOrder })
      this.imageSections = subProfile.imageSections.map(s => new ImageSection(s)).sort(function (a: ImageSection, b: ImageSection) { return a.listOrder - b.listOrder })
      this.headerSections = subProfile.headerSections.map(s => new HeaderSection(s)).sort(function (a: HeaderSection, b: HeaderSection) { return a.listOrder - b.listOrder })
      this.linkSections = subProfile.linkSections.map(s => new LinkSection(s)).sort(function (a: LinkSection, b: LinkSection) { return a.listOrder - b.listOrder })
      this.newsSections = subProfile.newsSections.map(s => new NewsSection(s)).sort(function (a: NewsSection, b: NewsSection) { return a.listOrder - b.listOrder })
      this.socialsSections = subProfile.socialsSections.map(s => new SocialsSection(s)).sort(function (a: SocialsSection, b: SocialsSection) { return a.listOrder - b.listOrder })
      this.textSections = subProfile.textSections.map(s => new TextSection(s)).sort(function (a: TextSection, b: TextSection) { return a.listOrder - b.listOrder })
      this.tableSections = subProfile.tableSections.map(s => new TableSection(s)).sort(function (a: TableSection, b: TableSection) { return a.listOrder - b.listOrder })
      // this.userDetailSections = subProfile.userDetailSections.map(s => new UserDetailSection(s)).sort(function (a: UserDetailSection, b: UserDetailSection) { return a.listOrder - b.listOrder })

      for (let i = 0; i < this.amazonReviewCTASections.length + this.buttonSections.length + this.embeddedVideoSections.length + this.imageSections.length + this.headerSections.length + this.linkSections.length + this.newsSections.length + this.socialsSections.length + this.textSections.length + this.tableSections.length + this.featuresSections.length; i++) {
        const amazonReviewCTASection = this.amazonReviewCTASections.find((s: AmazonReviewCTASection) => s.listOrder === i)
        if (amazonReviewCTASection !== undefined) { this.sections.push({ type: 7, section: amazonReviewCTASection }); continue }

        const buttonSection = this.buttonSections.find((s: ButtonSection) => s.listOrder === i)
        if (buttonSection !== undefined) { this.sections.push({ type: 9, section: buttonSection }); continue }

        const embeddedVideoSection = this.embeddedVideoSections.find((s: EmbeddedVideoSection) => s.listOrder === i)
        if (embeddedVideoSection !== undefined) { this.sections.push({ type: 10, section: embeddedVideoSection }); continue }

        const imageSection = this.imageSections.find((s: ImageSection) => s.listOrder === i)
        if (imageSection !== undefined) { this.sections.push({ type: 0, section: imageSection }); continue }

        const headerSection = this.headerSections.find((s: HeaderSection) => s.listOrder === i)
        if (headerSection !== undefined) { this.sections.push({ type: 1, section: headerSection }); continue }

        const linkSection = this.linkSections.find((s: LinkSection) => s.listOrder === i)
        if (linkSection !== undefined) { this.sections.push({ type: 8, section: linkSection }); continue }

        const newsSection = this.newsSections.find((s: NewsSection) => s.listOrder === i)
        if (newsSection !== undefined) { this.sections.push({ type: 11, section: newsSection }); continue }

        const socialsSection = this.socialsSections.find((s: SocialsSection) => s.listOrder === i)
        if (socialsSection !== undefined) { this.sections.push({ type: 4, section: socialsSection }); continue }

        const textSection = this.textSections.find((s: TextSection) => s.listOrder === i)
        if (textSection !== undefined) { this.sections.push({ type: 2, section: textSection }); continue }

        const tableSection = this.tableSections.find((s: TableSection) => s.listOrder === i)
        if (tableSection !== undefined) { this.sections.push({ type: 5, section: tableSection }); continue }

        /* const detailSection = this.userDetailSections.find((s: UserDetailSection) => s.listOrder === i)
        if (detailSection !== undefined) { this.sections.push({ type: 3, section: detailSection }); continue } */

        const featuresSection = this.featuresSections.find((s: FeaturesSection) => s.listOrder === i)
        if (featuresSection !== undefined) { this.sections.push({ type: 6, section: featuresSection }); continue }
      }
      this.theme = new SubProfileTheme(subProfile.theme)
    }
  }

  getUrl () {
    return `https://www.keyp.in/qr/${this.id}`
  }

  getTruncatedLabel () {
    const maxLength = 50
    if (this.label.length <= maxLength) {
      return this.label
    }

    // Find the last space within the maxLength
    const lastSpaceIndex = this.label.lastIndexOf(' ', maxLength)

    // If there is a space within the maxLength, truncate at that position
    if (lastSpaceIndex !== -1) {
      return this.label.substring(0, lastSpaceIndex) + '...'
    }

    // If there is no space within the maxLength, simply truncate at the maxLength
    return this.label.substring(0, maxLength) + '...'
  }
}
