<template>
  <div class="tag-container" :style="cssVars">
    <slot/>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TagComponent',
  props: {
    backgroundColor: {
      type: String,
      default: 'white'
    },
    color: {
      type: String,
      default: 'black'
    },
    style: {
      type: Object,
      default: null
    }
  },
  computed: {
    cssVars () {
      return {
        '--background-color': this.backgroundColor,
        '--color': this.color,
        ...this.style
      }
    }
  }
})
</script>

<style scoped lang="scss">
  div.tag-container {
    background-color: var(--background-color);
    color: var(--color);
    position: absolute;
    width: 76px;
    height: 20px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 10px; /* 100% */
  }

  </style>
