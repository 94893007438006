<template>
  <head>
</head>

</template>

<script lang="ts">
import { defineComponent } from 'vue'
import VueMeta from 'vue-meta'

import SubProfile from '@/models/SubProfile'

import { fetchSubProfileFromQr } from '@/api'

export default defineComponent({
  name: 'QrTempView',
  metaInfo: {
    title: 'yerd',
    titleTemplate: null
  },
  mounted () {
    fetchSubProfileFromQr(
      String(this.$route.params.id),
      () => this.$router.push('/login'),
      () => this.$router.push('/not-found'),
      () => this.$router.back(),
      () => this.$router.push('/server-error'),
      (response) => {
        console.log(JSON.stringify(response))
        const subProfile = new SubProfile(response.data.data.subProfile)
        const qrScanId = response.data.data.qrScanId
        const accessType = response.data.data.accessType

        if (subProfile.qrRedirect.active) {
          window.location.href = subProfile.qrRedirect.url
        }

        this.$store.commit('setCurrentSubProfile', subProfile)
        this.$store.commit('setCurrentQrScanId', qrScanId)
        this.$store.commit('setCurrentAccessType', accessType)
        this.$router.push({ name: 'SubProfile', params: { name: subProfile.name, subProfileSuffix: subProfile.id.slice(-2) } })
      })
  }
})
</script>
