import axios from 'axios'

export const BASE_URL = 'https://www.keypin.tech:8000/api/'
export const BASE_URL_UNAUTHORISED = 'https://www.keypin.tech:8000/'

export * from './QrRedirect'
export * from './QrSectionAmazonReviewCTA'
export * from './QrSectionButton'
export * from './QrSectionEmbeddedVideo'
export * from './QrSectionHeader'
export * from './QrSectionFeatures'
export * from './QrSectionFeaturesItem'
export * from './QrSectionImage'
export * from './QrSectionLink'
export * from './QrSectionNews'
export * from './QrSectionNewsItem'
export * from './QrSectionSocials'
export * from './QrSectionSocialsItem'
export * from './QrSectionTable'
export * from './QrSectionText'

function authorisedGet (
  url : string,
  token : string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback : () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void) {
  console.log('will fetch ' + url)
  axios(
    { method: 'get', url: BASE_URL + url, withCredentials: false, headers: { Authorization: `bearer ${token}` } })
    .then((response) => {
      callback(response)
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        if (error.response.status === 401 || error.response.status === 403) {
          unauthorisedCallback()
        } else if (error.response.status === 404) {
          notFoundCallback()
        } else if (error.response.status === 400) {
          badRequestCallback()
        } else if (error.response.status === 500) {
          serverErrorCallback()
        }
      } else {
        console.log(error)
      }
    })
}

function authorisedGetBlob (
  url : string,
  token : string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback : () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void) {
  axios(
    { method: 'get', url: BASE_URL + url, withCredentials: false, responseType: 'blob', headers: { Authorization: `bearer ${token}` } })
    .then((response) => {
      callback(response)
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        if (error.response.status === 401 || error.response.status === 403) {
          unauthorisedCallback()
        } else if (error.response.status === 404) {
          notFoundCallback()
        } else if (error.response.status === 400) {
          badRequestCallback()
        } else if (error.response.status === 500) {
          serverErrorCallback()
        }
      } else {
        console.log(error)
      }
    })
}

function getBlob (
  url : string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback : () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void) {
  axios(
    { method: 'get', url: BASE_URL_UNAUTHORISED + url, withCredentials: false, responseType: 'blob' })
    .then((response) => {
      callback(response)
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        if (error.response.status === 401 || error.response.status === 403) {
          unauthorisedCallback()
        } else if (error.response.status === 404) {
          notFoundCallback()
        } else if (error.response.status === 400) {
          badRequestCallback()
        } else if (error.response.status === 500) {
          serverErrorCallback()
        }
      } else {
        console.log(error)
      }
    })
}

function authorisedPost (
  url : string,
  data: any,
  token : string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  console.log('will post ' + JSON.stringify(data))
  console.log('to ' + BASE_URL + url)
  axios(
    { method: 'post', url: BASE_URL + url, data: data, withCredentials: false, headers: { Authorization: `bearer ${token}` } })
    .then((response) => {
      callback(response)
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        if (error.response.status === 401 || error.response.status === 403) {
          unauthorisedCallback()
        } else if (error.response.status === 404) {
          notFoundCallback()
        } else if (error.response.status === 400) {
          badRequestCallback()
        } else if (error.response.status === 500) {
          serverErrorCallback()
        }
      } else {
        console.log(error)
      }
    })
}

function get (
  url : string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  console.log('will get: ' + BASE_URL_UNAUTHORISED + url)
  axios(
    { method: 'get', url: BASE_URL_UNAUTHORISED + url, withCredentials: false })
    .then((response) => {
      callback(response)
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        if (error.response.status === 401 || error.response.status === 403) {
          unauthorisedCallback()
        } else if (error.response.status === 404) {
          notFoundCallback()
        } else if (error.response.status === 400) {
          badRequestCallback()
        } else if (error.response.status === 500) {
          serverErrorCallback()
        }
      } else {
        console.log(error)
      }
    })
}

function post (url : string,
  data: any,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  axios(
    { method: 'post', url: url, data: data, withCredentials: false })
    .then((response) => {
      callback(response)
    }).catch((error) => {
      console.log(error.response)
      if (error.response.status === 401 || error.response.status === 403) {
        unauthorisedCallback()
      } else if (error.response.status === 404) {
        notFoundCallback()
      } else if (error.response.status === 400) {
        badRequestCallback()
      } else if (error.response.status === 500) {
        serverErrorCallback()
      }
    })
}

export function postLoginEmail (
  email : string,
  password : string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  post(BASE_URL + 'keypin-email', { value: email }, unauthorisedCallback, notFoundCallback, badRequestCallback, serverErrorCallback, (response) => {
    post(BASE_URL + 'login_check', { username: response.data.data.value, password: password }, unauthorisedCallback, notFoundCallback, badRequestCallback, serverErrorCallback, callback)
  })
}

export function postRegistration (
  email: string,
  name: string,
  regCode: string,
  password: string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  post(BASE_URL + 'onboarding/register-web', { firstName: name, lastName: '', email: email, password: password, regCode: regCode }, unauthorisedCallback, notFoundCallback, badRequestCallback, serverErrorCallback, callback)
}

export function postEmailVerification (
  email: string,
  code: string,
  token: string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  authorisedPost(BASE_URL + 'verification/verify-email', { email: email, code: code }, token, unauthorisedCallback, notFoundCallback, badRequestCallback, serverErrorCallback, callback)
}

export function fetchDashboard (
  token : string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  authorisedGet('dashboard', token, unauthorisedCallback, notFoundCallback, badRequestCallback, serverErrorCallback, callback)
}

export function fetchSubProfile (
  name: string,
  suffix: string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  if (!(suffix === 'undefined')) get(`profile/link/${encodeURIComponent(name)}/${encodeURIComponent(suffix)}`, unauthorisedCallback, notFoundCallback, badRequestCallback, serverErrorCallback, callback)
  else get(`profile/link/${encodeURIComponent(name)}`, unauthorisedCallback, notFoundCallback, badRequestCallback, serverErrorCallback, callback)
}

export function fetchSubProfileFromQr (
  id: string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  get(`profile/qr/${encodeURIComponent(id)}`, unauthorisedCallback, notFoundCallback, badRequestCallback, serverErrorCallback, callback)
}
export function postSubProfile (
  name: string,
  label: string,
  preTitle: string,
  subTitle: string,
  description: string,
  token : string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  const data = {
    name: name,
    label: label,
    pretitle: preTitle,
    subtitle: subTitle,
    description: description
  }
  authorisedPost('dashboard/create-sub', data, token, unauthorisedCallback, badRequestCallback, serverErrorCallback, notFoundCallback, callback)
}

export function deleteSubProfile (
  id: string,
  token : string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  authorisedGet(`dashboard/${id}/delete`, token, unauthorisedCallback, notFoundCallback, badRequestCallback, serverErrorCallback, callback)
}

export function toggleActiveSubProfile (
  id: string,
  token : string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  authorisedGet(`dashboard/${id}/toggle-active`, token, unauthorisedCallback, notFoundCallback, badRequestCallback, serverErrorCallback, callback)
}

export function createSection (
  subProfileId: string,
  type: number,
  token : string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  const data = {
    type
  }
  authorisedPost(`dashboard/${subProfileId}`, data, token, unauthorisedCallback, badRequestCallback, serverErrorCallback, notFoundCallback, callback)
}

export function editQrSection (
  subProfileId: string,
  id: number,
  label: string,
  listOrder: number,
  active: boolean,
  labelVisible: boolean,
  token : string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  const data = {
    id,
    label,
    listOrder,
    active,
    labelVisible
  }
  authorisedPost(`dashboard/${subProfileId}/edit-qr-section?section=${id}`, data, token, unauthorisedCallback, badRequestCallback, serverErrorCallback, notFoundCallback, callback)
}

export function editUserDetailsSection (
  subProfileId: string,
  id: number,
  label: string,
  listOrder: number,
  active: boolean,
  token : string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  const data = {
    id,
    label,
    listOrder,
    active,
    labelVisible: false
  }
  authorisedPost(`dashboard/${subProfileId}/edit-user-details`, data, token, unauthorisedCallback, badRequestCallback, serverErrorCallback, notFoundCallback, callback)
}

export function deleteSection (
  subProfileId: string,
  id: number,
  token : string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  if (!id) return
  authorisedGet(`dashboard/${subProfileId}/${id}/delete`, token, unauthorisedCallback, badRequestCallback, serverErrorCallback, notFoundCallback, callback)
}

export function fetchAnalyticsDashboard (
  token : string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  authorisedGet('analytics', token, unauthorisedCallback, notFoundCallback, badRequestCallback, serverErrorCallback, callback)
}

export function fetchAnalyticsDateRange (
  id: string,
  dateStart: string,
  dateEnd: string,
  token : string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  authorisedGet(`analytics/${id}?start=${dateStart}&end=${dateEnd}`, token, unauthorisedCallback, notFoundCallback, badRequestCallback, serverErrorCallback, callback)
}

export function postTracker (
  name: string,
  subProfileId: string,
  token : string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  authorisedPost(
    'analytics/pixels/create',
    { name: name, subProfileId: subProfileId },
    token,
    unauthorisedCallback,
    notFoundCallback,
    badRequestCallback,
    serverErrorCallback,
    callback
  )
}

export function fetchSubProfileDashboard (
  id : string,
  token : string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  authorisedGet(`dashboard/${encodeURIComponent(id)}`, token, unauthorisedCallback, notFoundCallback, badRequestCallback, serverErrorCallback, callback)
}

export function postSectionOrder (
  sectionOrder : Array<number>,
  subProfileId : string,
  token : string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  authorisedPost(
    `dashboard/${encodeURIComponent(subProfileId)}/section-order`,
    { sectionOrder },
    token,
    unauthorisedCallback,
    notFoundCallback,
    badRequestCallback,
    notFoundCallback,
    callback
  )
}

export function deleteSubProfileImage (
  subProfileId: string,
  token : string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  authorisedGet(
    `dashboard/${encodeURIComponent(subProfileId)}/delete-sub-image`,
    token,
    unauthorisedCallback,
    notFoundCallback,
    badRequestCallback,
    serverErrorCallback,
    callback
  )
}

export function postHeader (
  name: string,
  preTitle: string,
  subTitle: string,
  description: string,
  subProfileId: string,
  token : string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  authorisedPost(
    'dashboard/edit-sub',
    { name: name, label: name, preTitle: preTitle, subTitle: subTitle, description: description, id: subProfileId },
    token,
    unauthorisedCallback,
    notFoundCallback,
    badRequestCallback,
    serverErrorCallback,
    callback
  )
}

export function postThemePreset (
  themeId : number,
  subProfileId : string,
  token : string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  authorisedPost(
    'dashboard/theme/preset',
    { themeId: themeId, subProfileId: subProfileId },
    token,
    unauthorisedCallback,
    notFoundCallback,
    badRequestCallback,
    serverErrorCallback,
    callback)
}

export function postThemeColour (
  target: string,
  colour: string,
  subProfileId : string,
  token : string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  authorisedPost(
    'dashboard/theme/colour',
    { target: target, colour: colour, subProfileId: subProfileId },
    token,
    unauthorisedCallback,
    notFoundCallback,
    badRequestCallback,
    serverErrorCallback,
    callback)
}

export function postDetail (
  category: string,
  type: string,
  label: string,
  value: string,
  subProfileId: string,
  token: string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  authorisedPost(
    'details/create',
    { category: category, type: type, label: label, value: value, subProfileId: subProfileId },
    token,
    unauthorisedCallback,
    notFoundCallback,
    badRequestCallback,
    serverErrorCallback,
    callback
  )
}

export function postBatchDetail (
  details: {category: string, type: string, label: string, value: string, qrSectionId: number}[],
  token: string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  authorisedPost(
    'details/create-batch',
    { details },
    token,
    unauthorisedCallback,
    notFoundCallback,
    badRequestCallback,
    serverErrorCallback,
    callback
  )
}

export function putDetail (
  id: string,
  category: string,
  type: string,
  label: string,
  value: string,
  qrSectionId: number,
  token: string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  authorisedPost(
    `details/${encodeURIComponent(id)}/edit`,
    { category: category, type: type, label: label, value: value, qrSectionId: qrSectionId },
    token,
    unauthorisedCallback,
    notFoundCallback,
    badRequestCallback,
    notFoundCallback,
    callback
  )
}

export function deleteDetail (
  id: string,
  sectionId: number,
  token: string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  authorisedGet(
    `details/${encodeURIComponent(sectionId)}/${encodeURIComponent(id)}/delete`,
    token,
    unauthorisedCallback,
    notFoundCallback,
    badRequestCallback,
    notFoundCallback,
    callback
  )
}

export function postDetailOrder (
  detailOrder : Array<string>,
  sectionId: number,
  token : string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  authorisedPost(
    'details/order',
    { detailOrders: detailOrder, qrSectionId: sectionId },
    token,
    unauthorisedCallback,
    notFoundCallback,
    badRequestCallback,
    notFoundCallback,
    callback
  )
}

export function deleteSubProfileHeaderListItem (
  itemId : string,
  subProfileId : string,
  token : string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  authorisedGet(
    `dashboard/${encodeURIComponent(subProfileId)}/delete-sub-profile-header-list-item/${encodeURIComponent(itemId)}`,
    token,
    unauthorisedCallback,
    notFoundCallback,
    badRequestCallback,
    notFoundCallback,
    callback
  )
}

export function postHeaderListItem (
  value: string,
  image: File,
  subProfileId: string,
  token : string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  authorisedPost(
    'dashboard/create-sub-profile-header-list-item',
    { value: value, subProfileId: subProfileId },
    token,
    unauthorisedCallback,
    notFoundCallback,
    badRequestCallback,
    notFoundCallback,
    (response) => {
      const createdId = response.data.data.createdId
      const data = new FormData()
      data.append('file', image)
      authorisedPost(
        `dashboard/${encodeURIComponent(subProfileId)}/edit-header-list-item-image/${encodeURIComponent(createdId)}`,
        data,
        token,
        unauthorisedCallback,
        notFoundCallback,
        badRequestCallback,
        notFoundCallback,
        callback
      )
    }
  )
}

export function postHeaderListItemOrder (
  itemOrder : Array<string>,
  subProfileId : string,
  token : string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  authorisedPost(
    'dashboard/edit-header-list-item-order',
    { itemOrders: itemOrder, subProfileId: subProfileId },
    token,
    unauthorisedCallback,
    notFoundCallback,
    badRequestCallback,
    notFoundCallback,
    callback
  )
}

export function getQrDownload (
  type: string,
  subProfileId: string,
  token: string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  authorisedGetBlob(
    `dashboard/qr-file/${encodeURIComponent(type)}/${encodeURIComponent(subProfileId)}`,
    token,
    unauthorisedCallback,
    notFoundCallback,
    badRequestCallback,
    notFoundCallback,
    callback
  )
}

export function getAnalCsvDownload (
  keypin: string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  getBlob(
    `profile/csv-anon?keypin=${encodeURIComponent(keypin)}`,
    unauthorisedCallback,
    notFoundCallback,
    badRequestCallback,
    notFoundCallback,
    callback
  )
}

export function pushDetailClick (
  id: string,
  subProfileId: string,
  qrSectionId: number,
  qrScanId: number,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  post(
    BASE_URL_UNAUTHORISED + `profile/${encodeURIComponent(subProfileId)}/push-click`,
    {
      qrScanId: qrScanId,
      qrSectionId: qrSectionId,
      linkId: id
    },
    unauthorisedCallback,
    notFoundCallback,
    badRequestCallback,
    notFoundCallback,
    callback
  )
}
