<template>
    <div>
      <!-- <SideBar /> -->
      <div class="landing-page" data-bs-spy="scroll" data-bs-target=".navbar-landing" data-bs-offset="78">
        <!--Navbar Start-->
        <nav class="navbar navbar-expand-lg fixed-top navbar-landing sticky-dark" id="sticky">
            <div class="container-fluid">
                <!-- LOGO -->
                <a class="logo text-uppercase" href="/">
                    <img :src="require('@/assets/logo.png')" alt="" class="logo-light" height="21" />
                    <img :src="require('@/assets/logo.png')" alt="" class="logo-dark" height="21" />
                </a>

                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                    <i class="mdi mdi-menu"></i>
                </button>
                <div class="collapse navbar-collapse" id="navbarCollapse">
                    <ul class="navbar-nav mx-auto navbar-center" id="mySidenav">
                        <li class="nav-item">
                            <a href="#home" class="nav-link">Home</a>
                        </li>
                        <li class="nav-item">
                            <a href="#features" class="nav-link">Features</a>
                        </li>
                    </ul>
                    <a class="btn btn-info navbar-btn" @click="onLogin">Dashboard</a>
                </div>
            </div>
        </nav>
        <!-- Navbar End -->
        <!-- home start -->
        <section class="bg-home bg-gradient" id="home">
            <div class="home-center">
                <div class="home-desc-center">
                    <div class="container-fluid">
                        <div class="row align-items-center">
                            <div class="col-lg-6">
                                <div class="home-title mo-mb-20">
                                    <h1 class="mb-4 text-white">Introducing KeyPin - Revolutionising Customer Engagement and Analytics for Printed Media</h1>
                                    <p class="text-white-50 home-desc mb-5">At KeyPin, we are on a mission to transform the way businesses interact with their customers through printed media. Our cutting-edge tech startup combines the power of printed materials with QR codes to nurture your customer base, increase conversions, and provide comprehensive analytics to provide you with actionable insights and measurable results.</p>
                                    <div class="subscribe">
                                        <form>
                                            <div class="row">
                                                <div class="col-md-8">
                                                    <div class="mb-2">
                                                        <input type="text" class="form-control" placeholder="Enter your beta sign-up token" @input="onChangeRegCode">
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <button @click="onRegister" href="/register" class="btn btn-primary pt-2">Register</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-5 offset-lg-1 col-md-7">
                                <div class="home-img position-relative">
                                    <img :src="require('@/assets/images/landing/image-qr-page-1.png')" alt="" class="home-first-img">
                                    <img :src="require('@/assets/images/landing/image-qr-page-1.png')" alt="" class="home-second-img mx-auto d-block">
                                    <img :src="require('@/assets/images/landing/image-qr-page-1.png')" alt="" class="home-third-img">
                                </div>
                            </div>
                        </div>
                        <!-- end row -->
                    </div>
                    <!-- end container-fluid -->
                </div>
            </div>
        </section>
        <!-- home end -->
                <!-- features start -->
                <section class="section-sm" id="features">
            <div class="container-fluid">
                <div class="row justify-content-center">
                    <div class="col-lg-6">
                        <div class="text-center mb-4 pb-1">
                            <h3 class="mb-3">Key Features</h3>
                            <p class="text-muted"></p>
                        </div>
                    </div>
                </div>
                <!-- end row -->
                <div class="row">
                    <div class="col-lg-3 col-md-6">
                        <div class="features-box">
                            <div class="features-img mb-4">
                                <img :src="require('@/assets/icons/landing/icon-relations.png')" alt="">
                            </div>
                            <h4 class="mb-3">Customer Relationships</h4>
                            <p class="text-muted">Build strong relationships with your customers by improving their interaction with printed media through KeyPin’s immersive landing pages.
                                KeyPin enables you to tailor your printed medias landing page, with easy access to media specific links, social medias and videos.</p>
                        </div>
                    </div>
                    <!-- end col -->
                    <div class="col-lg-3 col-md-6">
                        <div class="features-box">
                            <div class="features-img mb-4">
                                <img :src="require('@/assets/icons/landing/icon-conversion.png')" alt="">
                            </div>
                            <h4 class="mb-2">Conversion Optimization</h4>
                            <p class="text-muted">Increase your conversion rates by incorporating QR codes into your printed media.
                                KeyPin helps you create engaging experiences that drive action and encourage customers to take the next step.</p>
                        </div>
                    </div>
                    <!-- end col -->
                    <div class="col-lg-3 col-md-6">
                        <div class="features-box">
                            <div class="features-img mb-4">
                                <img :src="require('@/assets/icons/landing/icon-analytics.png')" alt="">
                            </div>
                            <h4 class="mb-2">Analytics and Insights</h4>
                            <p class="text-muted">Measure the impact of your product portfolio or printed media campaigns with KeyPin's robust analytics tools.
                                Gain valuable insights into customer behavior, track campaign performance, and make data-driven decisions to optimize your marketing efforts.</p>
                        </div>
                    </div>
                    <!-- end col -->
                    <div class="col-lg-3 col-md-6">
                        <div class="features-box">
                            <div class="features-img mb-4">
                                <img :src="require('@/assets/icons/landing/icon-integration.png')" alt="">
                            </div>
                            <h4 class="mb-2">Seamless Integration</h4>
                            <p class="text-muted">KeyPin seamlessly integrates with your current business processes & QR codes can be quickly integrated into current or new packaging. </p>
                        </div>
                    </div>
                    <!-- end col -->
                </div>
                <!-- end row -->
            </div> <!-- end container-fluid -->
        </section>
        <!-- features end -->
        <!-- power-up/tracking start -->
        <section class="section">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="feature-img">
                            <img :src="require('@/assets/images/landing/image-dashboard-1.png')" alt="" class="img-fluid mx-auto d-block">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="p-5 features-content">
                            <h1 class="mb-3">Power up your products</h1>
                            <ul class="landing-feature-list">
                                <li><h4 class="text-muted mb-3">No more product inserts (eco friendly & saves printing costs)</h4></li>
                                <li><h4 class="text-muted mb-3">Unique QR & KeyPin landing page for each product, helping you understand the performance of each product individually</h4></li>
                                <li><h4 class="text-muted mb-3">Add digital product information leaflets or instructions to your landing page</h4></li>
                                <li><h4 class="text-muted mb-3">Add QR specific videos to help nurture your community</h4></li>
                                <li><h4 class="text-muted mb-3">Analyse customer engagement with the KeyPin analytics dashboard</h4></li>
                                <li><h4 class="text-muted mb-3">Make more space on your packaging learn more</h4></li>
                                <li><h4 class="text-muted mb-3">Adaptable & flexible landing pages, allowing you to make updates after printing learn more</h4></li>
                            </ul>
                            <a href="#" class="btn btn-primary btn-sm">Learn More <i class="mdi mdi-arrow-right"></i></a>
                        </div>
                    </div>
                </div>
                <!-- end row -->

                <div class="row align-items-center mt-5">
                    <div class="col-lg-6">
                        <div class="p-5 features-content">
                            <h1 class="mb-3">Start tracking your offline media with KeyPin</h1>
                            <ul class="landing-feature-list">
                                <li><h4 class="text-muted mb-3">Unlimited QRs for your business, which can be used on offline media, such as packaging, leaflets, posters, billboards learn more</h4></li>
                                <li><h4 class="text-muted mb-3">Your portfolio of QRs are shown on your dashboard, where you can create unique landing pages and track the performance of all of your KeyPin pages</h4></li>
                                <li><h4 class="text-muted mb-3">Start tracking your offline media today</h4></li>
                            </ul>
                            <a href="#" class="btn btn-primary btn-sm">Learn More <i class="mdi mdi-arrow-right"></i></a>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="feature-img">
                            <img :src="require('@/assets/images/landing/image-analytics-1.png')" alt="" class="img-fluid mx-auto d-block">
                        </div>
                    </div>
                </div>
                <!-- end row -->
            </div>
            <!-- end container-fluid -->
        </section>
        <!-- power-up/tracking end -->
        <!-- about start -->
        <section class="section-sm" id="features">
            <div class="container-fluid">
                <div class="row justify-content-center">
                    <div class="col-lg-6">
                        <div class="text-center mb-4 pb-1">
                            <h3 class="mb-3"></h3>
                            <h3 class="text-muted mb-5">In today's fast-paced digital world, it's easy to overlook the potential of traditional printed media.
                                However, we believe that it remains a valuable channel for businesses to connect with their target audience in a meaningful and impactful way.
                                With KeyPin, you can harness the power of printed media like never before..</h3>
                            <h3 class="text-muted mb-5">Our innovative platform empowers you to nurture your customers and create engaging experiences that drive conversions.
                                We provide you with a suite of tools and features designed to captivate your audience and inspire action.
                                From personalized messaging to interactive content, KeyPin helps you craft compelling landing pages for your printed media,
                                that resonate with your customers and leave a lasting impression.</h3>
                            <h3 class="text-muted mb-5">But it doesn't stop there. KeyPin goes beyond the creation and distribution of printed media.
                                Our advanced analytics capabilities allow you to measure the effectiveness of your campaigns, track key metrics, and gain valuable insights into customer behavior.
                                With our intuitive dashboard, you can monitor engagement, conversion rates, and ROI,
                                enabling you to make data-driven decisions and optimize your marketing strategies for maximum impact.</h3>
                        </div>
                    </div>
                </div>
                <!-- end row -->
            </div> <!-- end container-fluid -->
        </section>
        <!-- aboutend -->
      </div>
    </div>
  </template>
<script lang="ts">
import { fetchDashboard } from '@/api'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LandingView',
  methods: {
    onChangeRegCode (event) {
      this.$store.commit('setRegCode', event.target.value)
    },
    onRegister () {
      this.$router.push('/register')
    },
    onLogin () {
      this.$router.push('/dashboard')
    },
    windowScroll () {
      const navbar = document.getElementById('sticky')
      if (navbar) {
        if (document.body.scrollTop >= 50 || document.documentElement.scrollTop >= 50) {
          navbar.classList.add('nav-sticky')
        } else {
          navbar.classList.remove('nav-sticky')
        }
      }
    }
  },
  mounted () {
    window.addEventListener('scroll', (ev) => {
      ev.preventDefault()
      this.windowScroll()
    })

    fetchDashboard(
      this.$store.state.token,
      () => this.$router.push('/login'),
      () => this.$router.push('/not-found'),
      () => this.$router.back(),
      () => this.$router.push('/server-error'),
      (response) => {
        // this.configureFromResponse(response)
      })
  }
})
</script>

<style scoped lang="scss">
</style>
