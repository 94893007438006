import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e039af5e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  id: "container"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = { id: "content" }
const _hoisted_4 = { id: "icon-label" }
const _hoisted_5 = { style: {"height":"85%","width":"100%"} }
const _hoisted_6 = { style: {"height":"25%","width":"100%"} }
const _hoisted_7 = { href: "google.co.uk" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { href: "google.co.uk" }
const _hoisted_10 = ["src"]
const _hoisted_11 = { href: "google.co.uk" }
const _hoisted_12 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconLabel = _resolveComponent("IconLabel")!

  return (_ctx.profile && _ctx.colorScheme)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("img", {
          id: "avatar",
          src: _ctx.avatar
        }, null, 8, _hoisted_2),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_IconLabel, {
              text: _ctx.profile.keypin,
              image: "icons/main/icon.png",
              color: _ctx.colorScheme.foreground
            }, null, 8, ["text", "color"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("p", {
              style: _normalizeStyle(_ctx.cssVars)
            }, _toDisplayString(_ctx.profile.firstName + " " + _ctx.profile.lastName), 5),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("a", _hoisted_7, [
                _createElementVNode("img", {
                  class: "pdtemp",
                  src: require(`@/assets/icons/details/core/icon_website.png`)
                }, null, 8, _hoisted_8)
              ]),
              _createElementVNode("a", _hoisted_9, [
                _createElementVNode("img", {
                  class: "pdtemp",
                  src: require(`@/assets/icons/details/socials/icon_facebook.png`)
                }, null, 8, _hoisted_10)
              ]),
              _createElementVNode("a", _hoisted_11, [
                _createElementVNode("img", {
                  class: "pdtemp",
                  src: require(`@/assets/icons/details/services/icon_amazon.png`)
                }, null, 8, _hoisted_12)
              ])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}