import { authorisedPost } from './Api'

export function editFeaturesSectionStyle (
  subProfileId: string,
  id: number,
  layout: string,
  fillColour: string,
  token : string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  const data = {
    layout,
    fillColour
  }
  authorisedPost(`dashboard/${subProfileId}/edit-features-style?section=${id}`, data, token, unauthorisedCallback, badRequestCallback, serverErrorCallback, notFoundCallback, callback)
}
