<template>
    <EditorPanel
      :title="buttonSection.label"
      :active="buttonSection.active"
      :persistent="buttonSection.persistent"
      :styleMode="styleMode"
      @onSetActive="onSetActive"
      @onDelete="onDelete"
      @onToggleMode="onToggleMode"
      >
      <div v-if="!this.styleMode" class="d-flex flex-column">
        <EditorInputComponent label="Button Text" :value="buttonSection.text" @onChangeValue="onEditText"/>
        <EditorInputComponent label="URL" :value="buttonSection.url" @onChangeValue="onEditUrl"/>
        </div>
      <div v-else class="d-flex flex-column button-style-panel-content">
        <div class="d-flex button-style-panel-row align-items-center">
            <p class="style-header d-flex flex-fill">Colour</p>
            <div class="d-flex align-items-center button-style-panel-row-group-br" style="padding: 0 10px 0 0">
              <ColourInput :label="'Text'" :value="buttonSection.style.textColour" @onChangeColour="onEditTextColour"/>
            </div>
            <div class="d-flex" style="padding: 0 0 0 10px">
              <ColourInput :label="'Fill'" :value="buttonSection.style.fillColour" @onChangeColour="onEditFillColour"/>
            </div>
          </div>
        <div class="d-flex button-style-panel-row align-items-center">
            <p class="style-header d-flex flex-fill">Style</p>
            <div class="d-flex align-items-center button-style-panel-row-group-br" style="padding: 0 10px 0 2px">
              <StyleTextButton
                :selected="buttonSection.style.style == 'solid'"
                @click="() => onEditStyle('solid')"
                style="width: 94px; border-radius: 0">
                Solid
              </StyleTextButton>
              <StyleTextButton
                :selected="buttonSection.style.style == 'outline'"
                @click="() => onEditStyle('outline')"
                style="width: 94px; border-radius: 0; background-color: #FFFFFF; box-shadow: 0px 0px 0px 2px rgba(33, 37, 41, 0.20) inset">
                Outline
              </StyleTextButton>
              <StyleTextButton
                :selected="buttonSection.style.style == 'pill'"
                @click="() => onEditStyle('pill')"
                style="width: 94px; border-radius: 18px">
                Pill
              </StyleTextButton>
            </div>
            <div class="d-flex" style="padding: 0 0 0 2px">
              <StyleTextButton
                :selected="buttonSection.style.corners == 'sharp'"
                @click="() => onEditCorners('sharp')"
                style="width: 108px; border-radius: 0">
                Sharp Corners
              </StyleTextButton>
              <StyleTextButton
                :selected="buttonSection.style.corners == 'rounded'"
                @click="() => onEditCorners('rounded')"
                style="width: 135px">
                Rounded Corners
              </StyleTextButton>
            </div>
          </div>
          <div class="d-flex button-style-panel-row align-items-center">
            <p class="style-header d-flex flex-fill">Size & Align</p>
            <div class="d-flex align-items-center button-style-panel-row-group-br" style="padding: 0 10px 0 2px">
              <StyleTextButton
                :selected="buttonSection.style.size == 'sm'"
                @click="() => onEditSize('sm')"
                style="width: 30px; height: 30px">
                S
              </StyleTextButton>
              <StyleTextButton
                :selected="buttonSection.style.size == 'md'"
                @click="() => onEditSize('md')"
                style="width: 36px; height: 36px;">
                M
              </StyleTextButton>
              <StyleTextButton
                :selected="buttonSection.style.size == 'lg'"
                @click="() => onEditSize('lg')"
                style="width: 40px;">
                L
              </StyleTextButton>
            </div>
            <div class="d-flex align-items-center" style="padding: 0 10px 0 2px">
              <StyleIconButton :icon="'style-align-left'" :selected="buttonSection.style.align == 'left'" @click="() => onEditAlign('left')"/>
              <StyleIconButton :icon="'style-align-center'" :selected="buttonSection.style.align == 'center'" @click="() => onEditAlign('center')"/>
              <StyleIconButton :icon="'style-align-right'" :selected="buttonSection.style.align == 'right'" @click="() => onEditAlign('right')"/>
              <StyleIconButton :icon="'style-align-fill'" :selected="buttonSection.style.align == 'fill'" @click="() => onEditAlign('fill')"/>
            </div>
          </div>
        </div>
      </EditorPanel>
</template>

<script lang="ts">

import { defineComponent } from 'vue'

import ColourInput from '../input/ColourInput.vue'
import CrossButton from '../button/CrossButton.vue'
import DebouncedTextInput from '../input/DebouncedTextInput.vue'
import EditorPanel from '@/components/editor/EditorPanel.vue'
import EditorNumInputComponent from '../input/EditorNumInputComponent.vue'
import EditorNumSelectComponent from '../input/EditorNumSelectComponent.vue'
import FaIconButton from '../button/FaIconButton.vue'
import StyleTextButton from '../button/style-panel/StyleTextButton.vue'
import StyleIconButton from '../button/style-panel/StyleIconButton.vue'

import ButtonSection from '@/models/qr-sections/ButtonSection'
import { SlickItem, SlickList, HandleDirective } from 'vue-slicksort'
import EditorInputComponent from '../input/EditorInputComponent.vue'

export default defineComponent({
  name: 'ButtonPanel',
  components: {
    ColourInput,
    EditorInputComponent,
    EditorPanel,
    StyleIconButton,
    StyleTextButton
  },
  props: {
    buttonSection: {
      type: ButtonSection,
      required: true
    }
  },
  data () {
    return {
      styleMode: false
    }
  },
  computed: {
    styleDark () {
      return this.buttonSection.style.theme === 'dark'
    }
  },
  methods: {
    onToggleMode (emit = true) {
      this.styleMode = !this.styleMode
      if (emit) this.$emit('onToggleMode', this.buttonSection.id, this.styleMode)
    },
    onSetActive (value: boolean) {
      this.$emit(
        'onEdit',
        this.buttonSection.id,
        this.buttonSection.label,
        this.buttonSection.listOrder,
        value,
        this.buttonSection.text,
        this.buttonSection.url
      )
    },
    onDelete () {
      this.$emit('onDelete', this.buttonSection.id)
    },
    onEditText (value: string) {
      this.$emit(
        'onEdit',
        this.buttonSection.id,
        this.buttonSection.label,
        this.buttonSection.listOrder,
        this.buttonSection.active,
        value,
        this.buttonSection.url
      )
    },
    onEditUrl (value: string) {
      this.$emit(
        'onEdit',
        this.buttonSection.id,
        this.buttonSection.label,
        this.buttonSection.listOrder,
        this.buttonSection.active,
        this.buttonSection.text,
        value
      )
    },
    onEditTextColour (value: string) {
      this.$emit(
        'onEditStyle',
        this.buttonSection.id,
        value,
        this.buttonSection.style.fillColour,
        this.buttonSection.style.style,
        this.buttonSection.style.corners,
        this.buttonSection.style.size,
        this.buttonSection.style.align
      )
    },
    onEditFillColour (value: string) {
      this.$emit(
        'onEditStyle',
        this.buttonSection.id,
        this.buttonSection.style.textColour,
        value,
        this.buttonSection.style.style,
        this.buttonSection.style.corners,
        this.buttonSection.style.size,
        this.buttonSection.style.align
      )
    },
    onEditStyle (style: string) {
      this.$emit(
        'onEditStyle',
        this.buttonSection.id,
        this.buttonSection.style.textColour,
        this.buttonSection.style.fillColour,
        style,
        this.buttonSection.style.corners,
        this.buttonSection.style.size,
        this.buttonSection.style.align
      )
    },
    onEditCorners (corners: string) {
      this.$emit(
        'onEditStyle',
        this.buttonSection.id,
        this.buttonSection.style.textColour,
        this.buttonSection.style.fillColour,
        this.buttonSection.style.style,
        corners,
        this.buttonSection.style.size,
        this.buttonSection.style.align
      )
    },
    onEditSize (size: string) {
      this.$emit(
        'onEditStyle',
        this.buttonSection.id,
        this.buttonSection.style.textColour,
        this.buttonSection.style.fillColour,
        this.buttonSection.style.style,
        this.buttonSection.style.corners,
        size,
        this.buttonSection.style.align
      )
    },
    onEditAlign (align: string) {
      this.$emit(
        'onEditStyle',
        this.buttonSection.id,
        this.buttonSection.style.textColour,
        this.buttonSection.style.fillColour,
        this.buttonSection.style.style,
        this.buttonSection.style.corners,
        this.buttonSection.style.size,
        align
      )
    }
  }
})
</script>

<style scoped>

div.button-style-panel-content {
    flex-shrink: 0;
    width: 100%;
    padding: 10px;
  }

  div.button-style-panel-row {
    width: 100%;
    height: 60px;
    border-top: 1px solid rgba(33, 37, 41, 0.1);
  }

  div.button-style-panel-row-group-br {
    height: 100%;
    border-right: 1px solid rgba(33, 37, 41, 0.1);
  }

  p.style-header {
    color: var(--Nearly-Black, #212529);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    opacity: 0.5;
    margin: 0px;
  }

</style>
