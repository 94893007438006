import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.tableSection)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "table-container d-flex flex-row justify-content-center",
        style: _normalizeStyle(_ctx.cssVars)
      }, [
        _createElementVNode("table", null, [
          _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("th", {
                style: _normalizeStyle(_ctx.cssVarsTL)
              }, _toDisplayString(_ctx.tableSection.value[0][0]), 5),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableSection.value[0].slice(1, -1), (header, index) => {
                return (_openBlock(), _createElementBlock("th", {
                  key: index,
                  style: _normalizeStyle(_ctx.cssVarsTC)
                }, _toDisplayString(header), 5))
              }), 128)),
              _createElementVNode("th", {
                style: _normalizeStyle(_ctx.cssVarsTR)
              }, _toDisplayString(_ctx.tableSection.value[0].slice(-1)[0]), 5)
            ])
          ]),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableSection.value.slice(1, -1), (row, rowIndex) => {
              return (_openBlock(), _createElementBlock("tr", { key: rowIndex }, [
                _createElementVNode("td", {
                  style: _normalizeStyle(_ctx.cssVarsLC)
                }, _toDisplayString(row[0]), 5),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.slice(1, -1), (cell, cellIndex) => {
                  return (_openBlock(), _createElementBlock("td", {
                    style: _normalizeStyle(_ctx.cssVarsCC),
                    key: cellIndex
                  }, _toDisplayString(cell), 5))
                }), 128)),
                _createElementVNode("td", {
                  style: _normalizeStyle(_ctx.cssVarsRC)
                }, _toDisplayString(row.slice(-1)[0]), 5)
              ]))
            }), 128)),
            _createElementVNode("tr", null, [
              _createElementVNode("td", {
                style: _normalizeStyle(_ctx.cssVarsBL)
              }, _toDisplayString(_ctx.tableSection.value[_ctx.tableSection.value.length - 1][0]), 5),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableSection.value[_ctx.tableSection.value.length - 1].slice(1, -1), (cell, cellIndex) => {
                return (_openBlock(), _createElementBlock("td", {
                  style: _normalizeStyle(_ctx.cssVarsBC),
                  key: cellIndex
                }, _toDisplayString(cell), 5))
              }), 128)),
              _createElementVNode("td", {
                style: _normalizeStyle(_ctx.cssVarsBR)
              }, _toDisplayString(_ctx.tableSection.value[_ctx.tableSection.value.length - 1].slice(-1)[0]), 5)
            ])
          ])
        ])
      ], 4))
    : _createCommentVNode("", true)
}