<template>
   <div class="sidebar-link-container" :style="cssVarsContainer" href="/analytics">
        <img class="sidebar-link-icon" :src="srcImg"/>
        <span class="sidebar-link-text" :style="cssVarsText"><slot/></span>
    </div>
  </template>
<script lang="ts">
import Palette from '@/theme/palette'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SideBarLink',
  props: {
    name: {
      type: String,
      default: ''
    },
    src: {
      type: String,
      default: ''
    },
    srcSelected: {
      type: String,
      default: ''
    }
  },
  computed: {
    cssVarsContainer () {
      if (this.name === this.$route.name) return { '--background': 'linear-gradient(116deg, rgba(63, 128, 255, 0.1) 16.27%, rgba(123, 61, 255, 0.1) 83.73%)' }
      else return { '--background': Palette.white }
    },
    cssVarsText () {
      if (this.name === this.$route.name) return { '--font-weight': 600, '--opacity': 1.0 }
      else return { '--font-weight': 400, '--opacity': 1.0 }
    },
    srcImg () {
      if (this.name === this.$route.name) return require(`@/assets/icons/svg/${this.srcSelected}`)
      else return require(`@/assets/icons/svg/${this.src}`)
    }
  }
})
</script>

<style scoped lang="scss">
    div.sidebar-link-container {
        width: 180px;
        height: 34px;
        flex-shrink: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        background: var(--background);
        border: none;
        border-radius: 8px;
        cursor: pointer;
        margin-top: 6px;
    }

    .sidebar-link-icon {
        width: 18px;
        height: 18px;
        flex-shrink: 0;
        opacity: var(--opacity);
        margin: 0 15px 0 15px;
    }

    span.sidebar-link-text {
        color: var(--Nearly-Black, #212529);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: var(--font-weight);
        line-height: 14px; /* 100% */
        opacity: var(--opacity);
    }

  </style>
