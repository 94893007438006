/* danger, primary and sucess colors are from ubold/bootstrap */

const Palette: {[id: string] : string } = {
  black: '#000',
  border_grey: '#787878',
  danger: '#f1556c',
  dark_blue: '#151B54',
  dark_grey: '#525252',
  green: '#00FFB6',
  light_blue: '#58b4ff',
  light_grey: '#c9c9c9',
  primary: '#6658dd',
  success: '#1abc9c',
  ultra_light_grey: '#e6e6e6',
  red: '#d8365b',
  white: '#fff',
  hover_blue: '#0038FF20',
  hover_red: '#fae6ea',
  neutral500: '#21252980',
  neutral800: '#212529',
  neutral900: '#000000',
  blue300: '#2E75FF',
  green100: '#EFF9F1',
  green300: '#65C372',
  transparent: 'transparent',
  gradientBP: 'linear-gradient(135deg, #2E75FF 0%, #7A3DFF 99.99%, #7B3DFF 100%)'
}

export default Palette
