import { Detail } from '../Detail'
import Section from './Section'

interface UserDetailSectionInterface {
  id: number
  label: string
  listOrder: number
  active: boolean
  persistent: boolean
  labelVisible: boolean
  details: Detail[]
}

export default class UserDetailSection extends Section implements UserDetailSectionInterface {
  details = [] as Array<Detail>

  constructor (userDetailSection?: UserDetailSectionInterface) {
    super(userDetailSection)
    if (userDetailSection) {
      this.details = userDetailSection.details.map(d => new Detail(d)).sort(function (a: Detail, b: Detail) { return a.listOrder - b.listOrder })
    }
  }
}
