<template>
    <div class="colour-input-container" :style="cssVars">
        <p class="colour-input-label" :style="cssVarsLabel">{{ this.label }}</p>
        <div class="input-container">
            <DebouncedTextInput
              :value="value"
              type="text"
              @update:value="onChangeColour"
              :container-style="cssInputContainer"
              :wrapper-style="cssInputWrapper"
              :input-style="cssInput"
              />
        </div>
        <div class="colour-indicator align-middle mx-2" :style="indicatorColour"/>
    </div>
  </template>

<script lang="ts">

import { defineComponent } from 'vue'

import DebouncedTextInput from './DebouncedTextInput.vue'
import { hexToRGBA } from '@/utils'

export default defineComponent({
  name: 'ColourInput',
  components: {
    DebouncedTextInput
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: '#000000'
    },
    style: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      internalValue: this.value,
      valid: true
    }
  },
  computed: {
    cssVars () {
      return {
        ...this.style,
        background: this.disabled ? 'rgba(241, 241, 241, 0.5)' : 'rgba(241, 241, 241, 1)'
      }
    },
    cssInputContainer () {
      return {
        height: '100%'
      }
    },
    cssInputWrapper () {
      return {
        width: '100%',
        border: 'none',
        background: 'transparent',
        height: '100%',
        padding: '0',
        display: 'flex',
        alignItems: 'center'
      }
    },
    cssInput () {
      return {
        color: 'var(--Nearly-Black, #212529)',
        textAlign: 'right',
        fontFamily: 'Inter',
        fontSize: '13px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '20px', /* 153.846% */
        opacity: this.disabled ? 0.5 : 1
      }
    },
    cssVarsLabel () {
      return {
        opacity: this.disabled ? 0.5 : 1
      }
    },
    indicatorColour () {
      return {
        '--indicator-colour': this.valid ? hexToRGBA(this.internalValue, this.disabled ? 0.5 : 1) : 'linear-gradient(-45deg, transparent 48%, red 48%, red 52%, transparent 52%)'
      }
    }
  },
  watch: {
    value (value) {
      this.internalValue = value
    }
  },
  methods: {
    onChangeColour (colour: string) {
      this.valid = /^#([A-Fa-f0-9]{6})$/.test(colour)
      if (this.valid) {
        this.$emit('onChangeColour', colour)
      }
    }
  }
})

</script>

<style scoped>

  div.colour-input-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    padding: 10px;
    width: 255px;
    height: 40px;
    flex-shrink: 0;
  }

  div.colour-indicator {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    border-radius: 4px;
    border: 1px solid rgba(33, 37, 41, 0.10);
    background: var(--indicator-colour);
  }

  div.input-container {
    display: inline-block;
    width: 80%;
  }

  p.colour-input-label {
    margin: 0;
    color: var(--Nearly-Black, #212529);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px; /* 100% */
    opacity: 0.5;
    white-space: nowrap;
  }

  </style>
