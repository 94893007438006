<template>
    <div class="overlay-container d-flex flex-row justify-content-center" v-if="visible">
        <div class="overlay-content">
            <div class="overlay-header d-flex flex-row align-items-center justify-content-between p-2">
                <p class="m-0 title-text"> {{ this.title }}</p>
                <FaIconButton icon="xmark" size="md" @click="$emit('onDismiss')" :style="cssCloseButton"/>
            </div>
            <slot />
        </div>
    </div>
</template>

<script lang="ts">

import { defineComponent } from 'vue'

import FaIconButton from '@/components/button/FaIconButton.vue'
import { Palette } from '@/theme'

export default defineComponent({
  name: 'PopupOverlay',
  components: {
    FaIconButton
  },
  props: {
    title: {
      type: String,
      required: true
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    cssCloseButton () {
      return {
        backgroundColor: Palette.neutral900,
        color: Palette.white,
        width: '25px',
        height: '25px'
      }
    }
  }
})
</script>

<style scoped>

  div.overlay-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100vw;
    height: 100vw;
    overflow: hidden;
    background-color: rgba(0,0,0,.5);
  }

  div.overlay-content {
    margin-top: 5vh;
    width: 45vw;
    height: 90vh;
    border-radius: 20px;
    background-color: white;
  }

  div.overlay-header {
    width: '100%';
    height: 50px;
    border-bottom: 1px solid lightgrey;
  }

  p.title-text {
    color: var(--Nearly-Black, #212529);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 125% */
  }
</style>
