import Section from './Section'

interface TableSectionStyleInterface {
  header: string,
  border: number
}

interface TableSectionInterface {
  id: number
  label: string
  listOrder: number
  active: boolean
  persistent: boolean
  labelVisible: boolean
  value: string[][]
  style: TableSectionStyleInterface
}

class TableSectionStyle implements TableSectionStyleInterface {
  header = ''
  border = 0

  constructor (tableSectionStyle?: TableSectionStyleInterface) {
    if (tableSectionStyle) {
      this.header = tableSectionStyle.header
      this.border = tableSectionStyle.border
    }
  }
}

export default class TableSection extends Section implements TableSectionInterface {
  value = Array<Array<string>>()
  style = new TableSectionStyle()

  constructor (tableSection?: TableSectionInterface) {
    super(tableSection)
    if (tableSection) {
      this.value = tableSection.value
      this.style = new TableSectionStyle(tableSection.style)
    }
  }
}
