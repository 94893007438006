<template>
    <div v-if="linkSection" class="link-body" :style="cssVarsContainer">
        <p
            class="link-text"
            :style="cssVarsText"
            @click="onClick"
            >
            {{ linkSection.text }}
        </p>

    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import LinkSectionModel from '@/models/qr-sections/LinkSection'

export default defineComponent({
  name: 'LinkSection',
  props: {
    linkSection: LinkSectionModel
  },
  computed: {
    cssVarsContainer () {
      return {
      }
    },
    cssVarsText () {
      return {
        '--text-align': this.linkSection.style.align,
        '--font-size': this.linkSection.style.size + 'px',
        '--line-height': Math.round(this.linkSection.style.size * 1.57143) + 'px',
        '--font-weight': this.linkSection.style.bold ? 'bold' : 'normal',
        '--font-style': this.linkSection.style.italic ? 'italic' : 'normal',
        '--text-decoration': this.linkSection.style.underline ? 'underline' : 'none',
        '--color': this.linkSection.style.textColour,
        '--background-color': this.linkSection.style.backgroundColor,
        '--margin': String(this.linkSection.style.spacingTop) + 'px ' + String(this.linkSection.style.spacingRight) + 'px ' + String(this.linkSection.style.spacingBottom) + 'px ' + String(this.linkSection.style.spacingLeft) + 'px'
      }
    }
  },
  methods: {
    onClick () {
      window.open(this.linkSection.url, '_blank')
      this.$emit('onPushClick')
    }
  }
})
</script>

<style scoped lang="scss">
  div.link-body{
    width: 100%;
    margin-top: 20px;
    display: flex;
  }
  p.link-text{
    width: 100%;
    text-align: var(--text-align);
    font-family: Inter;
    font-size: var(--font-size);
    font-style: var(--font-style);
    font-weight: var(--font-weight);
    line-height: var(--line-height); /* 157.143% */
    text-decoration: var(--text-decoration);
    color: var(--color);
    background-color: var(--background-color);
    margin: var(--margin);
    cursor: pointer;
  }
</style>
