<template >
    <div v-if="subProfile" class="sub-profile-container" :style="cssVars">
      <div class="sub-profile-content">
        <div class="section-container d-flex flex-row justify-content-center" v-for="(section, i) in subProfile.sections" :key="i" :index="i">
          <ImageSection v-if="section.type === 0 && section.section.active" :image="(section.section as ImageSectionModel)"/>
          <HeaderSection v-else-if="section.type === 1 && section.section.active" :header="(section.section as HeaderSectionModel)"/>
          <TextSection v-else-if="section.type === 2 && section.section.active" :text="(section.section as TextSectionModel)"/>
          <DetailSection v-else-if="section.type === 3 && section.section.active"
            :detailSection="(section.section as DetailSectionModel)"
            :theme="subProfile.theme"
            @onDetailClick="(id: string, qrSectionId: number, contentActive: boolean) => $emit('onDetailClick', id, qrSectionId, contentActive)"
            />
          <SocialsSection v-else-if="section.type === 4 && section.section.active" :socialsSection="(section.section as SocialsSectionModel)" @onItemClick="(itemId: number) => this.$emit('onSocialsSectionItemClick', section.section.id, itemId)"/>
          <TableSection v-else-if="section.type === 5 && section.section.active" :tableSection="(section.section as TableSectionModel)"/>
          <FeaturesSection v-else-if="section.type === 6 && section.section.active" :featuresSection="(section.section as FeaturesSectionModel)"/>
          <AmazonReviewCTASection v-else-if="section.type === 7 && section.section.active" :amazonReviewCTASection="(section.section as AmazonReviewCTASectionModel)" @onPushClick="() => this.$emit('onAmazonReviewCTASectionClick', section.section.id)"/>
          <LinkSection v-else-if="section.type === 8 && section.section.active" :linkSection="(section.section as LinkSectionModel)" @onPushClick="() => this.$emit('onLinkSectionClick', section.section.id)"/>
          <ButtonSection v-else-if="section.type === 9 && section.section.active" :buttonSection="(section.section as ButtonSectionModel)" @onPushClick="() => this.$emit('onButtonSectionClick', section.section.id)"/>
          <EmbeddedVideoSection v-else-if="section.type === 10 && section.section.active" :embeddedVideoSection="(section.section as EmbeddedVideoSectionModel)" @onPushClick="() => this.$emit('onEmbeddedVideoSectionClick', section.section.id)"/>
          <NewsSection v-else-if="section.type === 11 && section.section.active" :newsSection="(section.section as NewsSectionModel)" @onPushClick="(itemId: number) => this.$emit('onNewsSectionClick', itemId, section.section.id)"/>
        </div>
        <div class="sub-profile-footer">
          <img class="sub-profile-footer-image" :src="require('@/assets/icons/main/icon-blue.png')"/>
          <p class="sub-profile-footer-text">Powered by keyp.in ©2024</p>
        </div>
      </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import AmazonReviewCTASection from '../qr-section/AmazonReviewCTASection.vue'
import ButtonSection from '../qr-section/ButtonSection.vue'
import EmbeddedVideoSection from '../qr-section/EmbeddedVideoSection.vue'
import FeaturesSection from '../qr-section/FeaturesSection.vue'
import HeaderSection from '../qr-section/HeaderSection.vue'
import ImageSection from '../qr-section/ImageSection.vue'
import LinkSection from '../qr-section/LinkSection.vue'
import NewsSection from '../qr-section/NewsSection.vue'
import TextSection from '../qr-section/TextSection.vue'
import DetailSection from '../qr-section/DetailSection.vue'
import SocialsSection from '../qr-section/SocialsSection.vue'
import TableSection from '../qr-section/TableSection.vue'

import AmazonReviewCTASectionModel from '@/models/qr-sections/AmazonReviewCTASection'
import ButtonSectionModel from '@/models/qr-sections/ButtonSection'
import EmbeddedVideoSectionModel from '@/models/qr-sections/EmbeddedVideoSection'
import FeaturesSectionModel from '@/models/qr-sections/FeaturesSection'
import ImageSectionModel from '../../models/qr-sections/ImageSection'
import HeaderSectionModel from '../../models/qr-sections/HeaderSection'
import LinkSectionModel from '../../models/qr-sections/LinkSection'
import NewsSectionModel from '../../models/qr-sections/NewsSection'
import TextSectionModel from '../../models/qr-sections/TextSection'
import DetailSectionModel from '../../models/qr-sections/UserDetailSection'
import SocialsSectionModel from '../../models/qr-sections/SocialsSection'
import TableSectionModel from '../../models/qr-sections/TableSection'

import SubProfile from '../../models/SubProfile'

export default defineComponent({
  name: 'SubProfileContainerModular',
  components: {
    AmazonReviewCTASection,
    ButtonSection,
    DetailSection,
    EmbeddedVideoSection,
    FeaturesSection,
    HeaderSection,
    ImageSection,
    LinkSection,
    NewsSection,
    SocialsSection,
    TextSection,
    TableSection
  },
  props: {
    subProfile: SubProfile,
    contentActive: Boolean
  },
  computed: {
    cssVars () {
      return {
        '--background-image': this.$props.subProfile?.theme.backgroundImage,
        '--background-color': this.$props.subProfile?.theme.backgroundColour
      }
    }
  }
})
</script>

<style scoped lang="scss">
    div.sub-profile-container{
        background-image: var(--background-image);
        background-size: cover;
        background-color: var(--background-color);
        display: flex;
        flex-direction: column;
        min-height: 90vh;
        height: 100%;
    }
    div.sub-profile-content{
        height: 100%;
        width: 90%;
        margin: 0 auto;
    }

    div.section-container {
      min-height: 50px;
      width: 100%;
    }

    div.sub-profile-footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 150px;
      background-color: '#ffffff';
    }

    img.sub-profile-footer-image {
      width: 30px;
      height: 30px;
    }

    p.sub-profile-footer-text {
      margin: 10px 0 0 0;
      color: var(--Nearly-Black, #212529);
      text-align: center;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 150% */
      opacity: 0.5;
    }
</style>
