import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QRListItem = _resolveComponent("QRListItem")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.subProfiles, (subProfile) => {
    return (_openBlock(), _createElementBlock("div", {
      key: subProfile.id,
      class: "row"
    }, [
      _createVNode(_component_QRListItem, {
        subProfile: subProfile,
        interactive: true,
        onOnDelete: _ctx.onDelete,
        onOnToggleActive: _ctx.onToggleActive,
        onOnSelect: _ctx.selectSubProfile
      }, null, 8, ["subProfile", "onOnDelete", "onOnToggleActive", "onOnSelect"])
    ]))
  }), 128))
}