import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c84715b0"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "svg-icon-link-wrapper",
    style: _normalizeStyle(_ctx.cssVars)
  }, [
    _createElementVNode("img", {
      src: _ctx.imgSrc,
      alt: "icon",
      style: _normalizeStyle(_ctx.cssVarsIcon)
    }, null, 12, _hoisted_1),
    _createElementVNode("p", {
      class: "svg-icon-link-text",
      style: _normalizeStyle(_ctx.cssVarsText)
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 4)
  ], 4))
}