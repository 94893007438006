import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c307dc96"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "socials-content" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["src"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "social-list-button-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconFacebookSvg = _resolveComponent("IconFacebookSvg")!
  const _component_IconInstagramSvg = _resolveComponent("IconInstagramSvg")!
  const _component_IconTwitterSvg = _resolveComponent("IconTwitterSvg")!

  return (_ctx.socialsSection)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "socials-container",
        style: _normalizeStyle(_ctx.cssVars)
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("p", {
            class: "socials-title",
            style: _normalizeStyle(_ctx.cssVarsTitle)
          }, "Social Links", 4),
          (_ctx.socialsSection.style.layout === 'row')
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "socials-buttons-row-container",
                style: _normalizeStyle(_ctx.cssVars)
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.socialsSection.socials, (social) => {
                  return (_openBlock(), _createElementBlock("a", {
                    key: social.id,
                    class: "social-row-button",
                    style: _normalizeStyle(_ctx.cssVarsRowBtn),
                    onClick: () => _ctx.onClick(social.id, social.value)
                  }, [
                    (_ctx.socialsSection.style.logoTheme === 'brand')
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          class: "social-row-button-icon",
                          src: require(`@/assets/${_ctx.getIcon(social.type)}`)
                        }, null, 8, _hoisted_3))
                      : (social.type === 'facebook')
                        ? (_openBlock(), _createBlock(_component_IconFacebookSvg, {
                            key: 1,
                            colour: _ctx.socialsSection.style.logoColour
                          }, null, 8, ["colour"]))
                        : (social.type === 'instagram')
                          ? (_openBlock(), _createBlock(_component_IconInstagramSvg, {
                              key: 2,
                              colour: _ctx.socialsSection.style.logoColour
                            }, null, 8, ["colour"]))
                          : (social.type === 'twitter')
                            ? (_openBlock(), _createBlock(_component_IconTwitterSvg, {
                                key: 3,
                                colour: _ctx.socialsSection.style.logoColour
                              }, null, 8, ["colour"]))
                            : _createCommentVNode("", true)
                  ], 12, _hoisted_2))
                }), 128))
              ], 4))
            : (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "socials-buttons-list-container",
                style: _normalizeStyle(_ctx.cssVars)
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.socialsSection.socials, (social) => {
                  return (_openBlock(), _createElementBlock("a", {
                    key: social.id,
                    class: "social-list-button",
                    style: _normalizeStyle(_ctx.cssVarsListBtn),
                    onClick: () => _ctx.onClick(social.id, social.value)
                  }, [
                    (_ctx.socialsSection.style.logoTheme === 'brand')
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          class: "social-list-button-icon",
                          src: require(`@/assets/${_ctx.getIcon(social.type)}`)
                        }, null, 8, _hoisted_5))
                      : (social.type === 'facebook')
                        ? (_openBlock(), _createBlock(_component_IconFacebookSvg, {
                            key: 1,
                            colour: _ctx.socialsSection.style.logoColour,
                            style: {"margin-right":"15px"}
                          }, null, 8, ["colour"]))
                        : (social.type === 'instagram')
                          ? (_openBlock(), _createBlock(_component_IconInstagramSvg, {
                              key: 2,
                              colour: _ctx.socialsSection.style.logoColour,
                              style: {"margin-right":"15px"}
                            }, null, 8, ["colour"]))
                          : (social.type === 'twitter')
                            ? (_openBlock(), _createBlock(_component_IconTwitterSvg, {
                                key: 3,
                                colour: _ctx.socialsSection.style.logoColour,
                                style: {"margin-right":"15px"}
                              }, null, 8, ["colour"]))
                            : _createCommentVNode("", true),
                    _createElementVNode("p", _hoisted_6, _toDisplayString(social.type), 1)
                  ], 12, _hoisted_4))
                }), 128))
              ], 4))
        ])
      ], 4))
    : _createCommentVNode("", true)
}