export interface SocialsItemInterface {
  id: number,
  category: string,
  type: string,
  value: string,
  listOrder: number
}

export class SocialsItem implements SocialsItemInterface {
  id = 0
  category = ''
  type = ''
  value = ''
  listOrder = 0

  constructor (data: SocialsItemInterface) {
    this.id = data.id
    this.category = data.category
    this.type = data.type
    this.value = data.value
    this.listOrder = data.listOrder
  }
}
