import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-03e4fcbc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex flex-column justify-content-center icon-container" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "d-flex justify-content-center align-items-center button-content",
    style: _normalizeStyle(_ctx.cssVars)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        src: _ctx.iconSrc,
        alt: "icon",
        class: "icon"
      }, null, 8, _hoisted_2)
    ]),
    _createElementVNode("p", {
      class: _normalizeClass(_ctx.cssTextClass),
      style: _normalizeStyle(_ctx.cssVarsText)
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 6)
  ], 4))
}