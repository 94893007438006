import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "fa-icon-link-container d-flex flex-row justify-content-center align-items-center",
    style: _normalizeStyle(_ctx.cssVars)
  }, [
    _createElementVNode("div", {
      class: "d-flex flex-column justify-content-center",
      style: _normalizeStyle(_ctx.cssIconContainer)
    }, [
      _createVNode(_component_fa_icon, {
        class: _normalizeClass(_ctx.faIconClass),
        icon: _ctx.faIconIcon
      }, null, 8, ["class", "icon"])
    ], 4),
    _createElementVNode("p", {
      class: _normalizeClass(_ctx.cssTextClass),
      style: _normalizeStyle(_ctx.cssText)
    }, _toDisplayString(_ctx.text), 7)
  ], 4))
}