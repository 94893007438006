<template>
    <EditorPanel :title="imageSection.label" :active="imageSection.active" :persistent="imageSection.persistent" @onSetActive="onSetActive" @onDelete="onDelete">
        <div class="row">
            <div class="col-3 m-2 p-2 d-flex flex-column panel-section">
              <a v-if="imageSection.imageExists()" class="delete-button"  href="#" @click="() => $emit('onReset')">
                <img class="delete-button-image" :src="require('@/assets/icons/button/btn-close.png')">
              </a>
              <img class="header-image" :src=imageSection.uri crossorigin="anonymous">
            </div>
            <div class="col-8 m-2 pt-4 px-4 align-items-center panel-section">
              <h4>Replace Header image</h4>
              <p>Use a square image with a resolution that's at least 600 pixels.</p>
              <div class="d-flex flex-row">
                <div class="col-6 px-2">
                  <input type="file" id="image-select" ref="imageSelect" hidden @change="onSelectImageEnd( $event )"/>
                  <label for="image-select" style="width: 100%">
                    <Btn
                        backgroundColor="white"
                        color="grey"
                        @click="onSelectImageStart()">
                        <fa-icon class="fa-lg mx-1" icon="fa-image fa-solid"></fa-icon>
                        Select Image
                    </Btn>

                  </label>
                </div>
                <div class="col-6 px-2">
                     <Btn
                        backgroundColor="white"
                        color="grey"
                        @click="onSelectImageStart()">
                        <fa-icon class="fa-lg mx-1" icon="fa-image fa-upload"></fa-icon>
                        Upload New
                    </Btn>
                  </div>
              </div>
            </div>
        </div>
    </EditorPanel>
</template>

<script lang="ts">

import { defineComponent } from 'vue'

import Btn from '@/components/button/Button.vue'
import EditorPanel from '@/components/editor/EditorPanel.vue'

import ImageSection from '@/models/qr-sections/ImageSection'

export default defineComponent({
  name: 'ImagePanel',
  components: {
    Btn,
    EditorPanel
  },
  props: {
    imageSection: {
      type: ImageSection,
      required: true
    }
  },
  methods: {
    onSelectImageStart () {
      const imageSelect = document.getElementById('image-select')
      if (imageSelect) {
        imageSelect.click()
      }
    },
    onSelectImageEnd (event : Event) {
      const target = event.target as HTMLInputElement
      if (target.files) {
        this.$emit('onSubmit', target.files[0])
        target.value = ''
      }
    },
    onSetActive (value: boolean) {
      this.$emit('onEdit', this.imageSection.id, this.imageSection.label, this.imageSection.listOrder, value)
    },
    onDelete () {
      this.$emit('onDelete', this.imageSection.id)
    }
  }
})
</script>

<style scoped lang="scss">
    div.panel-section {
        background-color: #F1F1F1;
        border-radius: 1em;
    }
    img.header-image {
        width: 90%;
        aspect-ratio: 1;
        align-self: center;
    }

    a.delete-button {
      position: absolute;
      margin-top: -1.5em;
      margin-right: -1.5em;
      width: 2em;
      align-self: flex-end;
    }

    img.delete-button-image {
      width: 100%;
      aspect-ratio: 1;
    }

    h4, p{
        text-align: left;
        font-family: inter;
    }
    h4 {
        font-weight: bold;
        font-size: 14px;
    }
    p {
        font-size: 14px;
    }
</style>
