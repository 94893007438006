<template>
  <div class="qr-list-item-container" :style="cssVars">
    <!-- Image -->
    <div class="qr-list-item-image-container">
      <img v-if="subProfile.imageSections[0]" class="qr-list-item-image" :src=subProfile.imageSections[0].uri crossorigin="anonymous">
    </div>
     <!-- Label -->
    <div class="qr-list-item-label-container">
      <p class="qr-list-item-label">{{ subProfile.getTruncatedLabel() }}</p>
    </div>
    <div class="qr-list-item-tag-container">
      <QrRootTag v-if="subProfile.isRootProfile"/>
    </div>
    <div class="qr-list-item-tag-container">
      <QrPublishedTag v-if="subProfile.active"/>
      <QrUnpublishedTag v-else/>
    </div>
    <div class="qr-grid-item-code-container">
        <img class="qr-grid-item-code" :src="subProfile.qrUri" />
    </div>
      <div class="qr-grid-item-buttons-container">
          <DropdownQrDownload
            @onClickPng="() => onDownload('png', subProfile.id)"
            @onClickSvg="() => onDownload('svg', subProfile.id)"
          />
          <DropdownQrEdit
            @onClickEdit="() => $emit('onSelect', subProfile.id)"
          />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import DropdownQrDownload from '@/components/dropdown/DropdownQrDownload.vue'
import DropdownQrEdit from '@/components/dropdown/DropdownQrEdit.vue'
import QrRootTag from '@/components/tag/QrRootTag.vue'

import { UserSubProfile } from '@/models/UserSubProfile'
import { getQrDownload } from '@/api'

import { Palette } from '@/theme'
import QrPublishedTag from '@/components/tag/QrPublishedTag.vue'
import QrUnpublishedTag from '@/components/tag/QrUnpublishedTag copy.vue'

export default defineComponent({
  name: 'QRListItem',
  components: {
    DropdownQrDownload,
    DropdownQrEdit,
    QrRootTag,
    QrPublishedTag,
    QrUnpublishedTag
  },
  props: {
    subProfile: {
      type: UserSubProfile,
      required: true
    },
    interactive: Boolean
  },
  data () {
    return {
      optionsDropdownVisible: false,
      downloadDropdownVisible: false
    }
  },
  computed: {
    cssVars () {
      return {
        '--background-color': Palette.white,
        '--opacity': 1.0,
        '--border-color': this.subProfile.isRootProfile ? Palette.primary : Palette.white,
        '--border-width': '0px',
        '--qr-type-color': this.subProfile.isRootProfile ? Palette.primary : Palette.white
      }
    },
    dropdownCssVars () {
      return {
        '--background-color': Palette.light_grey,
        '--color': Palette.white
      }
    },
    cssVarsStatus () {
      return {
        'background-color': this.subProfile.active ? Palette.green100 : Palette.light_grey,
        color: this.subProfile.active ? Palette.green300 : Palette.neutral800
      }
    },
    statusText () {
      return this.subProfile.active === true ? 'Published' : 'Unpublished'
    }
  },
  methods: {
    toggleOptionsDropdown () {
      this.optionsDropdownVisible = !this.optionsDropdownVisible
    },
    toggleDownloadDropdown () {
      this.downloadDropdownVisible = !this.downloadDropdownVisible
    },
    handleClickOutside (event) {
      if (
        this.$refs.optionsContent &&
        !this.$refs.optionsContent.contains(event.target)
      ) {
        this.optionsDropdownVisible = false
      }
      if (
        this.$refs.downloadContent &&
        !this.$refs.downloadContent.contains(event.target)
      ) {
        this.downloadDropdownVisible = false
      }
    },
    onDownload (type: string, subProfileId: string) {
      getQrDownload(
        type,
        subProfileId,
        this.$store.state.token,
        () => this.$router.push('/login'),
        () => null,
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response) => {
          const href = URL.createObjectURL(response.data)
          const link = document.createElement('a')
          link.href = href
          link.setAttribute('download', subProfileId + '.' + type)
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          URL.revokeObjectURL(href)
        })
    }
  },
  mounted () {
    document.addEventListener('click', this.handleClickOutside)
  },
  beforeUnmount () {
    document.removeEventListener('click', this.handleClickOutside)
  }
})
</script>

<style scoped lang="scss">

  div.qr-list-item-container {
    background-color: var(--background-color);
    border-radius: 16px;
    margin: 16px 0 16px 0;
    padding: 10px;
    height: 100px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
  }

  div.qr-list-item-image-container {
    width: 160px;
    height: 100%;
    overflow: hidden;
    display: flex;
  }

  img.qr-list-item-image {
    width: 100%;
    aspect-ratio: 1 / 1;
    align-self: center;
  }

  div.qr-list-item-label-container {
    height: 20px;
    margin: 0 0 0 30px;
    display: flex;
    flex-grow: 1;
  }

  div.qr-list-item-label {
    color: var(--Nearly-Black, #212529);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
  }

  div.qr-list-item-tag-container {
    width: 76px;
    height: 20px;
    margin: 0 18px 0 18px;
  }

  div.qr-grid-item-code-container{
      background-color: black;
      color: white;
      width: 40px;
      height: 40px;
  }
  img.qr-grid-item-code{
    width: 100%;
    height: 100%;
  }
  div.qr-grid-item-buttons-container {
    display: flex;
    flex-direction: row;
    height: 46px;
    width: 86px;
    margin: 0 30px 0 30px;
  }

</style>
