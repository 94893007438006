import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelDynamic as _vModelDynamic, createElementVNode as _createElementVNode, withDirectives as _withDirectives, vModelText as _vModelText, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8c862bfa"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["type", "name", "placeholder"]
const _hoisted_2 = ["src"]
const _hoisted_3 = ["maxlength", "placeholder"]
const _hoisted_4 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "text-input-container",
    style: _normalizeStyle(_ctx.cssVarsContainer)
  }, [
    (_ctx.label.length > 0)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: "text-input",
          class: "text-input-label",
          style: _normalizeStyle(_ctx.cssVarsLabel)
        }, _toDisplayString(_ctx.label), 5))
      : _createCommentVNode("", true),
    (!_ctx.multiline)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "text-input-wrapper",
          style: _normalizeStyle(_ctx.cssVarsWrapper)
        }, [
          _withDirectives(_createElementVNode("input", {
            class: "text-input-text",
            type: _ctx.type,
            name: _ctx.name,
            placeholder: _ctx.placeholder,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
            style: _normalizeStyle(_ctx.cssVarsText)
          }, null, 12, _hoisted_1), [
            [_vModelDynamic, _ctx.value]
          ]),
          (this.rightImageUri.length > 0)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                class: "right-image",
                src: require(`@/assets/${this.rightImageUri}`)
              }, null, 8, _hoisted_2))
            : _createCommentVNode("", true)
        ], 4))
      : _withDirectives((_openBlock(), _createElementBlock("textarea", {
          key: 2,
          class: "text-input-wrapper",
          maxlength: _ctx.maxlength,
          placeholder: _ctx.placeholder,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value) = $event))
        }, null, 8, _hoisted_3)), [
          [_vModelText, _ctx.value]
        ]),
    (_ctx.multiline)
      ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.modelValue?.length + '/' + _ctx.maxlength), 1))
      : _createCommentVNode("", true)
  ], 4))
}