import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d8ca6e88"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex flex-direction-row detail-container align-items-center my-2" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "detail-type-label" }
const _hoisted_4 = { class: "detail-value-input-container" }
const _hoisted_5 = { class: "detail-delete-button-container" }
const _hoisted_6 = { class: "detail-grip-handle-container" }
const _hoisted_7 = { class: "handle" }
const _hoisted_8 = { href: "#" }
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "my-2 align-self-start" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DebouncedTextInput = _resolveComponent("DebouncedTextInput")!
  const _component_FaIconButton = _resolveComponent("FaIconButton")!
  const _component_SlickItem = _resolveComponent("SlickItem")!
  const _component_SlickList = _resolveComponent("SlickList")!
  const _component_FaIconLink = _resolveComponent("FaIconLink")!
  const _component_EditorPanel = _resolveComponent("EditorPanel")!
  const _directive_handle = _resolveDirective("handle")!

  return (_openBlock(), _createBlock(_component_EditorPanel, {
    title: _ctx.detailSection.label,
    active: _ctx.detailSection.active,
    persistent: _ctx.detailSection.persistent,
    onOnSetActive: _ctx.onSetActive,
    onOnDelete: _ctx.onDelete
  }, {
    default: _withCtx(() => [
      _createVNode(_component_SlickList, {
        axis: "y",
        list: _ctx.details,
        "onUpdate:list": [
          _cache[0] || (_cache[0] = ($event: any) => ((_ctx.details) = $event)),
          _ctx.onChangeDetailOrder
        ],
        useDragHandle: true
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.details, (detail, i) => {
            return (_openBlock(), _createBlock(_component_SlickItem, {
              key: detail,
              index: i
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("img", {
                    class: "detail-type-icon mx-3",
                    src: require(`@/assets/${_ctx.getIcon(detail.type)}`)
                  }, null, 8, _hoisted_2),
                  _createElementVNode("p", _hoisted_3, _toDisplayString(detail.getType()), 1),
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_DebouncedTextInput, {
                      value: detail.getValue(),
                      type: "text",
                      placeholder: "title",
                      "onUpdate:value": (value) => _ctx.$emit('onEditDetail', detail.id, detail.category, detail.type, detail.label, JSON.stringify({value: value}), _ctx.detailSection.id)
                    }, null, 8, ["value", "onUpdate:value"])
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_FaIconButton, {
                      icon: "xmark",
                      "background-color": "transparent",
                      color: "netural800",
                      onClick: () => _ctx.$emit('onDeleteDetail', detail.id, _ctx.detailSection.id),
                      style: _normalizeStyle(_ctx.cssDeleteDetailButton)
                    }, null, 8, ["onClick", "style"])
                  ]),
                  _createElementVNode("div", _hoisted_6, [
                    _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_7, [
                      _createElementVNode("a", _hoisted_8, [
                        _createElementVNode("img", {
                          class: "grip-icon",
                          src: require('@/assets/icons/icon-grip-1.png')
                        }, null, 8, _hoisted_9)
                      ])
                    ])), [
                      [_directive_handle]
                    ])
                  ])
                ])
              ]),
              _: 2
            }, 1032, ["index"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["list", "onUpdate:list"]),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_FaIconLink, {
          icon: "plus",
          size: "md",
          text: "Add Social Link",
          "icon-container-style": _ctx.cssAddIcon,
          "text-style": _ctx.cssAddText,
          "text-uses-gradient": true,
          onClick: _cache[1] || (_cache[1] = () => _ctx.$emit('onAddLink'))
        }, null, 8, ["icon-container-style", "text-style"])
      ])
    ]),
    _: 1
  }, 8, ["title", "active", "persistent", "onOnSetActive", "onOnDelete"]))
}