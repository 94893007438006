<template>
  <div class="text-input-container" :style="cssVarsContainer">
    <label v-if="label.length > 0" for="text-input" class="text-input-label" :style="cssVarsLabel">{{ label }}</label>
    <div v-if="!multiline" class="text-input-wrapper" :style="cssVarsWrapper">
      <input class="text-input-text" :type="type" :name="name" :placeholder="placeholder" v-model="value" :style="cssVarsText"/>
      <img v-if="this.rightImageUri.length > 0" class="right-image" :src="require(`@/assets/${this.rightImageUri}`)"/>
    </div>
    <textarea v-else class="text-input-wrapper" :maxlength="maxlength" :placeholder="placeholder" v-model="value"/>
    <p v-if="multiline">{{ modelValue?.length + '/' + maxlength }}</p>
</div>
</template>

<script lang="ts">

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TextInput',
  props: {
    label: {
      String,
      default: ''
    },
    maxlength: {
      type: String,
      default: '200'
    },
    rightImageUri: {
      type: String,
      default: ''
    },
    containerStyle: {
      type: Object,
      default: null
    },
    wrapperStyle: {
      type: Object,
      default: null
    },
    textStyle: {
      type: Object,
      default: null
    },
    labelStyle: {
      type: Object,
      default: null
    },
    rightImageStyle: {
      type: Object,
      default: null
    },
    multiline: {
      type: Boolean,
      default: false
    },
    name: String,
    placeholder: String,
    type: String,
    modelValue: String
  },
  emits: ['update:modelValue'],
  computed: {
    value: {
      get () {
        return this.modelValue
      },
      set (value : string) {
        this.$emit('update:modelValue', value)
      }
    },
    cssVarsLabel () {
      return {
        ...this.labelStyle
      }
    },
    cssVarsContainer () {
      return {
        ...this.containerStyle
      }
    },
    cssVarsWrapper () {
      return {
        ...this.wrapperStyle
      }
    },
    cssVarsText () {
      return {
        ...this.textStyle
      }
    },
    cssVarsRightComponent () {
      return {
        ...this.rightComponentStyle
      }
    }
  }
})

</script>

<style scoped>

div.text-input-container {
  display: flex;
  flex-direction: column;
}
div.text-input-wrapper {
  background-color: white;
  display: flex;
  width: 470px;
  height: 40px;
  padding: 10px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid rgba(33, 37, 41, 0.10);
  background: #FFF;
}

.text-input-text {
  flex: 1 0 0;
  color: var(--Nearly-Black, #212529);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 100% */
  border: none;
  outline: none;
}

.text-input-text::placeholder {
  opacity: 0.4;
}

textarea{
  aspect-ratio: 6.5/1;
  resize: none;
}

label.text-input-label {
  float: left;
  font-size: 0.75rem;
  padding-left: 0.1rem;
}

img.right-label {
  float: right;
  width: 20px;
  height: 20px;
}

</style>
