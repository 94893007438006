import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7464ec1f"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["index", "onClick"]
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "news-text-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconChevronNewsSectionSvg = _resolveComponent("IconChevronNewsSectionSvg")!

  return (_ctx.newsSection)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "news-body",
        style: _normalizeStyle(_ctx.cssVarsContainer)
      }, [
        (_ctx.newsSection.labelVisible)
          ? (_openBlock(), _createElementBlock("p", {
              key: 0,
              class: "label-text",
              style: _normalizeStyle(_ctx.cssVarsText)
            }, _toDisplayString(_ctx.newsSection.label), 5))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.newsSection.items, (item, i) => {
          return (_openBlock(), _createElementBlock("div", {
            key: item.id,
            index: i,
            class: "news-item",
            style: _normalizeStyle(_ctx.cssVarsItem),
            onClick: () => _ctx.onClick(item)
          }, [
            _createElementVNode("img", {
              class: "news-image",
              src: item.imageUri
            }, null, 8, _hoisted_2),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("p", {
                class: "news-title",
                style: _normalizeStyle(_ctx.cssVarsText)
              }, _toDisplayString(item.title), 5),
              _createElementVNode("p", {
                class: "news-sub-title",
                style: _normalizeStyle(_ctx.cssVarsText)
              }, _toDisplayString(item.subTitle), 5)
            ]),
            _createVNode(_component_IconChevronNewsSectionSvg, {
              colour: _ctx.newsSection.style.textColour
            }, null, 8, ["colour"])
          ], 12, _hoisted_1))
        }), 128))
      ], 4))
    : _createCommentVNode("", true)
}