<template>
    <div
      v-for="subProfile in this.subProfiles"
      :key="subProfile.id"
      class="row">
        <QRListItem
            :subProfile="subProfile"
            :interactive="true"
            @onDelete="onDelete"
            @onToggleActive="onToggleActive"
            @onSelect="selectSubProfile"/>
      </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import QRListItem from './QRListItem.vue'

export default defineComponent({
  name: 'QRList',
  components: {
    QRListItem
  },
  props: {
    subProfiles: Array
  },
  data () {
    return {
      selectedSubProfile: ''
    }
  },
  methods: {
    selectSubProfile (id: string) {
      this.$emit('onSelectSubProfile', id)
    },
    onDelete (id: string) {
      this.$emit('onDelete', id)
    },
    onToggleActive (id: string) {
      this.$emit('onToggleActive', id)
    },
    onSubmit (name: string, label: string, description: string) {
      this.$emit('onSubmit', name, label, description)
    }
  }
})
</script>

<style scoped lang="scss">
  li {
      margin-top: 20px;
  }
  li.manage-qr{
    transition: all .2s ease-in-out;
  }
  li.manage-qr:hover{
    transform: scale(1.05);
  }
</style>
