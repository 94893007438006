import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-70ea8bd4"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "d-flex justify-content-center align-items-center button-content",
    style: _normalizeStyle(_ctx.cssVars)
  }, [
    _createElementVNode("img", {
      src: _ctx.iconSrc,
      style: _normalizeStyle(_ctx.cssImgVars),
      alt: "icon",
      class: "icon"
    }, null, 12, _hoisted_1)
  ], 4))
}