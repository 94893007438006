import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7787e144"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex flex-column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DebouncedTextInput = _resolveComponent("DebouncedTextInput")!
  const _component_EditorPanel = _resolveComponent("EditorPanel")!

  return (_openBlock(), _createBlock(_component_EditorPanel, {
    title: _ctx.textSection.label,
    active: _ctx.textSection.active,
    persistent: _ctx.textSection.persistent,
    onOnSetActive: _ctx.onSetActive,
    onOnDelete: _ctx.onDelete
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_DebouncedTextInput, {
          ref: "textInput",
          value: _ctx.content,
          multiline: true,
          type: "text",
          placeholder: "title",
          "onUpdate:value": _ctx.onEditContent
        }, null, 8, ["value", "onUpdate:value"])
      ])
    ]),
    _: 1
  }, 8, ["title", "active", "persistent", "onOnSetActive", "onOnDelete"]))
}