<template>
    <EditorPanel
      :title="tableSection.label"
      :active="tableSection.active"
      :persistent="tableSection.persistent"
      :styleMode="styleMode"
      @onSetActive="onSetActive"
      @onDelete="onDelete"
      @onToggleMode="onToggleMode"
      >
        <div v-if="!this.styleMode" class="d-flex table-panel-content">
          <div class="table-container">
            <table>
              <tbody>
                <tr v-for="(row, rowIndex) in tableSection.value" :key="rowIndex">
                  <td v-for="(cell, cellIndex) in row" :key="cellIndex">
                    <DebouncedTextInput
                      :ref="'cell-' + rowIndex.toString() + '-' + cellIndex.toString()"
                      :value="cell"
                      type="text"
                      placeholder="title"
                      @update:value="(value: string) => onEditCell(value, rowIndex, cellIndex)"
                      :containerStyle="cssInputContainer"
                      :wrapperStyle="cssInputWrapper"
                      :inputStyle="cssInput"
                      :border-on-focus="true"
                      />
                    </td>
                </tr>
              </tbody>
            </table>
            <div class="add-col-container d-flex">
              <div v-if="this.value.length < 8" class="add-row-col-button" @click="() => onAddCells('row')">
                <img :src="require('@/assets/icons/svg/plus-table.svg')">
              </div>
              <div v-if="this.value.length > 2" class="rem-row-col-button mx-2" @click="() => onRemoveCells('row')">
                <img :src="require('@/assets/icons/svg/minus-table.svg')">
              </div>
          </div>
          </div>
          <div class="add-col-container">
            <div v-if="this.value[0].length < 8" class="add-row-col-button" @click="() => onAddCells('col')">
              <img :src="require('@/assets/icons/svg/plus-table.svg')">
            </div>
            <div v-if="this.value[0].length > 2" class="rem-row-col-button mt-2" @click="() => onRemoveCells('col')">
              <img :src="require('@/assets/icons/svg/minus-table.svg')">
            </div>
          </div>
        </div>
        <div v-else class="d-flex flex-column table-style-panel-content">
          <div class="d-flex table-style-panel-row align-items-center">
            <p class="style-header d-flex flex-fill">Header</p>
            <div class="d-flex">
              <StyleTextIconButton :icon="'table-style-row'" :selected="styleRow" @click="() => onEditHeader('row')">First Row</StyleTextIconButton>
              <StyleTextIconButton :icon="'table-style-col'" :selected="!styleRow" @click="() => onEditHeader('col')">First Column</StyleTextIconButton>
            </div>
          </div>
          <div class="d-flex table-style-panel-row align-items-center">
            <p class="style-header d-flex flex-fill">Border</p>
            <div class="d-flex">
              <StyleIconButton v-for="i in 6" :key="(i-1)" :icon="'table-style-border-'+(i-1).toString()" :selected="tableSection.style.border === (i-1)" @click="() => onEditBorder(i-1)"/>
            </div>
          </div>
        </div>
      </EditorPanel>
</template>

<script lang="ts">

import { defineComponent } from 'vue'

import DebouncedTextInput from '@/components/input/DebouncedTextInput.vue'
import EditorPanel from '@/components/editor/EditorPanel.vue'
import StyleIconButton from '@/components/button/style-panel/StyleIconButton.vue'
import StyleTextIconButton from '@/components/button/style-panel/StyleTextIconButton.vue'
import TableSection from '@/models/qr-sections/TableSection'

export default defineComponent({
  name: 'TablePanel',
  components: {
    DebouncedTextInput,
    EditorPanel,
    StyleIconButton,
    StyleTextIconButton
  },
  props: {
    tableSection: {
      type: TableSection,
      required: true
    }
  },
  data () {
    return {
      value: this.tableSection.value,
      styleMode: false
    }
  },
  computed: {
    cssInputContainer () {
      return {
        height: '100%'
      }
    },
    cssInputWrapper () {
      return {
        'border-radius': '0',
        border: '1px solid rgba(33, 37, 41, 0.15)',
        background: 'transparent',
        focusedBorder: '1px solid var(--bp---Gradient, #2E75FF)',
        focusedBrackground: '#fff',
        height: '100%'
      }
    },
    cssInput () {
      return {
      }
    },
    styleRow () {
      return this.tableSection.style.header === 'row'
    }
  },
  methods: {
    onToggleMode (emit = true) {
      this.styleMode = !this.styleMode
      if (emit) this.$emit('onToggleMode', this.tableSection.id, this.styleMode)
    },
    focusCell (r: number, c: number) {
      this.$refs['cell-' + r.toString() + '-' + c.toString()][0].focus()
    },
    onAddCells (type: string) {
      if (type === 'row') {
        const newRow: string[] = []
        for (let i = 0; i < this.value[0].length; i++) {
          newRow.push('New Cell')
        }
        this.value.push(newRow)
      } else {
        this.value.forEach((row) => {
          row.push('New Cell')
        })
      }
      this.$emit(
        'onEdit',
        this.tableSection.id,
        this.tableSection.label,
        this.tableSection.listOrder,
        this.tableSection.active,
        this.value
      )
    },
    onRemoveCells (type: string) {
      if (type === 'row') {
        this.value.pop()
      } else {
        for (let i = 0; i < this.value.length; i++) {
          this.value[i].pop()
        }
      }
      this.$emit(
        'onEdit',
        this.tableSection.id,
        this.tableSection.label,
        this.tableSection.listOrder,
        this.tableSection.active,
        this.value
      )
    },
    onEditCell (value: string, r: number, c: number) {
      this.value[r][c] = value
      this.$emit(
        'onEdit',
        this.tableSection.id,
        this.tableSection.label,
        this.tableSection.listOrder,
        this.tableSection.active,
        this.value,
        r,
        c
      )
    },
    onEditHeader (value: string) {
      if (value === this.tableSection.style.header) return
      this.$emit(
        'onEditStyle',
        this.tableSection.id,
        value,
        this.tableSection.style.border
      )
    },
    onEditBorder (value: number) {
      if (value === this.tableSection.style.border) return
      this.$emit(
        'onEditStyle',
        this.tableSection.id,
        this.tableSection.style.header,
        value
      )
    },
    onSetActive (value: boolean) {
      this.$emit(
        'onEdit',
        this.tableSection.id,
        this.tableSection.label,
        this.tableSection.listOrder,
        value,
        this.value,
        this.tableSection.header
      )
    },
    onDelete () {
      this.$emit('onDelete', this.tableSection.id)
    }
  }
})
</script>

<style scoped>

  div.table-panel-content {
    flex-shrink: 0;
    border-radius: 12px;
    background: var(--Light-Grey, #F1F1F1);
    padding: 10px;
  }

  div.table-container {
    flex: 1;
  }
  div.add-col-container {
    width: 32px;
    padding: 10px;
  }

  div.add-row-col-button, div.rem-row-col-button {
    cursor: pointer;
  }

  table {
      border-collapse: collapse;
      width: 100%;
    }

  div.table-style-panel-content {
    flex-shrink: 0;
    width: 100%;
    padding: 10px;
  }

  div.table-style-panel-row {
    width: 100%;
    height: 60px;
    border-top: 1px solid rgba(33, 37, 41, 0.1);
  }

  p.style-header {
    color: var(--Nearly-Black, #212529);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    opacity: 0.5;
    margin: 0px;
  }

</style>
