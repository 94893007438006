<template>
    <div class="qr-grid-container">
        <div
          v-for="subProfile in this.subProfiles"
          :key="subProfile.id"
          class="qr-grid-item-container"
          >
            <QRGridItem
              :subProfile="subProfile"
              :interactive="true"
              @onDelete="onDelete"
              @onToggleActive="onToggleActive"
              @onSelect="selectSubProfile"/>
          </div>
      </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import QRGridItem from './QRGridItem.vue'

export default defineComponent({
  name: 'QRGrid',
  components: {
    QRGridItem
  },
  props: {
    subProfiles: Array
  },
  data () {
    return {
      selectedSubProfile: ''
    }
  },
  methods: {
    selectSubProfile (id: string) {
      this.$emit('onSelectSubProfile', id)
    },
    onDelete (id: string) {
      this.$emit('onDelete', id)
    },
    onToggleActive (id: string) {
      this.$emit('onToggleActive', id)
    },
    onSubmit (name: string, label: string, description: string) {
      this.$emit('onSubmit', name, label, description)
    }
  }
})
</script>

<style scoped lang="scss">
  div.qr-grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, 270px );
    gap: 30px;
  }
  div.qr-grid-item-container {
      width: 100%;
      height: 410px; /* Adjust the height as needed */
  }
</style>
