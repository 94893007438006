<template>
    <div class="d-flex justify-content-center align-items-center button-content" :style="cssVars">
        <img :src="iconSrc" :style="cssImgVars" alt="icon" class="icon">
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'StyleIconButton',
  props: {
    icon: {
      type: String,
      required: true
    },
    iconStyle: {
      type: Object,
      default: null
    },
    style: {
      type: Object,
      default: null
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    cssVars () {
      return {
        ...this.style,
        background: this.selected
          ? 'linear-gradient(135deg, rgba(46, 117, 255, 0.10) 0%, rgba(122, 61, 255, 0.10) 99.99%, rgba(123, 61, 255, 0.10) 100%)'
          : 'var(--Light-Grey, #F1F1F1)'
      }
    },
    iconSrc () {
      if (this.selected) return require('@/assets/icons/svg/' + this.icon + '-grad.svg')
      else return require('@/assets/icons/svg/' + this.icon + '.svg')
    },
    cssImgVars () {
      return {
        ...this.iconStyle
      }
    }
  }
})
</script>

<style scoped lang="scss">
  div.button-content {
      cursor: pointer;
      width: 40px;
      height: 40px;
      border-radius: 8px;
      margin: 0 0 0 8px;
  }
  img.icon {
    width: 20px;
    height: 20px;
  }
</style>
