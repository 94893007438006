import { BASE_URL_UNAUTHORISED } from '@/api'

interface EmbeddedVideoSectionStyleInterface {
    controls: string,
    thumbnail: string,
    thumbnailUri: string
}

interface EmbeddedVideoSectionInterface {
    id: number
    label: string
    listOrder: number,
    active: boolean,
    persistent: boolean,
    labelVisible: boolean,
    url: string
    style: EmbeddedVideoSectionStyleInterface
}

class EmbeddedVideoSectionStyle implements EmbeddedVideoSectionStyleInterface {
  controls = ''
  thumbnail = ''
  thumbnailUri = ''

  constructor (style?: EmbeddedVideoSectionStyleInterface) {
    if (style) {
      this.controls = style.controls
      this.thumbnail = style.thumbnail
      this.thumbnailUri = BASE_URL_UNAUTHORISED + 'profile/image/' + style.thumbnailUri
    }
  }

  imageExists () {
    return this.thumbnailUri !== BASE_URL_UNAUTHORISED + 'profile/image/default'
  }
}

export default class EmbeddedVideoSection implements EmbeddedVideoSectionInterface {
  id = 0
  label = ''
  listOrder = 0
  active = false
  persistent = false
  labelVisible = false
  url = ''
  style = new EmbeddedVideoSectionStyle()

  constructor (EmbeddedVideoSection?: EmbeddedVideoSectionInterface) {
    if (EmbeddedVideoSection) {
      this.id = EmbeddedVideoSection.id
      this.label = EmbeddedVideoSection.label
      this.listOrder = EmbeddedVideoSection.listOrder
      this.active = EmbeddedVideoSection.active
      this.persistent = EmbeddedVideoSection.persistent
      this.labelVisible = EmbeddedVideoSection.labelVisible
      this.url = EmbeddedVideoSection.url
      this.style = new EmbeddedVideoSectionStyle(EmbeddedVideoSection.style)
    }
  }
}
