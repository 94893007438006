import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-58083c3d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sub-profile-view" }
const _hoisted_2 = {
  key: 0,
  class: "sub-profile-content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SubProfileContainerModular = _resolveComponent("SubProfileContainerModular")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.subProfile.id.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_SubProfileContainerModular, {
            subProfile: _ctx.subProfile,
            contentActive: _ctx.contentActive,
            onOnDetailClick: _cache[0] || (_cache[0] = (id, qrSectionId, contentActive) =>_ctx.onDetailClick(id, qrSectionId, contentActive)),
            onOnAmazonReviewCTASectionClick: _ctx.onAmazonReviewCTASectionClick,
            onOnButtonSectionClick: _ctx.onButtonSectionClick,
            onOnEmbeddedVideoSectionClick: _ctx.onEmbeddedVideoSectionClick,
            onOnLinkSectionClick: _ctx.onLinkSectionClick,
            onOnSocialsSectionItemClick: _ctx.onSocialsSectionItemClick
          }, null, 8, ["subProfile", "contentActive", "onOnAmazonReviewCTASectionClick", "onOnButtonSectionClick", "onOnEmbeddedVideoSectionClick", "onOnLinkSectionClick", "onOnSocialsSectionItemClick"])
        ]))
      : _createCommentVNode("", true)
  ]))
}