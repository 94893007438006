import { authorisedPost } from './Api'

export function editNewsSectionStyle (
  subProfileId: string,
  id: number,
  textColour: string,
  backgroundColour: string,
  size: string,
  align: string,
  imageStyle: string,
  token: string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  const data = {
    textColour,
    backgroundColour,
    size,
    align,
    imageStyle
  }
  authorisedPost(`dashboard/${subProfileId}/edit-news-style?section=${id}`, data, token, unauthorisedCallback, badRequestCallback, serverErrorCallback, notFoundCallback, callback)
}
