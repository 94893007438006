import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    class: "button-container",
    style: _normalizeStyle(_ctx.cssVarsContainer)
  }, [
    (_ctx.buttonSection)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "button-body",
          style: _normalizeStyle(_ctx.cssVarsContent),
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
        }, [
          _createElementVNode("p", {
            class: "button-text",
            style: _normalizeStyle(_ctx.cssVarsText)
          }, _toDisplayString(_ctx.buttonSection.text), 5)
        ], 4))
      : _createCommentVNode("", true)
  ], 4))
}