<template>
    <div class="svg-icon-button-wrapper" :style="cssVars">
        <img :src="imgSrc" alt="icon">
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SvgButton',
  props: {
    icon: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: '40'
    },
    style: {
      type: Object,
      default: null
    }
  },
  computed: {
    cssVars () {
      return {
        '--size': this.size + 'px',
        ...this.style
      }
    },
    imgSrc () {
      return require('@/assets/icons/svg/' + this.icon + '.svg')
    }
  }
})
</script>

<style scoped lang="scss">
    div.svg-icon-button-wrapper {
      width: var(--size);
      height: var(--size);
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      margin: 3px;
      background: #F1F1F1;
      transition: all .2s ease-in-out;
      cursor: pointer;
    }
    div.svg-icon-button-wrapper:hover{
      transform: scale(1.1);
    }
    div.svg-icon-button-wrapper:active{
      transform: scale(1.05);
      transition: .2s;
    }
    img {
        width: 50%;
        height: 50%;
    }
</style>
