import { authorisedPost, get } from './Api'

export function editAmazonReviewCTASection (
  subProfileId: string,
  id: number,
  label: string,
  listOrder: number,
  active: boolean,
  title: string,
  subtitle: string,
  btnText: string,
  amazonUrl: string,
  token : string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  const data = {
    id,
    label,
    listOrder,
    active,
    labelVisible: false,
    title,
    subtitle,
    btnText,
    amazonUrl
  }
  authorisedPost(`dashboard/${subProfileId}/edit-amazon-review-cta?section=${id}`, data, token, unauthorisedCallback, badRequestCallback, serverErrorCallback, notFoundCallback, callback)
}

export function editAmazonReviewCTASectionStyle (
  subProfileId: string,
  id: number,
  theme: string,
  token : string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  const data = {
    theme
  }
  authorisedPost(`dashboard/${subProfileId}/edit-amazon-review-cta-style?section=${id}`, data, token, unauthorisedCallback, badRequestCallback, serverErrorCallback, notFoundCallback, callback)
}

export function pushSectionAmazonReviewCTAClick (
  qrSectionId: number,
  qrScanId: number,
  accessType: string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  get(
    `profile/push-section-amazon-review-cta-click?qrScanId=${qrScanId}&sectionId=${qrSectionId}&accessType=${accessType}`,
    unauthorisedCallback,
    notFoundCallback,
    badRequestCallback,
    notFoundCallback,
    callback
  )
}
