import { FeaturesItem, FeaturesItemInterface } from './FeaturesItem'

interface FeaturesSectionStyleInterface {
    layout: string,
    fillColour: string
  }

interface FeaturesSectionInterface {
    id: number
    label: string
    listOrder: number,
    active: boolean,
    persistent: boolean,
    labelVisible: boolean,
    features: FeaturesItemInterface[],
    style: FeaturesSectionStyleInterface
}

class FeaturesSectionStyle implements FeaturesSectionStyleInterface {
  layout = ''
  fillColour = ''

  constructor (style?: FeaturesSectionStyleInterface) {
    if (style) {
      this.layout = style.layout
      this.fillColour = style.fillColour
    }
  }
}

export default class FeaturesSection implements FeaturesSectionInterface {
  id = 0
  label = ''
  listOrder = 0
  active = false
  persistent = false
  labelVisible = false
  features = Array<FeaturesItemInterface>()
  style = new FeaturesSectionStyle()

  constructor (featuresSection?: FeaturesSectionInterface) {
    if (featuresSection) {
      this.id = featuresSection.id
      this.label = featuresSection.label
      this.listOrder = featuresSection.listOrder
      this.active = featuresSection.active
      this.persistent = featuresSection.persistent
      this.labelVisible = featuresSection.labelVisible
      this.features = featuresSection.features.map(d => new FeaturesItem(d)).sort(function (a: FeaturesItem, b: FeaturesItem) { return a.listOrder - b.listOrder })
      this.style = new FeaturesSectionStyle(featuresSection.style)
    }
  }
}
