<template>
    <div class="preview-panel-container" :style="cssVarsContainer">
        <div class="d-flex flex-row align-items-center preview-panel-header-container">
            <a href="#"><img class="preview-icon" :src="require('@/assets/icons/svg/eye.svg')"></a>
            <p class="m-0 preview-text">Keypin Preview</p>
            <div class="d-flex flex-fill justify-content-end">
              <ViewportButton :selected="viewport === 'desktop'" icon="desktop" @click="() => $emit('onSelectViewport', 'desktop')"/>
              <ViewportButton :selected="viewport === 'tablet'" icon="tablet" @click="() => $emit('onSelectViewport', 'tablet')"/>
              <ViewportButton :selected="viewport === 'mobile'" icon="mobile" @click="() => $emit('onSelectViewport', 'mobile')"/>
            </div>
        </div>
        <SubProfileContainerModular
          :subProfile="subProfile"
          :contentActive="contentActive"
          @onDetailClick="(id: string, contentActive: boolean) => setContentActive(contentActive)"
        />
    </div>
</template>

<script lang="ts">

import { defineComponent } from 'vue'

import SubProfileContainerModular from '@/components/profile/SubProfileContainerModular.vue'
import ViewportButton from '../button/ViewportButton.vue'

import { UserSubProfile } from '@/models/UserSubProfile'

export default defineComponent({
  name: 'PreviewPanel',
  components: {
    SubProfileContainerModular,
    ViewportButton
  },
  props: {
    subProfile: {
      type: UserSubProfile,
      required: true
    },
    viewport: {
      type: String,
      default: 'mobile'
    },
    containerStyle: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      contentActive: false
    }
  },
  computed: {
    cssVarsContainer () {
      if (this.viewport === 'desktop') {
        return {
          width: 'calc(100vw - 340px)',
          height: '67vh',
          '--left': 'inherit',
          '--top': 'inherit',
          '--bottom': 'inherit',
          ...this.containerStyle
        }
      } else {
        return {
          height: '67vh',
          aspectRatio: this.viewport === 'mobile' ? '329 / 709' : '720 / 709',
          '--left': 'inherit',
          '--top': 'inherit',
          '--bottom': 'inherit',
          ...this.containerStyle
        }
      }
    }
  },
  methods: {
    setContentActive (active: boolean) {
      this.contentActive = active
    }
  }
})
</script>

<style scoped>

div.preview-panel-container {
  overflow: scroll;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background: #FFF;
  box-shadow: 0px 12px 50px 0px rgba(0, 0, 0, 0.15), 0px -1px 10px 0px rgba(0, 0, 0, 0.10);
  position: fixed;
  right:0;
  left: var(--left);
  top: var(--top);
  bottom: var(--bottom);
}

div.preview-panel-header-container {
  padding: 10px 10px 10px 24px;
  position: sticky;
  top: 0;
  z-index: 1;
  background: #FFF;
}

img.preview-icon {
    height: 1.75em;
    aspect-ratio: 1;
    margin-right: 0.75em;
}

p.preview-text {
  color: var(--Nearly-Black, #212529);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 107.692% */
  opacity: 0.5;
}

</style>
