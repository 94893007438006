<template >
    <div v-if="tableSection" class="table-container d-flex flex-row justify-content-center" :style="cssVars">
      <table>
        <!-- Table cant be less than 2x2-->
        <thead>
          <tr>
            <th :style="cssVarsTL"> {{ tableSection.value[0][0] }}</th>
            <th v-for="(header, index) in tableSection.value[0].slice(1, -1)" :key="index" :style="cssVarsTC">{{ header }}</th>
            <th :style="cssVarsTR"> {{ tableSection.value[0].slice(-1)[0] }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, rowIndex) in tableSection.value.slice(1, -1)" :key="rowIndex">
            <td :style="cssVarsLC">{{ row[0] }}</td>
            <td :style="cssVarsCC" v-for="(cell, cellIndex) in row.slice(1, -1)" :key="cellIndex">{{ cell }}</td>
            <td :style="cssVarsRC">{{ row.slice(-1)[0] }}</td>
          </tr>
          <tr>
            <td :style="cssVarsBL">{{ tableSection.value[tableSection.value.length - 1][0] }}</td>
            <td :style="cssVarsBC" v-for="(cell, cellIndex) in tableSection.value[tableSection.value.length - 1].slice(1, -1)" :key="cellIndex">{{ cell }}</td>
            <td :style="cssVarsBR">{{ tableSection.value[tableSection.value.length - 1].slice(-1)[0] }}</td>
          </tr>
        </tbody>
      </table>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import TableSection from '@/models/qr-sections/TableSection'

export default defineComponent({
  name: 'TableSection',
  props: {
    tableSection: TableSection
  },
  computed: {
    cssVarsTL (): Record<string, string> {
      return {
        '--opacity': '1.0',
        '--border-bottom': (this.tableSection.style.header === 'row' || this.tableSection.style.border === 0) ? '1px solid rgba(0, 0, 0, 0.20)' : 'none',
        '--border-top': this.tableSection.style.border in [0, 1, 3] ? '1px solid rgba(0, 0, 0, 0.20)' : 'none',
        '--border-right': this.tableSection.style.border in [0, 4] ? '1px solid rgba(0, 0, 0, 0.20)' : 'none',
        '--border-left': (this.tableSection.style.border === 0 || this.tableSection.style.border === 1) ? '1px solid rgba(0, 0, 0, 0.20)' : 'none'
      }
    },
    cssVarsTC (): Record<string, string> {
      return {
        '--opacity': this.tableSection.style.header === 'row' ? '1.0' : '0.5',
        '--border-bottom': this.tableSection.style.header === 'row' ? '1px solid rgba(0, 0, 0, 0.20)' : 'none',
        '--border-top': this.tableSection.style.border in [0, 1, 3] ? '1px solid rgba(0, 0, 0, 0.20)' : 'none',
        '--border-right': this.tableSection.style.border in [0, 4] ? '1px solid rgba(0, 0, 0, 0.20)' : 'none'
      }
    },
    cssVarsTR (): Record<string, string> {
      return {
        '--opacity': this.tableSection.style.header === 'row' ? '1.0' : '0.5',
        '--border-bottom': (this.tableSection.style.header === 'row' || this.tableSection.style.border === 0) ? '1px solid rgba(0, 0, 0, 0.20)' : 'none',
        '--border-top': this.tableSection.style.border in [0, 1, 3] ? '1px solid rgba(0, 0, 0, 0.20)' : 'none',
        '--border-right': this.tableSection.style.border in [0, 1] ? '1px solid rgba(0, 0, 0, 0.20)' : 'none'
      }
    },
    cssVarsLC (): Record<string, string> {
      return {
        '--opacity': this.tableSection.style.header === 'col' ? '1.0' : '0.5',
        '--border-right': this.tableSection.style.border in [0, 4] ? '1px solid rgba(0, 0, 0, 0.20)' : 'none',
        '--border-bottom': this.tableSection.style.border === 0 ? '1px solid rgba(0, 0, 0, 0.20)' : 'none',
        '--border-left': this.tableSection.style.border in [0, 1] ? '1px solid rgba(0, 0, 0, 0.20)' : 'none'
      }
    },
    cssVarsCC (): Record<string, string> {
      return {
        '--opacity': '0.5',
        '--border-bottom': this.tableSection.style.border === 0 ? '1px solid rgba(0, 0, 0, 0.20)' : 'none',
        '--border-right': this.tableSection.style.border in [0, 4] ? '1px solid rgba(0, 0, 0, 0.20)' : 'none'
      }
    },
    cssVarsRC (): Record<string, string> {
      return {
        '--opacity': '0.5',
        '--border-bottom': this.tableSection.style.border === 0 ? '1px solid rgba(0, 0, 0, 0.20)' : 'none',
        '--border-right': this.tableSection.style.border in [0, 1] ? '1px solid rgba(0, 0, 0, 0.20)' : 'none'
      }
    },
    cssVarsBL (): Record<string, string> {
      return {
        '--opacity': this.tableSection.style.header === 'col' ? '1.0' : '0.5',
        '--border-right': this.tableSection.style.border in [0, 4] ? '1px solid rgba(0, 0, 0, 0.20)' : 'none',
        '--border-bottom': this.tableSection.style.border in [0, 1, 2] ? '1px solid rgba(0, 0, 0, 0.20)' : 'none',
        '--border-left': this.tableSection.style.border in [0, 1] ? '1px solid rgba(0, 0, 0, 0.20)' : 'none'
      }
    },
    cssVarsBC (): Record<string, string> {
      return {
        '--opacity': '0.5',
        '--border-bottom': this.tableSection.style.border in [0, 1, 2] ? '1px solid rgba(0, 0, 0, 0.20)' : 'none',
        '--border-right': this.tableSection.style.border in [0, 4] ? '1px solid rgba(0, 0, 0, 0.20)' : 'none'
      }
    },
    cssVarsBR (): Record<string, string> {
      return {
        '--opacity': '0.5',
        '--border-bottom': this.tableSection.style.border in [0, 1, 2] ? '1px solid rgba(0, 0, 0, 0.20)' : 'none',
        '--border-right': this.tableSection.style.border in [0, 1] ? '1px solid rgba(0, 0, 0, 0.20)' : 'none'
      }
    }
  }
})
</script>

<style scoped lang="scss">
    div.table-container {
        width: 90%;
        background-color: transparent;
        margin-top: 40px;
    }

    table {
      border-collapse: collapse;
      width: 100%;
      table-layout: fixed;
    }
    td, th {
      color: var(--Nearly-Black, #212529);
      font-family: Inter;
      font-style: normal;
      opacity: var(--opacity);
      border-bottom: var(--border-bottom);
      border-top: var(--border-top);
      border-left: var(--border-left);
      border-right: var(--border-right);
    }
    td {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
    }
    th {
      font-size: 13px;
      font-weight: 600;
      line-height: 20px;
    }
</style>
