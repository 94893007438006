interface ButtonSectionStyleInterface {
    textColour: string,
    fillColour: string,
    style: string,
    corners: string,
    size: string,
    align: string
}

interface ButtonSectionInterface {
    id: number,
    label: string,
    listOrder: number,
    active: boolean,
    persistent: boolean,
    labelVisible: boolean,
    text: string,
    url: string
    style: ButtonSectionStyleInterface
}

class ButtonSectionStyle implements ButtonSectionStyleInterface {
  textColour = ''
  fillColour = ''
  style = ''
  corners = ''
  size = ''
  align = ''

  constructor (style?: ButtonSectionStyleInterface) {
    if (style) {
      this.textColour = style.textColour
      this.fillColour = style.fillColour
      this.style = style.style
      this.corners = style.corners
      this.size = style.size
      this.align = style.align
    }
  }
}

export default class ButtonSection implements ButtonSectionInterface {
  id = 0
  label = ''
  listOrder = 0
  active = false
  persistent = false
  labelVisible = false
  text = ''
  url = ''
  style = new ButtonSectionStyle()

  constructor (ButtonSection?: ButtonSectionInterface) {
    if (ButtonSection) {
      this.id = ButtonSection.id
      this.label = ButtonSection.label
      this.listOrder = ButtonSection.listOrder
      this.active = ButtonSection.active
      this.persistent = ButtonSection.persistent
      this.labelVisible = ButtonSection.labelVisible
      this.text = ButtonSection.text
      this.url = ButtonSection.url
      this.style = new ButtonSectionStyle(ButtonSection.style)
    }
  }
}
