interface ScanLocationInterface {
  location: string,
  totalScans: number,
}

export default class ScanLocation implements ScanLocationInterface {
  location = ''
  totalScans = 0

  constructor (scanLocation?: ScanLocationInterface) {
    if (scanLocation) {
      this.location = scanLocation.location
      this.totalScans = scanLocation.totalScans
    }
  }
}
