interface TopLinkInterface {
  id: string,
  name: string,
  totalClicks: number
}

export default class TopLink implements TopLinkInterface {
  id = ''
  name = ''
  totalClicks = 0

  constructor (topQr?: TopLinkInterface) {
    if (topQr) {
      this.id = topQr.id
      this.name = topQr.name
      this.totalClicks = topQr.totalClicks
    }
  }
}
