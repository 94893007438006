<template >
    <div v-if="text" class="text-container d-flex flex-row justify-content-center px-3" :style="cssVars">
      <p class="content-text">{{ text.content }}</p>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import TextSection from '../../models/qr-sections/TextSection'

export default defineComponent({
  name: 'TextSection',
  props: {
    text: TextSection
  },
  computed: {
    avatar () {
      return this.subProfile?.image.imageUri
    },
    cssVars () {
      return {
        '--text-color': '#000000'
      }
    }
  }
})
</script>

<style scoped lang="scss">
    div.text-container {
        width: 90%;
        background-color: transparent
    }

    p.content-text {
      font-size: small;
      font-family: inter;
      overflow-wrap: break-word;
      width: 100%;
      color: var(--text-color);
      text-align: center;
    }
</style>
