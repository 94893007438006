import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-05ad1972"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")!

  return (_openBlock(), _createElementBlock("div", {
    class: "vl-parent button",
    disabled: _ctx.disabled,
    style: _normalizeStyle(_ctx.cssVars),
    onClick: _cache[1] || (_cache[1] = ($event: any) => ( _ctx.onClick()))
  }, [
    _createVNode(_component_loading, {
      active: _ctx.isLoading,
      "onUpdate:active": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isLoading) = $event)),
      height: "20",
      "can-cancel": true,
      "is-full-page": false
    }, null, 8, ["active"]),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 12, _hoisted_1))
}