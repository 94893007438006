<template>
    <EditorPanel
      :title="amazonReviewCTASection.label"
      :active="amazonReviewCTASection.active"
      :persistent="amazonReviewCTASection.persistent"
      :styleMode="styleMode"
      @onSetActive="onSetActive"
      @onDelete="onDelete"
      @onToggleMode="onToggleMode"
      >
      <div v-if="!this.styleMode" class="d-flex flex-column">
        <EditorInputComponent label="CTA Title" :value="amazonReviewCTASection.title" @onChangeValue="onEditTitle"/>
        <EditorInputComponent label="CTA Subtitle" :value="amazonReviewCTASection.subtitle" @onChangeValue="onEditSubTitle"/>
        <EditorInputComponent label="Button Text" :value="amazonReviewCTASection.btnText" @onChangeValue="onEditBtnText"/>
        <EditorInputComponent label="Amazon URL" :value="amazonReviewCTASection.amazonUrl" @onChangeValue="onEditAmazonUrl"/>
        </div>
      <div v-else class="d-flex flex-column amazon-review-cta-style-panel-content">
          <div class="d-flex amazon-review-cta-style-panel-row align-items-center">
            <p class="style-header d-flex flex-fill">Theme</p>
            <div class="d-flex">
              <StyleTextIconButton :icon="'amazon-review-cta-theme-light'" :selected="!styleDark" @click="() => onEditTheme('light')">Light</StyleTextIconButton>
              <StyleTextIconButton :icon="'amazon-review-cta-theme-dark'" :selected="styleDark" @click="() => onEditTheme('dark')">Dark</StyleTextIconButton>
            </div>
          </div>
        </div>
      </EditorPanel>
</template>

<script lang="ts">

import { defineComponent } from 'vue'

import ColourInput from '../input/ColourInput.vue'
import CrossButton from '../button/CrossButton.vue'
import DebouncedTextInput from '../input/DebouncedTextInput.vue'
import EditorPanel from '@/components/editor/EditorPanel.vue'
import FaIconLink from '../button/FaIconLink.vue'
import StyleTextIconButton from '../button/style-panel/StyleTextIconButton.vue'

import AmazonReviewCTASection from '@/models/qr-sections/AmazonReviewCTASection'
import { SlickItem, SlickList, HandleDirective } from 'vue-slicksort'
import EditorInputComponent from '../input/EditorInputComponent.vue'

export default defineComponent({
  name: 'AmazonReviewCTAPanel',
  components: {
    EditorInputComponent,
    EditorPanel,
    StyleTextIconButton
  },
  props: {
    amazonReviewCTASection: {
      type: AmazonReviewCTASection,
      required: true
    }
  },
  data () {
    return {
      styleMode: false
    }
  },
  computed: {
    styleDark () {
      return this.amazonReviewCTASection.style.theme === 'dark'
    }
  },
  methods: {
    onToggleMode (emit = true) {
      this.styleMode = !this.styleMode
      if (emit) this.$emit('onToggleMode', this.amazonReviewCTASection.id, this.styleMode)
    },
    onSetActive (value: boolean) {
      this.$emit(
        'onEdit',
        this.amazonReviewCTASection.id,
        this.amazonReviewCTASection.label,
        this.amazonReviewCTASection.listOrder,
        value,
        this.amazonReviewCTASection.title,
        this.amazonReviewCTASection.subtitle,
        this.amazonReviewCTASection.btnText,
        this.amazonReviewCTASection.amazonUrl)
    },
    onDelete () {
      this.$emit('onDelete', this.amazonReviewCTASection.id)
    },
    onEditTitle (value: string) {
      this.$emit(
        'onEdit',
        this.amazonReviewCTASection.id,
        this.amazonReviewCTASection.label,
        this.amazonReviewCTASection.listOrder,
        this.amazonReviewCTASection.active,
        value,
        this.amazonReviewCTASection.subtitle,
        this.amazonReviewCTASection.btnText,
        this.amazonReviewCTASection.amazonUrl)
    },
    onEditSubTitle (value: string) {
      this.$emit(
        'onEdit',
        this.amazonReviewCTASection.id,
        this.amazonReviewCTASection.label,
        this.amazonReviewCTASection.listOrder,
        this.amazonReviewCTASection.active,
        this.amazonReviewCTASection.title,
        value,
        this.amazonReviewCTASection.btnText,
        this.amazonReviewCTASection.amazonUrl)
    },
    onEditBtnText (value: string) {
      this.$emit(
        'onEdit',
        this.amazonReviewCTASection.id,
        this.amazonReviewCTASection.label,
        this.amazonReviewCTASection.listOrder,
        this.amazonReviewCTASection.active,
        this.amazonReviewCTASection.title,
        this.amazonReviewCTASection.subtitle,
        value,
        this.amazonReviewCTASection.amazonUrl)
    },
    onEditAmazonUrl (value: string) {
      this.$emit(
        'onEdit',
        this.amazonReviewCTASection.id,
        this.amazonReviewCTASection.label,
        this.amazonReviewCTASection.listOrder,
        this.amazonReviewCTASection.active,
        this.amazonReviewCTASection.title,
        this.amazonReviewCTASection.subtitle,
        this.amazonReviewCTASection.btnText,
        value)
    },
    onEditTheme (value: string) {
      if (value === this.amazonReviewCTASection.style.theme) return
      this.$emit(
        'onEditStyle',
        this.amazonReviewCTASection.id,
        value
      )
    }
  }
})
</script>

<style scoped>

div.amazon-review-cta-style-panel-content {
    flex-shrink: 0;
    width: 100%;
    padding: 10px;
  }

  div.amazon-review-cta-style-panel-row {
    width: 100%;
    height: 60px;
    border-top: 1px solid rgba(33, 37, 41, 0.1);
  }

  p.style-header {
    color: var(--Nearly-Black, #212529);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    opacity: 0.5;
    margin: 0px;
  }

</style>
