import { SubProfileComponentTheme } from './SubProfileComponentTheme'

export interface SubProfileSplitButtonThemeInterface {
  backgroundColour: string,
  borderColour: string,
  secondaryColour: string
  textColour: string
}

export class SubProfileSplitButtonTheme extends SubProfileComponentTheme implements SubProfileSplitButtonThemeInterface {
  secondaryColour = ''
  constructor (theme?: SubProfileSplitButtonThemeInterface) {
    super(theme)
    if (theme) {
      const { secondaryColour } = theme
      this.secondaryColour = secondaryColour
    }
  }
}
