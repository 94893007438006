<template>
    <div v-if="featuresSection" :class="classBody">
        <div v-for="feature in featuresSection.features" :key="feature.id" :class="classItemContainer" :style="cssVars">
         <img class="tick-img" :src="require('@/assets/icons/svg/tick-wg.svg')" alt="tick" :style="cssImg"/>
         <p class="feature-item-text"> {{ feature.value }}</p>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import FeaturesSection from '@/models/qr-sections/FeaturesSection'

import { hexToRGBA } from '@/utils'

export default defineComponent({
  name: 'FeaturesSection',
  props: {
    featuresSection: FeaturesSection
  },
  data () {
    return {
      activeContentId: ''
    }
  },
  computed: {
    classBody () {
      return {
        'features-body': true,
        'features-body-list': this.featuresSection.style.layout === 'list',
        'features-body-grid': this.featuresSection.style.layout === 'grid'
      }
    },
    classItemContainer () {
      return {
        'feature-item-container': true,
        'feature-item-list': this.featuresSection.style.layout === 'list',
        'feature-item-grid': this.featuresSection.style.layout === 'grid'
      }
    },
    cssVars () {
      return {
        '--background-color': hexToRGBA(this.featuresSection.style.fillColour, 0.15)
      }
    },
    cssImg () {
      return {
        marginBottom: this.featuresSection.style.layout === 'list' ? '0' : '10px'
      }
    }
  }
})
</script>

<style scoped lang="scss">
    div.features-body{
        width: 100%;
        margin-top: 10px;
        display: table;
    }
    div.features-body.features-body-list{
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }
    div.features-body.features-body-grid{
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: stretch;
    }

    div.feature-item-container{
        margin-top: 10px;
        border-radius: 10px;
        background-color: var(--background-color);
        min-height: 40px;
        padding: 10px;
        word-wrap: break-word;
    }
    div.feature-item-container.feature-item-list{
        display: flex;
        flex-direction: row;
        width: 100%;
    }
    div.feature-item-container.feature-item-grid{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: calc(50% - 10px);
    }
    img.tick-img {
        width: 24px;
        height: 24px;
        margin-right: 10px;
    }
    p.feature-item-text {
        color: #212529;
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 153.846% */
        margin: 0;
        text-align: left;
    }
</style>
