<template>
    <div class="text-clipboard-container">
        <div class="text-clipboard-text-wrapper">
            <p class="text-clipboard-text">{{ this.text }}</p>
        </div>
        <div class="text-clipboard-button-wrapper" @click="onCopy" v-on:mouseover="changeImage" v-on:mouseout="resetImage">
          <img v-bind:src="this.srcTextClipboardButton" class="text-clipboard-button-image">
            <div class="tooltip-container">
                <TooltipComponent>Copy to clipboard</TooltipComponent>
            </div>
        </div>
    </div>
</template>

<script lang="ts">

import { defineComponent, shallowReactive } from 'vue'

import TooltipComponent from '@/components/tooltip/TooltipComponent.vue'

export default defineComponent({
  name: 'TextClipboard',
  components: {
    TooltipComponent
  },
  props: {
    text: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      srcTextClipboardButton: require('@/assets/icons/svg/copy-clipboard.svg'),
      clickFlag: false
    }
  },
  methods: {
    onCopy () {
      // this.clickFlag = true
      navigator.clipboard.writeText(this.text)
      // setTimeout(function () {
      //   this.clickFlag = false
      //   this.srcTextClipboardButton = require('@/assets/icons/svg/copy-clipboard.svg')
      // }, 3000)
    },
    changeImage () {
      this.srcTextClipboardButton = require('@/assets/icons/svg/copy-clipboard-grad.svg')
    },
    resetImage () {
      if (!this.clickFlag) this.srcTextClipboardButton = require('@/assets/icons/svg/copy-clipboard.svg')
    }
  }
})

</script>

<style scoped>
div.text-clipboard-container{
    min-width: 233px;
    height: 32px;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
}
 div.text-clipboard-text-wrapper {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    flex: 1;
    padding: 0 0 0 12px;
    border-radius: 8px 0 0 8px;
    border-right: 2px solid #F1F1F1;
    background-color: #FFF;
 }

 p.text-clipboard-text {
    color: var(--Nearly-Black, #212529);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 107.692% */
    margin: 0;
 }

 div.text-clipboard-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 9px;
    flex-shrink: 0;
    border-radius: 0 8px 8px 0;
    background-color: #FFF;
    cursor: pointer;
    position: relative;
 }

 img.text-clipboard-button-image{
    width: 14px;
    height: 14px;
 }

 div.tooltip-container {
    display: none;
    position: inherit;
 }

 div.text-clipboard-button-wrapper:hover div.tooltip-container {
    display: block;
 }
 </style>
