import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TagComponent = _resolveComponent("TagComponent")!

  return (_openBlock(), _createBlock(_component_TagComponent, {
    "background-color": "#F1F1F1",
    color: "#212529",
    style: _normalizeStyle(this.style)
  }, {
    default: _withCtx(() => [
      _createTextVNode(" Unpublished ")
    ]),
    _: 1
  }, 8, ["style"]))
}