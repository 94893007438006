<template>
    <EditorPanel
      :title="featuresSection.label"
      :active="featuresSection.active"
      :persistent="featuresSection.persistent"
      :styleMode="styleMode"
      @onSetActive="onSetActive"
      @onDelete="onDelete"
      @onToggleMode="onToggleMode"
      >
      <div v-if="!this.styleMode" class="d-flex flex-column">
      <SlickList axis="y" v-model:list="features" @update:list="onChangeFeatureOrder" :useDragHandle="true">
              <SlickItem v-for="(feature, i) in features" :key="feature" :index="i">
                <div class="d-flex flex-direction-row feature-container align-items-center">
                  <div class="feature-value-input-container">
                    <DebouncedTextInput
                      :value="feature.value"
                      type="text"
                      placeholder="title"
                      @update:value="(value: string) => $emit('onEditFeature', feature.id, value, featuresSection.id)"
                      />
                  </div>
                  <div class="feature-delete-button-container">
                    <CrossButton @click="() => $emit('onDeleteFeature', feature.id, featuresSection.id)" :style="cssDeleteFeatureButton"/>
                  </div>
                  <div class="feature-grip-handle-container">
                    <span v-handle class="handle"><a href="#"><img class="grip-icon" :src="require('@/assets/icons/icon-grip-1.png')"></a></span>
                  </div>
                </div>
            </SlickItem>
        </SlickList>
        <div class="my-2 align-self-start">
            <FaIconLink
              icon="plus"
              size="md"
              text="Add item"
              :icon-container-style="cssAddIcon"
              :text-style="cssAddText"
              :text-uses-gradient="true"
              @click="() => $emit('onAddFeature', featuresSection.id)"
              />
          </div>
        </div>
          <div v-else class="d-flex flex-column features-style-panel-content">
          <div class="d-flex features-style-panel-row align-items-center">
            <p class="style-header d-flex flex-fill">Layout</p>
            <div class="d-flex">
              <StyleTextIconButton :icon="'style-layout-list'" :selected="styleList" @click="() => onEditLayout('list')">List</StyleTextIconButton>
              <StyleTextIconButton :icon="'style-layout-grid'" :selected="!styleList" @click="() => onEditLayout('grid')">Grid</StyleTextIconButton>
            </div>
          </div>
          <div class="d-flex features-style-panel-row align-items-center">
            <p class="style-header d-flex flex-fill">Colour</p>
            <div class="d-flex">
              <ColourInput :label="'Fill'" :value="featuresSection.style.fillColour" @onChangeColour="onEditFillColour"/>
            </div>
          </div>
        </div>
      </EditorPanel>
</template>

<script lang="ts">

import { defineComponent } from 'vue'

import ColourInput from '../input/ColourInput.vue'
import CrossButton from '../button/CrossButton.vue'
import DebouncedTextInput from '../input/DebouncedTextInput.vue'
import EditorPanel from '@/components/editor/EditorPanel.vue'
import FaIconLink from '../button/FaIconLink.vue'
import StyleTextIconButton from '../button/style-panel/StyleTextIconButton.vue'

import FeaturesSection from '@/models/qr-sections/FeaturesSection'
import { FeaturesItem } from '@/models/qr-sections/FeaturesItem'
import { SlickItem, SlickList, HandleDirective } from 'vue-slicksort'
import { DETAIL_TYPES } from '@/constants'
import { Palette } from '@/theme'

export default defineComponent({
  name: 'FeaturesPanel',
  components: {
    ColourInput,
    CrossButton,
    DebouncedTextInput,
    EditorPanel,
    FaIconLink,
    SlickItem,
    SlickList,
    StyleTextIconButton
  },
  props: {
    featuresSection: {
      type: FeaturesSection,
      required: true
    }
  },
  data () {
    return {
      features: this.featuresSection.features,
      drag: false,
      styleMode: false
    }
  },
  computed: {
    cssVarsContainer () {
      return {
        ...this.containerStyle
      }
    },
    cssAddIcon () {
      return {
        backgroundImage: Palette.gradientBP,
        width: '1.95em',
        borderRadius: '0.975em',
        padding: '0.5em',
        color: Palette.white
      }
    },
    cssAddText () {
      return {
        backgroundImage: Palette.gradientBP,
        backgroundClip: 'text',
        backgroundSize: '100%',
        textFillColor: 'transparent'
      }
    },
    cssDeleteFeatureButton () {
      return {
        width: '30px',
        height: '30px'
      }
    },
    styleList () {
      return this.featuresSection.style.layout === 'list'
    }
  },
  directives: { handle: HandleDirective },
  watch: {
    featureSection (featureSection) {
      if (featureSection) this.features = featureSection.features
    }
  },
  methods: {
    onToggleMode (emit = true) {
      this.styleMode = !this.styleMode
      if (emit) this.$emit('onToggleMode', this.featuresSection.id, this.styleMode)
    },
    getIcon (type: string): string {
      return DETAIL_TYPES[type].icon
    },
    onSetActive (value: boolean) {
      this.$emit('onEdit', this.featuresSection.id, this.featuresSection.label, this.featuresSection.listOrder, value, this.featuresSection.labelVisible)
    },
    onChangeFeatureOrder (features : Array<FeaturesItem>) {
      this.drag = false
      this.$emit('onChangeFeatureOrder', this.featuresSection.id, features)
    },
    onDelete () {
      this.$emit('onDelete', this.featuresSection.id)
    },
    onEditLayout (value: string) {
      if (value === this.featuresSection.style.layout) return
      this.$emit(
        'onEditStyle',
        this.featuresSection.id,
        value,
        this.featuresSection.style.fillColour
      )
    },
    onEditFillColour (value: number) {
      if (value === this.featuresSection.style.fillColour) return
      this.$emit(
        'onEditStyle',
        this.featuresSection.id,
        this.featuresSection.style.layout,
        value
      )
    }
  }
})
</script>

<style scoped>

div.feature-container {
  border-radius: 8px;
  background: var(--Light-Grey, #F1F1F1);
  width: 100%;
  padding: 12px;
}

img.feature-type-icon {
  width: 3.75%;
  aspect-ratio: 1;
}
p.feature-type-label {
  width: 25%;
  padding: 0em 0em 0em 2em;
  margin: 0;
  text-align: left;
}

div.feature-container{
  margin: 10px 0 10px 0;
}

div.feature-value-input-container {
  display: flex;
  flex-grow: 1;
}

div.feature-delete-button-container {
  width: 40px;
  margin: 0 10px 0 10px;
}

div.feature-grip-handle-container {
  width: 10px;
  margin: 0 8px 0 0;
}

img.grip-icon {
  height: 16.8px;
  width: 10px;
}

div.features-style-panel-content {
    flex-shrink: 0;
    width: 100%;
    padding: 10px;
  }

  div.features-style-panel-row {
    width: 100%;
    height: 60px;
    border-top: 1px solid rgba(33, 37, 41, 0.1);
  }

  p.style-header {
    color: var(--Nearly-Black, #212529);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    opacity: 0.5;
    margin: 0px;
  }

</style>
