import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-83c90724"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "check-label",
  for: "customSwitch1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "form-check form-switch",
    style: _normalizeStyle(_ctx.cssVars)
  }, [
    _withDirectives(_createElementVNode("input", {
      type: "checkbox",
      class: "form-check-input",
      id: "customSwitch1",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event))
    }, null, 512), [
      [_vModelCheckbox, _ctx.value]
    ]),
    _createElementVNode("label", _hoisted_1, _toDisplayString(this.label), 1)
  ], 4))
}