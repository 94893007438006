import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-16e50fc2"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "viewport-button-wrapper",
    style: _normalizeStyle(_ctx.cssVars)
  }, [
    _createElementVNode("img", {
      src: _ctx.imgSrc,
      alt: "icon"
    }, null, 8, _hoisted_1)
  ], 4))
}