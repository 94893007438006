import Section from './Section'
import { BASE_URL_UNAUTHORISED } from '@/api'

interface ImageSectionInterface {
  id: number
  label: string
  listOrder: number
  active: boolean
  persistent: boolean
  labelVisible: boolean
  uri: string
}

export default class ImageSection extends Section implements ImageSectionInterface {
  uri = ''

  constructor (imageSection?: ImageSectionInterface) {
    super(imageSection)
    if (imageSection) {
      this.uri = BASE_URL_UNAUTHORISED + 'profile/image/' + imageSection.uri
    }
  }

  imageExists () {
    return this.uri !== BASE_URL_UNAUTHORISED + 'profile/image/default'
  }
}
