<template>
  <div class="select-container" :style="cssVarsContainer">
    <label class="select-label" v-if="label" for="select-input">{{ label }}</label>
    <select class="select-wrapper" :selected="this.internalValue" id="select-tinput" v-model="value" :style="cssVarsComponent">
      <option v-for="[k,v] in Object.entries(this.options)" :key="k">{{ v }}</option>
    </select>
  </div>
</template>

<script lang="ts">

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SelectInput',
  props: {
    options: Object,
    label: String,
    modelValue: String,
    layout: {
      type: String,
      default: 'column'
    },
    containerStyle: {
      type: Object,
      default: null
    }
  },
  emits: ['update:value'],
  computed: {
    value: {
      get () {
        return this.modelValue
      },
      set (value : string) {
        this.$emit('update:value', value)
      }
    },
    cssVarsContainer () {
      return {
        '--container-flex-direction': this.layout,
        ...this.containerStyle
      }
    },
    cssVarsComponent () {
      return {
        '--component-width': this.layout === 'column' ? '100%' : '70%',
        ...this.style
      }
    }
  }
})

</script>

<style scoped>

div.select-container {
  flex-direction: var(--container-flex-direction);
  justify-content: center;
}
select.select-wrapper {
  width: 132px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid rgba(33, 37, 41, 0.10);
  background: #FFF;
  color: var(--Nearly-Black, #212529);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 107.692% */
  padding-left: 10px;
}

label.select-label {
  color: var(--Nearly-Black, #212529);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 107.692% */
  margin-right: 10px;
  opacity: 0.5;
}

</style>
