<template>
    <a class="button-container" :style="cssVarsContainer">
      <div v-if="buttonSection" class="button-body" :style="cssVarsContent" @click="onClick">
          <p
              class="button-text"
              :style="cssVarsText"
              >
              {{ buttonSection.text }}
          </p>
        </div>
    </a>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import ButtonSectionModel from '@/models/qr-sections/ButtonSection'

export default defineComponent({
  name: 'ButtonSection',
  props: {
    buttonSection: ButtonSectionModel
  },
  computed: {
    btnStyleWidth () {
      if (this.buttonSection.style.align === 'fill') {
        return '100%'
      } else if (this.buttonSection.style.size === 'sm') {
        return '100px'
      } else if (this.buttonSection.style.size === 'md') {
        return '200px'
      } else {
        return '300px'
      }
    },
    btnStyleHeight () {
      if (this.buttonSection.style.size === 'sm') {
        return '30px'
      } else if (this.buttonSection.style.size === 'md') {
        return '40px'
      } else {
        return '50px'
      }
    },
    btnStyleBorderRadius () {
      if (this.buttonSection.style.style === 'pill') {
        return '18px'
      } else if (this.buttonSection.style.corners === 'rounded') {
        return '12px'
      } else {
        return '0px'
      }
    },
    btnStyleContainerMargin () {
      if (this.buttonSection.style.align === 'left') {
        return '0 auto 0 0'
      } else if (this.buttonSection.style.align === 'right') {
        return '0 0 0 auto'
      } else {
        return '0 0 0 0'
      }
    },
    cssVarsContent () {
      return {
        '--height': this.btnStyleHeight,
        '--background': this.buttonSection.style.fillColour,
        '--border-radius': this.btnStyleBorderRadius
      }
    },
    cssVarsContainer () {
      return {
        '--width': this.btnStyleWidth,
        '--container-margin': this.btnStyleContainerMargin
      }
    },
    cssVarsText () {
      return {
        '--color': this.buttonSection.style.textColour
      }
    }
  },
  methods: {
    onClick () {
      window.open(this.buttonSection.url, '_blank')
      this.$emit('onPushClick')
    }
  }
})
</script>

<style scoped lang="scss">

  a.button-container{
    text-decoration: none;
    margin: var(--container-margin);
    width: var(--width);
    cursor: pointer;
  }
  div.button-body{
    width: 100%;
    height: var(--height);
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--background);
    border-radius: var(--border-radius);
  }
  p.button-text{
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 100% */
    color: var(--color);
    margin: 0;
  }
</style>
