import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FaIconLink = _resolveComponent("FaIconLink")!
  const _component_SvgButton = _resolveComponent("SvgButton")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "content",
    style: _normalizeStyle(_ctx.cssContainer)
  }, [
    (this.btnText)
      ? (_openBlock(), _createBlock(_component_FaIconLink, {
          key: 0,
          onClick: _ctx.toggle,
          icon: this.btnIcon,
          text: this.btnText,
          style: _normalizeStyle(this.btnStyle)
        }, null, 8, ["onClick", "icon", "text", "style"]))
      : (_openBlock(), _createBlock(_component_SvgButton, {
          key: 1,
          onClick: _ctx.toggle,
          icon: this.btnIcon,
          style: _normalizeStyle(this.btnStyle)
        }, null, 8, ["onClick", "icon", "style"])),
    (this.visible)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: "dropdown-content",
          style: _normalizeStyle(_ctx.cssContent)
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.items, (item, i) => {
            return (_openBlock(), _createBlock(_component_FaIconLink, {
              key: i,
              onClick: () => _ctx.$emit('onClickLink', i),
              icon: item.icon,
              text: item.text,
              style: _normalizeStyle(_ctx.cssLinkContainer),
              iconContainerStyle: _ctx.cssLinkIconContainer,
              textStyle: _ctx.cssLinkText
            }, null, 8, ["onClick", "icon", "text", "style", "iconContainerStyle", "textStyle"]))
          }), 128))
        ], 4))
      : _createCommentVNode("", true)
  ], 4))
}