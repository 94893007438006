<template>
    <div class="d-flex">
        <iframe :width="playerWidth" :height="playerHeight" :style="cssVarsPlayer" :src="getSrc" class="embedded-video-player">
        </iframe>
    </div>
</template>

<script lang="ts">

import { defineComponent } from 'vue'

import EmbeddedVideoSection from '@/models/qr-sections/EmbeddedVideoSection'

import { youtubeUrlToEmbed } from '@/utils'

export default defineComponent({
  name: 'EmbeddedVideoPlayer',
  props: {
    embeddedVideoSection: {
      type: EmbeddedVideoSection,
      required: true
    },
    playerStyle: {
      type: Object,
      default: null
    },
    playerWidth: {
      type: Number,
      required: true
    },
    playerHeight: {
      type: Number,
      required: true
    }
  },
  computed: {
    cssVarsPlayer () {
      return {
        ...this.playerStyle
      }
    },
    controlsShow (): boolean {
      return this.embeddedVideoSection.style.controls === 'show'
    },
    getSrc () {
      return youtubeUrlToEmbed(this.embeddedVideoSection.url)
    }
  }
})
</script>

<style scoped>

.embedded-video-player {
    border-radius: 8px;
}

</style>
