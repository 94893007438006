<template>
    <EditorPanel :title="textSection.label" :active="textSection.active" :persistent="textSection.persistent" @onSetActive="onSetActive" @onDelete="onDelete">
        <div class="d-flex flex-column">
          <DebouncedTextInput ref="textInput" :value="content" :multiline=true type="text" placeholder="title" @update:value="onEditContent"/>
        </div>
      </EditorPanel>
</template>

<script lang="ts">

import { defineComponent } from 'vue'

import DebouncedTextInput from '@/components/input/DebouncedTextInput.vue'
import EditorPanel from '@/components/editor/EditorPanel.vue'

import TextSection from '@/models/qr-sections/TextSection'

export default defineComponent({
  name: 'TextPanel',
  components: {
    DebouncedTextInput,
    EditorPanel
  },
  props: {
    textSection: {
      type: TextSection,
      required: true
    }
  },
  data () {
    return {
      editingPreTitle: false,
      editingSubTitle: false,
      content: this.textSection.content
    }
  },
  methods: {
    focusInput () {
      this.$refs.textInput.focus()
    },
    onEditContent (value: string) {
      this.$emit('onEdit', this.textSection.id, this.textSection.label, this.textSection.listOrder, this.textSection.active, value)
    },
    onSetActive (value: boolean) {
      this.$emit('onEdit', this.textSection.id, this.textSection.label, this.textSection.listOrder, value, this.content)
    },
    onDelete () {
      this.$emit('onDelete', this.textSection.id)
    }
  }
})
</script>

<style scoped>

div.pre-title-container {
  background-color: lightgray;
  border-radius: 1em;
  width: 100%;
  height: 4em;
}

p.pre-title-label {
  width: 25%;
  padding: 0em 0em 0em 2em;
  margin: 0;
  text-align: left;
}

div.pre-title-input-container {
  width: 65%;
  margin: 0em 1em 0em 1em;
}

div.pre-title-delete-button-container {
  width: 10%;
}

</style>
