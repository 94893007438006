import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-33994cb2"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "sidebar-link-container",
    style: _normalizeStyle(_ctx.cssVarsContainer),
    href: "/analytics"
  }, [
    _createElementVNode("img", {
      class: "sidebar-link-icon",
      src: _ctx.srcImg
    }, null, 8, _hoisted_1),
    _createElementVNode("span", {
      class: "sidebar-link-text",
      style: _normalizeStyle(_ctx.cssVarsText)
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 4)
  ], 4))
}