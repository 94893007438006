import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-68593dd7"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "overlay-container d-flex flex-row justify-content-center"
}
const _hoisted_2 = { class: "overlay-content" }
const _hoisted_3 = { class: "overlay-header d-flex flex-row align-items-center justify-content-between p-2" }
const _hoisted_4 = { class: "m-0 title-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FaIconButton = _resolveComponent("FaIconButton")!

  return (_ctx.visible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("p", _hoisted_4, _toDisplayString(this.title), 1),
            _createVNode(_component_FaIconButton, {
              icon: "xmark",
              size: "md",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onDismiss'))),
              style: _normalizeStyle(_ctx.cssCloseButton)
            }, null, 8, ["style"])
          ]),
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ])
      ]))
    : _createCommentVNode("", true)
}