import { createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-46f7e884"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex flex-row align-items-center" }
const _hoisted_2 = { class: "handle" }
const _hoisted_3 = { href: "#" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "label-text" }
const _hoisted_6 = { class: "controls-container d-flex flex-row align-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FaIconButton = _resolveComponent("FaIconButton")!
  const _component_SwitchComponent = _resolveComponent("SwitchComponent")!
  const _component_EditorToggle = _resolveComponent("EditorToggle")!
  const _directive_handle = _resolveDirective("handle")!

  return (_openBlock(), _createElementBlock("div", {
    class: "editor-panel-container",
    style: _normalizeStyle(_ctx.cssVarsContainer)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_2, [
        _createElementVNode("a", _hoisted_3, [
          _createElementVNode("img", {
            class: "grip-icon",
            src: require('@/assets/icons/icon-grip-1.png')
          }, null, 8, _hoisted_4)
        ])
      ])), [
        [_directive_handle]
      ]),
      _createElementVNode("h4", _hoisted_5, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", _hoisted_6, [
        (!this.persistent)
          ? (_openBlock(), _createBlock(_component_FaIconButton, {
              key: 0,
              icon: "trash",
              onClick: _cache[0] || (_cache[0] = () => _ctx.$emit('onDelete')),
              style: _normalizeStyle(_ctx.cssDeleteSectionButton)
            }, null, 8, ["style"]))
          : _createCommentVNode("", true),
        _createVNode(_component_SwitchComponent, {
          initialValue: _ctx.isActive,
          "v-model": _ctx.isActive,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = (value) => _ctx.$emit('onSetActive', value))
        }, null, 8, ["initialValue", "v-model"]),
        _createVNode(_component_EditorToggle, {
          styleMode: this.styleMode,
          style: {"margin-left":"10px"},
          onClick: _cache[2] || (_cache[2] = () => _ctx.$emit('onToggleMode'))
        }, null, 8, ["styleMode"])
      ])
    ]),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 4))
}