<template>
  <div class="sub-profile-view">
    <div v-if="subProfile.id.length > 0" class="sub-profile-content">
      <SubProfileContainerModular
        :subProfile="subProfile"
        :contentActive="contentActive"
        @onDetailClick="(id: string, qrSectionId: number, contentActive: boolean) =>onDetailClick(id, qrSectionId, contentActive)"
        @onAmazonReviewCTASectionClick="onAmazonReviewCTASectionClick"
        @onButtonSectionClick="onButtonSectionClick"
        @onEmbeddedVideoSectionClick="onEmbeddedVideoSectionClick"
        @onLinkSectionClick="onLinkSectionClick"
        @onSocialsSectionItemClick="onSocialsSectionItemClick"
        />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import Cookies from 'js-cookie'

import SubProfileContainerModular from '@/components/profile/SubProfileContainerModular.vue'

import Profile from '@/models/Profile'

import { PROFILE_COLORS } from '@/constants'
import { Detail } from '@/models/Detail'

import { fetchSubProfile, pushDetailClick, pushSectionAmazonReviewCTAClick, pushSectionButtonClick, pushSectionEmbeddedVideoClick, pushSectionLinkClick, pushSectionSocialsItemClick } from '@/api'
import SubProfile from '@/models/SubProfile'

const beforeUnloadListener = (e: Event) => {
  e.preventDefault()
  return ''
}

export default defineComponent({
  name: 'SubProfileView',
  components: {
    SubProfileContainerModular
  },
  data () {
    return {
      subProfile: new SubProfile(),
      qrScanId: 1,
      contentActive: false
    }
  },

  beforeRouteLeave (to, from, next) {
    if (this.contentActive) {
      this.contentActive = false
      next(false)
    } else {
      next(false)
    }
  },
  methods: {
    setContentActive (active: boolean) {
      this.contentActive = active
      if (active) {
        addEventListener('beforeunload', beforeUnloadListener, { capture: true })
      } else {
        removeEventListener('beforeunload', beforeUnloadListener, { capture: true })
      }
    },
    onDetailClick (id: string, qrSectionId: number, contentActive: boolean) {
      this.setContentActive(contentActive)
      pushDetailClick(
        id,
        this.subProfile.id,
        qrSectionId,
        this.qrScanId,
        () => this.$router.push('/login'),
        () => this.$router.push('/not-found'),
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        () => null
      )
    },
    onAmazonReviewCTASectionClick (sectionId: number) {
      pushSectionAmazonReviewCTAClick(
        sectionId,
        this.qrScanId,
        this.accessType,
        () => this.$router.push('/login'),
        () => this.$router.push('/not-found'),
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        () => null
      )
    },
    onButtonSectionClick (sectionId: number) {
      pushSectionButtonClick(
        sectionId,
        this.qrScanId,
        this.accessType,
        () => this.$router.push('/login'),
        () => this.$router.push('/not-found'),
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        () => null
      )
    },
    onEmbeddedVideoSectionClick (sectionId: number) {
      pushSectionEmbeddedVideoClick(
        sectionId,
        this.qrScanId,
        this.accessType,
        () => this.$router.push('/login'),
        () => this.$router.push('/not-found'),
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        () => null
      )
    },
    onLinkSectionClick (sectionId: number) {
      pushSectionLinkClick(
        sectionId,
        this.qrScanId,
        this.accessType,
        () => this.$router.push('/login'),
        () => this.$router.push('/not-found'),
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        () => null
      )
    },
    onSocialsSectionItemClick (sectionId: number, socialId: number) {
      pushSectionSocialsItemClick(
        sectionId,
        this.qrScanId,
        socialId,
        this.accessType,
        () => this.$router.push('/login'),
        () => this.$router.push('/not-found'),
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        () => null
      )
    }
  },
  mounted () {
    if (this.$store.state.subProfile.id.length > 0) {
      this.subProfile = this.$store.state.subProfile
      this.qrScanId = this.$store.state.qrScanId
      this.accessType = this.$store.state.accessType
    } else {
      fetchSubProfile(
        String(this.$route.params.name),
        String(this.$route.params.subProfileSuffix),
        () => this.$router.push('/login'),
        () => this.$router.push('/not-found'),
        () => this.$router.back(),
        () => this.$router.push('/server-error'),
        (response) => {
          this.subProfile = new SubProfile(response.data.data.subProfile)
          this.qrScanId = response.data.data.qrScanId
          this.accessType = response.data.data.accessType
          this.$router.push({ params: { name: this.subProfile.name, subProfileSuffix: this.subProfile.id.slice(-2) } })

          Cookies.set('qr', this.subProfile.id)
          if (this.subProfile.qrRedirect.active) window.location.href = this.subProfile.qrRedirect.url
        })
    }
  }
})
</script>

<style scoped lang="scss">
  div.sub-profile-view{
    width: 100%;
    min-height: 500px;
  }
  div.sub-profile-content{
    width: 25%;
    margin: 0 auto;
    padding-top: 5px;
    padding-bottom: 15px;
    min-height: 500px;
  }
  html, body {
    padding:0;
    margin:0;
    border: none
  }

  @media only screen and (min-width: 1200px){
    div.sub-profile-content{
      width: 40%;
    }
  }
  @media only screen and (min-width: 1001px){
    div.sub-profile-content{
      width: 45%;
    }
  }
  @media only screen and (max-width: 1000px){
    div.sub-profile-content{
      width: 50%;
    }
  }
  @media only screen and (max-width: 850px){
    div.sub-profile-content{
      width: 65%;
    }
  }
  @media only screen and (max-width: 750px){
    div.sub-profile-content{
      width: 75%;
    }
  }
  @media only screen and (max-width: 650px){
    div.sub-profile-content{
      width: 100%;
    }
  }
</style>
