import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EmbeddedVideoPlayer = _resolveComponent("EmbeddedVideoPlayer")!

  return (_ctx.embeddedVideoSection)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "embedded-video-container",
        onClick: _cache[0] || (_cache[0] = () => this.$emit('onPushClick'))
      }, [
        _createVNode(_component_EmbeddedVideoPlayer, {
          embeddedVideoSection: _ctx.embeddedVideoSection,
          playerWidth: 310,
          playerHeight: 174
        }, null, 8, ["embeddedVideoSection"])
      ]))
    : _createCommentVNode("", true)
}