import { authorisedPost } from './Api'

export function editTextSection (
  subProfileId: string,
  id: number,
  label: string,
  listOrder: number,
  active: boolean,
  content: string,
  token : string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  const data = {
    id,
    label,
    listOrder,
    active,
    labelVisible: false,
    content
  }
  authorisedPost(`dashboard/${subProfileId}/edit-text`, data, token, unauthorisedCallback, badRequestCallback, serverErrorCallback, notFoundCallback, callback)
}
