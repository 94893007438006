<template>
    <div class="editor-num-select-input-container">
        <p class="editor-num-select-input-label">{{ this.label }}</p>
        <div class="input-container">
            <select
              :style="cssInputWrapper"
              :value="value"
              @change="onChange($event)"
              :v-model="internalValue"
              >
              <option v-for="n in numbers" :key="n" :value="n">{{ n }}</option>
            </select>
        </div>
    </div>
  </template>

<script lang="ts">

import { defineComponent } from 'vue'

import DebouncedTextInput from './DebouncedTextInput.vue'

export default defineComponent({
  name: 'EditorNumSelectComponent',
  props: {
    label: {
      type: String,
      default: ''
    },
    min: {
      type: Number,
      default: 8
    },
    max: {
      type: Number,
      default: 24
    },
    value: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      internalValue: this.value,
      valid: true
    }
  },
  computed: {
    numbers () {
      const numbersArray = [] as Array<number>
      for (let i = this.min; i <= this.max; i++) {
        numbersArray.push(i)
      }
      return numbersArray
    },
    cssInputContainer () {
      return {
        height: '100%'
      }
    },
    cssInputWrapper () {
      return {
        width: '60px',
        border: 'none',
        background: 'transparent',
        height: '100%',
        padding: '0',
        display: 'flex',
        alignItems: 'center'
      }
    },
    cssInput () {
      return {
        color: 'var(--Nearly-Black, #212529)',
        textAlign: 'right',
        fontFamily: 'Inter',
        fontSize: '13px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '20px' /* 153.846% */
      }
    }
  },
  watch: {
    value (value) {
      this.internalValue = value
    }
  },
  methods: {
    onChange ($event) {
      const number = parseInt($event.target.value)
      this.valid = number >= this.min && number <= this.max
      if (this.valid) {
        this.$emit('onChange', number)
      }
    }
  }
})

</script>

<style scoped>

  div.editor-num-select-input-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: var(--Light-Grey, #F1F1F1);
    padding: 10px;
    width: 214px;
    height: 40px;
    flex-shrink: 0;
  }

  div.input-container {
    display: flex;
    width: 80%;
    justify-content: flex-end;
  }

  p.editor-num-select-input-label {
    margin: 0;
    color: var(--Nearly-Black, #212529);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px; /* 100% */
    opacity: 0.5;
  }

  </style>
