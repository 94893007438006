<template>
    <div class="d-flex justify-content-center align-items-center button-content" :style="cssVars">
        <div class="d-flex flex-column justify-content-center icon-container">
          <img :src="iconSrc" alt="icon" class="icon">
        </div>
        <p :class="cssTextClass" :style="cssVarsText"><slot/></p>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import { Palette } from '@/theme'

export default defineComponent({
  name: 'StyleTextIconButton',
  props: {
    icon: {
      type: String,
      required: true
    },
    style: {
      type: Object,
      default: null
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    cssVars () {
      return {
        ...this.style,
        background: this.selected
          ? 'linear-gradient(135deg, rgba(46, 117, 255, 0.10) 0%, rgba(122, 61, 255, 0.10) 99.99%, rgba(123, 61, 255, 0.10) 100%)'
          : 'var(--Light-Grey, #F1F1F1)'
      }
    },
    cssVarsText () {
      if (this.selected) {
        return {
          backgroundImage: Palette.gradientBP,
          backgroundClip: 'text',
          backgroundSize: '100%',
          textFillColor: 'transparent'
        }
      } else {
        return {
          color: 'var(--Nearly-Black, #212529);'
        }
      }
    },
    cssTextClass () {
      const baseClass = 'button-text'
      return this.selected ? baseClass + ' text-gradient' : baseClass
    },
    iconSrc () {
      if (this.selected) return require('@/assets/icons/svg/' + this.icon + '-grad.svg')
      else return require('@/assets/icons/svg/' + this.icon + '.svg')
    }
  }
})
</script>

<style scoped lang="scss">
  div.button-content {
      cursor: pointer;
      width: 132px;
      height: 40px;
      border-radius: 8px;
      margin: 0 0 0 10px;
  }

  div.icon-container {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }
  img.icon {
    width: 100%;
  }

  p.button-text {
    margin: 0px;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px; /* 100% */
  }

  p.text-gradient {
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }
</style>
