import { authorisedPost } from './Api'

export function editTableSection (
  subProfileId: string,
  id: number,
  label: string,
  listOrder: number,
  active: boolean,
  value: Array<Array<string>>,
  token : string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  const data = {
    id,
    label,
    listOrder,
    active,
    labelVisible: false,
    value
  }
  authorisedPost(`dashboard/${subProfileId}/edit-table`, data, token, unauthorisedCallback, badRequestCallback, serverErrorCallback, notFoundCallback, callback)
}

export function editTableSectionStyle (
  subProfileId: string,
  id: number,
  header: string,
  border: number,
  token : string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  const data = {
    header,
    border
  }
  authorisedPost(`dashboard/${subProfileId}/edit-table-style?section=${id}`, data, token, unauthorisedCallback, badRequestCallback, serverErrorCallback, notFoundCallback, callback)
}
