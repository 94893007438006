<template>
    <EditorPanel :title="headerSection.label" :active="headerSection.active" :persistent="headerSection.persistent" @onSetActive="onSetActive" @onDelete="onDelete">
        <div class="d-flex flex-column">
          <DebouncedTextInput :value="title" type="text" placeholder="title" @update:value="onEditTitle"/>
          <div v-if="editingPreTitle || preTitle.length > 0" class="d-flex flex-direction-row pre-title-container align-items-center my-1">
            <p class="pre-title-label">Pre-title</p>
            <div class="pre-title-input-container">
              <DebouncedTextInput :value="preTitle" type="text" placeholder="title" @update:value="onEditPreTitle"/>
            </div>
            <div class="pre-title-delete-button-container">
              <FaIconButton icon="xmark" @click="onResetPreTitle" :style="cssDeleteButton"/>
            </div>
          </div>
          <div v-if="editingSubTitle || subTitle.length > 0" class="d-flex flex-direction-row pre-title-container align-items-center my-1">
            <p class="pre-title-label">Sub-title</p>
            <div class="pre-title-input-container">
              <DebouncedTextInput :value="subTitle" type="text" placeholder="title" @update:value="onEditSubTitle"/>
            </div>
            <div class="pre-title-delete-button-container">
              <FaIconButton icon="xmark" @click="onResetSubTitle" :style="cssDeleteButton"/>
            </div>
          </div>
          <div class="add-button-container my-1 align-self-start" v-if="!(editingPreTitle || preTitle.length > 0)">
            <FaIconLink
              icon="plus"
              size="md"
              text="Add Pre-title"
              :icon-container-style="cssAddIcon"
              :text-style="cssAddText"
              :text-uses-gradient="true"
              @click="() => this.editingPreTitle = true"/>
          </div>
          <div class="add-button-container my-1 align-self-start" v-if="!(editingSubTitle || subTitle.length > 0)">
            <FaIconLink
              icon="plus"
              size="md"
              text="Add Sub-title"
              :icon-container-style="cssAddIcon"
              :text-style="cssAddText"
              :text-uses-gradient="true"
              @click="() => this.editingSubTitle = true"
              />
          </div>
        </div>
      </EditorPanel>
</template>

<script lang="ts">

import { defineComponent } from 'vue'

import DebouncedTextInput from '@/components/input/DebouncedTextInput.vue'
import EditorPanel from '@/components/editor/EditorPanel.vue'
import FaIconButton from '../button/FaIconButton.vue'

import HeaderSection from '@/models/qr-sections/HeaderSection'
import FaIconLink from '../button/FaIconLink.vue'
import { Palette } from '@/theme'

export default defineComponent({
  name: 'HeaderPanel',
  components: {
    DebouncedTextInput,
    EditorPanel,
    FaIconButton,
    FaIconLink
  },
  props: {
    headerSection: {
      type: HeaderSection,
      required: true
    }
  },
  data () {
    return {
      editingPreTitle: false,
      editingSubTitle: false,
      preTitle: this.headerSection.preTitle,
      subTitle: this.headerSection.subTitle,
      title: this.headerSection.title
    }
  },
  computed: {
    cssVarsContainer () {
      return {
        ...this.containerStyle
      }
    },
    cssAddIcon () {
      return {
        backgroundImage: Palette.gradientBP,
        width: '1.95em',
        borderRadius: '0.975em',
        padding: '0.5em',
        color: Palette.white
      }
    },
    cssAddText () {
      return {
        backgroundImage: Palette.gradientBP,
        backgroundClip: 'text',
        backgroundSize: '100%',
        textFillColor: 'transparent'
      }
    },
    cssDeleteButton () {
      return {
        color: Palette.neutral800,
        backgroundColor: 'transparent'
      }
    }
  },
  methods: {
    setContentActive (active: boolean) {
      this.contentActive = active
    },
    onEditTitle (value: string) {
      this.$emit('onEdit', this.headerSection.id, this.headerSection.label, this.headerSection.listOrder, this.headerSection.active, value, this.preTitle, this.subTitle)
    },
    onEditPreTitle (value: string) {
      this.$emit('onEdit', this.headerSection.id, this.headerSection.label, this.headerSection.listOrder, this.headerSection.active, this.title, value, this.subTitle)
    },
    onEditSubTitle (value: string) {
      this.$emit('onEdit', this.headerSection.id, this.headerSection.label, this.headerSection.listOrder, this.headerSection.active, this.title, this.preTitle, value)
    },
    onSetActive (value: boolean) {
      this.$emit('onEdit', this.headerSection.id, this.headerSection.label, this.headerSection.listOrder, value, this.title, this.preTitle, this.subTitle)
    },
    onResetPreTitle () {
      this.editingPreTitle = false
      this.$emit('onEdit', this.headerSection.id, this.headerSection.label, this.headerSection.listOrder, this.headerSection.active, this.title, '', this.subTitle)
    },
    onResetSubTitle () {
      this.editingSubTitle = false
      this.$emit('onEdit', this.headerSection.id, this.headerSection.label, this.headerSection.listOrder, this.headerSection.active, this.title, this.preTitle, '')
    },
    onDelete () {
      this.$emit('onDelete', this.headerSection.id)
    }
  }
})
</script>

<style scoped>

div.pre-title-container {
  background-color: lightgray;
  border-radius: 1em;
  width: 100%;
  height: 4em;
}

p.pre-title-label {
  width: 25%;
  padding: 0em 0em 0em 2em;
  margin: 0;
  text-align: left;
}

div.pre-title-input-container {
  width: 65%;
  margin: 0em 1em 0em 1em;
}

div.pre-title-delete-button-container {
  width: 10%;
}

</style>
