<template>
  <div v-if="loaded" class="content-page">
    <div class="d-flex flex-row top-bar-scroll">
      <div class="d-flex justify-content-center flex-column" style="width: 60px;">
        <SvgButton  @click="$router.replace('/dashboard')" icon="chevron-left" :style="cssBackButton"/>
      </div>
      <div class="d-flex flex-row align-items-center" style="flex-grow: 1">
          <div class="d-flex align-items-center">
            <h4 class="sub-profile-label-text">{{ subProfile.label }}</h4>
          </div>
          <div class="d-flex flex-column qr-switch-download-container">
            <div class="d-flex flex-row align-items-center">
              <div class="sub-profile-url-container">
                <TextClipboard :text="subProfile.getUrlShort(this.$store.state.profile)"/>
              </div>
              <div class="sub-profile-qr-view-download-container">
                <DropdownQrViewDownload :qrUri="subProfile.qrUri" @onDownload="onDownload"/>
              </div>
              <div class="sub-profile-status-toggle-container">
                <DropToggle
                  v-if="!subProfile.isRootProfile"
                  on-text="Online"
                  off-text="Offline"
                  :initial-value="subProfile.active"
                  @update:model-value="onToggleStatus"/>
              </div>
            </div>
            <div v-if="!subProfile.isRootProfile" class="d-flex flex-row align-items-end mt-3" style="background-color: lightgray; padding: 8px; border-radius: 8px;">
              <div class="d-flex flex-column align-items-start" style="flex: 1; margin: 0 8px 0 0">
                <p style="margin: 0 0 0 2px">Redirect to:</p>
                <DebouncedTextInput :value="qrRedirectUrl" type="text" placeholder="title" @update:value="onEditQrRedirectUrl"/>
              </div>
              <div class="sub-profile-status-toggle-container">
                <DropToggle
                  on-text="On"
                  off-text="Off"
                  :initial-value="subProfile.qrRedirect.active"
                  @update:model-value="onToggleQrRedirectStatus"/>
              </div>
            </div>
          </div>
        </div>
    </div>
    <div style="margin: 0 60px 0 60px; width: calc(100vw - 340px)">
      <div>
        <SubNav :tabs="tabs" :current-tab="currentTab" @selectTab="selectTab"/>
      </div>
      <div class="d-flex mt-3 justify-content-end" style="width: 100%;">
        <div class="d-flex flex-column pb-5 panel-list-container" style="flex-grow: 1; width: 40vw">
          <SlickList axis="y" v-model:list="sections" @update:list="onChangeSectionOrder" :useDragHandle="true">
            <SlickItem v-for="(section, i) in sections" :key="section" :index="i">
              <div>
                <ImagePanel
                  v-if="section.type === 0"
                  :imageSection="(section.section as ImageSectionModel)"
                  @onSubmit="(file: File) => onSubmitImageSection(section.section.id, file)"
                  @onReset="() => onResetSectionImage(section.section.id)"
                  @onEdit="onEditImageSection"
                  @onDelete="onDeleteSection"
                />
                <HeaderPanel
                  v-else-if="section.type === 1"
                  :headerSection="(section.section as HeaderSectionModel)"
                  @onEdit="onEditHeaderSection"
                  @onDelete="onDeleteSection"
                  />
                <TextPanel
                  :ref="'textPanel' + section.section.id.toString()"
                  v-else-if="section.type === 2"
                  :textSection="(section.section as TextSectionModel)"
                  @onEdit="onEditTextSection"
                  @onDelete="onDeleteSection"
                  />
                <DetailPanel
                  v-else-if="section.type === 3"
                  :detailSection="(section.section as DetailSectionModel)"
                  @onEditDetail="onEditDetail"
                  @onDeleteDetail="onDeleteDetail"
                  @onChangeDetailOrder="onChangeDetailOrder"
                  @onEdit="onEditUserDetailsSection"
                  @onDelete="onDeleteSection"
                  @onAddLink="() => {
                    this.currentQrSectionId = section.section.id
                    this.addLinksPopup = true
                    } "
                  />
                <TablePanel
                  :ref="'panel' + section.section.id.toString()"
                  v-else-if="section.type === 5"
                  :tableSection="(section.section as TableSectionModel)"
                  @onEdit="onEditTableSection"
                  @onEditStyle="onEditTableSectionStyle"
                  @onDelete="onDeleteSection"
                  @onToggleMode="onToggleMode"
                  />
                <FeaturesPanel
                :ref="'panel' + section.section.id.toString()"
                  v-else-if="section.type === 6"
                  :featuresSection="(section.section as FeaturesSectionModel)"
                  @onEdit="onEditQrSection"
                  @onEditFeature="onEditFeature"
                  @onEditStyle="onEditFeaturesSectionStyle"
                  @onDeleteFeature="onDeleteFeature"
                  @onAddFeature="onAddFeature"
                  @onChangeFeatureOrder="onChangeFeatureOrder"
                  @onToggleMode="onToggleMode"
                  />
                <AmazonReviewCTAPanel
                  :ref="'panel' + section.section.id.toString()"
                  v-else-if="section.type === 7"
                  :amazonReviewCTASection="(section.section as AmazonReviewCTASectionModel)"
                  @onEdit="onEditAmazonReviewCTASection"
                  @onEditStyle="onEditAmazonReviewCTASectionStyle"
                  @onDelete="onDeleteSection"
                  @onToggleMode="onToggleMode"
                  />
                <LinkPanel
                  :ref="'panel' + section.section.id.toString()"
                  v-else-if="section.type === 8"
                  :linkSection="(section.section as LinkSectionModel)"
                  @onEdit="onEditLinkSection"
                  @onEditStyle="onEditLinkSectionStyle"
                  @onDelete="onDeleteSection"
                  @onToggleMode="onToggleMode"
                  />
                <ButtonPanel
                  :ref="'panel' + section.section.id.toString()"
                  v-else-if="section.type === 9"
                  :buttonSection="(section.section as ButtonSectionModel)"
                  @onEdit="onEditButtonSection"
                  @onEditStyle="onEditButtonSectionStyle"
                  @onDelete="onDeleteSection"
                  @onToggleMode="onToggleMode"
                  />
                <EmbeddedVideoPanel
                  :ref="'panel' + section.section.id.toString()"
                  v-else-if="section.type === 10"
                  :embeddedVideoSection="(section.section as EmbeddedVideoSectionModel)"
                  @onEdit="onEditEmbeddedVideoSection"
                  @onEditStyle="onEditEmbeddedVideoSectionStyle"
                  @onDelete="onDeleteSection"
                  @onToggleMode="onToggleMode"
                  />
                <SocialsPanel
                  :ref="'panel' + section.section.id.toString()"
                  v-else-if="section.type === 4"
                  :socialsSection="(section.section as SocialsSectionModel)"
                  @onEdit="onEditQrSection"
                  @onEditStyle="onEditSocialsSectionStyle"
                  @onDeleteSocial="onDeleteSocialsItem"
                  @onEditSocialsItem="onEditSocialsItem"
                  @onDeleteSocialsItem="onDeleteSocialsItem"
                  @onChangeSocialsItemOrder="onEditSocialsItemOrder"
                  @onToggleMode="onToggleMode"
                  @onAddSocials="() => {
                    this.currentQrSectionId = section.section.id
                    this.addSocialsPopup = true
                    } "
                  />
                <NewsPanel
                  :ref="'panel' + section.section.id.toString()"
                  v-else-if="section.type === 11"
                  :newsSection="(section.section as NewsSectionModel)"
                  @onEdit="onEditQrSection"
                  @onEditStyle="onEditNewsSectionStyle"
                  @onCreateNewsItem="onCreateNewsItem"
                  @onEditNewsItem="onEditNewsItem"
                  @onEditNewsItemImage="(id, image) => onEditNewsItemImage(id, image, section.section.id)"
                  @onDeleteNewsItem="onDeleteNewsItem"
                  @onChangeNewsItemOrder="onChangeNewsItemOrder"
                  @onDelete="onDeleteSection"
                  @onToggleMode="onToggleMode"
                  />
              </div>
            </SlickItem>
          </SlickList>
          <div class="create-section-button-container mt-4">
            <DropdownQrAddSection
              @onClickQrSectionText="() => onCreateSection(2)"
              @onClickQrSectionDetails="() => onCreateSection(3)"
              @onClickQrSectionTable="() => onCreateSection(5)"
              @onClickQrSectionFeatures="() => onCreateSection(6)"
              @onClickQrSectionAmazonReviewCTA="() => onCreateSection(7)"
              @onClickQrSectionLink="() => onCreateSection(8)"
              @onClickQrSectionButton="() => onCreateSection(9)"
              @onClickQrSectionEmbeddedVideo="() => onCreateSection(10)"
              @onClickQrSectionSocials="() => onCreateSection(4)"
              @onClickQrSectionNews="() => onCreateSection(11)"
            />
          </div>
        </div>
        <div class="preview-panel-container">
          <PreviewPanel :subProfile="subProfile" :viewport="this.previewViewport" @onSelectViewport="onSelectViewport"/>
        </div>
      </div>
    </div>
  </div>
  <AddLinksPopup
    :visible="this.addLinksPopup"
    @onAddLinks="(links: string[]) => {
      this.onAddLinks(links, this.currentQrSectionId)
      }"
    @onDismiss="() => {
      this.currentQrSectionId = -1
      this.addLinksPopup = false
    } "/>
  <AddSocialsPopup
    :visible="this.addSocialsPopup"
    @onAddSocials="(socials: string[]) => {
      this.onAddSocialsItemBatch(this.currentQrSectionId, socials)
    }"
    @onDismiss="() => {
      this.currentQrSectionId = -1
      this.addSocialsPopup = false
    }"/>
</template>

<script lang="ts">
import { defineComponent, nextTick } from 'vue'

import AddLinksPopup from '@/components/editor/AddLinksPopup.vue'
import AddSocialsPopup from '@/components/editor/AddSocialsPopup.vue'
import DebouncedTextInput from '@/components/input/DebouncedTextInput.vue'
import DetailPanel from '@/components/editor/DetailPanel.vue'
import DropdownQrAddSection from '@/components/dropdown/DropdownQrAddSection.vue'
import DropdownQrViewDownload from '@/components/dropdown/DropdownQrViewDownload.vue'
import DropToggle from '@/components/switch/DropToggle.vue'
import AmazonReviewCTAPanel from '@/components/editor/AmazonReviewCTAPanel.vue'
import ButtonPanel from '@/components/editor/ButtonPanel.vue'
import EmbeddedVideoPanel from '@/components/editor/EmbeddedVideoPanel.vue'
import FeaturesPanel from '@/components/editor/FeaturesPanel.vue'
import ImagePanel from '@/components/editor/ImagePanel.vue'
import HeaderPanel from '@/components/editor/HeaderPanel.vue'
import LinkPanel from '@/components/editor/LinkPanel.vue'
import NewsPanel from '@/components/editor/NewsPanel.vue'
import SocialsPanel from '@/components/editor/SocialsPanel.vue'
import TextClipboard from '@/components/text/TextClipboard.vue'
import TablePanel from '@/components/editor/TablePanel.vue'
import TextPanel from '@/components/editor/TextPanel.vue'
import ManageDetailList from '@/components/list/ManageDetailList.vue'

import PreviewPanel from '@/components/editor/PreviewPanel.vue'
import SubNav from '@/components/nav/SubNav.vue'
import SvgButton from '@/components/button/SvgButton.vue'

import AmazonReviewCTASectionModel from '@/models/qr-sections/AmazonReviewCTASection'
import ButtonSectionModel from '@/models/qr-sections/ButtonSection'
import EmbeddedVideoSectionModel from '@/models/qr-sections/EmbeddedVideoSection'
import DetailSectionModel from '@/models/qr-sections/UserDetailSection'
import FeaturesSectionModel from '@/models/qr-sections/FeaturesSection'
import HeaderSectionModel from '@/models/qr-sections/HeaderSection'
import ImageSectionModel from '@/models/qr-sections/ImageSection'
import LinkSectionModel from '@/models/qr-sections/LinkSection'
import NewsSectionModel from '@/models/qr-sections/NewsSection'
import SocialsSectionModel from '@/models/qr-sections/SocialsSection'
import TableSectionModel from '@/models/qr-sections/TableSection'
import TextSectionModel from '@/models/qr-sections/TextSection'

import Profile from '@/models/Profile'
import { UserSubProfile } from '@/models/UserSubProfile'
import { SubProfileTheme } from '@/models/theme/SubProfileTheme'
import { Detail } from '@/models/Detail'
import SubProfileHeaderListItem from '@/models/SubProfileHeaderListItem'

import { DEFAULT_DETAIL, DETAIL_TYPES } from '@/constants'

import { SlickList, SlickItem, HandleDirective } from 'vue-slicksort'

import {
  fetchSubProfileDashboard,
  postThemePreset,
  postDetail,
  putDetail,
  deleteDetail,
  postDetailOrder,
  deleteSubProfileHeaderListItem,
  postHeaderListItem,
  postHeaderListItemOrder,
  postThemeColour,
  toggleActiveSubProfile,
  getQrDownload,
  editHeaderSection,
  editImageSection,
  resetImageSection,
  postSectionOrder,
  editImageSectionImage,
  editTextSection,
  editTableSection,
  deleteSection,
  createSection,
  editUserDetailsSection,
  postBatchDetail,
  editQrRedirectUrl,
  editQrRedirectStatus,
  editTableSectionStyle,
  postFeatureItem,
  editFeatureItem,
  deleteFeatureItem,
  postFeatureItemOrder,
  editFeaturesSectionStyle,
  editQrSection,
  editAmazonReviewCTASection,
  editAmazonReviewCTASectionStyle,
  editLinkSection,
  editLinkSectionStyle,
  editButtonSection,
  editButtonSectionStyle,
  editEmbeddedVideoSection,
  editEmbeddedVideoSectionStyle,
  editSocialsSectionStyle,
  postSocialsItemBatch,
  editSocialsItem,
  deleteSocialsItem,
  postSocialsItemOrder,
  editEmbeddedVideoSectionThumbnail,
  editNewsSectionStyle,
  postNewsItem,
  editNewsItem,
  deleteNewsItem,
  postNewsItemOrder,
  editNewsItemImage
} from '@/api'

import Palette from '@/theme/palette'
import { FeaturesItem } from '@/models/qr-sections/FeaturesItem'
import { SocialsItem } from '@/models/qr-sections/SocialsItem'

export default defineComponent({
  name: 'ManageQrView',
  components: {
    AddLinksPopup,
    AddSocialsPopup,
    AmazonReviewCTAPanel,
    ButtonPanel,
    DebouncedTextInput,
    DetailPanel,
    DropdownQrAddSection,
    DropToggle,
    EmbeddedVideoPanel,
    FeaturesPanel,
    ImagePanel,
    HeaderPanel,
    LinkPanel,
    NewsPanel,
    PreviewPanel,
    DropdownQrViewDownload,
    SlickItem,
    SlickList,
    SubNav,
    SvgButton,
    SocialsPanel,
    TextClipboard,
    TextPanel,
    TablePanel
  },
  data () {
    return {
      loaded: false,
      tabs: ['Editor', 'Appearance', 'Templates'],
      currentTab: 0,
      contentActive: false,
      selectedTab: 0,
      subProfile: new UserSubProfile(),
      qrRedirectUrl: '',
      sections: [] as Array<{type: number, section: AmazonReviewCTASectionModel | ButtonSectionModel | EmbeddedVideoSectionModel | FeaturesSectionModel | ImageSectionModel | HeaderSectionModel | LinkSectionModel | SocialsSectionModel | TableSectionModel | TextSectionModel}>,
      drag: false,
      addLinksPopup: false,
      addSocialsPopup: false,
      currentQrSectionId: -1,
      styleModeActive: [],
      previewViewport: 'mobile',
      analyticsCharts: {
        scansMonth: {
          labels: [''],
          datasets: [
            {
              label: 'Daily Scans',
              backgroundColor: '#084de0',
              borderColor: '#084de0',
              borderWidth: 0.5,
              data: [0],
              pointRadius: 0
            }
          ]
        },
        scansYear: {
          labels: [''],
          datasets: [
            {
              label: 'Daily Scans',
              backgroundColor: '#084de0',
              borderColor: '#084de0',
              borderWidth: 0.5,
              data: [0],
              pointRadius: 0
            }
          ]
        }
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    if (this.contentActive) {
      this.contentActive = false
      next(false)
    } else {
      next()
    }
  },
  computed: {
    dropdownCssVars () {
      return {
        '--background-color': Palette.light_grey,
        '--color': Palette.white
      }
    },
    cssBackButton () {
      return {
        backgroundColor: 'transparent',
        color: Palette.neutral800
      }
    }
  },
  methods: {
    selectTab (tab: number) {
      this.currentTab = tab
    },
    toggleDownloadDropdown () {
      this.downloadDropdownVisible = !this.downloadDropdownVisible
    },
    handleClickOutside (event) {
      if (
        this.$refs.downloadContent &&
        !this.$refs.downloadContent.contains(event.target)
      ) {
        this.downloadDropdownVisible = false
      }
    },
    configureFromResponse (response: any) {
      this.loaded = false
      this.profile = new Profile(response.data.data.userProfile)
      this.subProfile = new UserSubProfile(response.data.data.subProfile)
      this.themePresets = response.data.data.themePresets.map((t: SubProfileTheme) => new SubProfileTheme(t))

      this.qrRedirectUrl = this.subProfile.qrRedirect.url
      this.sections = this.subProfile.sections

      this.loaded = true
      console.log('SMA' + JSON.stringify(this.styleModeActive))
      nextTick(() => {
        this.styleModeActive.forEach((x) => this.$refs['panel' + x.toString()][0].onToggleMode(false))
      })
    },
    onSelectDetail (id: string) {
      if (this.currentDetail === id) this.currentDetail = ''
      else this.currentDetail = id
    },
    setContentActive (active: boolean) {
      this.contentActive = active
    },
    onToggleStatus () {
      toggleActiveSubProfile(
        this.subProfile.id,
        this.$store.state.token,
        () => this.$router.push('/login'),
        () => null,
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response) => {
          this.configureFromResponse(response)
        })
    },
    onDownload (type: string) {
      getQrDownload(
        type,
        this.subProfile.id,
        this.$store.state.token,
        () => this.$router.push('/login'),
        () => null,
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response) => {
          const href = URL.createObjectURL(response.data)
          const link = document.createElement('a')
          link.href = href
          link.setAttribute('download', this.subProfile.id + '.' + type)
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          URL.revokeObjectURL(href)
        })
    },
    onEditQrRedirectUrl (url: string) {
      editQrRedirectUrl(
        this.subProfile.id,
        url,
        this.$store.state.token,
        () => this.$router.push('/login'),
        () => null,
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response) => {
          this.subProfile.qrRedirect.url = response.data.data.value
          this.qrRedirectUrl = response.data.data.value
        })
    },
    onToggleQrRedirectStatus (value: boolean) {
      editQrRedirectStatus(
        this.subProfile.id,
        value,
        this.$store.state.token,
        () => this.$router.push('/login'),
        () => null,
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response) => {
          this.subProfile.qrRedirect.active = response.data.data.value
        })
    },
    onSelectViewport (value: string) {
      this.previewViewport = value
    },
    onToggleMode (id: number, mode: boolean) {
      if (mode) this.styleModeActive.push(id)
      else {
        this.styleModeActive.forEach((item, index) => {
          if (item === id) this.styleModeActive.splice(index, 1)
        })
      }
    },
    onCreateSection (type: number) {
      createSection(
        this.subProfile.id,
        type,
        this.$store.state.token,
        () => this.$router.push('/login'),
        () => null,
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response) => {
          this.configureFromResponse(response)
        })
    },
    onEditQrSection (id: number, label: string, listOrder: number, active: boolean, labelVisible: boolean) {
      editQrSection(
        this.subProfile.id,
        id,
        label,
        listOrder,
        active,
        labelVisible,
        this.$store.state.token,
        () => this.$router.push('/login'),
        () => null,
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response) => {
          this.configureFromResponse(response)
        })
    },
    onEditAmazonReviewCTASection (
      id: number,
      label: string,
      listOrder: number,
      active: boolean,
      title: string,
      subtitle: string,
      btnText: string,
      amazonUrl: string
    ) {
      editAmazonReviewCTASection(
        this.subProfile.id,
        id,
        label,
        listOrder,
        active,
        title,
        subtitle,
        btnText,
        amazonUrl,
        this.$store.state.token,
        () => this.$router.push('/login'),
        () => null,
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response) => {
          this.configureFromResponse(response)
        })
    },
    onEditAmazonReviewCTASectionStyle (
      id: number,
      theme: string
    ) {
      editAmazonReviewCTASectionStyle(
        this.subProfile.id,
        id,
        theme,
        this.$store.state.token,
        () => this.$router.push('/login'),
        () => null,
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response) => {
          this.configureFromResponse(response)
        })
    },
    onEditButtonSection (
      id: number,
      label: string,
      listOrder: number,
      active: boolean,
      text: string,
      url: string
    ) {
      editButtonSection(
        this.subProfile.id,
        id,
        label,
        listOrder,
        active,
        text,
        url,
        this.$store.state.token,
        () => this.$router.push('/login'),
        () => null,
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response) => {
          this.configureFromResponse(response)
        })
    },
    onEditButtonSectionStyle (
      id: number,
      textColour: string,
      fillColour: string,
      style: string,
      corners: string,
      size: string,
      align: string
    ) {
      editButtonSectionStyle(
        this.subProfile.id,
        id,
        textColour,
        fillColour,
        style,
        corners,
        size,
        align,
        this.$store.state.token,
        () => this.$router.push('/login'),
        () => null,
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response) => {
          this.configureFromResponse(response)
        })
    },
    onEditEmbeddedVideoSection (
      id: number,
      label: string,
      listOrder: number,
      active: boolean,
      url: string
    ) {
      editEmbeddedVideoSection(
        this.subProfile.id,
        id,
        label,
        listOrder,
        active,
        url,
        this.$store.state.token,
        () => this.$router.push('/login'),
        () => null,
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response) => {
          this.configureFromResponse(response)
        })
    },
    onEditEmbeddedVideoSectionStyle (
      id: number,
      controls: string,
      thumbnail: string,
      thumbnailUri: string
    ) {
      editEmbeddedVideoSectionStyle(
        this.subProfile.id,
        id,
        controls,
        thumbnail,
        thumbnailUri,
        this.$store.state.token,
        () => this.$router.push('/login'),
        () => null,
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response) => {
          this.configureFromResponse(response)
        })
    },
    onEditEmbeddedVideoSectionThumbnail (id: number, image: File) {
      editEmbeddedVideoSectionThumbnail(
        image,
        id,
        this.subProfile.id,
        this.$store.state.token,
        () => this.$router.push('/login'),
        () => null,
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response) => {
          this.configureFromResponse(response)
        })
    },
    onEditImageSection (
      id: number,
      label: string,
      listOrder: number,
      active: boolean
    ) {
      editImageSection(
        this.subProfile.id,
        id,
        label,
        listOrder,
        active,
        this.$store.state.token,
        () => this.$router.push('/login'),
        () => null,
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response) => {
          this.configureFromResponse(response)
        })
    },
    onEditHeaderSection (
      id: number,
      label: string,
      listOrder: number,
      active: boolean,
      title: string,
      preTitle: string,
      subTitle: string
    ) {
      editHeaderSection(
        this.subProfile.id,
        id,
        label,
        listOrder,
        active,
        title,
        preTitle,
        subTitle,
        this.$store.state.token,
        () => this.$router.push('/login'),
        () => null,
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response) => {
          this.configureFromResponse(response)
        })
    },
    onEditLinkSection (
      id: number,
      label: string,
      listOrder: number,
      active: boolean,
      text: string,
      url: string
    ) {
      editLinkSection(
        this.subProfile.id,
        id,
        label,
        listOrder,
        active,
        text,
        url,
        this.$store.state.token,
        () => this.$router.push('/login'),
        () => null,
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response) => {
          this.configureFromResponse(response)
        })
    },
    onEditLinkSectionStyle (
      id: number,
      align: string,
      bold: boolean,
      italic: boolean,
      underline: boolean,
      size: number,
      textColour: string,
      backgroundColour: string,
      spacingLeft: number,
      spacingTop: number,
      spacingRight: number,
      spacingBottom: number
    ) {
      editLinkSectionStyle(
        this.subProfile.id,
        id,
        align,
        bold,
        italic,
        underline,
        size,
        textColour,
        backgroundColour,
        spacingLeft,
        spacingTop,
        spacingRight,
        spacingBottom,
        this.$store.state.token,
        () => this.$router.push('/login'),
        () => null,
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response) => {
          this.configureFromResponse(response)
        })
    },
    onEditNewsSectionStyle (
      id: number,
      textColour: string,
      backgroundColour: string,
      size: string,
      align: string,
      imageStyle: string
    ) {
      editNewsSectionStyle(
        this.subProfile.id,
        id,
        textColour,
        backgroundColour,
        size,
        align,
        imageStyle,
        this.$store.state.token,
        () => this.$router.push('/login'),
        () => null,
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response) => {
          this.configureFromResponse(response)
        })
    },
    onEditSocialsSectionStyle (
      id: number,
      layout: string,
      style: string,
      corners: string,
      size: string,
      align: string,
      logoTheme: string,
      logoColour: string,
      backgroundColour: string,
      leftSpacing: number,
      topSpacing: number,
      rightSpacing: number,
      bottomSpacing: number
    ) {
      editSocialsSectionStyle(
        this.subProfile.id,
        id,
        layout,
        style,
        corners,
        size,
        align,
        logoTheme,
        logoColour,
        backgroundColour,
        leftSpacing,
        topSpacing,
        rightSpacing,
        bottomSpacing,
        this.$store.state.token,
        () => this.$router.push('/login'),
        () => null,
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response) => {
          this.configureFromResponse(response)
        })
    },
    onEditTextSection (
      id: number,
      label: string,
      listOrder: number,
      active: boolean,
      content: string
    ) {
      editTextSection(
        this.subProfile.id,
        id,
        label,
        listOrder,
        active,
        content,
        this.$store.state.token,
        () => this.$router.push('/login'),
        () => null,
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response) => {
          this.configureFromResponse(response)
          nextTick(() => {
            this.$refs['textPanel' + id.toString()][0].focusInput()
          })
        })
    },
    onEditTableSection (
      id: number,
      label: string,
      listOrder: number,
      active: boolean,
      value: Array<Array<string>>,
      r?: number,
      c?: number
    ) {
      editTableSection(
        this.subProfile.id,
        id,
        label,
        listOrder,
        active,
        value,
        this.$store.state.token,
        () => this.$router.push('/login'),
        () => null,
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response) => {
          this.configureFromResponse(response)
          if (r !== undefined && c !== undefined) {
            nextTick(() => {
              this.$refs['tablePanel' + id.toString()][0].focusCell(r, c)
            })
          }
        })
    },
    onEditTableSectionStyle (
      id: number,
      header: string,
      border: number
    ) {
      editTableSectionStyle(
        this.subProfile.id,
        id,
        header,
        border,
        this.$store.state.token,
        () => this.$router.push('/login'),
        () => null,
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response) => {
          this.configureFromResponse(response)
        })
    },
    onEditFeaturesSectionStyle (
      id: number,
      layout: string,
      fillColour: string
    ) {
      editFeaturesSectionStyle(
        this.subProfile.id,
        id,
        layout,
        fillColour,
        this.$store.state.token,
        () => this.$router.push('/login'),
        () => null,
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response) => {
          this.configureFromResponse(response)
        })
    },
    onEditUserDetailsSection (
      id: number,
      label: string,
      listOrder: number,
      active: boolean
    ) {
      editUserDetailsSection(
        this.subProfile.id,
        id,
        label,
        listOrder,
        active,
        this.$store.state.token,
        () => this.$router.push('/login'),
        () => null,
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response) => {
          this.configureFromResponse(response)
        })
    },
    onChangeSectionOrder (sections : Array<{type: number, section: ImageSectionModel | HeaderSectionModel | TextSectionModel}>) {
      postSectionOrder(
        sections.map(section => section.section.id),
        this.subProfile.id,
        this.$store.state.token,
        () => this.$router.push('/login'),
        () => null,
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response) => {
          this.configureFromResponse(response)
        })
    },
    onDeleteSection (
      id: number
    ) {
      deleteSection(
        this.subProfile.id,
        id,
        this.$store.state.token,
        () => this.$router.push('/login'),
        () => null,
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response) => {
          this.configureFromResponse(response)
        })
    },
    onAddLinks (selectedDetails: string[], qrSectionId: number) {
      const details = selectedDetails.map(value => {
        return {
          category: DETAIL_TYPES[value].category,
          type: value,
          label: value,
          value: '{"value":""}',
          qrSectionId: qrSectionId
        }
      })
      postBatchDetail(
        details,
        this.$store.state.token,
        () => this.$router.push('/login'),
        () => null,
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response) => {
          this.configureFromResponse(response)
          this.currentDetail = ''
        })
    },
    onEditDetail (id: string, category: string, type: string, label: string, value: string, qrSectionId: number) {
      putDetail(
        id,
        category,
        type,
        label,
        value,
        qrSectionId,
        this.$store.state.token,
        () => this.$router.push('/login'),
        () => null,
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response) => {
          this.configureFromResponse(response)
          this.currentDetail = ''
        })
    },
    onDeleteDetail (id: string, sectionId: number) {
      deleteDetail(
        id,
        sectionId,
        this.$store.state.token,
        () => this.$router.push('/login'),
        () => null,
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response) => {
          this.configureFromResponse(response)
          this.currentDetail = ''
        })
    },
    onCreateDetail () {
      postDetail(
        DEFAULT_DETAIL.category,
        DEFAULT_DETAIL.type,
        DEFAULT_DETAIL.label + ' #' + String(this.subProfile.details.length + 1),
        DEFAULT_DETAIL.value,
        this.subProfile.id,
        this.$store.state.token,
        () => this.$router.push('/login'),
        () => null,
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response) => {
          this.configureFromResponse(response)
          this.currentDetail = ''
        })
    },
    onChangeDetailOrder (sectionId: number, details : Array<Detail>) {
      postDetailOrder(
        details.map(detail => detail.id),
        sectionId,
        this.$store.state.token,
        () => this.$router.push('/login'),
        () => null,
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response) => {
          this.configureFromResponse(response)
          this.currentDetail = ''
        })
    },
    onAddFeature (sectionId: number) {
      postFeatureItem(
        sectionId,
        this.subProfile.id,
        this.$store.state.token,
        () => this.$router.push('/login'),
        () => null,
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response) => {
          this.configureFromResponse(response)
          this.currentDetail = ''
        })
    },
    onEditFeature (id: number, value: string, sectionId: number) {
      editFeatureItem(
        id,
        value,
        sectionId,
        this.subProfile.id,
        this.$store.state.token,
        () => this.$router.push('/login'),
        () => null,
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response) => {
          this.configureFromResponse(response)
        })
    },
    onDeleteFeature (id: number, sectionId: number) {
      deleteFeatureItem(
        id,
        sectionId,
        this.subProfile.id,
        this.$store.state.token,
        () => this.$router.push('/login'),
        () => null,
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response) => {
          this.configureFromResponse(response)
          this.currentDetail = ''
        })
    },
    onChangeFeatureOrder (sectionId: number, features : Array<FeaturesItem>) {
      postFeatureItemOrder(
        features.map(feature => feature.id),
        sectionId,
        this.subProfile.id,
        this.$store.state.token,
        () => this.$router.push('/login'),
        () => null,
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response) => {
          this.configureFromResponse(response)
          this.currentDetail = ''
        })
    },
    onCreateNewsItem (sectionId: number) {
      postNewsItem(
        sectionId,
        this.subProfile.id,
        this.$store.state.token,
        () => this.$router.push('/login'),
        () => null,
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response) => {
          this.configureFromResponse(response)
          this.currentDetail = ''
        })
    },
    onEditNewsItem (id: number, title: string, subTitle: string, url: string, sectionId: number) {
      editNewsItem(
        id,
        title,
        subTitle,
        url,
        sectionId,
        this.subProfile.id,
        this.$store.state.token,
        () => this.$router.push('/login'),
        () => null,
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response) => {
          this.configureFromResponse(response)
        })
    },
    onEditNewsItemImage (id: number, image: File, sectionId: number) {
      editNewsItemImage(
        image,
        id,
        sectionId,
        this.subProfile.id,
        this.$store.state.token,
        () => this.$router.push('/login'),
        () => null,
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response) => {
          this.configureFromResponse(response)
        })
    },
    onDeleteNewsItem (id: number, sectionId: number) {
      deleteNewsItem(
        id,
        sectionId,
        this.subProfile.id,
        this.$store.state.token,
        () => this.$router.push('/login'),
        () => null,
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response) => {
          this.configureFromResponse(response)
          this.currentDetail = ''
        })
    },
    onChangeNewsItemOrder (sectionId: number, items : Array<FeaturesItem>) {
      postNewsItemOrder(
        items.map(item => item.id),
        sectionId,
        this.subProfile.id,
        this.$store.state.token,
        () => this.$router.push('/login'),
        () => null,
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response) => {
          this.configureFromResponse(response)
        })
    },
    onAddSocialsItemBatch (sectionId: number, items: Array<string>) {
      postSocialsItemBatch(
        sectionId,
        this.subProfile.id,
        items,
        this.$store.state.token,
        () => this.$router.push('/login'),
        () => null,
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response) => {
          this.configureFromResponse(response)
          this.currentDetail = ''
        })
    },
    onEditSocialsItem (id: number, value: string, sectionId: number) {
      editSocialsItem(
        id,
        value,
        sectionId,
        this.subProfile.id,
        this.$store.state.token,
        () => this.$router.push('/login'),
        () => null,
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response) => {
          this.configureFromResponse(response)
        })
    },
    onDeleteSocialsItem (id: number, sectionId: number) {
      deleteSocialsItem(
        id,
        sectionId,
        this.subProfile.id,
        this.$store.state.token,
        () => this.$router.push('/login'),
        () => null,
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response) => {
          this.configureFromResponse(response)
          this.currentDetail = ''
        })
    },
    onEditSocialsItemOrder (sectionId: number, items : Array<SocialsItem>) {
      postSocialsItemOrder(
        items.map(item => item.id),
        sectionId,
        this.subProfile.id,
        this.$store.state.token,
        () => this.$router.push('/login'),
        () => null,
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response) => {
          this.configureFromResponse(response)
        })
    },
    onSubmitImageSection (id: number, image: File) {
      editImageSectionImage(
        image,
        id,
        this.subProfile.id,
        this.$store.state.token,
        () => this.$router.push('/login'),
        () => null,
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response) => {
          this.configureFromResponse(response)
        })
    },
    onResetSectionImage (id: number) {
      resetImageSection(
        id,
        this.subProfile.id,
        this.$store.state.token,
        () => this.$router.push('/login'),
        () => null,
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response) => {
          this.configureFromResponse(response)
        })
    },
    onCreateHeaderListItem (value: string, image: File) {
      postHeaderListItem(
        value,
        image,
        this.subProfile.id,
        this.$store.state.token,
        () => this.$router.push('/login'),
        () => null,
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response) => {
          this.configureFromResponse(response)
        })
    },
    onChangeHeaderListItemOrder (items : Array<SubProfileHeaderListItem>) {
      this.drag = false
      postHeaderListItemOrder(
        items.map(item => item.id),
        this.subProfile.id,
        this.$store.state.token,
        () => this.$router.push('/login'),
        () => null,
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response) => {
          this.configureFromResponse(response)
        })
    },
    onDeleteHeaderListItem (id: string) {
      deleteSubProfileHeaderListItem(
        id,
        this.subProfile.id,
        this.$store.state.token,
        () => this.$router.push('/login'),
        () => null,
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response) => {
          this.configureFromResponse(response)
          this.currentDetail = ''
        })
    },
    onSubmitThemePreset (themeId: number) {
      postThemePreset(
        themeId,
        this.subProfile.id,
        this.$store.state.token,
        () => this.$router.push('/login'),
        () => null,
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response) => {
          this.configureFromResponse(response)
          this.currentDetail = ''
        })
    },
    onUpdateThemeColor (target: string, colour: string) {
      console.log('hh ' + target + ', ' + colour)
      postThemeColour(
        target,
        colour,
        this.subProfile.id,
        this.$store.state.token,
        () => this.$router.push('/login'),
        () => null,
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response) => {
          this.configureFromResponse(response)
          this.currentDetail = ''
        })
    },
    onSelectTab (tabId: number) {
      const links = document.getElementsByClassName('nav-link')
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove('active')
        links[i].removeAttribute('aria-current')
      }
      links[tabId].classList.add('active')
      links[tabId].setAttribute('aria-current', 'page')
      this.selectedTab = tabId
      console.log(this.selectedTab)
    }
  },
  mounted () {
    document.addEventListener('click', this.handleClickOutside)
    fetchSubProfileDashboard(
      String(this.$route.params.id),
      this.$store.state.token,
      () => this.$router.push('/login'),
      () => this.$router.push('/not-found'),
      () => this.$router.back(),
      () => this.$router.push('/server-error'),
      (response) => {
        this.configureFromResponse(response)

        this.analyticsCharts.scansMonth.labels = this.subProfile.analytics.dailyScansMonthDates
        this.analyticsCharts.scansYear.labels = this.subProfile.analytics.dailyScansYearDates
        this.analyticsCharts.scansMonth.datasets[0].data = this.subProfile.analytics.dailyScansMonth
        this.analyticsCharts.scansYear.datasets[0].data = this.subProfile.analytics.dailyScansYear

        this.loaded = true
      })
  },
  beforeUnmount () {
    document.removeEventListener('click', this.handleClickOutside)
  }
})

</script>

<style scoped lang="scss">

div.top-bar-scroll {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: var(--ct-body-bg);
  padding: 27px 60px 13.5px 0;
  width: calc(100vw - 220px);
}

h4.sub-profile-label-text {
  text-align: left;
  color: var(--Nearly-Black, #212529);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px; /* 130% */
}

div.qr-switch-download-container {
  margin: 0 0 0 auto;
}

div.sub-profile-url-container {
  margin: 0 8px 0 0;
}

div.sub-profile-qr-view-download-container {
  margin: 0 8px 0 0;
  z-index: 100;
}

  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    position: fixed;
    top: 2.5em;
    background-color: var(--background-color);
    color: var(--color);
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }

  /* Links inside the dropdown */
  .dropdown-content a {
    color: inherit;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }

  .qr-image{
    width: 50%;
    aspect-ratio: 1/1;
  }

  div.qr-image-container {
    background-color: white;
    border-radius: 1em;
  }
  div.download-qrs{
    flex: 1;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
  }
  a{
    width: 50%;
    float: left;
  }
  div.create-section-button-container {
    width: 25%;
  }

  div.preview-panel-container {
    min-width: 370px;
    margin: 0 60px 0 0;
  }
</style>
