interface SectionInterface {
  id: number
  label: string
  listOrder: number
  active: boolean
  persistent: boolean
  labelVisible: boolean
}

export default class Section implements SectionInterface {
  id = 0
  label = ''
  listOrder = 0
  active = false
  persistent = false
  labelVisible = false

  constructor (section?: SectionInterface) {
    if (section) {
      this.id = section.id
      this.label = section.label
      this.listOrder = section.listOrder
      this.active = section.active
      this.persistent = section.persistent
      this.labelVisible = section.labelVisible
    }
  }
}
