<template>
    <div class="container">
         <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-8 mx-auto">
                  <img :src="require(`@/assets/logo.png`)"/>
                  <h1>404</h1>
                  <h4>Page Not Found</h4>
               </div>
            </div>
         </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'NotFoundView'
})
</script>

<style scoped lang="scss">
    img {
        height: 200px;
        width: 200px;
        align-self: center;
        margin: 10px;
    }
</style>
