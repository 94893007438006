import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-02c7e691"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "editor-input-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DebouncedTextInput = _resolveComponent("DebouncedTextInput")!

  return (_openBlock(), _createElementBlock("div", {
    class: "editor-input-container",
    style: _normalizeStyle(_ctx.cssVarsContainer)
  }, [
    _createElementVNode("p", _hoisted_1, _toDisplayString(this.label), 1),
    _createVNode(_component_DebouncedTextInput, {
      value: this.internalValue,
      type: "text",
      multiline: this.multiline,
      "input-style": this.inputComponentStyle,
      "onUpdate:value": _ctx.onChangeValue
    }, null, 8, ["value", "multiline", "input-style", "onUpdate:value"]),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 4))
}