<template >
    <div v-if="image" class="image-container d-flex justify-content-center py-5">
        <img class="image" :src=image.uri crossorigin="anonymous">
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import ImageSection from '../../models/qr-sections/ImageSection'

export default defineComponent({
  name: 'SubProfileHeader',
  props: {
    image: ImageSection
  }
})
</script>

<style scoped lang="scss">
    div.image-container{
        width: 100%;
        background-color: #F1F1F1;
    }
    img.image{
        width: 60%;
        aspect-ratio: 1 / 1;
    }
</style>
