<template>
    <div ref="content" :style="cssContainer">
        <FaIconLink
          v-if="this.btnText"
          @click="toggle"
          :icon="this.btnIcon"
          :text="this.btnText"
          :style="this.btnStyle"
          />
        <SvgButton v-else @click="toggle" :icon="this.btnIcon" :style="this.btnStyle"/>
        <div v-if="this.visible" class="dropdown-content" :style="cssContent">
            <FaIconLink
                v-for="(item, i) in this.items"
                :key="i"
                @click="() => $emit('onClickLink', i)"
                :icon="item.icon"
                :text="item.text"
                :style="cssLinkContainer"
                :iconContainerStyle="cssLinkIconContainer"
                :textStyle="cssLinkText"
            />
        </div>
    </div>
</template>

<script lang="ts">

import { defineComponent } from 'vue'

import SvgButton from '../button/SvgButton.vue'
import FaIconLink from '../button/FaIconLink.vue'

import { Palette } from '@/theme'

export default defineComponent({
  name: 'DropdownButton',
  components: {
    SvgButton,
    FaIconLink
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    btnIcon: {
      type: String,
      required: true
    },
    btnStyle: {
      type: Object,
      default: null
    },
    btnText: {
      type: String,
      default: null
    },
    containerStyle: {
      type: Object,
      default: null
    },
    contentStyle: {
      type: Object,
      default: null
    },
    linkContainerStyle: {
      type: Object,
      default: null
    },
    linkIconContainerStyle: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      visible: false
    }
  },
  computed: {
    cssContainer () {
      return {
        ...this.containerStyle
      }
    },
    cssContent () {
      return {
        '--color': Palette.neutral800,
        ...this.contentStyle
      }
    },
    cssLinkContainer () {
      return {
        width: '100%',
        ...this.linkContainerStyle
      }
    },
    cssLinkIconContainer () {
      return {
        width: '10%'
      }
    },
    cssLinkText () {
      return {
        width: '90%',
        textAlign: 'left'
      }
    }
  },
  methods: {
    toggle () {
      this.visible = !this.visible
    },
    handleClickOutside (event) {
      if (
        this.$refs.content &&
        !this.$refs.content.contains(event.target)
      ) {
        this.visible = false
      }
    }
  },
  mounted () {
    document.addEventListener('click', this.handleClickOutside)
  },
  beforeUnmount () {
    document.removeEventListener('click', this.handleClickOutside)
  }
})
</script>

<style scoped>
.dropdown-content {
    position: absolute;
    margin-top: 0.5em;
    padding: 1em;
    background-color: white;
    color: var(--color);
    min-width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    border-radius: 1em;
    z-index: 1;
}
</style>
