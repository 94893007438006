<template>
    <div ref="content" class="dropdown-container">
      <div class="d-flex flex-column">
        <div class="option-wrapper" :style="cssVarsOptionSelected" @click="toggle">
          <div class="option-dot" :style="cssVarsOptionSelected" />
          <p class="option-text">{{ this.textSelected }}</p>
          <img class="option-chevron" :src="require(`@/assets/icons/svg/chevron-${this.pathChevronSuffix}.svg`)" />
        </div>
        <div v-if="visible" class="option-wrapper dropdown-content" :style="cssVarsOptionUnselected" @click="onSwitch">
          <div class="option-dot" :style="cssVarsOptionUnselected" />
          <p class="option-text">{{ this.textUnselected }}</p>
        </div>
    </div>
  </div>
</template>

<script lang="ts">

import { defineComponent } from 'vue'

import 'vue-loading-overlay/dist/css/index.css'

import { Palette } from '@/theme'

export default defineComponent({
  name: 'DropToggle',
  props: {
    onText: {
      type: String,
      required: true
    },
    offText: {
      type: String,
      required: true
    },
    initialValue: {
      type: Boolean,
      default: false
    },
    onBackColor: {
      type: String,
      default: '#f0f9f1' // Green #65C372 10%
    },
    onColor: {
      type: String,
      default: '#65C372' // Green #65C372
    },
    offBackColor: {
      type: String,
      default: '#ffe6e6' // red #FF0000 10%
    },
    offColor: {
      type: String,
      default: '#FF0000' // red #FF0000
    },
    onChevronSuffix: {
      type: String,
      default: 'green'
    },
    offChevronSuffix: {
      type: String,
      default: 'red'
    }
  },
  data () {
    return {
      modelValue: this.initialValue,
      visible: false
    }
  },
  emits: ['update:modelValue'],
  computed: {
    value: {
      get () {
        return this.modelValue
      },
      set (value : boolean) {
        this.modelValue = value
        this.$emit('update:modelValue', value)
      }
    },
    cssVarsOptionSelected () {
      return {
        '--background-color': this.modelValue ? this.onBackColor : this.offBackColor,
        '--color': this.modelValue ? this.onColor : this.offColor
      }
    },
    cssVarsOptionUnselected () {
      return {
        '--background-color': '#FFFFFF',
        '--color': this.modelValue ? this.offColor : this.onColor
      }
    },
    textSelected () {
      return this.modelValue ? this.onText : this.offText
    },
    textUnselected () {
      return this.modelValue ? this.offText : this.onText
    },
    pathChevronSuffix () {
      if (this.modelValue) {
        return this.visible ? 'up-' + this.onChevronSuffix : 'down-' + this.onChevronSuffix
      } else {
        return this.visible ? 'up-' + this.offChevronSuffix : 'down-' + this.offChevronSuffix
      }
    }
  },
  methods: {
    toggle () {
      this.visible = !this.visible
    },
    onSwitch () {
      this.value = !this.value
      this.visible = false
    },
    handleClickOutside (event) {
      if (
        this.$refs.content &&
        !this.$refs.content.contains(event.target)
      ) {
        this.visible = false
      }
    }
  },
  mounted () {
    document.addEventListener('click', this.handleClickOutside)
  },
  beforeUnmount () {
    document.removeEventListener('click', this.handleClickOutside)
  }
})
</script>

<style scoped>

div.dropdown-container {
  width: 91px;
  position: relative
 }
 div.option-wrapper {
  display: inline-flex;
  padding: 9px 8px;
  align-items: center;
  gap: 8px;
  width: 100%;
  border-radius: 8px;
  background-color: var(--background-color);
  color: var(--color);
  height: 32px;
  cursor: pointer;
 }

 div.dropdown-content {
  position: absolute;
  margin-top: 32px;
  z-index: 1;
 }

 div.option-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--color);
 }

 p.option-text {
  color: var(--color);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px; /* 107.692% */
  margin: 0;
 }

 img.option-chevron {
  width: 8px;
  height: 8px;
 }
</style>
