import Section from './Section'

interface TextSectionInterface {
  id: number
  label: string
  listOrder: number
  active: boolean
  persistent: boolean
  labelVisible: boolean
  content: string
}

export default class TextSection extends Section implements TextSectionInterface {
  content = ''

  constructor (textSection?: TextSectionInterface) {
    super(textSection)
    if (textSection) {
      this.content = textSection.content
    }
  }
}
