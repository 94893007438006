<template>
    <EditorPanel
      :title="embeddedVideoSection.label"
      :active="embeddedVideoSection.active"
      :persistent="embeddedVideoSection.persistent"
      :styleMode="styleMode"
      @onSetActive="onSetActive"
      @onDelete="onDelete"
      @onToggleMode="onToggleMode"
      >
      <div v-if="!this.styleMode" class="d-flex flex-column">
        <EditorInputComponent
          label="URL"
          :value="embeddedVideoSection.url"
          @onChangeValue="onEditUrl"
          :multiline="true"
          :input-component-style="{height: '138px', width: '387px'}"
          style="height: 160px; align-items: flex-start; padding: 10px 10px 10px 20px;"
          >
          <EmbeddedVideoPlayer :player-width="245" :player-height="138" :player-style="{margin: '0 0 10px 10px'}" :embeddedVideoSection="embeddedVideoSection"/>
        </EditorInputComponent>
        </div>
      <div v-else class="d-flex flex-column embedded-video-style-panel-content">
          <div class="d-flex embedded-video-style-panel-row align-items-center">
            <p class="style-header d-flex flex-fill">Controls</p>
            <div class="d-flex">
              <StyleTextIconButton :icon="'style-controls-show'" :selected="controlsShow" @click="() => onEditControls('show')">Show</StyleTextIconButton>
              <StyleTextIconButton :icon="'style-controls-hide'" :selected="!controlsShow" @click="() => onEditControls('hide')">Hide</StyleTextIconButton>
            </div>
          </div>
        </div>
      </EditorPanel>
</template>

<script lang="ts">

import { defineComponent } from 'vue'

import ColourInput from '../input/ColourInput.vue'
import CrossButton from '../button/CrossButton.vue'
import DebouncedTextInput from '../input/DebouncedTextInput.vue'
import EditorPanel from '@/components/editor/EditorPanel.vue'
import EmbeddedVideoPlayer from '@/components/video/EmbeddedVideoPlayer.vue'
import FaIconLink from '../button/FaIconLink.vue'
import StyleTextIconButton from '../button/style-panel/StyleTextIconButton.vue'

import EmbeddedVideoSection from '@/models/qr-sections/EmbeddedVideoSection'
import { SlickItem, SlickList, HandleDirective } from 'vue-slicksort'
import EditorInputComponent from '../input/EditorInputComponent.vue'

import LiteYouTubeEmbed from 'vue-lite-youtube-embed'
import 'vue-lite-youtube-embed/style.css'

export default defineComponent({
  name: 'EmbeddedVideoPanel',
  components: {
    EditorInputComponent,
    EditorPanel,
    EmbeddedVideoPlayer,
    StyleTextIconButton
  },
  props: {
    embeddedVideoSection: {
      type: EmbeddedVideoSection,
      required: true
    }
  },
  data () {
    return {
      styleMode: false
    }
  },
  computed: {
    controlsShow (): boolean {
      return this.embeddedVideoSection.style.controls === 'show'
    }
  },
  methods: {
    onToggleMode (emit = true) {
      this.styleMode = !this.styleMode
      if (emit) this.$emit('onToggleMode', this.embeddedVideoSection.id, this.styleMode)
    },
    onSetActive (value: boolean) {
      this.$emit(
        'onEdit',
        this.embeddedVideoSection.id,
        this.embeddedVideoSection.label,
        this.embeddedVideoSection.listOrder,
        value,
        this.embeddedVideoSection.url)
    },
    onDelete () {
      this.$emit('onDelete', this.embeddedVideoSection.id)
    },
    onEditUrl (value: string) {
      this.$emit(
        'onEdit',
        this.embeddedVideoSection.id,
        this.embeddedVideoSection.label,
        this.embeddedVideoSection.listOrder,
        this.embeddedVideoSection.active,
        value
      )
    },
    onEditControls (value: string) {
      if (value === this.embeddedVideoSection.style.controls) return
      this.$emit(
        'onEditStyle',
        this.embeddedVideoSection.id,
        value,
        this.embeddedVideoSection.style.thumbnail,
        this.embeddedVideoSection.style.thumbnailUri
      )
    },
    onEditThumbnail (value: string) {
      if (value === this.embeddedVideoSection.style.thumbnail) return
      this.$emit(
        'onEditStyle',
        this.embeddedVideoSection.id,
        this.embeddedVideoSection.controls,
        value,
        this.embeddedVideoSection.style.thumbnailUri
      )
    }
  }
})
</script>

<style scoped>

div.embedded-video-style-panel-content {
    flex-shrink: 0;
    width: 100%;
    padding: 10px;
  }

  div.embedded-video-style-panel-row {
    width: 100%;
    height: 60px;
    border-top: 1px solid rgba(33, 37, 41, 0.1);
  }

  p.style-header {
    color: var(--Nearly-Black, #212529);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    opacity: 0.5;
    margin: 0px;
  }

</style>
