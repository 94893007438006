import { NewsItem, NewsItemInterface } from './NewsItem'

interface NewsSectionStyleInterface {
    textColour: string,
    backgroundColour: string,
    size: string,
    align: string,
    imageStyle: string
  }

interface NewsSectionInterface {
    id: number
    label: string
    listOrder: number,
    active: boolean,
    persistent: boolean,
    labelVisible: boolean,
    items: NewsItemInterface[],
    style: NewsSectionStyleInterface
}

class NewsSectionStyle implements NewsSectionStyleInterface {
  textColour = ''
  backgroundColour = ''
  size = ''
  align = ''
  imageStyle = ''

  constructor (style?: NewsSectionStyleInterface) {
    if (style) {
      this.textColour = style.textColour
      this.backgroundColour = style.backgroundColour
      this.size = style.size
      this.align = style.align
      this.imageStyle = style.imageStyle
    }
  }
}

export default class NewsSection implements NewsSectionInterface {
  id = 0
  label = ''
  listOrder = 0
  active = false
  persistent = false
  labelVisible = false
  items = Array<NewsItemInterface>()
  style = new NewsSectionStyle()

  constructor (newsSection?: NewsSectionInterface) {
    if (newsSection) {
      this.id = newsSection.id
      this.label = newsSection.label
      this.listOrder = newsSection.listOrder
      this.active = newsSection.active
      this.persistent = newsSection.persistent
      this.labelVisible = newsSection.labelVisible
      this.items = newsSection.items.map(d => new NewsItem(d)).sort(function (a: NewsItem, b: NewsItem) { return a.listOrder - b.listOrder })
      this.style = new NewsSectionStyle(newsSection.style)
    }
  }
}
