import Section from './Section'

interface HeaderSectionInterface {
    id: number
    label: string
    listOrder: number,
    active: boolean,
    persistent: boolean,
    labelVisible: boolean,
    title: string,
    preTitle: string,
    subTitle: string
}

export default class HeaderSection extends Section implements HeaderSectionInterface {
  title = ''
  preTitle = ''
  subTitle = ''

  constructor (headerSection?: HeaderSectionInterface) {
    super(headerSection)
    if (headerSection) {
      this.title = headerSection.title
      this.preTitle = headerSection.preTitle
      this.subTitle = headerSection.subTitle
    }
  }
}
