import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-215d4f70"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "qr-grid-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QRGridItem = _resolveComponent("QRGridItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.subProfiles, (subProfile) => {
      return (_openBlock(), _createElementBlock("div", {
        key: subProfile.id,
        class: "qr-grid-item-container"
      }, [
        _createVNode(_component_QRGridItem, {
          subProfile: subProfile,
          interactive: true,
          onOnDelete: _ctx.onDelete,
          onOnToggleActive: _ctx.onToggleActive,
          onOnSelect: _ctx.selectSubProfile
        }, null, 8, ["subProfile", "onOnDelete", "onOnToggleActive", "onOnSelect"])
      ]))
    }), 128))
  ]))
}