import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f5703da0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex" }
const _hoisted_2 = ["width", "height", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("iframe", {
      width: _ctx.playerWidth,
      height: _ctx.playerHeight,
      style: _normalizeStyle(_ctx.cssVarsPlayer),
      src: _ctx.getSrc,
      class: "embedded-video-player"
    }, "\n        ", 12, _hoisted_2)
  ]))
}