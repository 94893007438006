import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4e9fc619"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("p", {
      style: _normalizeStyle(_ctx.cssVars)
    }, [
      _createElementVNode("img", {
        src: require(`@/assets/${_ctx.image}`)
      }, null, 8, _hoisted_1),
      _createTextVNode(" " + _toDisplayString(_ctx.text), 1)
    ], 4)
  ]))
}