<template>
    <DropdownButton
      btn-icon="download"
      :items="items"
      @onClickLink="onClickLink"/>
</template>

<script lang="ts">

import { defineComponent } from 'vue'

import DropdownButton from './DropdownButton.vue'

export default defineComponent({
  name: 'DropdownQrDownload',
  components: {
    DropdownButton
  },
  data () {
    return {
      items: [
        {
          icon: '',
          text: 'PNG',
          subItems: []
        },
        {
          icon: '',
          text: 'SVG',
          subItems: []
        }
      ]
    }
  },
  methods: {
    onClickLink (id: number) {
      switch (id) {
        case 0:
          this.$emit('onClickPng')
          break
        case 1:
          this.$emit('onClickSvg')
          break
      }
    }
  }
})
</script>

<style scoped>
</style>
