<template>
    <EditorPanel
      :title="newsSection.label"
      :active="newsSection.active"
      :persistent="newsSection.persistent"
      :styleMode="styleMode"
      @onSetActive="onSetActive"
      @onDelete="onDelete"
      @onToggleMode="onToggleMode"
      >
      <div v-if="!this.styleMode" class="d-flex flex-column">
        <EditorInputComponent
          label="Title"
          :value="newsSection.label"
          @onChangeValue="onEditLabel"
          >
          <SwitchComponent :initialValue="newsSection.labelVisible" :v-model="isLabelVisible" @update:modelValue="onSetLabelVisible"/>
        </EditorInputComponent>
        <SlickList axis="y" v-model:list="news" @update:list="onChangeNewsItemOrder" :useDragHandle="true">
                <SlickItem v-for="(item, i) in news" :key="item" :index="i">
                  <div class="d-flex news-item-container align-items-center">
                    <ImageInput @onSelect="(file: File) => onEditNewsItemImage(item.id, file)"/>
                    <div class="d-flex flex-column justify-content-between news-item-input-container flex-grow-1">
                      <div class="d-flex flex-grow  news-item-input-item">
                        <p class="news-item-input-label">Item title</p>
                        <DebouncedTextInput
                          :value="item.title"
                          type="text"
                          placeholder="title"
                          @update:value="(value: string) => $emit('onEditNewsItem', item.id, value, item.subTitle, item.url, newsSection.id)"
                        />
                      </div>
                      <div class="d-flex news-item-input-item">
                        <p class="news-item-input-label">Sub-title</p>
                        <DebouncedTextInput
                          :value="item.subTitle"
                          type="text"
                          placeholder="sub-title"
                          @update:value="(value: string) => $emit('onEditNewsItem', item.id, item.title, value, item.url, newsSection.id)"
                        />
                      </div>
                      <div class="d-flex news-item-input-item">
                        <p class="news-item-input-label">URL</p>
                        <DebouncedTextInput
                          :value="item.url"
                          type="text"
                          placeholder="url"
                          @update:value="(value: string) => $emit('onEditNewsItem', item.id, item.title, item.subTitle, value, newsSection.id)"
                        />
                      </div>
                    </div>
                    <div class="news-item-delete-button-container">
                      <CrossButton @click="() => $emit('onDeleteNewsItem', item.id, newsSection.id)" :style="cssDeleteFeatureButton"/>
                    </div>
                    <div class="news-item-grip-handle-container">
                      <span v-handle class="handle"><a href="#"><img class="grip-icon" :src="require('@/assets/icons/icon-grip-1.png')"></a></span>
                    </div>
                  </div>
              </SlickItem>
          </SlickList>
          <div class="my-2 align-self-start">
              <FaIconLink
                icon="plus"
                size="md"
                text="Add item"
                :icon-container-style="cssAddIcon"
                :text-style="cssAddText"
                :text-uses-gradient="true"
                @click="() => $emit('onCreateNewsItem', newsSection.id)"
                />
            </div>
          </div>
          <div v-else class="d-flex flex-column news-style-panel-content">
            <div class="d-flex news-style-panel-row align-items-center">
              <p class="style-header d-flex flex-fill">Colour</p>
              <div class="d-flex align-items-center news-style-panel-row-group-br" style="padding: 0 10px 0 0">
                <ColourInput :label="'Text'" :value="newsSection.style.textColour" @onChangeColour="onEditTextColour"/>
              </div>
              <div class="d-flex" style="padding: 0 0 0 10px">
                <ColourInput :label="'Fill'" :value="newsSection.style.backgroundColour" @onChangeColour="onEditBackgroundColour"/>
              </div>
            </div>
            <div class="d-flex news-style-panel-row align-items-center">
              <p class="style-header d-flex flex-fill">Size & Align</p>
              <div class="d-flex align-items-center news-style-panel-row-group-br" style="padding: 0 10px 0 2px">
                <StyleTextButton
                  :selected="newsSection.style.size == 'sm'"
                  @click="() => onEditSize('sm')"
                  style="width: 30px; height: 30px">
                  S
                </StyleTextButton>
                <StyleTextButton
                  :selected="newsSection.style.size == 'md'"
                  @click="() => onEditSize('md')"
                  style="width: 36px; height: 36px;">
                  M
                </StyleTextButton>
                <StyleTextButton
                  :selected="newsSection.style.size == 'lg'"
                  @click="() => onEditSize('lg')"
                  style="width: 40px;">
                  L
                </StyleTextButton>
              </div>
              <div class="d-flex align-items-center" style="padding: 0 10px 0 2px">
                <StyleIconButton :icon="'style-align-left'" :selected="newsSection.style.align == 'left'" @click="() => onEditAlign('left')"/>
                <StyleIconButton :icon="'style-align-center'" :selected="newsSection.style.align == 'center'" @click="() => onEditAlign('center')"/>
                <StyleIconButton :icon="'style-align-right'" :selected="newsSection.style.align == 'right'" @click="() => onEditAlign('right')"/>
                <StyleIconButton :icon="'style-align-fill'" :selected="newsSection.style.align == 'fill'" @click="() => onEditAlign('fill')"/>
              </div>
            </div>
            <div class="d-flex news-style-panel-row align-items-center">
              <p class="style-header d-flex flex-fill">Image Style</p>
              <div class="d-flex">
                <StyleTextButton :icon="'style-layout-list'" :selected="newsSection.style.imageStyle === 'square'" @click="() => onEditImageStyle('square')">Square</StyleTextButton>
                <StyleTextButton :icon="'style-layout-grid'" :selected="newsSection.style.imageStyle === 'auto'" @click="() => onEditImageStyle('auto')">Auto Height</StyleTextButton>
              </div>`
            </div>
          </div>
      </EditorPanel>
</template>

<script lang="ts">

import { defineComponent } from 'vue'

import ColourInput from '../input/ColourInput.vue'
import CrossButton from '../button/CrossButton.vue'
import DebouncedTextInput from '../input/DebouncedTextInput.vue'
import EditorInputComponent from '../input/EditorInputComponent.vue'
import EditorPanel from '@/components/editor/EditorPanel.vue'
import FaIconLink from '../button/FaIconLink.vue'
import ImageInput from '../input/ImageInput.vue'
import StyleIconButton from '../button/style-panel/StyleIconButton.vue'
import StyleTextButton from '../button/style-panel/StyleTextButton.vue'
import SwitchComponent from '../switch/Switch.vue'

import NewsSection from '@/models/qr-sections/NewsSection'
import { NewsItem } from '@/models/qr-sections/NewsItem'
import { SlickItem, SlickList, HandleDirective } from 'vue-slicksort'
import { DETAIL_TYPES } from '@/constants'
import { Palette } from '@/theme'

export default defineComponent({
  name: 'NewsPanel',
  components: {
    ColourInput,
    CrossButton,
    DebouncedTextInput,
    EditorInputComponent,
    EditorPanel,
    FaIconLink,
    ImageInput,
    SlickItem,
    SlickList,
    StyleIconButton,
    StyleTextButton,
    SwitchComponent
  },
  props: {
    newsSection: {
      type: NewsSection,
      required: true
    }
  },
  data () {
    return {
      news: this.newsSection.items,
      drag: false,
      styleMode: false,
      isLabelVisible: this.newsSection.labelVisible
    }
  },
  computed: {
    cssVarsContainer () {
      return {
        ...this.containerStyle
      }
    },
    cssAddIcon () {
      return {
        backgroundImage: Palette.gradientBP,
        width: '1.95em',
        borderRadius: '0.975em',
        padding: '0.5em',
        color: Palette.white
      }
    },
    cssAddText () {
      return {
        backgroundImage: Palette.gradientBP,
        backgroundClip: 'text',
        backgroundSize: '100%',
        textFillColor: 'transparent'
      }
    },
    cssDeleteFeatureButton () {
      return {
        width: '30px',
        height: '30px'
      }
    }
  },
  directives: { handle: HandleDirective },
  watch: {
    itemSection (itemSection) {
      if (itemSection) this.news = itemSection.news
    }
  },
  methods: {
    onToggleMode (emit = true) {
      this.styleMode = !this.styleMode
      if (emit) this.$emit('onToggleMode', this.newsSection.id, this.styleMode)
    },
    getIcon (type: string): string {
      return DETAIL_TYPES[type].icon
    },
    onSetActive (value: boolean) {
      this.$emit('onEdit', this.newsSection.id, this.newsSection.label, this.newsSection.listOrder, value, this.newsSection.labelVisible)
    },
    onEditLabel (value: string) {
      this.$emit('onEdit', this.newsSection.id, value, this.newsSection.listOrder, this.newsSection.active, this.newsSection.labelVisible)
    },
    onSetLabelVisible (value: boolean) {
      this.$emit('onEdit', this.newsSection.id, this.newsSection.label, this.newsSection.listOrder, this.newsSection.active, value)
    },
    onEditNewsItemImage (id: number, image: File) {
      this.$emit('onEditNewsItemImage', id, image)
    },
    onEditNewsItem (id: number, title: string, subTitle: string, url: string) {
      this.$emit('onEditNewsItem', id, title, subTitle, url, this.newsSection.id)
    },
    onChangeNewsItemOrder (items : Array<NewsItem>) {
      this.drag = false
      this.$emit('onChangeNewsItemOrder', this.newsSection.id, items)
    },
    onDelete () {
      this.$emit('onDelete', this.newsSection.id)
    },
    onEditTextColour (value: string) {
      if (value === this.newsSection.style.textColour) return
      this.$emit(
        'onEditStyle',
        this.newsSection.id,
        value,
        this.newsSection.style.backgroundColour,
        this.newsSection.style.size,
        this.newsSection.style.align,
        this.newsSection.style.imageStyle
      )
    },
    onEditBackgroundColour (value: string) {
      if (value === this.newsSection.style.backgroundColour) return
      this.$emit(
        'onEditStyle',
        this.newsSection.id,
        this.newsSection.style.textColour,
        value,
        this.newsSection.style.size,
        this.newsSection.style.align,
        this.newsSection.style.imageStyle
      )
    },
    onEditSize (value: string) {
      if (value === this.newsSection.style.size) return
      this.$emit(
        'onEditStyle',
        this.newsSection.id,
        this.newsSection.style.textColour,
        this.newsSection.style.backgroundColour,
        value,
        this.newsSection.style.align,
        this.newsSection.style.imageStyle
      )
    },
    onEditAlign (value: string) {
      if (value === this.newsSection.style.align) return
      this.$emit(
        'onEditStyle',
        this.newsSection.id,
        this.newsSection.style.textColour,
        this.newsSection.style.backgroundColour,
        this.newsSection.style.size,
        value,
        this.newsSection.style.imageStyle
      )
    },
    onEditImageStyle (value: string) {
      if (value === this.newsSection.style.imageStyle) return
      this.$emit(
        'onEditStyle',
        this.newsSection.id,
        this.newsSection.style.textColour,
        this.newsSection.style.backgroundColour,
        this.newsSection.style.size,
        this.newsSection.style.align,
        value
      )
    }
  }
})
</script>

<style scoped>

div.news-item-container {
  border-radius: 8px;
  background: var(--Light-Grey, #F1F1F1);
  width: 100%;
  padding: 12px;
}

.news-item-input-item {
    align-items: center;
  }
.news-item-input-label {
  margin: 0 10px 0 22.5px;
  color: var(--Nearly-Black, #212529);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  opacity: 0.5;
  width: 70px;
  text-align: left;
}

div.news-item-container{
  margin: 10px 0 10px 0;
}

div.news-item-input-container {
  height: 126px;
}

div.news-item-delete-button-container {
  width: 40px;
  margin: 0 10px 0 10px;
}

div.news-item-grip-handle-container {
  width: 10px;
  margin: 0 8px 0 0;
}

img.grip-icon {
  height: 16.8px;
  width: 10px;
}

div.news-style-panel-content {
    flex-shrink: 0;
    width: 100%;
    padding: 10px;
  }

  div.news-style-panel-row {
    width: 100%;
    height: 60px;
    border-top: 1px solid rgba(33, 37, 41, 0.1);
  }

  div.news-style-panel-row-group-br {
    height: 100%;
    border-right: 1px solid rgba(33, 37, 41, 0.1);
  }

  p.style-header {
    color: var(--Nearly-Black, #212529);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    opacity: 0.5;
    margin: 0px;
  }

</style>
