<template>
  <TagComponent background-color="#F1F1F1" color="#212529" :style="this.style">
      Unpublished
    </TagComponent>
  </template>

<script lang="ts">
import { defineComponent } from 'vue'
import TagComponent from '@/components/tag/TagComponent.vue'

export default defineComponent({
  name: 'QrUnpublishedTag',
  components: {
    TagComponent
  },
  props: {
    style: {
      type: Object,
      default: null
    }
  }
})
</script>
