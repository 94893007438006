import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7c8f5106"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.text)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "text-container d-flex flex-row justify-content-center px-3",
        style: _normalizeStyle(_ctx.cssVars)
      }, [
        _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.text.content), 1)
      ], 4))
    : _createCommentVNode("", true)
}