<template>
    <div class="fa-icon-button-wrapper" :style="cssVars">
        <fa-icon :class="faIconClass" :icon="faIconIcon"></fa-icon>
    </div>
</template>

<script lang="ts">
import { Palette } from '@/theme'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'FaIconButton',
  props: {
    icon: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: 'xl'
    },
    style: {
      type: Object,
      default: null
    }
  },
  computed: {
    cssVars () {
      return {
        ...this.style
      }
    },
    faIconClass () {
      return 'fa-' + this.size
    },
    faIconIcon () {
      return 'fa-solid ' + 'fa-' + this.icon
    }
  }
})
</script>

<style scoped lang="scss">
    div.fa-icon-button-wrapper {
        background-color: grey;
        color: white;
        transition: all .2s ease-in-out;
        border-radius: 1.5vmin;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    div.fa-icon-button-wrapper:hover{
      transform: scale(1.1);
    }
    div.fa-icon-button-wrapper:active{
      transform: scale(1.05);
      transition: .2s;
    }
    img {
        width: 100%;
        height: 100%;
    }
</style>
