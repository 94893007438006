import Profile from '@/models/Profile'
import SubProfile from '@/models/SubProfile'
import { UserSubProfile } from '@/models/UserSubProfile'
import { createStore } from 'vuex'

const Store = createStore({
  state: {
    token: '',
    verificationEmail: '',
    subProfile: new SubProfile(),
    profile: new Profile(),
    qrScanId: 1,
    accessType: 'scan',
    userSubProfile: new UserSubProfile(),
    previousResponse: null,
    regCode: ''
  },
  getters: {
    getToken (state) {
      return state.token
    },
    getVerificationEmail (state) {
      return state.verificationEmail
    },
    getPreviousResponse (state) {
      return state.previousResponse
    },
    getRegCode (state) {
      return state.regCode
    }
  },
  mutations: {
    setToken (state, token) {
      state.token = token
      localStorage.setItem('token', JSON.stringify(token))
    },
    setCurrentSubProfile (state, subProfile) {
      state.subProfile = subProfile
    },
    setCurrentQrScanId (state, qrScanId) {
      state.qrScanId = qrScanId
    },
    setCurrentAccessType (state, accessType) {
      state.accessType = accessType
    },
    setCurrentUserSubProfile (state, userSubProfile) {
      state.userSubProfile = userSubProfile
    },
    setProfile (state, profile) {
      state.profile = profile
    },
    setVerificationEmail (state, verificationEmail) {
      state.verificationEmail = verificationEmail
    },
    setPreviousResponse (state, response) {
      state.previousResponse = response
    },
    setRegCode (state, regCode) {
      state.regCode = regCode
    }

  },
  actions: {
  },
  modules: {
  }
})

export default Store
