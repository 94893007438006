<template>
  <nav class="navbar navbar-submenu d-flex justify-content-start" :style="cssVars">
    <div class="nav-item-wrapper" v-for="(tab, idx) in this.tabs" :key=idx>
      <a  class="nav-item" href="#" :style="getItemStyleOverride(idx)" @click="() => $emit('selectTab', idx)">{{ tab }}</a>
      <div v-if="idx == this.currentTab" class="nav-item-underline"/>
    </div>
  </nav>
</template>

<script lang="ts">
import Palette from '@/theme/palette'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SubNav',
  props: {
    tabs: {
      type: Array,
      required: true
    },
    currentTab: {
      type: Number,
      default: 0
    }
  },
  methods: {
    getItemStyleOverride (tab: number) {
      if (tab === this.currentTab) return { '--fontWeight': '600', '--background': 'linear-gradient(135deg, #2E75FF 0%, #7A3DFF 99.99%, #7B3DFF 100%)', '--opacity': 1.0 }
      else return { '--fontWeight': '400', '--background': '#212529', '--opacity': 0.5 }
    }
  },
  computed: {
    cssVars () {
      return {
        '--border-color': Palette.light_grey
      }
    }
  }
})
</script>

<style scoped lang="scss">

  nav.navbar-submenu {
    border-bottom: 1px solid rgba(33, 37, 41, .15);
    padding: 0px;
    position: relative;
  }

  div.nav-item-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  a.nav-item {
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    line-height: 14px; /* 100% */
    font-weight: var(--font-weight);
    opacity: var(--opacity);
    background: var(--background);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0 0 18px 0;
    padding: 0 30px 0 0;
  }

  div.nav-item-underline-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: end;
  }

  div.nav-item-underline {
    width: 100%;
    height: 2px;
    background: linear-gradient(106deg, #3F80FF 0%, #7B3DFF 44.51%);
    position: absolute;
    bottom: -1px;
    left: 0;
  }

</style>
