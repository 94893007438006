import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "button-container d-flex flex-row justify-content-center align-items-center",
    style: _normalizeStyle(_ctx.cssVars)
  }, [
    _createElementVNode("p", {
      class: _normalizeClass(_ctx.cssTextClass),
      style: _normalizeStyle(_ctx.cssText)
    }, _toDisplayString(_ctx.text), 7)
  ], 4))
}