<template>
    <div class="cross-button-container" @click="$emit('click')" :style="cssVars">
        <img class="cross-button-image" :src="require('@/assets/icons/svg/cross.svg')">
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CrossButton',
  props: {
    style: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    cssVars () {
      return {
        ...this.style
      }
    }
  }
})
</script>

<style scoped>
    .cross-button-container {
        width: 24px;
        height: 24px;
        cursor: pointer;
    }
    img.cross-button-image {
        width: 100%;
        height: 100%;
    }
</style>
