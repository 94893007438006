import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "d-flex justify-content-center align-items-center button-content",
    style: _normalizeStyle(_ctx.cssVars)
  }, [
    _createElementVNode("p", {
      class: _normalizeClass(_ctx.cssTextClass),
      style: _normalizeStyle(_ctx.cssVarsText)
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 6)
  ], 4))
}