interface LocationScans {
  location: string,
  scans: number
}

export interface AnalyticsInterface {
  totalScans: number,
  dailyScansMonth: number[],
  dailyScansYear: number[],
  dailyScansMonthDates: Array<string>,
  dailyScansYearDates: Array<string>,
  meanScansIpLifetime: number
  meanScansIpMonth: number,
  meanScansIpYear: number,
  scansByLocationMonth: Array<LocationScans>,
  scansByLocationYear: Array<LocationScans>,
  linkClicks: Record<string, number>
}

export class Analytics implements AnalyticsInterface {
  totalScans = 0
  dailyScansMonth = new Array<number>()
  dailyScansYear = new Array<number>()
  dailyScansMonthDates = [] as Array<string>
  dailyScansYearDates = [] as Array<string>
  meanScansIpLifetime = 0
  meanScansIpMonth = 0
  meanScansIpYear = 0
  scansByLocationMonth = new Array<LocationScans>()
  scansByLocationYear = new Array<LocationScans>()
  linkClicks = {} as Record<string, number>
  constructor (analytics?: AnalyticsInterface) {
    if (analytics) {
      this.totalScans = analytics.totalScans
      this.dailyScansMonth = analytics.dailyScansMonth
      this.dailyScansMonthDates = analytics.dailyScansMonthDates
      this.dailyScansYear = analytics.dailyScansYear
      this.dailyScansYearDates = analytics.dailyScansYearDates
      this.meanScansIpLifetime = analytics.meanScansIpLifetime
      this.meanScansIpMonth = analytics.meanScansIpMonth
      this.meanScansIpYear = analytics.meanScansIpYear
      this.scansByLocationMonth = analytics.scansByLocationMonth
      this.scansByLocationYear = analytics.scansByLocationYear
      this.linkClicks = analytics.linkClicks
    }
  }

  getMostClickedDetailId () {
    let currentKey = ''
    let currentValue = 0
    Object.entries(this.linkClicks).forEach(([key, value]) => {
      if (value > currentValue) {
        currentKey = key
        currentValue = value
      }
    })
    return currentKey
  }
}
