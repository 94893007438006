<template>
    <div class="d-flex justify-content-center align-items-center button-content" :style="cssVars">
        <p :class="cssTextClass" :style="cssVarsText"><slot/></p>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import { Palette } from '@/theme'

export default defineComponent({
  name: 'StyleTextButton',
  props: {
    style: {
      type: Object,
      default: null
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    cssVars () {
      if (this.selected) {
        return {
          ...this.style,
          background: 'linear-gradient(135deg, rgba(46, 117, 255, 0.10) 0%, rgba(122, 61, 255, 0.10) 99.99%, rgba(123, 61, 255, 0.10) 100%)'
        }
      } else {
        return {
          ...this.style
        }
      }
    },
    cssVarsText () {
      if (this.selected) {
        return {
          backgroundImage: Palette.gradientBP,
          backgroundClip: 'text',
          backgroundSize: '100%',
          textFillColor: 'transparent'
        }
      } else {
        return {
          color: 'var(--Nearly-Black, #212529);'
        }
      }
    },
    cssTextClass () {
      const baseClass = 'button-text'
      return this.selected ? baseClass + ' text-gradient' : baseClass
    }
  }
})
</script>

<style scoped lang="scss">
  div.button-content {
      cursor: pointer;
      width: 132px;
      height: 40px;
      border-radius: 8px;
      margin: 0 0 0 10px;
      background: var(--Light-Grey, #F1F1F1);
  }

  p.button-text {
    margin: 0px;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px; /* 100% */
  }

  p.text-gradient {
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }
</style>
