<template>
    <div>
        <p :style="cssVars"><img :src="require(`@/assets/${image}`)"/>
        {{ text }}</p>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'IconLabel',
  props: {
    text: String,
    image: String,
    color: String
  },
  computed: {
    cssVars () {
      return {
        '--color': this.color
      }
    }
  }
})
</script>

<style scoped lang="scss">
    div{
        width: 100%;
        height: 100%;
        flex-direction: row;
    }
    img{
        height: 75%;
        aspect-ratio: 1 / 1;
    }
    p{
        font-size: 14px;
        color: var(--color);
        height: 100%;
        width: 100%;
        flex-direction: row;
        margin-bottom: 0px;
    }
</style>
