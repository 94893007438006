<template>
    <DropdownButton
      btn-icon="plus"
      btn-text="Add Section"
      :btn-style="cssButton"
      :link-container-style="cssLinkContainer"
      :items="items"
      @onClickLink="onClickLink"
      />
</template>

<script lang="ts">

import { defineComponent } from 'vue'

import DropdownButton from './DropdownButton.vue'
import { Palette } from '@/theme'

export default defineComponent({
  name: 'DropdownQrAddSection',
  components: {
    DropdownButton
  },
  data () {
    return {
      items: [
        {
          icon: 'i-cursor',
          text: 'Text',
          subItems: []
        },
        {
          icon: 'diagram-project',
          text: 'Social Links',
          subItems: []
        },
        {
          icon: 'table-cells-large',
          text: 'Table',
          subItems: []
        },
        {
          icon: 'table-cells-large',
          text: 'Features',
          subItems: []
        },
        {
          icon: 'table-cells-large',
          text: 'Amazon Review CTA',
          subItems: []
        },
        {
          icon: 'table-cells-large',
          text: 'Link',
          subItems: []
        },
        {
          icon: 'table-cells-large',
          text: 'Button',
          subItems: []
        },
        {
          icon: 'table-cells-large',
          text: 'Embedded Video',
          subItems: []
        },
        {
          icon: 'table-cells-large',
          text: 'Socials',
          subItems: []
        },
        {
          icon: 'table-cells-large',
          text: 'News',
          subItems: []
        }
      ]
    }
  },
  methods: {
    onClickLink (id: number) {
      switch (id) {
        case 0:
          this.$emit('onClickQrSectionText')
          break
        case 1:
          this.$emit('onClickQrSectionDetails')
          break
        case 2:
          this.$emit('onClickQrSectionTable')
          break
        case 3:
          this.$emit('onClickQrSectionFeatures')
          break
        case 4:
          this.$emit('onClickQrSectionAmazonReviewCTA')
          break
        case 5:
          this.$emit('onClickQrSectionLink')
          break
        case 6:
          this.$emit('onClickQrSectionButton')
          break
        case 7:
          this.$emit('onClickQrSectionEmbeddedVideo')
          break
        case 8:
          this.$emit('onClickQrSectionSocials')
          break
        case 9:
          this.$emit('onClickQrSectionNews')
          break
      }
    }
  },
  computed: {
    cssButton () {
      return {
        backgroundImage: Palette.gradientBP,
        color: Palette.white,
        borderRadius: '1em',
        padding: '1em',
        width: '75%'
      }
    },
    cssLinkContainer () {
      return {
        justifySelf: 'flex-start'
      }
    }
  }
})
</script>

<style scoped>
</style>
