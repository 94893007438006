<template>
  <div :style="cssVarsContainer">
    <label v-if="label" for="text-input">{{ label }}</label>
    <div v-if="!multiline" class="text-input-container" :style="cssVarsWrapper">
      <input
        ref="textInput"
        id="text-input"
        class="text-input"
        :type="type"
        :name="name"
        :placeholder="placeholder"
        :value="internalValue"
        @input="updateInternalValue"
        v-on:focus="handleFocus"
        v-on:blur="handleBlur"
        :style="cssVarsInput"/>
    </div>
    <div v-else class="text-area-container d-flex">
      <textarea
        ref="textInput"
        class="text-input d-flex"
        :maxlength="maxlength"
        :placeholder="placeholder"
        :value="internalValue"
        @input="updateInternalValue"
        :style="cssVarsInput"
        />
    </div>
    <p v-if="multiline && maxlength != 'none'">{{ value?.length + '/' + maxlength }}</p>
  </div>
</template>

<script lang="ts">

import { defineComponent, nextTick } from 'vue'

import { debounce as _debounce } from 'lodash'

export default defineComponent({
  name: 'DebouncedTextInput',
  props: {
    label: String,
    maxlength: {
      type: String,
      default: 'none'
    },
    multiline: Boolean,
    name: String,
    placeholder: String,
    value: String,
    debounce: {
      type: Number,
      default: 600
    },
    type: { type: String, default: 'text' },
    containerStyle: {
      type: Object,
      default: () => ({})
    },
    wrapperStyle: {
      type: Object,
      default: () => ({})
    },
    inputStyle: {
      type: Object,
      default: () => ({})
    },
    borderOnFocus: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      internalValue: this.value,
      touched: false,
      isFocused: false
    }
  },
  computed: {
    cssVarsContainer () {
      return {
        ...this.containerStyle
      }
    },
    cssVarsWrapper () {
      if (this.borderOnFocussed && this.isFocused) {
        return {
          ...this.wrapperStyle,
          border: this.wrapperStyle.focusedBorder,
          'background-color': this.wrapperStyle.focusedBackgroundColor
        }
      } else {
        return {
          ...this.wrapperStyle
        }
      }
    },
    cssVarsInput () {
      return {
        ...this.inputStyle
      }
    }
  },
  watch: {
    value (value) {
      if (!this.touched) this.internalValue = value
    }
  },
  methods: {
    focus () {
      this.$refs.textInput.focus()
    },
    updateInternalValue (event) {
      this.touched = true
      this.updateValue(event.target.value)
    },
    updateValue: _debounce(function (value) {
      this.touched = false
      this.$emit('update:value', value)
    }, 600),
    handleFocus () {
      this.isFocused = true
    },
    handleBlur () {
      this.isFocused = false
    }
  }
})

</script>

<style scoped>

div {
  width: 100%;
}
.text-input-container {
  background-color: white;
  border-radius: 8px;
  border: 1px solid rgba(33, 37, 41, 0.15);
  width:  100%;
  min-height: 36px;
  padding: 0 0 0 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.text-area-container {
  background-color: white;
  border: 1px solid rgba(33, 37, 41, 0.15);
  border-radius: 8px;
  width:  100%;
  min-height: 36px;
  padding: 0 0 0 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.text-input {
  background-color: transparent;
  border: none;
  outline: none;
  width:  100%;
  color: var(--Nearly-Black, #212529);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
  width: 90%;
  float: left;
  height: 100%;
}

textarea{
  aspect-ratio: 6/1;
  resize: none;
}

label {
  float: left;
  font-size: 0.75rem;
  padding-left: 0.1rem;
}

p {
  margin: 0;
  padding: 0;
  text-align: right;
  font-size: 0.75rem;
}

</style>
