<template>
    <EditorPanel :title="detailSection.label" :active="detailSection.active" :persistent="detailSection.persistent" @onSetActive="onSetActive" @onDelete="onDelete">
      <SlickList axis="y" v-model:list="details" @update:list="onChangeDetailOrder" :useDragHandle="true">
              <SlickItem v-for="(detail, i) in details" :key="detail" :index="i">
                <div class="d-flex flex-direction-row detail-container align-items-center my-2">
                  <img class="detail-type-icon mx-3" :src="require(`@/assets/${getIcon(detail.type)}`)"/>
                  <p class="detail-type-label">{{ detail.getType() }}</p>
                  <div class="detail-value-input-container">
                    <DebouncedTextInput
                      :value="detail.getValue()"
                      type="text"
                      placeholder="title"
                      @update:value="(value: string) => $emit('onEditDetail', detail.id, detail.category, detail.type, detail.label, JSON.stringify({value: value}), detailSection.id)"
                      />
                  </div>
                  <div class="detail-delete-button-container">
                    <FaIconButton
                      icon="xmark"
                      background-color="transparent"
                      color="netural800"
                      @click="() => $emit('onDeleteDetail', detail.id, detailSection.id)"
                      :style="cssDeleteDetailButton"
                      />
                  </div>
                  <div class="detail-grip-handle-container">
                    <span v-handle class="handle"><a href="#"><img class="grip-icon" :src="require('@/assets/icons/icon-grip-1.png')"></a></span>
                  </div>
                </div>
            </SlickItem>
        </SlickList>
        <div class="my-2 align-self-start">
            <FaIconLink
              icon="plus"
              size="md"
              text="Add Social Link"
              :icon-container-style="cssAddIcon"
              :text-style="cssAddText"
              :text-uses-gradient="true"
              @click="() => $emit('onAddLink')"
              />
          </div>
      </EditorPanel>
</template>

<script lang="ts">

import { defineComponent } from 'vue'

import DebouncedTextInput from '../input/DebouncedTextInput.vue'
import EditorPanel from '@/components/editor/EditorPanel.vue'
import FaIconButton from '../button/FaIconButton.vue'
import FaIconLink from '../button/FaIconLink.vue'

import DetailSection from '@/models/qr-sections/UserDetailSection'
import { Detail } from '@/models/Detail'
import { SlickItem, SlickList, HandleDirective } from 'vue-slicksort'
import { DETAIL_TYPES } from '@/constants'
import { Palette } from '@/theme'

export default defineComponent({
  name: 'DetailPanel',
  components: {
    DebouncedTextInput,
    EditorPanel,
    FaIconButton,
    FaIconLink,
    SlickItem,
    SlickList
  },
  props: {
    detailSection: {
      type: DetailSection,
      required: true
    }
  },
  data () {
    return {
      details: this.detailSection.details,
      drag: false
    }
  },
  computed: {
    cssVarsContainer () {
      return {
        ...this.containerStyle
      }
    },
    cssAddIcon () {
      return {
        backgroundImage: Palette.gradientBP,
        width: '1.95em',
        borderRadius: '0.975em',
        padding: '0.5em',
        color: Palette.white
      }
    },
    cssAddText () {
      return {
        backgroundImage: Palette.gradientBP,
        backgroundClip: 'text',
        backgroundSize: '100%',
        textFillColor: 'transparent'
      }
    },
    cssDeleteDetailButton () {
      return {
        width: '30px',
        height: '30px'
      }
    }
  },
  directives: { handle: HandleDirective },
  watch: {
    detailSection (detailSection) {
      if (detailSection) this.details = detailSection.details
    }
  },
  methods: {
    getIcon (type: string): string {
      return DETAIL_TYPES[type].icon
    },
    onSetActive (value: boolean) {
      this.$emit('onEdit', this.detailSection.id, this.detailSection.label, this.detailSection.listOrder, value)
    },
    onChangeDetailOrder (details : Array<Detail>) {
      this.drag = false
      this.$emit('onChangeDetailOrder', this.detailSection.id, details)
    },
    onDelete () {
      this.$emit('onDelete', this.detailSection.id)
    }
  }
})
</script>

<style scoped>

div.detail-container {
  background-color: lightgray;
  border-radius: 1em;
  width: 100%;
  height: 4em;
}

img.detail-type-icon {
  width: 3.75%;
  aspect-ratio: 1;
}
p.detail-type-label {
  width: 25%;
  padding: 0em 0em 0em 2em;
  margin: 0;
  text-align: left;
}

div.detail-value-input-container {
  width: 65.25%;
  margin: 0em 1em 0em 1em;
}

div.detail-delete-button-container {
  width: 8%;
}

div.detail-grip-handle-container {
  width: 8%;
}

img.grip-icon {
  height: 1.5em;
  aspect-ratio: 0.75;
  margin-right: 0.75em;
}

</style>
