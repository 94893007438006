<template>
  <div v-if="this.visible" class="mask"/>
  <div ref="content" class="dropdown-qr-view-download-container">
      <SvgButton @click="toggle" v-bind:icon="this.srcBtnImg" size="32" v-bind:style="{...this.btnStyle, background: this.btnBackground}"/>
      <div v-if="this.visible" class="dropdown-content">
          <div class="qr-container">
             <img class="qr-code" :src="this.qrUri" />
          </div>
          <SvgLink @click="this.$emit('onDownload', 'png')" icon="download-white" :style="{background: '#212529', margin: '20px 0 10px 0'}">Download PNG</SvgLink>
          <SvgLink @click="this.$emit('onDownload', 'svg')" icon="download-white" :style="{background: '#212529'}">Download SVG</SvgLink>
      </div>
  </div>
</template>

<script lang="ts">

import { defineComponent } from 'vue'

import SvgButton from '@/components/button/SvgButton.vue'
import SvgLink from '@/components/button/SvgLink.vue'

export default defineComponent({
  name: 'DropdownQrViewDownload',
  components: {
    SvgButton,
    SvgLink
  },
  props: {
    qrUri: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      visible: false,
      srcBtnImg: 'qr-grad',
      btnBackground: '#FFFFFF'
    }
  },
  methods: {
    toggle () {
      this.visible = !this.visible

      this.btnBackground = this.visible ? 'linear-gradient(135deg, #2E75FF 0%, #7A3DFF 99.99%, #7B3DFF 100%)' : '#FFFFFF'
      this.srcBtnImg = this.visible ? 'qr-white' : 'qr-grad'
    },
    handleClickOutside (event) {
      if (
        this.$refs.content &&
        !this.$refs.content.contains(event.target)
      ) {
        this.visible = false

        this.btnBackground = '#FFFFFF'
        this.srcBtnImg = 'qr-grad'
      }
    }
  },
  mounted () {
    document.addEventListener('click', this.handleClickOutside)
  },
  beforeUnmount () {
    document.removeEventListener('click', this.handleClickOutside)
  }
})
</script>

<style scoped>
  div.dropdown-qr-view-download-container {
    position: relative;
  }
  div.dropdown-content {
    position: absolute;
    top: 42px;
    left: -228px;
    min-width: 260px;
    flex-shrink: 0;
    padding: 20px;
    background-color: white;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    z-index: inherit;
  }

  div.qr-container {
    border-bottom: 1px solid rgba(0, 0, 0, 0.20);
    padding: 0 45px 20px 45px;
  }
  img.qr-code {
    width: 100%;
    aspect-ratio: 1/1;
  }

  div.mask {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    background-color: grey;
    opacity: 0.5;
  }
</style>
