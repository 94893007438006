<template>
      <div class="content-page">
        <div v-if="this.analytics" class="container analytics-content">
          <div class="select-container mb-3">
            <div class="mb-3">
              <h2 class>{{ analytics.name ? analytics.name : 'Account Analytics'}}</h2>
            </div>
            <div class="row">
              <div class="col-md-7 col-12">
                <SelectComponent label="Select QR" :value="this.selectedSubProfileLabel" :options="subProfileAnalytics.map(spa => spa.name)" @onChange="onSelectSubProfile"/>
              </div>
              <div class="col-md-3 col-12">
                <a v-if="analytics.name" class="btn btn-primary" @click="onSelectAccountAnalytics">Account Analytics</a>
              </div>
              <div class="col-2">
                <a href="#" class="btn btn-secondary" @click="onDownloadAnalCsv">Download CSV</a>
              </div>
            </div>
            <div v-if="analytics.name" class="row date-selection-container">
              <div class="col-3">
                <label>From</label>
                <VueDatePicker v-model="dateStart"/>
              </div>
              <div class="col-1"/>
              <div class="col-3">
                <label>To</label>
                <VueDatePicker v-model="dateEnd"/>
              </div>
              <div class="col-1"/>
              <div class="col-2">
                <a class="btn btn-secondary" @click="onSelectDateRange">Update</a>
              </div>
              <div class="col-2">
              </div>
            </div>
          </div>
          <div class="row">
              <div class="col-md-6 col-xl-3">
                <div class="widget-rounded-circle card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-6">
                        <div class="avatar-lg rounded-circle bg-soft-primary border-primary border">
                          <fa-icon icon="fa-solid fa-qrcode" size="xl"></fa-icon>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="text-end">
                         <h3 class="text-dark mt-1"><CountUp :end-val="analytics.totalScansLifetime" :duration="1.0"/></h3>
                         <p class="text-muted mb-1 text-truncate">Total Scans</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-xl-3">
                <div class="widget-rounded-circle card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-6">
                        <div class="avatar-lg rounded-circle bg-soft-success border-success border">
                          <fa-icon icon="fa-solid fa-arrow-pointer" size="xl"></fa-icon>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="text-end">
                         <h3 class="text-dark mt-1"><CountUp :end-val="analytics.totalLinkClicksLifetime" :duration="1.0"/></h3>
                         <p class="text-muted mb-1 text-truncate">Total Clicks</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-xl-3">
                <div class="widget-rounded-circle card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-6">
                        <div class="avatar-lg rounded-circle bg-soft-warning border-warning border">
                          <fa-icon icon="fa-solid fa-clock" size="xl"></fa-icon>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="text-end">
                         <h3 class="text-dark mt-1"><CountUp :end-val="analytics.averageClickTime" :duration="1.0"/></h3>
                         <p class="text-muted mb-1 text-truncate">Avg. Time To Click</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-xl-3">
                <div class="widget-rounded-circle card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-6">
                        <div class="avatar-lg rounded-circle bg-soft-info border-info border">
                          <fa-icon icon="fa-solid fa-calendar-day" size="xl"></fa-icon>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="text-end">
                         <h3 class="text-dark mt-1"><CountUp :end-val="analytics.totalScansToday" :duration="1.0"/></h3>
                         <p class="text-muted mb-1 text-truncate">Scans Today</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            <div class="col-xl-6">
               <div v-if="selectedSubProfileLabel != ''" class="card">
                  <div class="card-body">
                    <h4 class="header-title mb-0">Top Performing Links</h4>
                    <div id="cardCollpase5" class="collapse show">
                      <div class="table-responsive pt-3">
                        <table class="table table-hover table-centered mb-0">
                          <thead>
                           <tr>
                              <th>Link Name</th>
                              <th>Total Clicks</th>
                           </tr>
                          </thead>
                           <tbody>
                            <tr v-for="link in this.analytics.topLinks" :key="link.id">
                              <td>{{ link.name }}</td>
                              <td>{{ link.totalClicks }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div> <!-- end table responsive-->
                    </div> <!-- collapsed end -->
                  </div> <!-- end card-body -->
                </div> <!-- end card-->
                <div v-else class="card">
                  <div class="card-body">
                    <h4 class="header-title mb-0">Top Performing QRs</h4>
                    <div id="cardCollpase5" class="collapse show">
                      <div class="table-responsive pt-3">
                        <table class="table table-hover table-centered mb-0">
                          <thead>
                           <tr>
                              <th>QR Name</th>
                              <th>Total Scans</th>
                              <th>Total Clicks</th>
                           </tr>
                          </thead>
                           <tbody>
                            <tr v-for="qr in this.analytics.topQrs" :key="qr.id">
                              <td>{{ qr.name }}</td>
                              <td>{{ qr.totalScans }}</td>
                              <td>{{ qr.totalClicks }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div> <!-- end table responsive-->
                    </div> <!-- collapsed end -->
                  </div> <!-- end card-body -->
                </div> <!-- end card-->
              </div> <!-- end col -->
            <div class="col-xl-6">
              <div class="card">
                <div class="card-body">
                  <h4 class="header-title mb-0">Top Locations</h4>
                  <apexchart v-if="loaded" type="donut" :options="this.locationChart.options" :series="this.locationChart.series"></apexchart>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div v-if="analytics.name" class="col-xl-12">
              <div class="card">
                  <div class="card-body">
                    <h4 class="header-title mb-0">Conversion Trackers</h4>
                    <div id="cardCollpase5" class="collapse show">
                      <div class="table-responsive pt-3">
                        <table class="table table-hover table-centered mb-0">
                          <thead>
                           <tr>
                              <th>Name</th>
                              <th>Total Conversions</th>
                              <th>Conversion Rate</th>
                           </tr>
                          </thead>
                           <tbody>
                            <tr v-for="conversion in this.analytics.conversions" :key="conversion.id">
                              <td>{{ conversion.name }}</td>
                              <td>{{ conversion.totalConversions }}</td>
                              <td>{{ conversion.conversionRate + "%"}}</td>
                              <td style="width: 20%">
                                <p>Paste snippet right after opening body tag of page you want to track</p>
                                <p  class="tracker-code-snippet">{{ "<img width=\"1\" height=\"1\" style=\"display: none\" src=\"https://www.keypin.tech:8000/profile/pixel/" + String(conversion.id) + "\"/>"}}</p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div> <!-- end table responsive-->
                      <div class="row new-tracker-input-container">
                        <div class="col-9">
                          <TextInput v-model="newTrackerName" class="new-tracker-input" type="email" placeholder="Name..."/>
                        </div>
                        <div class="col-3">
                         <btn backgroundColor="green" @click="onSubmitTracker">Create</btn>
                        </div>
                      </div>
                    </div> <!-- collapsed end -->
                  </div> <!-- end card-body -->
                </div> <!-- end card-->
            </div>
          </div>
        </div>
      </div>
  </template>

<script lang="ts">
import { defineComponent } from 'vue'

import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

import btn from '@/components/button/Button.vue'
import NavBar from '@/components/nav/NavUboldMig.vue'
import TextInput from '@/components/input/TextInput.vue'

import { fetchAnalyticsDashboard, fetchAnalyticsDateRange, getAnalCsvDownload, postTracker } from '@/api'

import CountUp from 'vue-countup-v3'

import Profile from '@/models/Profile'
import SubProfileAnalytics from '@/models/analytics/SubProfileAnalytics'
import ProfileAnalytics from '@/models/analytics/ProfileAnalytics'
import SelectComponent from '@/components/select/SelectComponent.vue'

export default defineComponent({
  name: 'AnalyticsView',
  components: {
    btn,
    CountUp,
    SelectComponent,
    TextInput,
    VueDatePicker
  },
  data () {
    return {
      subProfileAnalytics: new Array<SubProfileAnalytics>(),
      analytics: new SubProfileAnalytics(),
      selectedSubProfileLabel: '',
      isSubProfileAnalytics: false,
      profileAnalytics: new ProfileAnalytics(),
      profile: new Profile(),
      loaded: false,
      locationChart: {
        series: [],
        options: {
          labels: [],
          dataLabels: {
            enabled: true
          }
        }
      },
      newTrackerName: '',
      dateStart: null,
      dateEnd: null
    }
  },
  methods: {
    onSelectSubProfile (v) {
      this.loaded = false
      this.analytics = this.subProfileAnalytics.find(spa => spa.name === v)
      this.locationChart.series = this.analytics.topLocations.map(s => s.totalScans)
      this.locationChart.options.labels = this.analytics.topLocations.map(s => s.location)
      this.selectedSubProfileLabel = this.analytics.name
      this.$forceUpdate()
      this.loaded = true
    },
    onSelectAccountAnalytics () {
      this.loaded = false
      this.analytics = this.profileAnalytics
      this.locationChart.series = this.analytics.topLocations.map(s => s.totalScans)
      this.locationChart.options.labels = this.analytics.topLocations.map(s => s.location)
      this.selectedSubProfileLabel = ''
      this.$forceUpdate()
      this.loaded = true
    },
    onSelectDateRange () {
      let dateStartTrim = ''
      let dateEndTrim = ''
      if (this.dateStart != null && this.dateEnd != null) {
        dateStartTrim = JSON.stringify(this.dateStart).split('T')[0].replace('"', '')
        dateEndTrim = JSON.stringify(this.dateEnd).split('T')[0].replace('"', '')
      }

      fetchAnalyticsDateRange(
        this.analytics.id,
        dateStartTrim,
        dateEndTrim,
        this.$store.state.token,
        () => this.$router.push('/login'),
        () => null,
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response : any) => {
          this.analytics = new SubProfileAnalytics(response.data.data)

          this.locationChart.series = this.analytics.topLocations.map(s => s.totalScans)
          this.locationChart.options.labels = this.analytics.topLocations.map(s => s.location)
          this.loaded = true
        }
      )
    },

    onSubmitTracker () {
      postTracker(
        this.newTrackerName,
        this.analytics.id,
        this.$store.state.token,
        () => this.$router.push('/login'),
        () => null,
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response : any) => {
          this.profileAnalytics = new ProfileAnalytics(response.data.data.profileAnalytics)
          this.subProfileAnalytics = response.data.data.subProfileAnalytics.map(spa => new SubProfileAnalytics(spa))
          this.profile = new Profile(response.data.data.userProfile)

          this.analytics = this.subProfileAnalytics.find(spa => spa.name === this.analytics.name)

          this.locationChart.series = this.analytics.topLocations.map(s => s.totalScans)
          this.locationChart.options.labels = this.analytics.topLocations.map(s => s.location)
          this.loaded = true
        }
      )
    },
    onDownloadAnalCsv () {
      getAnalCsvDownload(
        this.profile.keypin,
        () => this.$router.push('/login'),
        () => null,
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response) => {
          const href = URL.createObjectURL(response.data)
          const link = document.createElement('a')
          link.href = href
          link.setAttribute('download', 'keypin-analytics.csv')
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          URL.revokeObjectURL(href)
        })
    }
  },
  mounted () {
    fetchAnalyticsDashboard(
      this.$store.state.token,
      () => this.$router.push('/login'),
      () => this.$router.push('/not-found'),
      () => this.$router.back(),
      () => this.$router.push('/server-error'),
      (response) => {
        this.profileAnalytics = new ProfileAnalytics(response.data.data.profileAnalytics)
        this.subProfileAnalytics = response.data.data.subProfileAnalytics.map(spa => new SubProfileAnalytics(spa))
        this.profile = new Profile(response.data.data.userProfile)

        this.analytics = this.profileAnalytics

        this.locationChart.series = this.analytics.topLocations.map(s => s.totalScans)
        this.locationChart.options.labels = this.analytics.topLocations.map(s => s.location)
        this.loaded = true
      })
  }
})
</script>

<style scoped lang="scss">
  div.analytics-content {
    margin-top: 2rem;
  }
  div.avatar-lg {
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items:center;
  }

  div.select-container {
    width: 100%;
  }

  div.date-selection-container {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  label {
    float: left;
    font-size: 0.75rem;
    padding-left: 0.1rem;
  }

  div.new-tracker-input-container {
    margin-top: 2rem;
  }

  p.tracker-code-snippet {
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: black;
    color: white;
  }
</style>
