<template>
  <SvgLink
    icon="plus"
    :style="{
      width: '167px',
      height: '40px',
      borderRadius: '8px',
      background: 'linear-gradient(90deg, #3F80FF 0%, #7B3DFF 100%)'
    }"
    :icon-style="{
      width: '14px',
      height: '14px'
    }"
    :text-style="{
      fontSize: '14px'
    }">
    Create New QR
  </SvgLink>
</template>

<script lang="ts">

import { defineComponent } from 'vue'
import SvgLink from '@/components/button/SvgLink.vue'

export default defineComponent({
  name: 'ManageCreateQrButton',
  components: {
    SvgLink
  }
})
</script>

<style scoped>
</style>
