<template>
    <div v-if="detailSection && theme" class="body-container">
        <DetailButtonFrame
         v-for="detail in detailSection.details"
         :key="detail.id" :detail="detail"
         :type="buttonStyle"
         :color="theme.buttonTheme.backgroundColour"
         :leftColour="theme.buttonTheme.secondaryColour"
         :rightColour="theme.buttonTheme.backgroundColour"
         :textColour="theme.buttonTheme.textColour"
         :content-active="activeContentId == detail.id && contentActive"
         @onClick="setActiveContent"
         />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import DetailButtonFrame from '@/components/button/detail-button/DetailButtonFrame.vue'

import UserDetailSection from '@/models/qr-sections/UserDetailSection'
import { SubProfileTheme } from '@/models/theme/SubProfileTheme'

export default defineComponent({
  name: 'DetailSection',
  props: {
    detailSection: UserDetailSection,
    theme: SubProfileTheme,
    contentActive: Boolean,
    buttonStyle: {
      type: String,
      default: 'normal'
    }
  },
  data () {
    return {
      activeContentId: ''
    }
  },
  methods: {
    setActiveContent (id: string) {
      console.log('SAC ' + id)
      const detail = this.detailSection.details.find(d => d.id === id)
      if (!detail) {
        this.activeContentId = ''
      } else if (this.activeContentId === id) {
        this.activeContentId = ''
      } else if (detail.type !== 'video') {
        this.$emit('onDetailClick', id, this.detailSection.id, false)
      } else {
        this.activeContentId = id
        this.$emit('onDetailClick', id, this.detailSection.id, true)
      }
    }
  },
  components: { DetailButtonFrame }
})
</script>

<style scoped lang="scss">
    div.body-container{
        width: 100%;
        margin-top: 20%;
        align-self: flex-start;
        flex-direction: row;
        word-wrap: break-word;
        text-align: left;
    }
</style>
