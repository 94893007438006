import { authorisedPost } from './Api'

export function postNewsItem (
  sectionId: number,
  subProfileId: string,
  token: string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  authorisedPost(
      `dashboard/${subProfileId}/create-news-item?section=${sectionId}`,
      {},
      token,
      unauthorisedCallback,
      notFoundCallback,
      badRequestCallback,
      notFoundCallback,
      callback
  )
}

export function editNewsItem (
  id: number,
  title: string,
  subTitle: string,
  url: string,
  sectionId: number,
  subProfileId: string,
  token: string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  authorisedPost(
    `dashboard/${subProfileId}/edit-news-item?section=${sectionId}&news=${id}`,
    { title, subTitle, url },
    token,
    unauthorisedCallback,
    notFoundCallback,
    badRequestCallback,
    notFoundCallback,
    callback
  )
}

export function editNewsItemImage (
  image: File,
  itemId: number,
  sectionId: number,
  subProfileId: string,
  token: string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  const data = new FormData()
  data.append('file', image)
  authorisedPost(
    `dashboard/${encodeURIComponent(subProfileId)}/edit-news-item-image?section=${encodeURIComponent(sectionId)}&news=${encodeURIComponent(itemId)}`,
    data,
    token,
    unauthorisedCallback,
    notFoundCallback,
    badRequestCallback,
    serverErrorCallback,
    callback
  )
}

export function deleteNewsItem (
  id: number,
  sectionId: number,
  subProfileId: string,
  token: string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  authorisedPost(
    `dashboard/${subProfileId}/delete-news-item?section=${sectionId}&news=${id}`,
    {},
    token,
    unauthorisedCallback,
    notFoundCallback,
    badRequestCallback,
    notFoundCallback,
    callback
  )
}

export function postNewsItemOrder (
  value: Array<number>,
  sectionId: number,
  subProfileId: string,
  token: string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  authorisedPost(
    `dashboard/${subProfileId}/edit-news-order?section=${sectionId}`,
    { value },
    token,
    unauthorisedCallback,
    notFoundCallback,
    badRequestCallback,
    notFoundCallback,
    callback
  )
}
