<template>
    <div class="select-view-container" :style="cssVarsContainer">
      <label class="select-view-label" for="select-view">View as</label>
      <div id="select-view" class="select-view-wrapper" :style="cssVarsComponentContainer">
        <div class="select-view-button" :style="cssVarsButtonGrid" @click="() => this.value = true">
            <img class="select-view-button-icon" :src="require('@/assets/icons/svg/grid.svg')"/>
        </div>
        <div class="select-view-button" :style="cssVarsButtonList" @click="() => this.value = false">
          <img class="select-view-button-icon" :src="require('@/assets/icons/svg/list.svg')"/>
        </div>
      </div>
    </div>
  </template>

<script lang="ts">

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SelectView',
  props: {
    options: Object,
    label: String,
    modelValue: Boolean,
    layout: {
      type: String,
      default: 'column'
    },
    containerStyle: {
      type: Object,
      default: null
    },
    style: {
      type: Object,
      default: null
    }
  },
  emits: ['update:modelValue'],
  computed: {
    value: {
      get () {
        return this.modelValue
      },
      set (value : boolean) {
        this.$emit('update:modelValue', value)
      }
    },
    cssVarsContainer () {
      return {
        '--container-flex-direction': this.layout,
        ...this.containerStyle
      }
    },
    cssVarsComponentContainer () {
      return {
        '--component-container-width': this.layout === 'row' ? '70%' : '100%',
        ...this.style
      }
    },
    cssVarsButtonGrid () {
      return {
        '--button-background-color': this.value ? 'white' : '#F1F1F1',
        ...this.style
      }
    },
    cssVarsButtonList () {
      return {
        '--button-background-color': !this.value ? 'white' : '#F1F1F1',
        ...this.style
      }
    }
  }
})

</script>

<style scoped>

  div.select-view-container {
    display: flex;
    flex-direction: var(--container-flex-direction);
    align-items: center;
  }

  label.select-view-label {
    color: var(--Nearly-Black, #212529);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 107.692% */
    opacity: 0.5;
    margin-right: 10px;
  }

  div.select-view-wrapper {
    display: flex;
    flex-direction: row;
  }
  div.select-view-button {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    cursor: pointer;
    border-radius: 8px;
    border: 1px solid rgba(33, 37, 41, 0.10);
    background: var(--button-background-color);
    margin-right: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img.select-view-button-icon {
    width: 20px;
    height: 20px;
  }

</style>
