<template>
    <div class="svg-icon-link-wrapper" :style="cssVars">
        <img :src="imgSrc" alt="icon" :style="cssVarsIcon">
        <p class="svg-icon-link-text" :style="cssVarsText"><slot/></p>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SvgLink',
  props: {
    icon: {
      type: String,
      required: true
    },
    style: {
      type: Object,
      default: null
    },
    textStyle: {
      type: Object,
      default: null
    },
    iconStyle: {
      type: Object,
      default: null
    }
  },
  computed: {
    cssVars () {
      return {
        ...this.style
      }
    },
    cssVarsText () {
      return {
        ...this.textStyle
      }
    },
    cssVarsIcon () {
      return {
        ...this.iconStyle
      }
    },
    imgSrc () {
      return require('@/assets/icons/svg/' + this.icon + '.svg')
    }
  }
})
</script>

<style scoped lang="scss">
    div.svg-icon-link-wrapper {
      width: 220px;
      height: 40px;
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      gap: 8px;
      border-radius: 8px;
      background: #F1F1F1;
      transition: all .2s ease-in-out;
      cursor: pointer;
    }
    div.svg-icon-link-wrapper:hover{
      transform: scale(1.1);
    }
    div.svg-icon-link-wrapper:active{
      transform: scale(1.05);
      transition: .2s;
    }
    img {
        width: 20px;
        height: 20px;
    }
    p.svg-icon-link-text {
      color: var(--White, #FFF);
      font-family: Inter;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px; /* 107.692% */
      margin: 0
    }
</style>
