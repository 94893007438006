import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DropdownButton = _resolveComponent("DropdownButton")!

  return (_openBlock(), _createBlock(_component_DropdownButton, {
    "btn-icon": "plus",
    "btn-text": "Add Section",
    "btn-style": _ctx.cssButton,
    "link-container-style": _ctx.cssLinkContainer,
    items: _ctx.items,
    onOnClickLink: _ctx.onClickLink
  }, null, 8, ["btn-style", "link-container-style", "items", "onOnClickLink"]))
}