interface AmazonReviewCTASectionStyleInterface {
    theme: string
  }

interface AmazonReviewCTASectionInterface {
    id: number
    label: string
    listOrder: number,
    active: boolean,
    persistent: boolean,
    labelVisible: boolean,
    title: string,
    subtitle: string,
    btnText: string,
    amazonUrl: string,
    style: AmazonReviewCTASectionStyleInterface
}

class AmazonReviewCTASectionStyle implements AmazonReviewCTASectionStyleInterface {
  theme = ''

  constructor (style?: AmazonReviewCTASectionStyleInterface) {
    if (style) {
      this.theme = style.theme
    }
  }
}

export default class AmazonReviewCTASection implements AmazonReviewCTASectionInterface {
  id = 0
  label = ''
  listOrder = 0
  active = false
  persistent = false
  labelVisible = false
  title = ''
  subtitle = ''
  btnText = ''
  amazonUrl = ''
  style = new AmazonReviewCTASectionStyle()

  constructor (amazonReviewCTASection?: AmazonReviewCTASectionInterface) {
    if (amazonReviewCTASection) {
      this.id = amazonReviewCTASection.id
      this.label = amazonReviewCTASection.label
      this.listOrder = amazonReviewCTASection.listOrder
      this.active = amazonReviewCTASection.active
      this.persistent = amazonReviewCTASection.persistent
      this.labelVisible = amazonReviewCTASection.labelVisible
      this.title = amazonReviewCTASection.title
      this.subtitle = amazonReviewCTASection.subtitle
      this.btnText = amazonReviewCTASection.btnText
      this.amazonUrl = amazonReviewCTASection.amazonUrl
      this.style = new AmazonReviewCTASectionStyle(amazonReviewCTASection.style)
    }
  }
}
