import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1e3e5280"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "mask"
}
const _hoisted_2 = {
  ref: "content",
  class: "dropdown-qr-view-download-container"
}
const _hoisted_3 = {
  key: 0,
  class: "dropdown-content"
}
const _hoisted_4 = { class: "qr-container" }
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SvgButton = _resolveComponent("SvgButton")!
  const _component_SvgLink = _resolveComponent("SvgLink")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (this.visible)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SvgButton, {
        onClick: _ctx.toggle,
        icon: this.srcBtnImg,
        size: "32",
        style: _normalizeStyle({...this.btnStyle, background: this.btnBackground})
      }, null, 8, ["onClick", "icon", "style"]),
      (this.visible)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("img", {
                class: "qr-code",
                src: this.qrUri
              }, null, 8, _hoisted_5)
            ]),
            _createVNode(_component_SvgLink, {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (this.$emit('onDownload', 'png'))),
              icon: "download-white",
              style: {background: '#212529', margin: '20px 0 10px 0'}
            }, {
              default: _withCtx(() => [
                _createTextVNode("Download PNG")
              ]),
              _: 1
            }),
            _createVNode(_component_SvgLink, {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (this.$emit('onDownload', 'svg'))),
              icon: "download-white",
              style: {background: '#212529'}
            }, {
              default: _withCtx(() => [
                _createTextVNode("Download SVG")
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ], 512)
  ], 64))
}