interface ConversionInterface {
  id: string,
  name: string,
  totalConversions: number,
  conversionRate: number
}

export default class Conversion implements ConversionInterface {
  id = ''
  name = ''
  totalConversions = 0
  conversionRate = 0.0

  constructor (conversion?: ConversionInterface) {
    if (conversion) {
      this.id = conversion.id
      this.name = conversion.name
      this.totalConversions = conversion.totalConversions
      this.conversionRate = conversion.conversionRate
    }
  }
}
