export function hexToRGBA (hex: string, alpha: number): string | null {
  // Check if the hex string is valid
  const hexRegex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i
  const result = hexRegex.exec(hex)
  if (!result) {
    return null // Invalid hex color
  }

  // Parse the RGB values
  const [, r, g, b] = result

  // Convert hex to decimal
  const red = parseInt(r, 16)
  const green = parseInt(g, 16)
  const blue = parseInt(b, 16)

  // Validate alpha value
  alpha = Math.min(1, Math.max(0, alpha))

  // Generate the RGBA string
  return `rgba(${red}, ${green}, ${blue}, ${alpha})`
}

export function isHexColourAboveLumaThreshold (hex: string) : boolean {
  // Check if the hex string is valid
  const hexRegex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i
  const result = hexRegex.exec(hex)
  if (!result) {
    return false
  }

  // Parse the RGB values
  const [, r, g, b] = result

  // Convert hex to decimal
  const red = parseInt(r, 16)
  const green = parseInt(g, 16)
  const blue = parseInt(b, 16)

  if (red * 0.299 + green * 0.587 + blue * 0.114 > 186) {
    return true
  }
  return false
}

export function youtubeUrlToEmbed (url) {
  const res = url.split('=')
  const embeddedUrl = 'https://www.youtube.com/embed/' + res[1]
  return embeddedUrl
}
