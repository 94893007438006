import { authorisedPost } from './Api'

export function editQrRedirectUrl (
  subProfileId: string,
  url: string,
  token : string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  const data = {
    value: url
  }
  authorisedPost(`dashboard/${subProfileId}/redirect/edit-url`, data, token, unauthorisedCallback, badRequestCallback, serverErrorCallback, notFoundCallback, callback)
}

export function editQrRedirectStatus (
  subProfileId: string,
  status: boolean,
  token : string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  const data = {
    value: status
  }
  authorisedPost(`dashboard/${subProfileId}/redirect/edit-status`, data, token, unauthorisedCallback, badRequestCallback, serverErrorCallback, notFoundCallback, callback)
}

export function resetQrRedirectUrl (
  subProfileId: string,
  token : string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  const data = {
  }
  authorisedPost(`dashboard/${subProfileId}/redirect/reset`, data, token, unauthorisedCallback, badRequestCallback, serverErrorCallback, notFoundCallback, callback)
}
