<template>
    <div class="fa-icon-link-container d-flex flex-row justify-content-center align-items-center" :style="cssVars">
        <div class="d-flex flex-column justify-content-center" :style="cssIconContainer">
          <fa-icon :class="faIconClass" :icon="faIconIcon"></fa-icon>
        </div>
        <p :class="cssTextClass" :style="cssText">{{ text }}</p>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'FaIconLink',
  props: {
    icon: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: 'lg'
    },
    style: {
      type: Object,
      default: null
    },
    iconContainerStyle: {
      type: Object,
      default: null
    },
    textStyle: {
      type: Object,
      default: null
    },
    textUsesGradient: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    cssVars () {
      return {
        ...this.style
      }
    },
    cssIconContainer () {
      return {
        ...this.iconContainerStyle
      }
    },
    cssText () {
      return {
        ...this.textStyle
      }
    },
    cssTextClass () {
      const baseClass = 'fa-icon-link-text mx-1'
      return this.textUsesGradient ? baseClass + ' text-gradient' : baseClass
    },
    faIconClass () {
      return 'fa-' + this.size
    },
    faIconIcon () {
      return 'fa-solid ' + 'fa-' + this.icon
    }
  }
})
</script>

<style scoped lang="scss">
  div.fa-icon-link-container {
      cursor: pointer;
  }

  p.fa-icon-link-text {
    font-family: inter;
    margin-bottom: 0;
  }

  p.text-gradient {
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }
</style>
