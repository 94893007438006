import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SvgLink = _resolveComponent("SvgLink")!

  return (_openBlock(), _createBlock(_component_SvgLink, {
    icon: "plus",
    style: _normalizeStyle({
      width: '167px',
      height: '40px',
      borderRadius: '8px',
      background: 'linear-gradient(90deg, #3F80FF 0%, #7B3DFF 100%)'
    }),
    "icon-style": {
      width: '14px',
      height: '14px'
    },
    "text-style": {
      fontSize: '14px'
    }
  }, {
    default: _withCtx(() => [
      _createTextVNode(" Create New QR ")
    ]),
    _: 1
  }, 8, ["style"]))
}