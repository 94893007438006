import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.linkSection)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "link-body",
        style: _normalizeStyle(_ctx.cssVarsContainer)
      }, [
        _createElementVNode("p", {
          class: "link-text",
          style: _normalizeStyle(_ctx.cssVarsText),
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
        }, _toDisplayString(_ctx.linkSection.text), 5)
      ], 4))
    : _createCommentVNode("", true)
}