import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6da786a4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  for: "text-input"
}
const _hoisted_2 = ["type", "name", "placeholder", "value"]
const _hoisted_3 = {
  key: 2,
  class: "text-area-container d-flex"
}
const _hoisted_4 = ["maxlength", "placeholder", "value"]
const _hoisted_5 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(_ctx.cssVarsContainer)
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    (!_ctx.multiline)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "text-input-container",
          style: _normalizeStyle(_ctx.cssVarsWrapper)
        }, [
          _createElementVNode("input", {
            ref: "textInput",
            id: "text-input",
            class: "text-input",
            type: _ctx.type,
            name: _ctx.name,
            placeholder: _ctx.placeholder,
            value: _ctx.internalValue,
            onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateInternalValue && _ctx.updateInternalValue(...args))),
            onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleFocus && _ctx.handleFocus(...args))),
            onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleBlur && _ctx.handleBlur(...args))),
            style: _normalizeStyle(_ctx.cssVarsInput)
          }, null, 44, _hoisted_2)
        ], 4))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("textarea", {
            ref: "textInput",
            class: "text-input d-flex",
            maxlength: _ctx.maxlength,
            placeholder: _ctx.placeholder,
            value: _ctx.internalValue,
            onInput: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.updateInternalValue && _ctx.updateInternalValue(...args))),
            style: _normalizeStyle(_ctx.cssVarsInput)
          }, null, 44, _hoisted_4)
        ])),
    (_ctx.multiline && _ctx.maxlength != 'none')
      ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.value?.length + '/' + _ctx.maxlength), 1))
      : _createCommentVNode("", true)
  ], 4))
}