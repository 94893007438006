import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextInput = _resolveComponent("TextInput")!

  return (_openBlock(), _createBlock(_component_TextInput, {
    label: _ctx.label,
    name: _ctx.name,
    placeholder: _ctx.placeholder,
    modelValue: _ctx.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.value = $event)),
    rightImageUri: "icons/svg/search.svg",
    containerStyle: _ctx.containerStyle,
    wrapperStyle: _ctx.wrapperStyle,
    textStyle: _ctx.textStyle
  }, null, 8, ["label", "name", "placeholder", "modelValue", "containerStyle", "wrapperStyle", "textStyle"]))
}