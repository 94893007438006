import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-073ddc28"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex flex-column" }
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "d-flex flex-column align-items-end" }
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.amazonReviewCTASection)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "amazon-review-cta-body",
        style: _normalizeStyle(_ctx.cssVars)
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("p", {
            style: _normalizeStyle(_ctx.cssVars),
            class: "amazon-review-cta-title"
          }, _toDisplayString(_ctx.amazonReviewCTASection.title), 5),
          _createElementVNode("p", {
            style: _normalizeStyle(_ctx.cssVars),
            class: "amazon-review-cta-subtitle"
          }, _toDisplayString(_ctx.amazonReviewCTASection.subtitle), 5),
          _createElementVNode("a", {
            href: _ctx.amazonReviewCTASection.amazonUrl,
            class: "amazon-review-cta-btn",
            onClick: _cache[0] || (_cache[0] = () => this.$emit('onPushClick'))
          }, _toDisplayString(_ctx.amazonReviewCTASection.btnText), 9, _hoisted_2)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("img", {
            src: _ctx.logoSrc,
            class: "logo-image mb-auto"
          }, null, 8, _hoisted_4),
          _createElementVNode("img", {
            src: require('@/assets/images/qr-sections/amazon-stars.svg'),
            class: "stars-image"
          }, null, 8, _hoisted_5)
        ])
      ], 4))
    : _createCommentVNode("", true)
}