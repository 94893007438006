export interface FeaturesItemInterface {
  id: number,
  value: string,
  listOrder: number
}

export class FeaturesItem implements FeaturesItemInterface {
  id = 0
  value = ''
  listOrder = 0

  constructor (data: FeaturesItemInterface) {
    this.id = data.id
    this.value = data.value
    this.listOrder = data.listOrder
  }
}
