<template>
  <div>
    <div class="button-container">
      <DetailButtonSlant v-if="type == 'slant'" :detail="detail" :leftColour="leftColour" :rightColour="rightColour" :textColour="textColour" :closable="contentActive" @onClick="onClick"/>
      <DetailButton v-else :detail="detail" :color="color" :textColour="textColour" :closable="contentActive" @onClick="onClick"/>
    </div>
    <div v-if="contentActive" id="video-player">
      <VideoPlayer
        :sources="[{type: 'video/youtube', src: detail?.getValue()}]"
        :tech-order="['youtube']"
        controls
        autoplay
        :fluid="true"
    />
    </div>
  </div>
</template>

<script lang="ts">
import { Detail } from '@/models/Detail'
import { defineComponent } from 'vue'

import DetailButton from './DetailButton.vue'
import DetailButtonSlant from './DetailButtonSlant.vue'

import { VideoPlayer } from '@videojs-player/vue'
import 'videojs-youtube/dist/Youtube.min.js'
import 'video.js/dist/video-js.css'

export default defineComponent({
  name: 'DetailButtonFrame',
  components: {
    DetailButton,
    VideoPlayer,
    DetailButtonSlant
  },
  props: {
    detail: {
      type: Detail,
      required: true
    },
    type: String,
    color: String,
    leftColour: String,
    rightColour: String,
    textColour: String,
    contentActive: Boolean
  },
  computed: {
    url () {
      return this.detail ? (this.detail.type !== 'video' ? this.detail.getUrl() : '#') : '#'
    },
    cssVars () {
      return {
        '--color': this.leftColour
      }
    }
  },
  methods: {
    onClick (id: string) {
      if (this.detail.type !== 'video') {
        window.open(this.detail.getUrl(), '_blank')
      }
      this.$emit('onClick', id)
    }
  }
})
</script>

<style scoped lang="scss">
    div{
      width: 100%;
      height: 100%;
      background-color: var(--color);
      margin-bottom: 15px;
    }
    div.button-container{
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
    pre{
      flex: 1;
      font-size: 12px;
    }
    #video-player{
      margin-bottom: 25px;
    }
</style>
