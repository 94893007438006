<template>
  <div class="content-page">
  <div class="login-page">
      <div class="container">
         <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-8 mx-auto">
               <div class="card login" v-bind:class="{ error: emptyFields }">
                  <img :src="require(`@/assets/logo.png`)"/>
                  <form v-if="!locked" class="form-group" @submit.prevent="login">
                     <TextInput v-model="email" type="email" placeholder="Email" @keydown.enter="onLogin" :containerStyle="{margin: '0 0 10px 0'}" :wrapper-style="{width: '100%'}"/>
                     <TextInput v-model="password" type="password" placeholder="Password" @keydown.enter="onLogin" :containerStyle="{margin: '0 0 10px 0'}" :wrapper-style="{width: '100%'}"/>
                     <btn @click="onLogin">Login</btn>
                     <!--<p>Don't have an account? <a href="#" @click="registerActive = !registerActive, emptyFields = false">Sign up here</a>
                     </p>
                     <p><a href="#">Forgot your password?</a></p>-->
                  </form>
               </div>
            </div>
         </div>
        </div>
      </div>
   </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import btn from '@/components/button/Button.vue'
import TextInput from '@/components/input/TextInput.vue'

import { postLoginEmail } from '@/api'

export default defineComponent({
  name: 'LoginView',
  components: {
    btn,
    TextInput
  },
  data () {
    return {
      email: '',
      password: '',
      emptyFields: false,
      locked: false
    }
  },
  methods: {
    onLogin () {
      postLoginEmail(
        this.email,
        this.password,
        () => alert('Incorrect Email or Password'),
        () => alert('No account associated with this email address'),
        () => alert('Some of the data submitted is invalid'),
        () => this.$router.push('/server-error'),
        (response : any) => {
          this.$store.commit('setToken', response.data.token)
          this.$router.push('/')

          console.log(this.$cookies.keys().join('\n'))
        }
      )
    }
  }
})
</script>

<style scoped lang="scss">
  p {
    line-height: 1rem;
  }

  img {
    height: 200px;
    width: 200px;
    align-self: center;
    margin: 10px;
  }

  .card {
    padding: 20px;
    width: 100%;
  }

  .form-group {
    input {
      margin-bottom: 20px;
    }
  }

  .login-page {
    align-items: center;
    display: flex;
    height: 100vh;

    .wallpaper-login {
      background: url(https://images.pexels.com/photos/32237/pexels-photo.jpg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260)
        no-repeat center center;
      background-size: cover;
      height: 100%;
      position: absolute;
      width: 100%;
    }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity .5s;
  }
   .fade-enter,
   .fade-leave-to {
      opacity: 0;
   }

   .wallpaper-register {
      background: url(https://images.pexels.com/photos/533671/pexels-photo-533671.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260)
         no-repeat center center;
      background-size: cover;
      height: 100%;
      position: absolute;
      width: 100%;
      z-index: -1;
   }

   h1 {
      margin-bottom: 1.5rem;
   }
}

.error {
   animation-name: errorShake;
   animation-duration: 0.3s;
}

@keyframes errorShake {
   0% {
      transform: translateX(-25px);
   }
   25% {
      transform: translateX(25px);
   }
   50% {
      transform: translateX(-25px);
   }
   75% {
      transform: translateX(25px);
   }
   100% {
      transform: translateX(0);
   }
}

</style>
