<template>
      <div class="vl-parent button" :disabled="disabled" :style="cssVars" @click=" onClick()">
        <loading
          v-model:active="isLoading"
          height="20"
          :can-cancel="true"
          :is-full-page="false"/>
        <slot />
      </div>
</template>

<script lang="ts">

import { defineComponent } from 'vue'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/css/index.css'

import { Palette } from '@/theme'

export default defineComponent({
  name: 'ButtonComponent',
  components: {
    Loading
  },
  props: {
    backgroundColor: {
      type: String,
      default: 'light_blue'
    },
    color: {
      type: String,
      default: 'white'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    outline: {
      type: Boolean,
      default: false
    },
    style: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      isLoading: false
    }
  },
  methods: {
    onClick () {
      if (!this.disabled) {
        this.$emit('onClick')
      }
    }
  },
  computed: {
    cssVars () {
      return {
        '--background-color': this.disabled ? Palette.light_grey : Palette[this.backgroundColor],
        '--border-width': this.outline ? 5 : 0,
        '--color': this.disabled ? Palette.white : Palette[this.color],
        '--hover-color': this.disabled ? Palette.light_grey : '#FF6347',
        '--pointer-events': this.disabled ? 'none' : 'auto',
        '--border-radius': String(this.borderRadius) + 'px',
        ...this.style
      }
    }
  }
})
</script>

<style scoped>
  .button {
    display: inline-block;
    margin: 0.5em 0.5em;
    padding: 1em 2em;
    background: var(--background-color);
    border: var(--border-width) solid var(--background-color);
    border-radius: 1em;
    color: var(--color);
    font-family: "Inter";
    font-size: 0.9em;
    font-weight: 700;
    line-height: 1;
    text-decoration: none;
    cursor: pointer;
    pointer-events: var(--pointer-events);
    transition: 0.3s;
    width: 100%;
    justify-content: center;
  }

  @media (hover: hover) and (pointer: fine) {
    .button:hover {
      background: var(--hover-color)
    }
  }
</style>
