<template>
    <div class="viewport-button-wrapper" :style="cssVars">
        <img :src="imgSrc" alt="icon">
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ViewportButton',
  props: {
    selected: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      required: true
    }
  },
  computed: {
    cssVars () {
      return {
        '--background': this.selected ? 'linear-gradient(116deg, rgba(63, 128, 255, 0.1) 16.27%, rgba(123, 61, 255, 0.1) 83.73%)' : '#FFFFFF'
      }
    },
    imgSrc () {
      return require('@/assets/icons/svg/viewport-' + (this.selected ? this.icon + '-grad' : this.icon) + '.svg')
    }
  }
})
</script>

<style scoped lang="scss">
    div.viewport-button-wrapper {
      width: 40px;
      height: 40px;
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      margin: 0 4px 0 4px;
      background: var(--background);
      cursor: pointer;
    }

    img {
        height: 24px;
    }
</style>
