import { authorisedPost, get } from './Api'

export function editLinkSection (
  subProfileId: string,
  id: number,
  label: string,
  listOrder: number,
  active: boolean,
  text: string,
  url: string,
  token: string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  const data = {
    id,
    label,
    listOrder,
    active,
    labelVisible: false,
    text,
    url
  }
  authorisedPost(`dashboard/${subProfileId}/edit-link?section=${id}`, data, token, unauthorisedCallback, badRequestCallback, serverErrorCallback, notFoundCallback, callback)
}

export function editLinkSectionStyle (
  subProfileId: string,
  id: number,
  align: string,
  bold: boolean,
  italic: boolean,
  underline: boolean,
  size: number,
  textColour: string,
  backgroundColour: string,
  spacingLeft: number,
  spacingTop: number,
  spacingRight: number,
  spacingBottom: number,
  token: string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  const data = {
    align,
    bold,
    italic,
    underline,
    size,
    textColour,
    backgroundColour,
    spacingLeft,
    spacingTop,
    spacingRight,
    spacingBottom
  }
  authorisedPost(`dashboard/${subProfileId}/edit-link-style?section=${id}`, data, token, unauthorisedCallback, badRequestCallback, serverErrorCallback, notFoundCallback, callback)
}

export function pushSectionLinkClick (
  qrSectionId: number,
  qrScanId: number,
  accessType: string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  get(
    `profile/push-section-link-click?qrScanId=${qrScanId}&sectionId=${qrSectionId}&accessType=${accessType}`,
    unauthorisedCallback,
    notFoundCallback,
    badRequestCallback,
    notFoundCallback,
    callback
  )
}
