import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-wrap align-items-center" }
const _hoisted_2 = {
  for: "status-select",
  class: "me-2"
}
const _hoisted_3 = {
  class: "me-sm-3",
  style: {"width":"50%"}
}
const _hoisted_4 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, _toDisplayString(this.label), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("select", {
        class: "form-select my-1 my-md-0",
        id: "status-select",
        onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onChange($event))),
        value: _ctx.internalValue
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, i) => {
          return (_openBlock(), _createElementBlock("option", { key: i }, _toDisplayString(option), 1))
        }), 128))
      ], 40, _hoisted_4)
    ])
  ]))
}