<template>
    <TextInput
        :label="label"
        :name="name"
        :placeholder="placeholder"
        :modelValue="value"
        @update:modelValue="value = $event"
        rightImageUri="icons/svg/search.svg"
        :containerStyle="containerStyle"
        :wrapperStyle="wrapperStyle"
        :textStyle="textStyle"
    />
  </template>

<script lang="ts">

import { defineComponent } from 'vue'
import TextInput from './TextInput.vue'

export default defineComponent({
  name: 'SearchInput',
  props: {
    label: {
      String,
      default: ''
    },
    name: String,
    placeholder: String,
    modelValue: String,
    containerStyle: {
      type: Object,
      default: null
    },
    wrapperStyle: {
      type: Object,
      default: null
    },
    textStyle: {
      type: Object,
      default: null
    }
  },
  emits: ['update:modelValue'],
  computed: {
    value: {
      get () {
        return this.modelValue
      },
      set (value: string) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  components: { TextInput }
})

</script>
