interface LinkSectionStyleInterface {
    align: string,
    bold: boolean,
    italic: boolean,
    underline: boolean,
    size: number,
    textColour: string,
    backgroundColour: string,
    spacingLeft: number,
    spacingTop: number,
    spacingRight: number,
    spacingBottom: number
}

interface LinkSectionInterface {
    id: number
    label: string
    listOrder: number,
    active: boolean,
    persistent: boolean,
    labelVisible: boolean,
    text: string,
    url: string
    style: LinkSectionStyleInterface
}

class LinkSectionStyle implements LinkSectionStyleInterface {
  align = ''
  bold = false
  italic = false
  underline = false
  size = 0
  textColour = ''
  backgroundColour = ''
  spacingLeft = 0
  spacingTop = 0
  spacingRight = 0
  spacingBottom = 0

  constructor (style?: LinkSectionStyleInterface) {
    if (style) {
      this.align = style.align
      this.bold = style.bold
      this.italic = style.italic
      this.underline = style.underline
      this.size = style.size
      this.textColour = style.textColour
      this.backgroundColour = style.backgroundColour
      this.spacingLeft = style.spacingLeft
      this.spacingTop = style.spacingTop
      this.spacingRight = style.spacingRight
      this.spacingBottom = style.spacingBottom
    }
  }
}

export default class LinkSection implements LinkSectionInterface {
  id = 0
  label = ''
  listOrder = 0
  active = false
  persistent = false
  labelVisible = false
  text = ''
  url = ''
  style = new LinkSectionStyle()

  constructor (LinkSection?: LinkSectionInterface) {
    if (LinkSection) {
      this.id = LinkSection.id
      this.label = LinkSection.label
      this.listOrder = LinkSection.listOrder
      this.active = LinkSection.active
      this.persistent = LinkSection.persistent
      this.labelVisible = LinkSection.labelVisible
      this.text = LinkSection.text
      this.url = LinkSection.url
      this.style = new LinkSectionStyle(LinkSection.style)
    }
  }
}
