import { Analytics, AnalyticsInterface } from './Analytics'
import { Detail, ParsableDetailInterface } from './Detail'
import { SubProfileThemeInterface } from './theme/SubProfileTheme'
import SubProfileHeaderListItem from './SubProfileHeaderListItem'
import SubProfile from './SubProfile'
import ImageSection from './qr-sections/ImageSection'
import HeaderSection from './qr-sections/HeaderSection'
import TextSection from './qr-sections/TextSection'
import UserDetailSection from './qr-sections/UserDetailSection'
import TableSection from './qr-sections/TableSection'
import QrRedirect from './QrRedirect'
import FeaturesSection from './qr-sections/FeaturesSection'
import AmazonReviewCTASection from './qr-sections/AmazonReviewCTASection'
import LinkSection from './qr-sections/LinkSection'
import ButtonSection from './qr-sections/ButtonSection'
import EmbeddedVideoSection from './qr-sections/EmbeddedVideoSection'
import SocialsSection from './qr-sections/SocialsSection'
import NewsSection from './qr-sections/NewsSection'
import Profile from './Profile'

export interface UserSubProfileInterface {
  id: string,
  keypin: string,
  name: string,
  label: string,
  qrUri: string,
  qrRedirect: QrRedirect,
  amazonReviewCTASections: AmazonReviewCTASection[],
  buttonSections: ButtonSection[],
  embeddedVideoSections: EmbeddedVideoSection[],
  featuresSections: FeaturesSection[],
  imageSections: ImageSection[],
  headerSections: HeaderSection[],
  linkSections: LinkSection[],
  newsSections: NewsSection[],
  socialsSections: SocialsSection[],
  textSections: TextSection[],
  tableSections: TableSection[],
  details: ParsableDetailInterface[],
  userDetailSections: UserDetailSection[],
  headerDetails: string[]
  headerGalleryItems: string[]
  headerListItems: SubProfileHeaderListItem[],
  theme: SubProfileThemeInterface,
  analytics: AnalyticsInterface,
  isRootProfile: boolean,
  active: boolean
}

export class UserSubProfile extends SubProfile implements UserSubProfileInterface {
  analytics = new Analytics()
  isRootProfile = false
  active = false
  constructor (userSubProfile?: UserSubProfileInterface) {
    super(userSubProfile)
    if (userSubProfile) {
      this.analytics = new Analytics(userSubProfile.analytics)
      this.isRootProfile = userSubProfile.isRootProfile
      this.active = userSubProfile.active
    }
  }

  getMostClickedDetail () {
    const id = this.analytics.getMostClickedDetailId()
    return this.details.find((d: Detail) => d.id === id)
  }

  getUrlShort (profile: Profile) {
    if (this.isRootProfile) return `keyp.in/${profile.firstName}`
    return `keyp.in/${profile.firstName}/${this.id.substring(6, 8)}`
  }
}
