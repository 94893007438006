import ScanLocation from './ScanLocation'
import TopQr from './TopQr'

interface BaseAnalyticsInterface {
    totalScansLifetime: number
    totalScansToday: number
    totalLinkClicksLifetime: number
    averageClickTime: number
    topLocations: ScanLocation[]
}

export default class BaseAnalytics implements BaseAnalyticsInterface {
  totalScansLifetime = 0
  totalScansToday = 0
  totalLinkClicksLifetime = 0
  averageClickTime = 0.0
  topLocations = [] as Array<ScanLocation>

  constructor (baseAnalytics?: BaseAnalytics) {
    if (baseAnalytics) {
      this.totalScansLifetime = baseAnalytics.totalScansLifetime
      this.totalScansToday = baseAnalytics.totalScansToday
      this.totalLinkClicksLifetime = baseAnalytics.totalLinkClicksLifetime
      this.averageClickTime = baseAnalytics.averageClickTime
      this.topLocations = baseAnalytics.topLocations.map(scanLocation => new ScanLocation(scanLocation))
    }
  }
}
