import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f14910ec"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "feature-item-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.featuresSection)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(_ctx.classBody)
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.featuresSection.features, (feature) => {
          return (_openBlock(), _createElementBlock("div", {
            key: feature.id,
            class: _normalizeClass(_ctx.classItemContainer),
            style: _normalizeStyle(_ctx.cssVars)
          }, [
            _createElementVNode("img", {
              class: "tick-img",
              src: require('@/assets/icons/svg/tick-wg.svg'),
              alt: "tick",
              style: _normalizeStyle(_ctx.cssImg)
            }, null, 12, _hoisted_1),
            _createElementVNode("p", _hoisted_2, _toDisplayString(feature.value), 1)
          ], 6))
        }), 128))
      ], 2))
    : _createCommentVNode("", true)
}