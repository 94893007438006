import { authorisedPost } from './Api'

export function postFeatureItem (
  sectionId: number,
  subProfileId: string,
  token: string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  authorisedPost(
    `dashboard/${subProfileId}/create-feature-item?section=${sectionId}`,
    { },
    token,
    unauthorisedCallback,
    notFoundCallback,
    badRequestCallback,
    notFoundCallback,
    callback
  )
}

export function editFeatureItem (
  id: number,
  value: string,
  sectionId: number,
  subProfileId: string,
  token: string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  authorisedPost(
    `dashboard/${subProfileId}/edit-feature-item?section=${sectionId}&feature=${id}`,
    { value },
    token,
    unauthorisedCallback,
    notFoundCallback,
    badRequestCallback,
    notFoundCallback,
    callback
  )
}

export function deleteFeatureItem (
  id: number,
  sectionId: number,
  subProfileId: string,
  token: string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  authorisedPost(
    `dashboard/${subProfileId}/delete-feature-item?section=${sectionId}&feature=${id}`,
    {},
    token,
    unauthorisedCallback,
    notFoundCallback,
    badRequestCallback,
    notFoundCallback,
    callback
  )
}

export function postFeatureItemOrder (
  value : Array<number>,
  sectionId: number,
  subProfileId: string,
  token: string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  authorisedPost(
    `dashboard/${subProfileId}/edit-features-order?section=${sectionId}`,
    { value },
    token,
    unauthorisedCallback,
    notFoundCallback,
    badRequestCallback,
    notFoundCallback,
    callback
  )
}
