import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2fbffa51"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "body-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DetailButtonFrame = _resolveComponent("DetailButtonFrame")!

  return (_ctx.detailSection && _ctx.theme)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.detailSection.details, (detail) => {
          return (_openBlock(), _createBlock(_component_DetailButtonFrame, {
            key: detail.id,
            detail: detail,
            type: _ctx.buttonStyle,
            color: _ctx.theme.buttonTheme.backgroundColour,
            leftColour: _ctx.theme.buttonTheme.secondaryColour,
            rightColour: _ctx.theme.buttonTheme.backgroundColour,
            textColour: _ctx.theme.buttonTheme.textColour,
            "content-active": _ctx.activeContentId == detail.id && _ctx.contentActive,
            onOnClick: _ctx.setActiveContent
          }, null, 8, ["detail", "type", "color", "leftColour", "rightColour", "textColour", "content-active", "onOnClick"]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}