<template>
    <PopupOverlay
        :visible="this.visible"
        title="Add Social Links"
        @onDismiss="$emit('onDismiss')"
    >
    <div class="d-flex flex-column p-2">
      <p class="type-header-text">Social Media</p>
      <div class="d-flex flex-wrap">
        <div v-for="(social, i) in socials" :key="i" class="d-flex  m-1 detail-type-button" :style="getCssDetailButton(social.label.toLowerCase())">
          <img class="detail-type-icon" :src="require(`@/assets/${social.icon}`)"/>
          <p class="detail-type-label mx-2">{{ social.label }}</p>
          <FaIconButton v-if="selectedDetails.includes(social.label.toLowerCase())"
            icon="xmark"
            size="md"
            @click="() => onRemove(social.label.toLowerCase())"
            :style="cssRemoveButton"
          />
          <FaIconButton v-else icon="plus" size="md" @click="() => onAdd(social.label.toLowerCase())" :style="cssAddButton"/>
        </div>
      </div>
      <p class="type-header-text mt-4">Streaming</p>
      <div class="d-flex flex-wrap">
        <div v-for="(social, i) in streaming" :key="i" class="d-flex  m-1 detail-type-button" :style="getCssDetailButton(social.label.toLowerCase())">
          <img class="detail-type-icon" :src="require(`@/assets/${social.icon}`)"/>
          <p class="detail-type-label mx-2">{{ social.label }}</p>
          <FaIconButton v-if="selectedDetails.includes(social.label.toLowerCase())"
            icon="xmark"
            size="md"
            @click="() => onRemove(social.label.toLowerCase())"
            :style="cssRemoveButton"
          />
          <FaIconButton v-else icon="plus" size="md" @click="() => onAdd(social.label.toLowerCase())" :style="cssAddButton"/>
        </div>
      </div>
      <p class="type-header-text mt-4">Other</p>
      <div class="d-flex flex-wrap">
        <div v-for="(social, i) in other" :key="i" class="d-flex  m-1 detail-type-button" :style="getCssDetailButton(social.label.toLowerCase())">
          <img class="detail-type-icon" :src="require(`@/assets/${social.icon}`)"/>
          <p class="detail-type-label mx-2">{{ social.label }}</p>
          <FaIconButton v-if="selectedDetails.includes(social.label.toLowerCase())"
            icon="xmark"
            size="md"
            @click="() => onRemove(social.label.toLowerCase())"
            :style="cssRemoveButton"
          />
          <FaIconButton v-else icon="plus" size="md" @click="() => onAdd(social.label.toLowerCase())" :style="cssAddButton"/>
        </div>
      </div>
      <div class="d-flex flex-row justify-content-between button-container mt-3">
        <ButtonTwo
          text="Unselect All"
          :style="cssRemoveLinksButton"
          @click="onRemoveAll"/>
        <ButtonTwo
          :text="'Add Selected (' + String(this.selectedDetails.length) + ')'"
          :style="cssAddLinksButton"
          @click="onAddAll"/>
      </div>
    </div>

    </PopupOverlay>
</template>

<script lang="ts">

import { defineComponent } from 'vue'

import ButtonTwo from '@/components/button/Button2.vue'
import FaIconButton from '../button/FaIconButton.vue'
import PopupOverlay from '@/components/overlay/PopupOverlay.vue'

import 'vue-loading-overlay/dist/css/index.css'
import { DETAIL_TYPES_OTHER, DETAIL_TYPES_SOCIALS, DETAIL_TYPES_STREAMING } from '@/constants/constants'
import { Palette } from '@/theme'

export default defineComponent({
  name: 'AddLinksPopup',
  components: {
    ButtonTwo,
    FaIconButton,
    PopupOverlay
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selectedDetails: [] as string[]
    }
  },
  computed: {
    socials () {
      return DETAIL_TYPES_SOCIALS()
    },
    streaming () {
      return DETAIL_TYPES_STREAMING()
    },
    other () {
      return DETAIL_TYPES_OTHER()
    },
    cssAddButton () {
      return {
        backgroundColor: Palette.white,
        color: Palette.neutral800,
        border: '2px solid ' + Palette.neutral800,
        borderRadius: '12.5px',
        width: '25px',
        height: '25px'
      }
    },
    cssRemoveButton () {
      return {
        backgroundColor: Palette.white,
        color: Palette.neutral800,
        width: '25px',
        height: '25px'
      }
    },
    cssAddLinksButton () {
      return {
        backgroundImage: Palette.gradientBP,
        color: Palette.white,
        borderRadius: '1em',
        padding: '1em'
      }
    },
    cssRemoveLinksButton () {
      return {
        backgroundColor: 'lightgray',
        color: Palette.neutral800,
        borderRadius: '1em',
        padding: '1em'
      }
    }
  },
  methods: {
    getCssDetailButton (type: string) {
      return this.selectedDetails.includes(type)
        ? {
            border: '2px solid blue'
          }
        : {
            border: '0.5px solid gray'
          }
    },
    onAdd (value: string) {
      this.selectedDetails.push(value)
    },
    onAddAll () {
      this.$emit('onAddLinks', this.selectedDetails)
      this.$emit('onDismiss')
      this.onRemoveAll()
    },
    onRemove (value: string) {
      this.selectedDetails = this.selectedDetails.filter(item => item !== value)
    },
    onRemoveAll () {
      this.selectedDetails = []
    }
  }
})
</script>

<style scoped>
div.detail-type-button {
  box-sizing: border-box;
  position: relative;
  height: 3em;
  border-radius: 1em;
  padding:0.5em 1em 0.5em 1em;
}

p.type-header-text {
  text-align: left;
  font-size: large;
  font-family: Inter;
  font-weight: bold;
}

</style>
