import { authorisedPost, get } from './Api'

export function editButtonSection (
  subProfileId: string,
  id: number,
  label: string,
  listOrder: number,
  active: boolean,
  text: string,
  url: string,
  token: string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  const data = {
    id,
    label,
    listOrder,
    active,
    labelVisible: false,
    text,
    url
  }
  authorisedPost(`dashboard/${subProfileId}/edit-button?section=${id}`, data, token, unauthorisedCallback, badRequestCallback, serverErrorCallback, notFoundCallback, callback)
}

export function editButtonSectionStyle (
  subProfileId: string,
  id: number,
  textColour: string,
  fillColour: string,
  style: string,
  corners: string,
  size: string,
  align: string,
  token: string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  const data = {
    textColour,
    fillColour,
    style,
    corners,
    size,
    align
  }
  authorisedPost(`dashboard/${subProfileId}/edit-button-style?section=${id}`, data, token, unauthorisedCallback, badRequestCallback, serverErrorCallback, notFoundCallback, callback)
}

export function pushSectionButtonClick (
  qrSectionId: number,
  qrScanId: number,
  accessType: string,
  unauthorisedCallback: () => void,
  notFoundCallback: () => void,
  badRequestCallback: () => void,
  serverErrorCallback: () => void,
  callback: (response: any) => void
) {
  get(
    `profile/push-section-button-click?qrScanId=${qrScanId}&sectionId=${qrSectionId}&accessType=${accessType}`,
    unauthorisedCallback,
    notFoundCallback,
    badRequestCallback,
    notFoundCallback,
    callback
  )
}
