import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5ab7465a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header-pretitle-text" }
const _hoisted_2 = { class: "header-title-text" }
const _hoisted_3 = { class: "header-subtitle-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.header)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "header-container d-flex flex-column align px-2 py-3",
        style: _normalizeStyle(_ctx.cssVars)
      }, [
        _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.header.preTitle), 1),
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.header.title), 1),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.header.subTitle), 1)
      ], 4))
    : _createCommentVNode("", true)
}