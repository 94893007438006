<template>
  <div class="content-page">
  <div class="login-page">
      <div class="container">
         <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-8 mx-auto">
               <div class="card login" v-bind:class="{ error: emptyFields }">
                  <img :src="require(`@/assets/logo.png`)"/>
                  <p>Sign up for your Keypin account</p>
                  <form class="form-group" @submit.prevent="register">
                     <input v-model="email" type="email" class="form-control" placeholder="Email" required>
                     <input v-model="regCode" type="text" class="form-control" placeholder="Beta-testing Code" required>
                     <input v-model="name" type="text" class="form-control" placeholder="Username/Company name" required>
                     <input v-model="password" type="password" class="form-control" placeholder="Password" required>
                     <input v-model="confirmPassword" type="password" class="form-control" placeholder="Confirm Password" required>
                     <input
                        type="submit"
                        class="btn btn-primary"
                        value="Register"
                        :disabled="!emailValid || !nameValid || !passwordMatch || !passwordValid"
                        @click="onRegister"
                        >
                  </form>
               </div>
            </div>
         </div>
        </div>
      </div>
   </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import { postRegistration } from '@/api'
import { validate } from '@babel/types'

export default defineComponent({
  name: 'RegisterView',
  data () {
    return {
      email: '',
      name: '',
      password: '',
      confirmPassword: '',
      regCode: '',
      emptyFields: false,
      emailValid: false,
      nameValid: false,
      passwordMatch: false,
      passwordValid: false
    }
  },
  methods: {
    onRegister () {
      postRegistration(
        this.email,
        this.name,
        this.regCode,
        this.password,
        () => null,
        () => alert('Failure to create new Keypin'),
        () => alert('No keypins available with this prefix/or verification entry already exists'),
        () => this.$router.push('/server-error'),
        (response : any) => {
          this.$store.commit('setToken', response.data.token)
          this.$store.commit('setVerificationEmail', this.email)
          this.$router.push(`/dashboard/${response.data.data.value}`)
        }
      )
    },
    validateEmail () {
      this.emailValid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email)
    },
    validateName () {
      this.nameValid = this.name.length > 0
    },
    validatePassword () {
      this.passwordMatch = this.password === this.confirmPassword
      this.passwordValid = this.password.length > 7 && this.password.length < 16
    }
  },
  watch: {
    email (value) {
      this.email = value
      this.validateEmail()
    },
    name (value) {
      this.name = value
      this.validateName()
    },
    password (value) {
      this.password = value
      this.validatePassword()
    },
    confirmPassword (value) {
      this.confirmPassword = value
      this.validatePassword()
    }
  }
})
</script>

<style scoped lang="scss">
  p {
    line-height: 1rem;
    margin-bottom: 25px;
  }
  img {
    height: 200px;
    width: 200px;
    align-self: center;
    margin: 10px;
  }

  .card {
    padding: 20px;
  }

  .form-group {
    input {
      margin-bottom: 20px;
    }
  }

  .login-page {
    align-items: center;
    display: flex;
    height: 100vh;

    .wallpaper-login {
      background: url(https://images.pexels.com/photos/32237/pexels-photo.jpg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260)
        no-repeat center center;
      background-size: cover;
      height: 100%;
      position: absolute;
      width: 100%;
    }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity .5s;
  }
   .fade-enter,
   .fade-leave-to {
      opacity: 0;
   }

   .wallpaper-register {
      background: url(https://images.pexels.com/photos/533671/pexels-photo-533671.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260)
         no-repeat center center;
      background-size: cover;
      height: 100%;
      position: absolute;
      width: 100%;
      z-index: -1;
   }

   h1 {
      margin-bottom: 1.5rem;
   }
}

.error {
   animation-name: errorShake;
   animation-duration: 0.3s;
}

@keyframes errorShake {
   0% {
      transform: translateX(-25px);
   }
   25% {
      transform: translateX(25px);
   }
   50% {
      transform: translateX(-25px);
   }
   75% {
      transform: translateX(25px);
   }
   100% {
      transform: translateX(0);
   }
}

</style>
