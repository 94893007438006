<template>
    <div v-if="socialsSection" class="socials-container" :style="cssVars">
      <div class="socials-content">
        <p class="socials-title" :style="cssVarsTitle">Social Links</p>
        <div v-if="socialsSection.style.layout === 'row'" class="socials-buttons-row-container" :style="cssVars">
          <a v-for="social in socialsSection.socials" :key="social.id" class="social-row-button" :style="cssVarsRowBtn" @click="() => onClick(social.id, social.value)">
              <img v-if="socialsSection.style.logoTheme === 'brand'" class="social-row-button-icon" :src="require(`@/assets/${getIcon(social.type)}`)" >
              <IconFacebookSvg v-else-if="social.type === 'facebook'" :colour="socialsSection.style.logoColour"/>
              <IconInstagramSvg v-else-if="social.type === 'instagram'" :colour="socialsSection.style.logoColour"/>
              <IconTwitterSvg v-else-if="social.type === 'twitter'" :colour="socialsSection.style.logoColour"/>
          </a>
        </div>
        <div v-else class="socials-buttons-list-container" :style="cssVars">
          <a v-for="social in socialsSection.socials" :key="social.id" class="social-list-button" :style="cssVarsListBtn" @click="() => onClick(social.id, social.value)">
              <img v-if="socialsSection.style.logoTheme === 'brand'" class="social-list-button-icon" :src="require(`@/assets/${getIcon(social.type)}`)" />
              <IconFacebookSvg v-else-if="social.type === 'facebook'" :colour="socialsSection.style.logoColour" style="margin-right: 15px"/>
              <IconInstagramSvg v-else-if="social.type === 'instagram'" :colour="socialsSection.style.logoColour" style="margin-right: 15px"/>
              <IconTwitterSvg v-else-if="social.type === 'twitter'" :colour="socialsSection.style.logoColour" style="margin-right: 15px"/>
              <p class="social-list-button-text">{{ social.type }}</p>
          </a>
        </div>
      </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import IconFacebookSvg from '@/components/svg/socials/IconFacebookSvg.vue'
import IconInstagramSvg from '@/components/svg/socials/IconInstagramSvg.vue'
import IconTwitterSvg from '@/components/svg/socials/IconTwitterSvg.vue'

import SocialsSection from '@/models/qr-sections/SocialsSection'
import { SOCIAL_TYPE_TO_ICON } from '@/constants/constants'
import { isHexColourAboveLumaThreshold } from '@/utils'

export default defineComponent({
  name: 'SocialsSection',
  components: {
    IconFacebookSvg,
    IconInstagramSvg,
    IconTwitterSvg
  },
  props: {
    socialsSection: SocialsSection
  },
  computed: {
    cssVars () {
      return {
        '--background-color': this.socialsSection.style.backgroundColour,
        '--justify-content': this.socialsSection.style.align === 'left' ? 'flex-start' : this.socialsSection.style.align === 'center' ? 'center' : 'flex-end',
        '--align-items': this.socialsSection.style.align === 'left' ? 'flex-start' : this.socialsSection.style.align === 'center' ? 'center' : 'flex-end',
        '--content-margin': this.socialsSection.style.spacingTop.toString() + 'px ' + this.socialsSection.style.spacingRight.toString() + 'px ' + this.socialsSection.style.spacingBottom.toString() + 'px ' + this.socialsSection.style.spacingLeft.toString() + 'px'
      }
    },
    cssVarsTitle () {
      return {
        '--title-margin': this.socialsSection.style.align === 'left' ? '0 0 0 5px' : this.socialsSection.style.align === 'center' ? '0 0 0 0' : '0 5px 0 0',
        '--title-colour': isHexColourAboveLumaThreshold(this.socialsSection.style.backgroundColour) ? '#000' : '#FFF'
      }
    },
    listBtnStyleWidth () {
      if (this.socialsSection.style.size === 'sm') {
        return '80%'
      } else if (this.socialsSection.style.size === 'md') {
        return '90%'
      } else {
        return '100%'
      }
    },
    listStyleHeight () {
      if (this.socialsSection.style.size === 'sm') {
        return '30px'
      } else if (this.socialsSection.style.size === 'md') {
        return '50px'
      } else {
        return '60px'
      }
    },
    rowBtnStyleWidthHeight () {
      if (this.socialsSection.style.size === 'sm') {
        return '40px'
      } else if (this.socialsSection.style.size === 'md') {
        return '50px'
      } else {
        return '60px'
      }
    },
    btnStyleBorderRadius () {
      if (this.socialsSection.style.style === 'pill') {
        return '15px'
      } else if (this.socialsSection.style.corners === 'rounded') {
        return '10px'
      } else {
        return '0px'
      }
    },
    cssVarsRowBtn () {
      return {
        '--width': this.rowBtnStyleWidthHeight,
        '--height': this.rowBtnStyleWidthHeight,
        '--border-radius': this.btnStyleBorderRadius
      }
    },
    cssVarsListBtn () {
      return {
        '--width': this.listBtnStyleWidth,
        '--height': this.listStyleHeight,
        '--border-radius': this.btnStyleBorderRadius
      }
    }
  },
  methods: {
    getIcon (type: string): string {
      if (this.socialsSection) return SOCIAL_TYPE_TO_ICON[type].value
      return SOCIAL_TYPE_TO_ICON.default.value
    },
    onClick (id: number, url: string) {
      window.open(url, '_blank')
      this.$emit('onItemClick', id)
    }
  }
})
</script>

<style scoped lang="scss">
  div.socials-container{
    min-width: 111%;
    margin-top: 20px;
    background-color: var(--background-color);
  }
  div.socials-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: var(--align-items);
    margin: var(--content-margin);
    background-color: var(--background-color);
  }
  p.socials-title{
    color: var(--title-colour);
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 137.5% */
    margin: var(--title-margin);
  }
  div.socials-buttons-row-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: var(--justify-content);
    align-items: center;
    margin: 10px 0 0 0;
  }
  div.socials-buttons-list-container {
    display: flex;
    flex-direction: column;
    align-items: var(--align-items);
    margin: 20px 0 0 0;
    width: 100%;
  }
  a.social-row-button{
    margin: 10px 5px 0 5px;
    width: var(--width);
    height: var(--height);
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--border-radius);
    background: var(--White, #FFF);
    cursor: pointer;
  }
  a.social-list-button{
    margin: 5px 0 5px 0;
    width: var(--width);
    height: var(--height);
    border-radius: var(--border-radius);
    background: var(--White, #FFF);
    display: flex;
    align-items: center;
    padding: 0 13px 0 13px;
    cursor: pointer;
  }
  img.social-row-button-icon{
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }
  img.social-list-button-icon{
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    margin-right: 15px;
  }

  p.social-list-button-text{
    color: var(--Nearly-Black, #212529);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 153.846% */
    margin: 0;
    text-transform: capitalize;
  }
</style>
