<template>
    <div class="tooltip-wrapper">
        <p class="tooltip-text"><slot/></p>
    </div>
</template>

<script lang="ts">

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TooltipComponent'
})

</script>

<style scoped>

.tooltip-wrapper {
      position: absolute;
      background-color: #3498db;
      color: #fff;
      padding: 10px;
      border-radius: 5px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      display: none;
      z-index: 1;
      width: 200px;
    }

    .tooltip-wrapper:before {
      content: "";
      position: absolute;
      border-style: solid;
      border-width: 10px 10px 0;
      border-color: #3498db transparent transparent transparent;
      top: 100%;
      left: 50%;
      margin-left: -10px;
    }

</style>
