<template>
    <!-- ========== Menu ========== -->
    <div v-if="visible" class="toggle-button-container">
        <FaIconButton @click="onToggle" icon="bars"/>
    </div>
    <div v-if="visible" class="sidebar">
        <div class="close-button-container">
            <FaIconButton @click="onToggle" icon="xmark"/>
        </div>
        <!-- Brand Logo -->
        <div class="logo-container">
            <!-- Brand Logo Dark -->
            <a href="#">
                <img :src="require('@/assets/icons/main/icon-blue.png')" alt="dark logo" class="logo">
                <h4 class="logo-text">Keypin</h4>
             </a>
        </div>

        <!-- menu-left -->
        <div class="sidebar-menu">
            <!--- Menu -->
           <router-link to="/dashboard">
                <SideBarLink
                    name="manage"
                    src="qrs.svg"
                    src-selected="qrs-blue.svg">
                    QR Codes
                </SideBarLink>
            </router-link>
            <router-link to="/analytics">
                <SideBarLink
                    name="analytics"
                    src="analytics.svg"
                    src-selected="analytics-blue.svg">
                    Analytics
                </SideBarLink>
            </router-link>
            <router-link to="#">
                <SideBarLink
                    name="users"
                    src="users.svg"
                    src-selected="users-blue.svg">
                    Users
                </SideBarLink>
            </router-link>
            <router-link to="#">
                <SideBarLink
                    name="settings"
                    src="settings.svg"
                    src-selected="settings-blue.svg">
                    Settings
                </SideBarLink>
            </router-link>
            <router-link to="#">
                <SideBarLink
                    name="resources"
                    src="resources.svg"
                    src-selected="resources-blue.svg">
                    Resources
                </SideBarLink>
            </router-link>
        </div>
            <!--- End Menu -->
            <div class="clearfix"></div>
    </div>
    <!-- ========== Left menu End ========== -->
</template>

<script lang="ts">
import FaIconButton from '../button/FaIconButton.vue'
import SideBarLink from './SideBarLink.vue'

import Palette from '@/theme/palette'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SideBar',
  components: {
    FaIconButton,
    SideBarLink
  },
  methods: {
    getBackgroundOverride (name: string) {
      console.log(name)
      if (name === this.$route.name) return { backgroundColor: Palette.hover_blue, color: Palette.black }
      else return { backgroundColor: Palette.white, color: Palette.border_grey }
    },
    onToggle () {
      console.log('tog')
      const sidebar = document.querySelector('.sidebar')
      sidebar?.classList.toggle('active')
    }
  },
  computed: {
    visible () {
      return this.$route.name === 'manage' || this.$route.name === 'manageqr' || this.$route.name === 'analytics'
    }
  }
})
</script>

<style scoped>

div.sidebar {
  top: 0;
  position: sticky;
  z-index: 2;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  padding-bottom: var(--ct-topbar-height);
  -webkit-transition: var(--ct-menu-transition);
  transition: var(--ct-menu-transition);
  background: var(--ct-menu-bg);
  -webkit-box-shadow: var(--ct-box-shadow);
          box-shadow: var(--ct-box-shadow);
  border-left: var(--ct-theme-border-width) solid var(--ct-border-color);
  overflow: hidden;
}

div.toggle-button-container {
  top: 10;
  display: none;
  width: 50px;
  margin: 1em;
  z-index: 3;
  position: fixed;
}

div.close-button-container {
    display: none;
    width: 50px;
    align-self: flex-end;
    margin: 1em;
}

@media screen and (min-width: 769px) {
    div.sidebar {
        width: 15vw;
        min-width: 220px;
    }
}

@media screen and (max-width: 768px) {
    div.sidebar {
        min-width: 0;
        width: 0
    }
    div.sidebar.active {
        width: 100vw;
        z-index: 100;
        position: fixed;
        top: 0;
        left: 0;
    }
    div.toggle-button-container {
        display: block;
    }

    div.close-button-container {
        display: block;
    }
}
div.logo-container {
    display: inline-block;
    width: 33%;
    margin-top: 60px;
}

img.logo {
    width: 60px;
    height: 60px;
}

h4.logo-text {
    color: var(--Nearly-Black, #212529);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin-top: 8px;
}

div.sidebar-menu {
    margin-top: 2.5rem;
    width: 100%;
    overflow:auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

</style>
