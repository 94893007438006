<template>
    <div class="editor-input-container" :style="cssVarsContainer">
        <p class="editor-input-label">{{ this.label }}</p>
        <DebouncedTextInput
            :value="this.internalValue"
            type="text"
            :multiline="this.multiline"
            :input-style="this.inputComponentStyle"
            @update:value="onChangeValue"
        />
        <slot/>
    </div>
  </template>

<script lang="ts">

import { defineComponent } from 'vue'
import DebouncedTextInput from './DebouncedTextInput.vue'

export default defineComponent({
  name: 'EditorInputComponent',
  components: { DebouncedTextInput },
  props: {
    label: {
      type: String,
      default: ''
    },
    multiline: {
      type: Boolean,
      default: false
    },
    inputComponentStyle: {
      type: Object,
      default: null
    },
    style: {
      type: Object,
      default: null
    },
    value: {
      type: String,
      default: 'some text'
    }
  },
  data () {
    return {
      internalValue: this.value,
      valid: true
    }
  },
  computed: {
    cssVarsContainer () {
      return {
        ...this.style
      }
    }
  },
  watch: {
    value (value) {
      this.internalValue = value
    }
  },
  methods: {
    onChangeValue (value: string) {
      this.valid = value.length > 0
      if (this.valid) {
        this.$emit('onChangeValue', value)
      }
    }
  }
})

</script>

<style scoped>
  .editor-input-container {
    width: 100%;
    height: 60px;
    border-radius: 8px;
    background: var(--Light-Grey, #F1F1F1);
    margin: 10px 0 0 0;
    padding: 0 10px 0 20px;
    display: flex;
    align-items: center;
  }
  .editor-input-label {
    text-align: left;
    width: 170px;
    margin: 0;
    color: var(--Nearly-Black, #212529);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    opacity: 0.5;
  }
</style>
